import React, { Children, useCallback } from 'react';
import DefaultNextLink, { LinkProps } from 'next/link';
import { Box, StyleProps } from 'deepstash-ui';
import useRouter from 'hooks/useRouter';
import useNavigation from 'src/providers/hooks/useNavigation';

type PrefetchType = 'visible' | 'hover';

const NextLink: React.FC<
  StyleProps &
    LinkProps & {
      /**
       * If true, the scroll of the page we are navigating to won't get dropped before navigation
       */
      keepScroll?: boolean;
      /**
       *  @default "hover"
       */
      prefetchType?: PrefetchType;
    }
> = ({
  passHref,
  href,
  as,
  replace,
  scroll,
  shallow,
  locale,
  children,
  keepScroll,
  prefetchType = 'hover',
  ...props
}) => {
  const { prefetch } = useRouter();
  const prefetchOnHover = useCallback(() => {
    prefetch(typeof href === 'string' ? href : href.toString());
  }, [prefetch, href]);
  const { currentPageScroll, updateScrollCache, invalidateScroll } =
    useNavigation();

  const saveScroll = () => {
    //We are navigating to url with push
    //Save the current scroll position
    //And invalidate the scroll for url
    updateScrollCache(window.location.pathname, currentPageScroll.current);

    if (!keepScroll) {
      invalidateScroll(href.toString());
    }
  };

  const childrenWithProps =
    React.isValidElement(children) && prefetchType === 'hover'
      ? React.cloneElement(children, {
          onMouseOver: prefetchOnHover,
          ...props,
        })
      : children;

  return (
    <Box onClick={saveScroll} display="contents">
      <DefaultNextLink
        href={href}
        passHref={passHref}
        as={as}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        locale={locale}
        {...(prefetchType === 'hover' && { prefetch: false })}
      >
        {Children.only(childrenWithProps)}
      </DefaultNextLink>
    </Box>
  );
};

export default NextLink;
