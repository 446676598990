import { getApiToken } from 'api/apiRequest';
import usePrevious from 'hooks/usePrevious';
import useRouter from 'hooks/useRouter';
import React, {
  createContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { useQueryClient } from 'react-query';
import {
  AuthContextType,
  AuthProviderReducer,
  AuthProviderStateType,
} from './AuthProviderTypes';
import authReducer from './AuthReducer';
import jsCookie from 'js-cookie';
import { DEFAULT_COOKIE_EXPIRATION_DATE } from 'utils/constants';

interface AuthProviderProps {
  token: string;
  cookieAgreement: boolean;
}

export const INITIAL_STATE: AuthProviderStateType = {
  hasAgreedCookies: false,
  isLoggedIn: false,
};

export const AuthContext = createContext({
  state: INITIAL_STATE,
  dispatch: () => {
    return;
  },
} as AuthContextType);

AuthContext.displayName = 'AuthContext';

const AuthProvider: React.FC<AuthProviderProps> = ({
  token,
  cookieAgreement,
  children,
}) => {
  const initialReducerState = useMemo(
    () =>
      ({
        hasAgreedCookies: cookieAgreement,
        isLoggedIn: !!token,
      } as AuthProviderStateType),
    [token, cookieAgreement],
  );
  const [state, dispatch] = useReducer<AuthProviderReducer>(
    authReducer,
    initialReducerState,
  );
  const prevIsLoggedIn = usePrevious(state.isLoggedIn);
  const router = useRouter();

  //The value being passed down
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  //Ensure that people that are already logged in have the cookie with the right attributes set
  useEffect(() => {
    if (!token) {
      return;
    }
    jsCookie.set('token', token, {
      expires: DEFAULT_COOKIE_EXPIRATION_DATE,
      sameSite: 'lax',
      secure:
        process.env.NODE_ENV === 'production' &&
        window.location.hostname !== 'localhost',
    });
    const refreshToken = jsCookie.get('refresh_token');
    if (refreshToken) {
      jsCookie.set('refresh_token', refreshToken, {
        expires: DEFAULT_COOKIE_EXPIRATION_DATE + 1,
        sameSite: 'lax',
        secure:
          process.env.NODE_ENV === 'production' &&
          window.location.hostname !== 'localhost',
      });
    }
  }, [token]);

  //Remember the last user token found in the cookies to see when a user logs out
  const lastToken = useRef<string | undefined>(undefined);

  // Watch for token cookie changes
  //If the user signs out from one tab we need to sign them out from all tabs
  useEffect(() => {
    const onFocus = () => {
      const currentToken = getApiToken({ allowDefault: true });
      if (!currentToken && lastToken.current) {
        lastToken.current = currentToken;
        router.reload();
      } else {
        lastToken.current = currentToken;
      }
    };
    const onBlur = () => {
      const currentToken = getApiToken({ allowDefault: true });
      lastToken.current = currentToken;
    };
    window.addEventListener('blur', onBlur);
    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('blur', onBlur);
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  const queryClient = useQueryClient();

  useEffect(() => {
    // When logging out
    if (!state.isLoggedIn && prevIsLoggedIn) {
      router.push('/');
      localStorage.removeItem('setupGoal');
      localStorage.removeItem('likedSourceIds');
      localStorage.removeItem('ideaDraft');
      localStorage.removeItem('ideaDraftSource');

      //Invalidate cache
      queryClient.clear();
    }
  }, [state.isLoggedIn]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
