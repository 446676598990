import React from 'react';
import {
  Flex,
  Modal,
  PrimaryButton,
  PrimaryHeading,
  PrimaryText,
  spacing,
} from 'deepstash-ui';
import pageStrings from 'utils/strings/pageStrings';
import { PAYWALL } from 'types';
import LabelButton from '../buttons/LabelButton';

interface InvalidPromoCodeModalProps {
  refetchPricesWithoutPromoAndClose: () => void;
  onClosePaywall: () => void;
  isOpen: boolean;
}

const InvalidPromoCodeModal: React.FC<InvalidPromoCodeModalProps> = ({
  refetchPricesWithoutPromoAndClose,
  onClosePaywall,
  isOpen,
}) => {
  const renderActions = () => {
    return (
      <Flex>
        <PrimaryButton
          mr={spacing.S.rem}
          onClick={refetchPricesWithoutPromoAndClose}
        >
          {pageStrings[PAYWALL].invalidPromoContinue}
        </PrimaryButton>
        <LabelButton onClick={onClosePaywall}>
          {pageStrings[PAYWALL].invalidPromoExit}
        </LabelButton>
      </Flex>
    );
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClosePaywall}
      closeButton="none"
      closeOnClickOutside={false}
      renderFooter={renderActions}
      headerTitle={
        <PrimaryHeading size={'h2'} textSize="m" type="semiBold">
          {pageStrings[PAYWALL].invalidPromoTitle}
        </PrimaryHeading>
      }
    >
      <PrimaryText size="l" mb={spacing.toRem(32)}>
        {pageStrings[PAYWALL].invalidPromoBody}
      </PrimaryText>
    </Modal>
  );
};

export default InvalidPromoCodeModal;
