import React, { createContext, useEffect, useState } from 'react';
import { Analytics } from 'services/analytics';
import { ExperimentProviderStateType } from './ExperimentProviderTypes';
import { useRouter } from 'next/router';
import jsCookie from 'js-cookie';
import { WEB_FUNNEL_CAMPAIGNS_FOR_EXPERIMENT } from 'utils/constants';

export const ExperimentContext = createContext({
  variants: {},
  isFetchingExperiments: true,
} as ExperimentProviderStateType);

ExperimentContext.displayName = 'ExperimentContext';

interface ExperimentProviderProps {
  children: React.ReactNode;
}

const ExperimentProvider: React.FC<ExperimentProviderProps> = ({
  children,
}) => {
  const [isFetchingExperiments, setIsFetchingExperiments] = useState(true);
  const [variants, setVariants] = useState<Record<string, string>>({});
  const router = useRouter();

  const utmCampaign =
    router?.query['utm_campaign'] ?? jsCookie.get('utm_campaign');

  const utmCampaignString = Array.isArray(utmCampaign)
    ? utmCampaign[0]
    : utmCampaign;

  const allCookies = jsCookie.get() || {};

  const testABExperiments: Record<string, string> = Object.keys(
    allCookies,
  ).reduce(
    (acc, cookieName) => ({
      ...acc,
      ...(cookieName.startsWith('testAB_')
        ? { [cookieName.replace('testAB_', '')]: allCookies[cookieName] }
        : {}),
    }),
    {},
  );

  const shouldEngageInExperiments =
    WEB_FUNNEL_CAMPAIGNS_FOR_EXPERIMENT.includes(utmCampaignString ?? '') ||
    Object?.keys(testABExperiments).length !== 0;

  useEffect(() => {
    if (!shouldEngageInExperiments) {
      setIsFetchingExperiments(false);
      return;
    }
    const asyncFetchExperiments = async () => {
      Analytics.initAmplitude();
      const experiments = await Analytics.initializeExperiments();
      setIsFetchingExperiments(false);
      return experiments;
    };
    asyncFetchExperiments()
      .then(response => {
        if (response) {
          setVariants({
            ...response,
            ...testABExperiments,
          });
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, [shouldEngageInExperiments]);

  return (
    <ExperimentContext.Provider value={{ variants, isFetchingExperiments }}>
      {children}
    </ExperimentContext.Provider>
  );
};

export default ExperimentProvider;
