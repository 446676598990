import {
  Flex,
  spacing,
  PrimaryText,
  color,
  useColorMode,
  StyleProps,
  TextInputProps,
  IconProps,
} from 'deepstash-ui';
import React from 'react';
import { authType } from 'src/providers/nsi/NsiProviderTypes';
import pageStrings from 'utils/strings/pageStrings';
import AuthTextInput from './AuthTextInput';

interface AuthEmailTextInputProps {
  onEnterPressed: () => void;
  emailError?: string;
  email: string;
  onChange?: (val: string) => void;
  authModalType?: authType;
  hideLabel?: boolean;
  textInputProps?: TextInputProps;
  StartIcon?: React.NamedExoticComponent<IconProps>;
  startIconSize?: string;
}

const AuthEmailTextInput: React.FC<AuthEmailTextInputProps & StyleProps> = ({
  onEnterPressed,
  emailError,
  email,
  onChange,
  authModalType,
  hideLabel,
  textInputProps,
  StartIcon,
  startIconSize = '1rem',
  ...props
}) => {
  const { colorMode } = useColorMode();

  const label =
    authModalType === 'sign-in'
      ? pageStrings.authentication.emailOrUsername
      : pageStrings.authentication.email;

  const isInvalid = (emailError?.length ?? 0) > 0;
  const startIconPadding = '10px';
  return (
    <Flex flexDir={'column'} mt={spacing.M.rem} width="100%" {...props}>
      {!hideLabel && (
        <PrimaryText
          size="s"
          type="bold"
          ml={spacing.XXS.rem}
          color={color[colorMode].textSecondary}
        >
          {pageStrings.authentication.email}
        </PrimaryText>
      )}
      <Flex position={'relative'}>
        <AuthTextInput
          value={email}
          onChange={onChange}
          placeholder={label}
          type="email"
          isInvalid={isInvalid}
          errorText={emailError}
          onEnterPressed={onEnterPressed}
          px={
            StartIcon
              ? `calc(${startIconPadding} + ${spacing.M.rem} + 4px)`
              : undefined
          }
          autoCapitalize={'off'}
          {...textInputProps}
        />
        {StartIcon && (
          <Flex
            position={'absolute'}
            top={`calc(50% - ${
              isInvalid ? spacing.XS.rem : spacing.M.rem
            } / 2)`}
            left={startIconPadding}
            transform={'translateY(-50%)'}
          >
            <StartIcon
              width={startIconSize}
              height={startIconSize}
              color={color[colorMode].text}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default AuthEmailTextInput;
