import * as React from 'react';
import { Icon, IconProps } from 'deepstash-ui';

export const MoneyIcon = React.memo<IconProps>(props => {
  return (
    <Icon width="48px" height="48px" viewBox="0 0 48 48" fill="none" {...props}>
      <path
        d="M10.2796 9.93414C10.2796 9.93414 7.2916 9.24725 8.1512 7.96668C9.0108 6.68612 19.4732 7.5359 22.0628 9.31692C22.7497 9.7899 22.259 18.4752 21.105 19.0698C20.2278 19.5222 18.4183 24.041 17.2575 21.7929C15.5598 18.5036 14.6531 18.4879 13.9054 18.5252C10.1726 18.7117 10.2796 9.93414 10.2796 9.93414Z"
        fill="url(#paint0_linear_210_88312)"
      />
      <path
        d="M9.83436 10.3932C9.19261 10.2264 6.30176 9.02529 7.01319 7.65837C7.72462 6.29145 13.0863 7.38263 15.8555 8.87025C18.6246 10.3579 11.7017 10.8799 9.83436 10.3932Z"
        fill="url(#paint1_radial_210_88312)"
      />
      <path
        d="M10.2149 13.0231C9.55256 12.9848 6.48018 12.3745 6.91292 10.8947C7.34566 9.41496 12.8143 9.4336 15.821 10.3501C18.8276 11.2666 12.1421 13.135 10.2149 13.0231Z"
        fill="url(#paint2_radial_210_88312)"
      />
      <path
        d="M10.2149 13.0231C9.55256 12.9848 6.48018 12.3745 6.91292 10.8947C7.34566 9.41496 12.8143 9.4336 15.821 10.3501C18.8276 11.2666 12.1421 13.135 10.2149 13.0231Z"
        fill="url(#paint3_radial_210_88312)"
      />
      <path
        d="M10.7173 15.382C10.0559 15.431 6.92854 15.2338 7.16209 13.7138C7.39563 12.1938 12.8182 11.4843 15.92 11.9936C19.0218 12.5029 12.6425 15.2367 10.7173 15.382Z"
        fill="url(#paint4_radial_210_88312)"
      />
      <path
        d="M10.7173 15.382C10.0559 15.431 6.92854 15.2338 7.16209 13.7138C7.39563 12.1938 12.8182 11.4843 15.92 11.9936C19.0218 12.5029 12.6425 15.2367 10.7173 15.382Z"
        fill="url(#paint5_radial_210_88312)"
      />
      <path
        d="M11.9722 17.2474C11.3343 17.4299 8.23252 17.8676 8.15205 16.328C8.07159 14.7883 13.241 13.0004 16.381 12.8729C19.5211 12.7453 13.8278 16.7165 11.9722 17.2474Z"
        fill="url(#paint6_radial_210_88312)"
      />
      <path
        d="M11.9722 17.2474C11.3343 17.4299 8.23252 17.8676 8.15205 16.328C8.07159 14.7883 13.241 13.0004 16.381 12.8729C19.5211 12.7453 13.8278 16.7165 11.9722 17.2474Z"
        fill="url(#paint7_radial_210_88312)"
      />
      <path
        d="M13.1048 18.9886C12.4964 19.2536 9.47409 20.0955 9.19639 18.5784C8.91869 17.0614 13.8084 14.6121 16.9014 14.0744C19.9944 13.5366 14.8741 18.2193 13.1048 18.9886Z"
        fill="url(#paint8_radial_210_88312)"
      />
      <path
        d="M13.1048 18.9886C12.4964 19.2536 9.47409 20.0955 9.19639 18.5784C8.91869 17.0614 13.8084 14.6121 16.9014 14.0744C19.9944 13.5366 14.8741 18.2193 13.1048 18.9886Z"
        fill="url(#paint9_radial_210_88312)"
      />
      <path
        d="M14.9534 20.6909C14.4265 21.0933 11.6976 22.6339 11.0617 21.2287C10.4258 19.8235 14.5864 16.2742 17.4635 15.0103C20.3407 13.7465 16.4833 19.5203 14.9534 20.6909Z"
        fill="url(#paint10_radial_210_88312)"
      />
      <path
        d="M14.9534 20.6909C14.4265 21.0933 11.6976 22.6339 11.0617 21.2287C10.4258 19.8235 14.5864 16.2742 17.4635 15.0103C20.3407 13.7465 16.4833 19.5203 14.9534 20.6909Z"
        fill="url(#paint11_radial_210_88312)"
      />
      <path
        d="M17.3263 21.064C16.7983 21.4663 14.0694 23.0059 13.4335 21.6007C12.7977 20.1956 16.9583 16.6473 19.8354 15.3824C22.7125 14.1175 18.86 19.8923 17.3263 21.064Z"
        fill="url(#paint12_radial_210_88312)"
      />
      <path
        d="M17.3263 21.064C16.7983 21.4663 14.0694 23.0059 13.4335 21.6007C12.7977 20.1956 16.9583 16.6473 19.8354 15.3824C22.7125 14.1175 18.86 19.8923 17.3263 21.064Z"
        fill="url(#paint13_radial_210_88312)"
      />
      <path
        d="M13.0353 9.75793C12.3935 9.59013 9.50269 8.39003 10.2141 7.02311C10.9255 5.65619 16.2882 6.74639 19.0564 8.23401C21.8246 9.72162 14.9036 10.2446 13.0353 9.75793Z"
        fill="url(#paint14_radial_210_88312)"
      />
      <path
        d="M13.0353 9.75793C12.3935 9.59013 9.50269 8.39003 10.2141 7.02311C10.9255 5.65619 16.2882 6.74639 19.0564 8.23401C21.8246 9.72162 14.9036 10.2446 13.0353 9.75793Z"
        fill="url(#paint15_radial_210_88312)"
      />
      <path
        d="M13.9587 12.0686C13.2973 12.0166 10.2367 11.3445 10.6998 9.87254C11.163 8.40063 16.6307 8.5331 19.6177 9.51045C22.6047 10.4878 15.8829 12.2247 13.9587 12.0686Z"
        fill="url(#paint16_radial_210_88312)"
      />
      <path
        d="M13.9587 12.0686C13.2973 12.0166 10.2367 11.3445 10.6998 9.87254C11.163 8.40063 16.6307 8.5331 19.6177 9.51045C22.6047 10.4878 15.8829 12.2247 13.9587 12.0686Z"
        fill="url(#paint17_radial_210_88312)"
      />
      <path
        d="M15.3324 15.0703C14.6809 15.1939 11.5516 15.3499 11.6114 13.8084C11.6713 12.2668 16.98 10.9548 20.1191 11.1128C23.2582 11.2708 17.2283 14.7121 15.3324 15.0703Z"
        fill="url(#paint18_radial_210_88312)"
      />
      <path
        d="M15.3324 15.0703C14.6809 15.1939 11.5516 15.3499 11.6114 13.8084C11.6713 12.2668 16.98 10.9548 20.1191 11.1128C23.2582 11.2708 17.2283 14.7121 15.3324 15.0703Z"
        fill="url(#paint19_radial_210_88312)"
      />
      <path
        d="M16.6806 17.6058C16.0732 17.8717 13.0567 18.7215 12.7722 17.2064C12.4876 15.6914 17.3694 13.2274 20.4644 12.6808C23.5593 12.1342 18.4488 16.8365 16.6806 17.6058Z"
        fill="url(#paint20_radial_210_88312)"
      />
      <path
        d="M16.6806 17.6058C16.0732 17.8717 13.0567 18.7215 12.7722 17.2064C12.4876 15.6914 17.3694 13.2274 20.4644 12.6808C23.5593 12.1342 18.4488 16.8365 16.6806 17.6058Z"
        fill="url(#paint21_radial_210_88312)"
      />
      <path
        d="M18.6157 19.3788C18.1977 19.787 15.9967 21.4335 15.3432 20.3129C14.6896 19.1923 17.9514 15.6558 20.3025 14.229C22.6537 12.8022 19.8296 18.1924 18.6157 19.3788Z"
        fill="url(#paint22_radial_210_88312)"
      />
      <path
        d="M18.6157 19.3788C18.1977 19.787 15.9967 21.4335 15.3432 20.3129C14.6896 19.1923 17.9514 15.6558 20.3025 14.229C22.6537 12.8022 19.8296 18.1924 18.6157 19.3788Z"
        fill="url(#paint23_radial_210_88312)"
      />
      <path
        d="M17.1652 9.65074C16.7403 9.51729 14.8249 8.56054 15.2949 7.47721C15.7649 6.39388 19.3103 7.2682 21.1433 8.4526C22.9763 9.637 18.4006 10.0403 17.1652 9.65074Z"
        fill="url(#paint24_radial_210_88312)"
      />
      <path
        d="M17.1652 9.65074C16.7403 9.51729 14.8249 8.56054 15.2949 7.47721C15.7649 6.39388 19.3103 7.2682 21.1433 8.4526C22.9763 9.637 18.4006 10.0403 17.1652 9.65074Z"
        fill="url(#paint25_radial_210_88312)"
      />
      <path
        d="M17.7797 11.4857C17.3421 11.4435 15.3177 10.9038 15.6209 9.73805C15.9241 8.57229 19.5391 8.68514 21.5154 9.46623C23.4917 10.2473 19.0514 11.6084 17.7797 11.4857Z"
        fill="url(#paint26_radial_210_88312)"
      />
      <path
        d="M17.7797 11.4857C17.3421 11.4435 15.3177 10.9038 15.6209 9.73805C15.9241 8.57229 19.5391 8.68514 21.5154 9.46623C23.4917 10.2473 19.0514 11.6084 17.7797 11.4857Z"
        fill="url(#paint27_radial_210_88312)"
      />
      <path
        d="M18.6932 13.8702C18.2625 13.9683 16.1929 14.0851 16.2302 12.8634C16.2675 11.6417 19.7756 10.6064 21.851 10.7399C23.9264 10.8733 19.9453 13.5876 18.6932 13.8702Z"
        fill="url(#paint28_radial_210_88312)"
      />
      <path
        d="M18.6932 13.8702C18.2625 13.9683 16.1929 14.0851 16.2302 12.8634C16.2675 11.6417 19.7756 10.6064 21.851 10.7399C23.9264 10.8733 19.9453 13.5876 18.6932 13.8702Z"
        fill="url(#paint29_radial_210_88312)"
      />
      <path
        d="M19.5882 15.8818C19.1868 16.0918 17.1949 16.76 17.0035 15.5579C16.8122 14.3559 20.0366 12.41 22.0816 11.9822C21.902 12.5042 20.7559 15.2665 19.5882 15.8818Z"
        fill="url(#paint30_radial_210_88312)"
      />
      <path
        d="M19.5882 15.8818C19.1868 16.0918 17.1949 16.76 17.0035 15.5579C16.8122 14.3559 20.0366 12.41 22.0816 11.9822C21.902 12.5042 20.7559 15.2665 19.5882 15.8818Z"
        fill="url(#paint31_radial_210_88312)"
      />
      <path
        d="M20.8706 17.2911C20.5959 17.6139 19.1426 18.9161 18.7118 18.0261C18.281 17.1361 20.4251 14.3375 21.9775 13.2061C22.741 13.3209 21.6714 16.352 20.8706 17.2911Z"
        fill="url(#paint32_radial_210_88312)"
      />
      <path
        d="M20.8706 17.2911C20.5959 17.6139 19.1426 18.9161 18.7118 18.0261C18.281 17.1361 20.4251 14.3375 21.9775 13.2061C22.741 13.3209 21.6714 16.352 20.8706 17.2911Z"
        fill="url(#paint33_radial_210_88312)"
      />
      <path
        d="M20.6055 9.90569C20.4201 9.80756 19.6105 9.22665 19.9088 8.76348C20.2071 8.30032 22.4248 8.61237 22.4445 9.74476C22.4592 10.5484 21.1452 10.1785 20.6055 9.90569Z"
        fill="url(#paint34_radial_210_88312)"
      />
      <path
        d="M20.6055 9.90569C20.4201 9.80756 19.6105 9.22665 19.9088 8.76348C20.2071 8.30032 22.4248 8.61237 22.4445 9.74476C22.4592 10.5484 21.1452 10.1785 20.6055 9.90569Z"
        fill="url(#paint35_radial_210_88312)"
      />
      <path
        d="M20.8214 11.0763C20.6251 11.0272 19.6988 10.6406 19.8843 10.1225C20.0697 9.60437 22.3012 9.40419 22.5759 10.4993C22.7731 11.2833 21.4092 11.2196 20.8214 11.0763Z"
        fill="url(#paint36_radial_210_88312)"
      />
      <path
        d="M20.8214 11.0763C20.6251 11.0272 19.6988 10.6406 19.8843 10.1225C20.0697 9.60437 22.3012 9.40419 22.5759 10.4993C22.7731 11.2833 21.4092 11.2196 20.8214 11.0763Z"
        fill="url(#paint37_radial_210_88312)"
      />
      <path
        d="M21.053 12.185C20.845 12.185 19.8598 12.0221 19.9196 11.4745C19.9795 10.927 22.1029 10.2156 22.625 11.2165C22.9998 11.9299 21.6565 12.1889 21.053 12.185Z"
        fill="url(#paint38_radial_210_88312)"
      />
      <path
        d="M21.053 12.185C20.845 12.185 19.8598 12.0221 19.9196 11.4745C19.9795 10.927 22.1029 10.2156 22.625 11.2165C22.9998 11.9299 21.6565 12.1889 21.053 12.185Z"
        fill="url(#paint39_radial_210_88312)"
      />
      <path
        d="M21.1267 13.1868C20.9187 13.1868 19.9335 13.0239 19.9933 12.4763C20.0532 11.9288 22.6349 11.0917 22.6987 12.2182C22.7429 13.0023 21.7302 13.1907 21.1267 13.1868Z"
        fill="url(#paint40_radial_210_88312)"
      />
      <path
        d="M21.1267 13.1868C20.9187 13.1868 19.9335 13.0239 19.9933 12.4763C20.0532 11.9288 22.6349 11.0917 22.6987 12.2182C22.7429 13.0023 21.7302 13.1907 21.1267 13.1868Z"
        fill="url(#paint41_radial_210_88312)"
      />
      <path
        d="M21.276 14.4097C21.071 14.443 20.072 14.4489 20.0396 13.8994C20.0072 13.3499 22.4104 12.0899 22.6626 13.1899C22.8382 13.9553 21.8717 14.3106 21.276 14.4097Z"
        fill="url(#paint42_radial_210_88312)"
      />
      <path
        d="M21.276 14.4097C21.071 14.443 20.072 14.4489 20.0396 13.8994C20.0072 13.3499 22.4104 12.0899 22.6626 13.1899C22.8382 13.9553 21.8717 14.3106 21.276 14.4097Z"
        fill="url(#paint43_radial_210_88312)"
      />
      <path
        d="M21.6587 15.7768C21.4792 15.8809 20.5479 16.242 20.3222 15.7405C20.0965 15.2391 21.8923 13.2059 22.5203 14.144C22.957 14.7965 22.1808 15.4726 21.6587 15.7768Z"
        fill="url(#paint44_radial_210_88312)"
      />
      <path
        d="M21.6587 15.7768C21.4792 15.8809 20.5479 16.242 20.3222 15.7405C20.0965 15.2391 21.8923 13.2059 22.5203 14.144C22.957 14.7965 22.1808 15.4726 21.6587 15.7768Z"
        fill="url(#paint45_radial_210_88312)"
      />
      <path
        d="M21.9592 16.242C21.8031 16.4382 20.919 17.2233 20.4588 16.7572C19.9986 16.291 21.1506 13.2893 22.2378 14.0704C22.9934 14.614 22.4115 15.6876 21.9592 16.242Z"
        fill="url(#paint46_radial_210_88312)"
      />
      <path
        d="M21.9592 16.242C21.8031 16.4382 20.919 17.2233 20.4588 16.7572C19.9986 16.291 21.1506 13.2893 22.2378 14.0704C22.9934 14.614 22.4115 15.6876 21.9592 16.242Z"
        fill="url(#paint47_radial_210_88312)"
      />
      <path
        d="M15.0233 42.7825L7.12109 31.5449V27.6198C10.0374 24.3394 32.5813 21.7105 40.0567 16.4548V20.3799C35.8316 23.2274 32.0174 26.6414 28.721 30.5263C24.718 35.2003 20.1118 39.3217 15.0233 42.7825Z"
        fill="#AA6916"
      />
      <path
        d="M15.0233 38.8574L7.12109 27.6198C11.3462 24.7724 15.1604 21.3584 18.4568 17.4734C22.4598 12.7995 27.066 8.67806 32.1545 5.21729L40.0567 16.4549C35.8316 19.3023 32.0174 22.7163 28.721 26.6013C24.718 31.2752 20.1118 35.3966 15.0233 38.8574Z"
        fill="#FBCA8D"
      />
      <path
        d="M15.3748 36.7995L9.16724 27.968L9.55386 27.6835C13.2553 25.029 16.6147 21.9276 19.556 18.4497C23.0526 14.3611 27.0289 10.7087 31.3991 7.57124L31.8033 7.27686L38.0109 16.1083L37.6243 16.3929C33.9229 19.0474 30.5634 22.1488 27.6221 25.6267C24.1254 29.7146 20.1491 33.3663 15.7791 36.5032L15.3748 36.7995ZM10.5224 28.1908L15.6015 35.415C19.7576 32.387 23.545 28.8829 26.8862 24.9742C29.7686 21.5649 33.0488 18.513 36.6567 15.8836L31.5767 8.65947C27.4205 11.6874 23.6332 15.1915 20.292 19.1003C17.4099 22.5094 14.13 25.5613 10.5224 28.1908Z"
        fill="#E69C3E"
      />
      <path
        d="M15.2587 27.0309L14.6218 26.2832C16.8367 24.3675 18.9033 22.2868 20.8039 20.0589C22.995 17.5104 25.3695 15.1254 27.9083 12.9231L28.5491 13.6669C26.0438 15.8364 23.7026 18.1884 21.5447 20.7036C19.612 22.9683 17.5106 25.0834 15.2587 27.0309Z"
        fill="#E1AD6B"
      />
      <path
        d="M24.2365 27.8639C26.44 27.8639 28.2263 25.8456 28.2263 23.3559C28.2263 20.8662 26.44 18.8479 24.2365 18.8479C22.0329 18.8479 20.2466 20.8662 20.2466 23.3559C20.2466 25.8456 22.0329 27.8639 24.2365 27.8639Z"
        fill="#E69C3E"
      />
      <path
        d="M22.4381 30.1351C22.2373 30.1351 22.0414 30.0736 21.8767 29.9588C21.712 29.844 21.5865 29.6815 21.5171 29.4931C21.4477 29.3048 21.4377 29.0997 21.4885 28.9054C21.5393 28.7112 21.6484 28.5373 21.8012 28.407C22.9468 27.4153 24.0156 26.338 24.9982 25.1845L25.3956 24.7351C26.3444 23.6188 27.381 22.5803 28.4955 21.6294C28.6946 21.4765 28.9448 21.4058 29.1945 21.4318C29.4442 21.4578 29.6744 21.5785 29.8379 21.769C30.0013 21.9596 30.0854 22.2056 30.073 22.4563C30.0606 22.707 29.9526 22.9435 29.7711 23.117C28.3728 24.321 22.6647 30.1351 22.4381 30.1351Z"
        fill="#E69C3E"
      />
      <path
        d="M17.0251 19.0689L25.0814 30.5243L25.0981 30.5135V34.471C26.7456 32.8738 28.3024 31.1856 29.7611 29.4145V25.4383L21.7628 13.8828C20.0772 15.5112 18.4949 17.2433 17.0251 19.0689Z"
        fill="url(#paint48_linear_210_88312)"
      />
      <path
        d="M15.7082 35.7544C16.6621 35.7544 17.4353 34.9812 17.4353 34.0273C17.4353 33.0735 16.6621 32.3003 15.7082 32.3003C14.7544 32.3003 13.9812 33.0735 13.9812 34.0273C13.9812 34.9812 14.7544 35.7544 15.7082 35.7544Z"
        fill="#E69C3E"
      />
      <path
        d="M11.7517 30.1123C12.7055 30.1123 13.4788 29.3391 13.4788 28.3852C13.4788 27.4314 12.7055 26.6582 11.7517 26.6582C10.7979 26.6582 10.0247 27.4314 10.0247 28.3852C10.0247 29.3391 10.7979 30.1123 11.7517 30.1123Z"
        fill="#E69C3E"
      />
      <path
        d="M35.1492 17.478C36.103 17.478 36.8762 16.7048 36.8762 15.751C36.8762 14.7972 36.103 14.0239 35.1492 14.0239C34.1953 14.0239 33.4221 14.7972 33.4221 15.751C33.4221 16.7048 34.1953 17.478 35.1492 17.478Z"
        fill="#E69C3E"
      />
      <path
        d="M31.1938 11.8354C32.1477 11.8354 32.9209 11.0622 32.9209 10.1084C32.9209 9.15457 32.1477 8.38135 31.1938 8.38135C30.24 8.38135 29.4668 9.15457 29.4668 10.1084C29.4668 11.0622 30.24 11.8354 31.1938 11.8354Z"
        fill="#E69C3E"
      />
      <path
        opacity="0.3"
        d="M28.7808 18.366C29.6837 18.366 30.4156 17.6341 30.4156 16.7312C30.4156 15.8284 29.6837 15.0964 28.7808 15.0964C27.8779 15.0964 27.146 15.8284 27.146 16.7312C27.146 17.6341 27.8779 18.366 28.7808 18.366Z"
        fill="#A5681C"
      />
      <path
        d="M17.7013 27.7574C17.8255 27.7574 17.947 27.7942 18.0502 27.8632C18.1535 27.9322 18.234 28.0303 18.2815 28.1451C18.3291 28.2598 18.3415 28.3861 18.3173 28.5079C18.2931 28.6298 18.2332 28.7417 18.1454 28.8295C18.0576 28.9173 17.9457 28.9771 17.8239 29.0014C17.702 29.0256 17.5758 29.0132 17.461 28.9656C17.3462 28.9181 17.2482 28.8376 17.1792 28.7343C17.1102 28.631 17.0733 28.5096 17.0733 28.3854C17.0736 28.2189 17.1398 28.0593 17.2575 27.9416C17.3753 27.8239 17.5349 27.7577 17.7013 27.7574ZM17.7013 26.7761C17.383 26.7761 17.0719 26.8705 16.8073 27.0473C16.5426 27.2242 16.3363 27.4755 16.2145 27.7696C16.0927 28.0636 16.0609 28.3872 16.123 28.6994C16.1851 29.0115 16.3383 29.2983 16.5634 29.5234C16.7885 29.7484 17.0752 29.9017 17.3874 29.9638C17.6996 30.0259 18.0231 29.994 18.3172 29.8722C18.6112 29.7504 18.8626 29.5441 19.0394 29.2795C19.2162 29.0148 19.3106 28.7037 19.3106 28.3854C19.3106 28.1741 19.269 27.9648 19.1881 27.7696C19.1073 27.5743 18.9887 27.3969 18.8393 27.2475C18.6898 27.098 18.5124 26.9795 18.3172 26.8986C18.1219 26.8177 17.9127 26.7761 17.7013 26.7761Z"
        fill="#E69C3E"
      />
      <path
        d="M24.6486 22.5658C24.5352 22.403 24.3626 22.291 24.1678 22.2537C23.9189 22.2323 23.6683 22.2658 23.4338 22.3519C23.1637 22.4372 22.8892 22.5076 22.6115 22.5628C22.3877 22.6076 22.1589 22.6212 21.9314 22.6031C21.7218 22.5868 21.5178 22.5277 21.3319 22.4294C21.1232 22.3111 20.9416 22.1503 20.799 21.9574C20.6633 21.7954 20.5621 21.6074 20.5017 21.4049C20.4413 21.2024 20.4229 20.9897 20.4478 20.7799C20.512 20.3376 20.7311 19.9326 21.066 19.6367L20.5213 18.937L21.0454 18.5298L21.5968 19.2402C21.9791 19.0021 22.4391 18.9222 22.8794 19.0175C23.3378 19.138 23.7393 19.4159 24.0137 19.8025L23.0678 20.5385C22.9218 20.3174 22.7031 20.1542 22.4496 20.0773C22.3471 20.0535 22.2405 20.054 22.1383 20.0787C22.0361 20.1034 21.9411 20.1516 21.8608 20.2195C21.7817 20.2744 21.7147 20.345 21.664 20.4268C21.6134 20.5087 21.5801 20.6002 21.5664 20.6955C21.5595 20.7919 21.5718 20.8887 21.6026 20.9804C21.6335 21.072 21.6822 21.1566 21.746 21.2293C21.8534 21.3817 22.0167 21.4854 22.2003 21.5178C22.463 21.5314 22.7258 21.4944 22.9745 21.4088C23.2614 21.3236 23.553 21.2555 23.8479 21.2047C24.0693 21.1673 24.295 21.1616 24.5181 21.1881C24.721 21.2144 24.9165 21.2811 25.0931 21.3843C25.2867 21.5006 25.4557 21.6536 25.5906 21.8347C25.7273 21.9962 25.8293 22.1841 25.8904 22.3866C25.9515 22.5892 25.9704 22.8021 25.9458 23.0123C25.8753 23.4594 25.6499 23.8675 25.309 24.1653L25.8144 24.8158L25.2943 25.2201L24.7909 24.5735C24.3793 24.8566 23.8763 24.9746 23.3818 24.9042C22.8948 24.8062 22.4638 24.5252 22.1777 24.1191L23.1227 23.3842C23.2754 23.6107 23.507 23.7723 23.7723 23.8375C23.8959 23.8563 24.0221 23.8476 24.142 23.8122C24.2619 23.7767 24.3725 23.7154 24.4661 23.6324C24.6389 23.5129 24.7594 23.3317 24.8027 23.1261C24.8207 23.0271 24.8163 22.9253 24.7896 22.8283C24.7629 22.7313 24.7147 22.6416 24.6486 22.5658Z"
        fill="#B77B21"
      />
      <path
        d="M37.8045 39.1485C37.8045 39.1485 38.7632 41.2533 40.0418 40.3898C41.3204 39.5262 39.8867 29.1669 38.0586 26.6372C37.5739 25.966 28.8297 26.5626 28.2458 27.7038C27.8013 28.5752 23.8301 30.7389 26.1067 31.8546C29.443 33.4855 28.9161 34.0566 28.8876 34.7936C28.7483 38.4753 37.8045 39.1485 37.8045 39.1485Z"
        fill="url(#paint49_linear_210_88312)"
      />
      <path
        d="M37.8045 39.1485C37.8045 39.1485 38.7632 41.2533 40.0418 40.3898C41.3204 39.5262 39.8867 29.1669 38.0586 26.6372C37.5739 25.966 28.8297 26.5626 28.2458 27.7038C27.8013 28.5752 23.8301 30.7389 26.1067 31.8546C29.443 33.4855 28.9161 34.0566 28.8876 34.7936C28.7483 38.4753 37.8045 39.1485 37.8045 39.1485Z"
        fill="url(#paint50_radial_210_88312)"
      />
      <path
        d="M37.1307 38.7031C37.3073 39.334 38.5545 42.1709 39.9215 41.4506C41.2884 40.7304 40.1177 35.4649 38.5869 32.7506C37.0561 30.0364 36.6185 36.8691 37.1307 38.7031Z"
        fill="url(#paint51_radial_210_88312)"
      />
      <path
        d="M34.4809 38.3606C34.528 39.0131 35.1835 42.0345 36.6691 41.589C38.1548 41.1435 38.0635 35.7533 37.1019 32.8016C36.1402 29.85 34.3406 36.4618 34.4809 38.3606Z"
        fill="url(#paint52_radial_210_88312)"
      />
      <path
        d="M34.4809 38.3606C34.528 39.0131 35.1835 42.0345 36.6691 41.589C38.1548 41.1435 38.0635 35.7533 37.1019 32.8016C36.1402 29.85 34.3406 36.4618 34.4809 38.3606Z"
        fill="url(#paint53_radial_210_88312)"
      />
      <path
        d="M32.0955 37.8958C32.0543 38.5484 32.2918 41.6247 33.8275 41.3793C35.3632 41.134 36 35.7763 35.4466 32.7255C34.8932 29.6747 32.2172 35.997 32.0955 37.8958Z"
        fill="url(#paint54_radial_210_88312)"
      />
      <path
        d="M32.0955 37.8958C32.0543 38.5484 32.2918 41.6247 33.8275 41.3793C35.3632 41.134 36 35.7763 35.4466 32.7255C34.8932 29.6747 32.2172 35.997 32.0955 37.8958Z"
        fill="url(#paint55_radial_210_88312)"
      />
      <path
        d="M30.2009 36.683C30.0253 37.3139 29.6239 40.3775 31.1763 40.4363C32.7287 40.4952 34.4626 35.3788 34.5509 32.2819C34.6393 29.185 30.7112 34.847 30.2009 36.683Z"
        fill="url(#paint56_radial_210_88312)"
      />
      <path
        d="M30.2009 36.683C30.0253 37.3139 29.6239 40.3775 31.1763 40.4363C32.7287 40.4952 34.4626 35.3788 34.5509 32.2819C34.6393 29.185 30.7112 34.847 30.2009 36.683Z"
        fill="url(#paint57_radial_210_88312)"
      />
      <path
        d="M30.2009 36.683C30.0253 37.3139 29.6239 40.3775 31.1763 40.4363C32.7287 40.4952 34.4626 35.3788 34.5509 32.2819C34.6393 29.185 30.7112 34.847 30.2009 36.683Z"
        fill="url(#paint58_radial_210_88312)"
      />
      <path
        d="M28.4314 35.5888C28.1733 36.1923 27.3638 39.1774 28.8956 39.4354C30.4273 39.6935 32.8315 34.8431 33.3339 31.7815C33.8363 28.7199 29.1821 33.8353 28.4314 35.5888Z"
        fill="url(#paint59_radial_210_88312)"
      />
      <path
        d="M28.4314 35.5888C28.1733 36.1923 27.3638 39.1774 28.8956 39.4354C30.4273 39.6935 32.8315 34.8431 33.3339 31.7815C33.8363 28.7199 29.1821 33.8353 28.4314 35.5888Z"
        fill="url(#paint60_radial_210_88312)"
      />
      <path
        d="M26.6926 33.7928C26.2942 34.3177 24.7782 37.031 26.202 37.6354C27.6258 38.2399 31.1467 34.1028 32.384 31.2473C33.6214 28.3918 27.8525 32.2659 26.6926 33.7928Z"
        fill="url(#paint61_radial_210_88312)"
      />
      <path
        d="M26.6926 33.7928C26.2942 34.3177 24.7782 37.031 26.202 37.6354C27.6258 38.2399 31.1467 34.1028 32.384 31.2473C33.6214 28.3918 27.8525 32.2659 26.6926 33.7928Z"
        fill="url(#paint62_radial_210_88312)"
      />
      <path
        d="M25.8584 31.3455C25.46 31.8705 23.9439 34.5837 25.3678 35.1892C26.7916 35.7946 30.3134 31.6517 31.5498 28.8001C32.7862 25.9485 27.0232 29.8187 25.8584 31.3455Z"
        fill="url(#paint63_radial_210_88312)"
      />
      <path
        d="M25.8584 31.3455C25.46 31.8705 23.9439 34.5837 25.3678 35.1892C26.7916 35.7946 30.3134 31.6517 31.5498 28.8001C32.7862 25.9485 27.0232 29.8187 25.8584 31.3455Z"
        fill="url(#paint64_radial_210_88312)"
      />
      <path
        d="M37.7322 35.5405C37.9098 36.1705 39.156 39.0084 40.5239 38.2881C41.8918 37.5678 40.7202 32.3023 39.1894 29.5881C37.6586 26.8739 37.218 33.7055 37.7322 35.5405Z"
        fill="url(#paint65_radial_210_88312)"
      />
      <path
        d="M37.7322 35.5405C37.9098 36.1705 39.156 39.0084 40.5239 38.2881C41.8918 37.5678 40.7202 32.3023 39.1894 29.5881C37.6586 26.8739 37.218 33.7055 37.7322 35.5405Z"
        fill="url(#paint66_radial_210_88312)"
      />
      <path
        d="M35.3917 34.6594C35.4526 35.311 36.1709 38.3186 37.6487 37.8437C39.1265 37.3687 38.9243 31.9815 37.8979 29.0495C36.8715 26.1174 35.2151 32.7656 35.3917 34.6594Z"
        fill="url(#paint67_radial_210_88312)"
      />
      <path
        d="M35.3917 34.6594C35.4526 35.311 36.1709 38.3186 37.6487 37.8437C39.1265 37.3687 38.9243 31.9815 37.8979 29.0495C36.8715 26.1174 35.2151 32.7656 35.3917 34.6594Z"
        fill="url(#paint68_radial_210_88312)"
      />
      <path
        d="M32.3475 33.3432C32.2307 33.9879 32.1149 37.072 33.6663 36.9955C35.2177 36.919 36.4718 31.6672 36.2726 28.5752C36.0734 25.4832 32.686 31.4699 32.3475 33.3432Z"
        fill="url(#paint69_radial_210_88312)"
      />
      <path
        d="M32.3475 33.3432C32.2307 33.9879 32.1149 37.072 33.6663 36.9955C35.2177 36.919 36.4718 31.6672 36.2726 28.5752C36.0734 25.4832 32.686 31.4699 32.3475 33.3432Z"
        fill="url(#paint70_radial_210_88312)"
      />
      <path
        d="M29.7788 32.0463C29.5178 32.6488 28.6994 35.6319 30.2321 35.8939C31.7649 36.1559 34.1798 31.3113 34.6911 28.2546C35.2023 25.198 30.5353 30.2947 29.7788 32.0463Z"
        fill="url(#paint71_radial_210_88312)"
      />
      <path
        d="M29.7788 32.0463C29.5178 32.6488 28.6994 35.6319 30.2321 35.8939C31.7649 36.1559 34.1798 31.3113 34.6911 28.2546C35.2023 25.198 30.5353 30.2947 29.7788 32.0463Z"
        fill="url(#paint72_radial_210_88312)"
      />
      <path
        d="M27.9674 30.1619C27.5611 30.579 25.9302 32.7702 27.0685 33.4002C28.2068 34.0301 31.7267 30.7703 33.1367 28.4349C34.5468 26.0995 29.1469 28.951 27.9674 30.1619Z"
        fill="url(#paint73_radial_210_88312)"
      />
      <path
        d="M27.9674 30.1619C27.5611 30.579 25.9302 32.7702 27.0685 33.4002C28.2068 34.0301 31.7267 30.7703 33.1367 28.4349C34.5468 26.0995 29.1469 28.951 27.9674 30.1619Z"
        fill="url(#paint74_radial_210_88312)"
      />
      <path
        d="M27.996 27.8779C27.6692 28.1949 25.6183 29.4774 26.4642 30.4155C27.31 31.3536 30.6199 29.5667 32.081 27.923C33.5421 26.2794 29.461 26.459 27.996 27.8779Z"
        fill="url(#paint75_radial_210_88312)"
      />
      <path
        d="M27.996 27.8779C27.6692 28.1949 25.6183 29.4774 26.4642 30.4155C27.31 31.3536 30.6199 29.5667 32.081 27.923C33.5421 26.2794 29.461 26.459 27.996 27.8779Z"
        fill="url(#paint76_radial_210_88312)"
      />
      <path
        d="M37.786 31.468C37.9264 31.885 38.9155 33.7612 39.9998 33.2833C41.0841 32.8055 40.1588 29.3239 38.942 27.5321C37.7252 25.7403 37.3778 30.2551 37.786 31.468Z"
        fill="url(#paint77_radial_210_88312)"
      />
      <path
        d="M37.786 31.468C37.9264 31.885 38.9155 33.7612 39.9998 33.2833C41.0841 32.8055 40.1588 29.3239 38.942 27.5321C37.7252 25.7403 37.3778 30.2551 37.786 31.468Z"
        fill="url(#paint78_radial_210_88312)"
      />
      <path
        d="M35.9303 30.8862C35.9784 31.317 36.5476 33.305 37.7182 32.991C38.8889 32.677 38.7279 29.1159 37.9145 27.1769C37.101 25.2379 35.79 29.6341 35.9303 30.8862Z"
        fill="url(#paint79_radial_210_88312)"
      />
      <path
        d="M35.9303 30.8862C35.9784 31.317 36.5476 33.305 37.7182 32.991C38.8889 32.677 38.7279 29.1159 37.9145 27.1769C37.101 25.2379 35.79 29.6341 35.9303 30.8862Z"
        fill="url(#paint80_radial_210_88312)"
      />
      <path
        d="M33.5153 30.0157C33.423 30.4416 33.3308 32.4826 34.5613 32.4306C35.7918 32.3786 36.7859 28.9078 36.6279 26.8638C36.4699 24.8198 33.7841 28.7773 33.5153 30.0157Z"
        fill="url(#paint81_radial_210_88312)"
      />
      <path
        d="M33.5153 30.0157C33.423 30.4416 33.3308 32.4826 34.5613 32.4306C35.7918 32.3786 36.7859 28.9078 36.6279 26.8638C36.4699 24.8198 33.7841 28.7773 33.5153 30.0157Z"
        fill="url(#paint82_radial_210_88312)"
      />
      <path
        d="M31.4784 29.1593C31.2713 29.5577 30.6237 31.529 31.8375 31.7027C33.0513 31.8764 34.9688 28.6735 35.3701 26.6521C34.85 26.8356 32.0789 28.0004 31.4784 29.1593Z"
        fill="url(#paint83_radial_210_88312)"
      />
      <path
        d="M31.4784 29.1593C31.2713 29.5577 30.6237 31.529 31.8375 31.7027C33.0513 31.8764 34.9688 28.6735 35.3701 26.6521C34.85 26.8356 32.0789 28.0004 31.4784 29.1593Z"
        fill="url(#paint84_radial_210_88312)"
      />
      <path
        d="M30.0414 27.9131C29.7195 28.1888 28.4262 29.6372 29.329 30.0533C30.2317 30.4693 33.0225 28.3145 34.1372 26.7709C34.0165 26.0202 30.9765 27.1124 30.0414 27.9131Z"
        fill="url(#paint85_radial_210_88312)"
      />
      <path
        d="M30.0414 27.9131C29.7195 28.1888 28.4262 29.6372 29.329 30.0533C30.2317 30.4693 33.0225 28.3145 34.1372 26.7709C34.0165 26.0202 30.9765 27.1124 30.0414 27.9131Z"
        fill="url(#paint86_radial_210_88312)"
      />
      <path
        d="M37.4848 28.081C37.583 28.2625 38.1815 29.0525 38.6437 28.7532C39.1059 28.4539 38.7644 26.2715 37.6271 26.2656C36.8127 26.2627 37.2032 27.5531 37.4848 28.081Z"
        fill="url(#paint87_radial_210_88312)"
      />
      <path
        d="M37.4848 28.081C37.583 28.2625 38.1815 29.0525 38.6437 28.7532C39.1059 28.4539 38.7644 26.2715 37.6271 26.2656C36.8127 26.2627 37.2032 27.5531 37.4848 28.081Z"
        fill="url(#paint88_radial_210_88312)"
      />
      <path
        d="M36.3024 27.8827C36.3544 28.0789 36.7557 28.9837 37.2758 28.7943C37.7959 28.6049 37.9627 26.4039 36.8617 26.1448C36.0688 25.9623 36.1493 27.3057 36.3024 27.8827Z"
        fill="url(#paint89_radial_210_88312)"
      />
      <path
        d="M36.3024 27.8827C36.3544 28.0789 36.7557 28.9837 37.2758 28.7943C37.7959 28.6049 37.9627 26.4039 36.8617 26.1448C36.0688 25.9623 36.1493 27.3057 36.3024 27.8827Z"
        fill="url(#paint90_radial_210_88312)"
      />
      <path
        d="M35.1825 27.6689C35.1825 27.8739 35.3621 28.8464 35.9126 28.7767C36.4631 28.7071 37.1529 26.6081 36.1373 26.1077C35.4102 25.7485 35.1678 27.0732 35.1825 27.6689Z"
        fill="url(#paint91_radial_210_88312)"
      />
      <path
        d="M35.1825 27.6689C35.1825 27.8739 35.3621 28.8464 35.9126 28.7767C36.4631 28.7071 37.1529 26.6081 36.1373 26.1077C35.4102 25.7485 35.1678 27.0732 35.1825 27.6689Z"
        fill="url(#paint92_radial_210_88312)"
      />
      <path
        d="M34.1721 27.6092C34.1721 27.8143 34.3526 28.7867 34.9031 28.7171C35.4536 28.6474 36.2632 26.0961 35.1279 26.048C34.3369 26.0136 34.1613 27.0136 34.1721 27.6092Z"
        fill="url(#paint93_radial_210_88312)"
      />
      <path
        d="M34.1721 27.6092C34.1721 27.8143 34.3526 28.7867 34.9031 28.7171C35.4536 28.6474 36.2632 26.0961 35.1279 26.048C34.3369 26.0136 34.1613 27.0136 34.1721 27.6092Z"
        fill="url(#paint94_radial_210_88312)"
      />
      <path
        d="M32.9397 27.4774C32.9083 27.6806 32.9152 28.6648 33.4696 28.6893C34.024 28.7138 35.2604 26.3293 34.1496 26.0948C33.3764 25.9319 33.031 26.8887 32.9397 27.4774Z"
        fill="url(#paint95_radial_210_88312)"
      />
      <path
        d="M32.9397 27.4774C32.9083 27.6806 32.9152 28.6648 33.4696 28.6893C34.024 28.7138 35.2604 26.3293 34.1496 26.0948C33.3764 25.9319 33.031 26.8887 32.9397 27.4774Z"
        fill="url(#paint96_radial_210_88312)"
      />
      <path
        d="M31.5566 27.1175C31.4585 27.2961 31.1023 28.2185 31.6106 28.4343C32.1189 28.6502 34.1443 26.8545 33.1905 26.2481C32.5271 25.8261 31.8569 26.5994 31.5566 27.1175Z"
        fill="url(#paint97_radial_210_88312)"
      />
      <path
        d="M31.5566 27.1175C31.4585 27.2961 31.1023 28.2185 31.6106 28.4343C32.1189 28.6502 34.1443 26.8545 33.1905 26.2481C32.5271 25.8261 31.8569 26.5994 31.5566 27.1175Z"
        fill="url(#paint98_radial_210_88312)"
      />
      <path
        d="M30.1075 26.7256C29.9476 26.857 29.2882 27.5999 29.6866 27.9777C30.0849 28.3555 32.6304 27.3653 31.9494 26.4704C31.4803 25.8483 30.5746 26.3429 30.1075 26.7256Z"
        fill="url(#paint99_radial_210_88312)"
      />
      <path
        d="M30.1075 26.7256C29.9476 26.857 29.2882 27.5999 29.6866 27.9777C30.0849 28.3555 32.6304 27.3653 31.9494 26.4704C31.4803 25.8483 30.5746 26.3429 30.1075 26.7256Z"
        fill="url(#paint100_radial_210_88312)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_210_88312"
          x1="7.14731"
          y1="13.8885"
          x2="22.0565"
          y2="16.2082"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stopColor="#DCE3BE" />
          <stop offset="0.565" stopColor="#DAE1BC" />
          <stop offset="0.683" stopColor="#D4DAB6" />
          <stop offset="0.768" stopColor="#C9CEAB" />
          <stop offset="0.837" stopColor="#BABD9C" />
          <stop offset="0.896" stopColor="#A6A787" />
          <stop offset="0.948" stopColor="#8D8C6F" />
          <stop offset="0.995" stopColor="#716C52" />
          <stop offset="1" stopColor="#6D684E" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.4365 8.89164) rotate(9.15139) scale(3.98878 2.52208)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.4778 11.31) rotate(-2.12109) scale(3.98862 2.52327)"
        >
          <stop offset="0.179" stopColor="#DCE3BE" />
          <stop offset="0.25" stopColor="#DCE3BE" />
          <stop offset="0.535" stopColor="#DAE1BC" />
          <stop offset="0.693" stopColor="#D5D9B4" />
          <stop offset="0.821" stopColor="#CCCDA6" />
          <stop offset="0.932" stopColor="#C0BB92" />
          <stop offset="1" stopColor="#B5AC82" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.4612 11.4546) rotate(8.48684) scale(4.02966 2.36857)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.789 13.4981) rotate(-9.72596) scale(3.99138 2.52141)"
        >
          <stop offset="0.179" stopColor="#DCE3BE" />
          <stop offset="0.25" stopColor="#DCE3BE" />
          <stop offset="0.535" stopColor="#DAE1BC" />
          <stop offset="0.693" stopColor="#D5D9B4" />
          <stop offset="0.821" stopColor="#CCCDA6" />
          <stop offset="0.932" stopColor="#C0BB92" />
          <stop offset="1" stopColor="#B5AC82" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.6884 13.6997) rotate(9.20066) scale(3.99247 2.53754)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.5996 15.1862) rotate(-21.4077) scale(3.98985 2.52285)"
        >
          <stop offset="0.179" stopColor="#DCE3BE" />
          <stop offset="0.25" stopColor="#DCE3BE" />
          <stop offset="0.535" stopColor="#DAE1BC" />
          <stop offset="0.693" stopColor="#D5D9B4" />
          <stop offset="0.821" stopColor="#CCCDA6" />
          <stop offset="0.932" stopColor="#C0BB92" />
          <stop offset="1" stopColor="#B5AC82" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.4771 15.2747) rotate(12.3305) scale(3.8451 3.23028)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.5064 16.8825) rotate(-28.9488) scale(3.98963 2.52516)"
        >
          <stop offset="0.179" stopColor="#DCE3BE" />
          <stop offset="0.25" stopColor="#DCE3BE" />
          <stop offset="0.535" stopColor="#DAE1BC" />
          <stop offset="0.693" stopColor="#D5D9B4" />
          <stop offset="0.821" stopColor="#CCCDA6" />
          <stop offset="0.932" stopColor="#C0BB92" />
          <stop offset="1" stopColor="#B5AC82" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.2897 16.8842) rotate(15.2846) scale(3.69494 3.78357)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.8098 18.5432) rotate(-42.7709) scale(3.98947 2.52132)"
        >
          <stop offset="0.179" stopColor="#DCE3BE" />
          <stop offset="0.25" stopColor="#DCE3BE" />
          <stop offset="0.535" stopColor="#DAE1BC" />
          <stop offset="0.693" stopColor="#D5D9B4" />
          <stop offset="0.821" stopColor="#CCCDA6" />
          <stop offset="0.932" stopColor="#C0BB92" />
          <stop offset="1" stopColor="#B5AC82" />
        </radialGradient>
        <radialGradient
          id="paint11_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.542 18.4967) rotate(22.1269) scale(3.32337 4.66949)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.1874 18.9171) rotate(-42.7709) scale(3.98947 2.52132)"
        >
          <stop offset="0.179" stopColor="#DCE3BE" />
          <stop offset="0.25" stopColor="#DCE3BE" />
          <stop offset="0.535" stopColor="#DAE1BC" />
          <stop offset="0.693" stopColor="#D5D9B4" />
          <stop offset="0.821" stopColor="#CCCDA6" />
          <stop offset="0.932" stopColor="#C0BB92" />
          <stop offset="1" stopColor="#B5AC82" />
        </radialGradient>
        <radialGradient
          id="paint13_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.9142 18.8687) rotate(22.1262) scale(3.32368 4.66982)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint14_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.5897 8.28878) rotate(12.6468) scale(3.99094 2.5225)"
        >
          <stop offset="0.179" stopColor="#E5EBCD" />
          <stop offset="0.25" stopColor="#E5EBCD" />
          <stop offset="0.532" stopColor="#E3E9CB" />
          <stop offset="0.689" stopColor="#DEE2C3" />
          <stop offset="0.816" stopColor="#D5D6B5" />
          <stop offset="0.925" stopColor="#C9C5A1" />
          <stop offset="1" stopColor="#BDB58F" />
        </radialGradient>
        <radialGradient
          id="paint15_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.6373 8.25619) rotate(9.15188) scale(3.98867 2.52214)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint16_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.3033 10.3147) rotate(-0.973933) scale(3.99045 2.52191)"
        >
          <stop offset="0.179" stopColor="#E5EBCD" />
          <stop offset="0.25" stopColor="#E5EBCD" />
          <stop offset="0.532" stopColor="#E3E9CB" />
          <stop offset="0.689" stopColor="#DEE2C3" />
          <stop offset="0.816" stopColor="#D5D6B5" />
          <stop offset="0.925" stopColor="#C9C5A1" />
          <stop offset="1" stopColor="#BDB58F" />
        </radialGradient>
        <radialGradient
          id="paint17_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.2441 10.5088) rotate(8.4644) scale(4.03106 2.36332)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint18_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.1388 13.0743) rotate(-16.2052) scale(3.98876 2.5216)"
        >
          <stop offset="0.179" stopColor="#E5EBCD" />
          <stop offset="0.25" stopColor="#E5EBCD" />
          <stop offset="0.532" stopColor="#E3E9CB" />
          <stop offset="0.689" stopColor="#DEE2C3" />
          <stop offset="0.816" stopColor="#D5D6B5" />
          <stop offset="0.925" stopColor="#C9C5A1" />
          <stop offset="1" stopColor="#BDB58F" />
        </radialGradient>
        <radialGradient
          id="paint19_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.0505 13.2246) rotate(10.6569) scale(3.92303 2.87101)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint20_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.0717 15.5244) rotate(-29.0768) scale(3.99002 2.5236)"
        >
          <stop offset="0.179" stopColor="#E5EBCD" />
          <stop offset="0.25" stopColor="#E5EBCD" />
          <stop offset="0.532" stopColor="#E3E9CB" />
          <stop offset="0.689" stopColor="#DEE2C3" />
          <stop offset="0.816" stopColor="#D5D6B5" />
          <stop offset="0.925" stopColor="#C9C5A1" />
          <stop offset="1" stopColor="#BDB58F" />
        </radialGradient>
        <radialGradient
          id="paint21_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.8603 15.4988) rotate(15.349) scale(3.69187 3.79482)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint22_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.2828 17.6105) rotate(-49.5188) scale(3.50928 2.11101)"
        >
          <stop offset="0.179" stopColor="#E5EBCD" />
          <stop offset="0.25" stopColor="#E5EBCD" />
          <stop offset="0.532" stopColor="#E3E9CB" />
          <stop offset="0.689" stopColor="#DEE2C3" />
          <stop offset="0.816" stopColor="#D5D6B5" />
          <stop offset="0.925" stopColor="#C9C5A1" />
          <stop offset="1" stopColor="#BDB58F" />
        </radialGradient>
        <radialGradient
          id="paint23_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.0697 17.5142) rotate(26.2363) scale(2.72164 4.34584)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint24_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.1111 8.52792) rotate(14.8414) scale(2.6674 1.97888)"
        >
          <stop offset="0.179" stopColor="#EBF0D4" />
          <stop offset="0.25" stopColor="#EBF0D4" />
          <stop offset="0.53" stopColor="#E9EED2" />
          <stop offset="0.687" stopColor="#E4E7CA" />
          <stop offset="0.813" stopColor="#DBDBBC" />
          <stop offset="0.922" stopColor="#CFCBA8" />
          <stop offset="1" stopColor="#C2BA95" />
        </radialGradient>
        <radialGradient
          id="paint25_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.2211 8.46092) rotate(10.9521) scale(2.65321 1.99288)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint26_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.6669 10.1474) rotate(-1.00095) scale(2.63847 2.00113)"
        >
          <stop offset="0.179" stopColor="#EBF0D4" />
          <stop offset="0.25" stopColor="#EBF0D4" />
          <stop offset="0.53" stopColor="#E9EED2" />
          <stop offset="0.687" stopColor="#E4E7CA" />
          <stop offset="0.813" stopColor="#DBDBBC" />
          <stop offset="0.922" stopColor="#CFCBA8" />
          <stop offset="1" stopColor="#C2BA95" />
        </radialGradient>
        <radialGradient
          id="paint27_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.6254 10.2488) rotate(10.1213) scale(2.6773 1.8651)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint28_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.3146 12.3189) rotate(-19.0766) scale(2.67948 1.97002)"
        >
          <stop offset="0.179" stopColor="#EBF0D4" />
          <stop offset="0.25" stopColor="#EBF0D4" />
          <stop offset="0.53" stopColor="#E9EED2" />
          <stop offset="0.687" stopColor="#E4E7CA" />
          <stop offset="0.813" stopColor="#DBDBBC" />
          <stop offset="0.922" stopColor="#CFCBA8" />
          <stop offset="1" stopColor="#C2BA95" />
        </radialGradient>
        <radialGradient
          id="paint29_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.1635 12.409) rotate(12.6951) scale(2.61135 2.25443)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint30_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.708 14.3781) rotate(-39.126) scale(2.61369 1.74484)"
        >
          <stop offset="0.179" stopColor="#EBF0D4" />
          <stop offset="0.25" stopColor="#EBF0D4" />
          <stop offset="0.53" stopColor="#E9EED2" />
          <stop offset="0.687" stopColor="#E4E7CA" />
          <stop offset="0.813" stopColor="#DBDBBC" />
          <stop offset="0.922" stopColor="#CFCBA8" />
          <stop offset="1" stopColor="#C2BA95" />
        </radialGradient>
        <radialGradient
          id="paint31_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.3983 14.2281) rotate(20.1798) scale(2.22298 2.89845)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint32_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.501 16.1346) rotate(-62.1544) scale(2.493 1.36977)"
        >
          <stop offset="0.179" stopColor="#EBF0D4" />
          <stop offset="0.25" stopColor="#EBF0D4" />
          <stop offset="0.53" stopColor="#E9EED2" />
          <stop offset="0.687" stopColor="#E4E7CA" />
          <stop offset="0.813" stopColor="#DBDBBC" />
          <stop offset="0.922" stopColor="#CFCBA8" />
          <stop offset="1" stopColor="#C2BA95" />
        </radialGradient>
        <radialGradient
          id="paint33_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.3488 15.8993) rotate(32.0267) scale(1.73408 3.13951)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint34_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.6276 9.54503) rotate(23.8796) scale(1.50309 1.63664)"
        >
          <stop offset="0.25" stopColor="#EBF0D4" />
          <stop offset="0.574" stopColor="#E9EED2" />
          <stop offset="0.755" stopColor="#E3E6CA" />
          <stop offset="0.9" stopColor="#DAD8BC" />
          <stop offset="1" stopColor="#CFC9AE" />
        </radialGradient>
        <radialGradient
          id="paint35_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.0723 9.43741) rotate(15.7319) scale(1.10855 1.16501)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint36_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.5626 10.4874) rotate(10.7843) scale(1.35094 1.47098)"
        >
          <stop offset="0.25" stopColor="#EBF0D4" />
          <stop offset="0.574" stopColor="#E9EED2" />
          <stop offset="0.755" stopColor="#E3E6CA" />
          <stop offset="0.9" stopColor="#DAD8BC" />
          <stop offset="1" stopColor="#CFC9AE" />
        </radialGradient>
        <radialGradient
          id="paint37_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.1521 10.4715) rotate(13.1432) scale(1.15231 1.02747)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint38_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.612 11.4722) rotate(-2.62107) scale(1.26881 1.38038)"
        >
          <stop offset="0.25" stopColor="#EBF0D4" />
          <stop offset="0.574" stopColor="#E9EED2" />
          <stop offset="0.755" stopColor="#E3E6CA" />
          <stop offset="0.9" stopColor="#DAD8BC" />
          <stop offset="1" stopColor="#CFC9AE" />
        </radialGradient>
        <radialGradient
          id="paint39_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.227 11.4867) rotate(13.0642) scale(1.16777 1.03544)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint40_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.7373 12.3981) rotate(-2.62107) scale(1.23018 1.33836)"
        >
          <stop offset="0.25" stopColor="#EBF0D4" />
          <stop offset="0.574" stopColor="#E9EED2" />
          <stop offset="0.755" stopColor="#E3E6CA" />
          <stop offset="0.9" stopColor="#DAD8BC" />
          <stop offset="1" stopColor="#CFC9AE" />
        </radialGradient>
        <radialGradient
          id="paint41_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.2707 12.4622) rotate(13.8448) scale(1.14472 1.07101)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint42_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.6341 13.6154) rotate(-12.2697) scale(1.15324 1.25498)"
        >
          <stop offset="0.25" stopColor="#EBF0D4" />
          <stop offset="0.574" stopColor="#E9EED2" />
          <stop offset="0.755" stopColor="#E3E6CA" />
          <stop offset="0.9" stopColor="#DAD8BC" />
          <stop offset="1" stopColor="#CFC9AE" />
        </radialGradient>
        <radialGradient
          id="paint43_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.2885 13.6302) rotate(15.4284) scale(1.12531 1.16208)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint44_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.8079 14.8437) rotate(-33.141) scale(1.28529 1.39818)"
        >
          <stop offset="0.25" stopColor="#EBF0D4" />
          <stop offset="0.574" stopColor="#E9EED2" />
          <stop offset="0.755" stopColor="#E3E6CA" />
          <stop offset="0.9" stopColor="#DAD8BC" />
          <stop offset="1" stopColor="#CFC9AE" />
        </radialGradient>
        <radialGradient
          id="paint45_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.411 15.0033) rotate(21.3809) scale(1.03345 1.41274)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint46_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.7079 15.157) rotate(-53.6428) scale(1.49 1.62193)"
        >
          <stop offset="0.25" stopColor="#EBF0D4" />
          <stop offset="0.574" stopColor="#E9EED2" />
          <stop offset="0.755" stopColor="#E3E6CA" />
          <stop offset="0.9" stopColor="#DAD8BC" />
          <stop offset="1" stopColor="#CFC9AE" />
        </radialGradient>
        <radialGradient
          id="paint47_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.4167 15.4973) rotate(29.947) scale(1.06421 1.85366)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <linearGradient
          id="paint48_linear_210_88312"
          x1="19"
          y1="17.5"
          x2="27"
          y2="28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8DDB6" />
          <stop offset="0.473958" stopColor="#EDD8C4" />
          <stop offset="1" stopColor="#FFD6A4" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_210_88312"
          x1="33.9297"
          y1="41.5749"
          x2="31.4188"
          y2="26.733"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stopColor="#D7DDB9" />
          <stop offset="0.566" stopColor="#D5DBB7" />
          <stop offset="0.684" stopColor="#CFD4B1" />
          <stop offset="0.769" stopColor="#C4C8A6" />
          <stop offset="0.838" stopColor="#B4B797" />
          <stop offset="0.897" stopColor="#A0A182" />
          <stop offset="0.949" stopColor="#87866A" />
          <stop offset="0.996" stopColor="#6A664D" />
          <stop offset="1" stopColor="#67634A" />
        </linearGradient>
        <radialGradient
          id="paint50_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.0186 29.9421) rotate(66.7978) scale(10.6633 19.2478)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint51_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.5 34.5) rotate(81.2538) scale(6.57647 4.92681)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint52_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.1673 37.0919) rotate(-88.5942) scale(3.93303 2.54144)"
        >
          <stop offset="0.179" stopColor="#D7DDB9" />
          <stop offset="0.25" stopColor="#D7DDB9" />
          <stop offset="0.536" stopColor="#D5DBB7" />
          <stop offset="0.696" stopColor="#D0D3AF" />
          <stop offset="0.824" stopColor="#C7C7A1" />
          <stop offset="0.935" stopColor="#B9B58D" />
          <stop offset="1" stopColor="#AFA77E" />
        </radialGradient>
        <radialGradient
          id="paint53_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.931 34.4664) rotate(82.0011) scale(6.65704 4.57274)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint54_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.0399 36.8961) rotate(-80.7687) scale(3.93456 2.54205)"
        >
          <stop offset="0.179" stopColor="#D7DDB9" />
          <stop offset="0.25" stopColor="#D7DDB9" />
          <stop offset="0.536" stopColor="#D5DBB7" />
          <stop offset="0.696" stopColor="#D0D3AF" />
          <stop offset="0.824" stopColor="#C7C7A1" />
          <stop offset="0.935" stopColor="#B9B58D" />
          <stop offset="1" stopColor="#AFA77E" />
        </radialGradient>
        <radialGradient
          id="paint55_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.6442 34.2959) rotate(81.4042) scale(6.60217 4.86361)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint56_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.2637 36.069) rotate(-68.9037) scale(3.94282 2.53595)"
        >
          <stop offset="0.179" stopColor="#D7DDB9" />
          <stop offset="0.25" stopColor="#D7DDB9" />
          <stop offset="0.536" stopColor="#D5DBB7" />
          <stop offset="0.696" stopColor="#D0D3AF" />
          <stop offset="0.824" stopColor="#C7C7A1" />
          <stop offset="0.935" stopColor="#B9B58D" />
          <stop offset="1" stopColor="#AFA77E" />
        </radialGradient>
        <radialGradient
          id="paint57_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.0022 33.6556) rotate(78.5903) scale(6.36305 6.15062)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint58_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.0022 33.6556) rotate(78.5903) scale(6.36305 6.15062)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint59_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(30.5965 35.2156) rotate(-61.2454) scale(3.95293 2.53142)"
        >
          <stop offset="0.179" stopColor="#D7DDB9" />
          <stop offset="0.25" stopColor="#D7DDB9" />
          <stop offset="0.536" stopColor="#D5DBB7" />
          <stop offset="0.696" stopColor="#D0D3AF" />
          <stop offset="0.824" stopColor="#C7C7A1" />
          <stop offset="0.935" stopColor="#B9B58D" />
          <stop offset="1" stopColor="#AFA77E" />
        </radialGradient>
        <radialGradient
          id="paint60_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(30.3407 32.996) rotate(75.8451) scale(6.11773 7.23108)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint61_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.8736 33.9716) rotate(-47.2971) scale(3.97171 2.51737)"
        >
          <stop offset="0.179" stopColor="#D7DDB9" />
          <stop offset="0.25" stopColor="#D7DDB9" />
          <stop offset="0.536" stopColor="#D5DBB7" />
          <stop offset="0.696" stopColor="#D0D3AF" />
          <stop offset="0.824" stopColor="#C7C7A1" />
          <stop offset="0.935" stopColor="#B9B58D" />
          <stop offset="1" stopColor="#AFA77E" />
        </radialGradient>
        <radialGradient
          id="paint62_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.644 32.1062) rotate(69.4072) scale(5.49124 9.01243)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint63_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.0406 31.5248) rotate(-47.2971) scale(3.97171 2.51737)"
        >
          <stop offset="0.179" stopColor="#D7DDB9" />
          <stop offset="0.25" stopColor="#D7DDB9" />
          <stop offset="0.536" stopColor="#D5DBB7" />
          <stop offset="0.696" stopColor="#D0D3AF" />
          <stop offset="0.824" stopColor="#C7C7A1" />
          <stop offset="0.935" stopColor="#B9B58D" />
          <stop offset="1" stopColor="#AFA77E" />
        </radialGradient>
        <radialGradient
          id="paint64_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.8097 29.6605) rotate(69.4061) scale(5.49086 9.01222)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint65_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.9792 33.3506) rotate(-100.116) scale(3.93676 2.54126)"
        >
          <stop offset="0.179" stopColor="#E0E5C8" />
          <stop offset="0.25" stopColor="#E0E5C8" />
          <stop offset="0.533" stopColor="#DEE3C6" />
          <stop offset="0.691" stopColor="#D9DCBE" />
          <stop offset="0.818" stopColor="#D0D0B0" />
          <stop offset="0.929" stopColor="#C3BF9C" />
          <stop offset="1" stopColor="#B7B08B" />
        </radialGradient>
        <radialGradient
          id="paint66_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(39.1017 31.3374) rotate(81.2489) scale(6.57658 4.92961)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint67_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.9008 33.1363) rotate(-89.7657) scale(3.93188 2.54084)"
        >
          <stop offset="0.179" stopColor="#E0E5C8" />
          <stop offset="0.25" stopColor="#E0E5C8" />
          <stop offset="0.533" stopColor="#DEE3C6" />
          <stop offset="0.691" stopColor="#D9DCBE" />
          <stop offset="0.818" stopColor="#D0D0B0" />
          <stop offset="0.929" stopColor="#C3BF9C" />
          <stop offset="1" stopColor="#B7B08B" />
        </radialGradient>
        <radialGradient
          id="paint68_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.8361 30.7262) rotate(82.004) scale(6.65781 4.57164)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint69_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.2588 32.1784) rotate(-74.1911) scale(3.94017 2.54033)"
        >
          <stop offset="0.179" stopColor="#E0E5C8" />
          <stop offset="0.25" stopColor="#E0E5C8" />
          <stop offset="0.533" stopColor="#DEE3C6" />
          <stop offset="0.691" stopColor="#D9DCBE" />
          <stop offset="0.818" stopColor="#D0D0B0" />
          <stop offset="0.929" stopColor="#C3BF9C" />
          <stop offset="1" stopColor="#B7B08B" />
        </radialGradient>
        <radialGradient
          id="paint70_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.0379 30.0441) rotate(80.1244) scale(6.49123 5.46737)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint71_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.9573 31.3924) rotate(-61.1151) scale(3.95329 2.52972)"
        >
          <stop offset="0.179" stopColor="#E0E5C8" />
          <stop offset="0.25" stopColor="#E0E5C8" />
          <stop offset="0.533" stopColor="#DEE3C6" />
          <stop offset="0.691" stopColor="#D9DCBE" />
          <stop offset="0.818" stopColor="#D0D0B0" />
          <stop offset="0.929" stopColor="#C3BF9C" />
          <stop offset="1" stopColor="#B7B08B" />
        </radialGradient>
        <radialGradient
          id="paint72_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.6887 29.4657) rotate(75.7752) scale(6.10922 7.25373)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint73_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.8081 30.4687) rotate(-40.5799) scale(3.50419 2.10049)"
        >
          <stop offset="0.179" stopColor="#E0E5C8" />
          <stop offset="0.25" stopColor="#E0E5C8" />
          <stop offset="0.533" stopColor="#DEE3C6" />
          <stop offset="0.691" stopColor="#D9DCBE" />
          <stop offset="0.818" stopColor="#D0D0B0" />
          <stop offset="0.929" stopColor="#C3BF9C" />
          <stop offset="1" stopColor="#B7B08B" />
        </radialGradient>
        <radialGradient
          id="paint74_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.6 29.0379) rotate(65.5114) scale(4.48903 8.44108)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint75_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.6478 28.4329) rotate(-25.5896) scale(2.76862 2.10976)"
        >
          <stop offset="0.179" stopColor="#E0E5C8" />
          <stop offset="0.25" stopColor="#E0E5C8" />
          <stop offset="0.533" stopColor="#DEE3C6" />
          <stop offset="0.691" stopColor="#D9DCBE" />
          <stop offset="0.818" stopColor="#D0D0B0" />
          <stop offset="0.929" stopColor="#C3BF9C" />
          <stop offset="1" stopColor="#B7B08B" />
        </radialGradient>
        <radialGradient
          id="paint76_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.9415 27.7395) rotate(57.7553) scale(3.19215 7.18041)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint77_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.8833 30.3511) rotate(-102.095) scale(2.62067 2.00152)"
        >
          <stop offset="0.179" stopColor="#E6EACF" />
          <stop offset="0.25" stopColor="#E6EACF" />
          <stop offset="0.532" stopColor="#E4E8CD" />
          <stop offset="0.689" stopColor="#DFE1C5" />
          <stop offset="0.816" stopColor="#D6D5B7" />
          <stop offset="0.925" stopColor="#C8C5A3" />
          <stop offset="1" stopColor="#BCB591" />
        </radialGradient>
        <radialGradient
          id="paint78_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.8719 28.6893) rotate(79.5336) scale(4.36935 3.89075)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint79_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.3439 29.9704) rotate(-89.6991) scale(2.60078 2.01612)"
        >
          <stop offset="0.179" stopColor="#E6EACF" />
          <stop offset="0.25" stopColor="#E6EACF" />
          <stop offset="0.532" stopColor="#E4E8CD" />
          <stop offset="0.689" stopColor="#DFE1C5" />
          <stop offset="0.816" stopColor="#D6D5B7" />
          <stop offset="0.925" stopColor="#C8C5A3" />
          <stop offset="1" stopColor="#BCB591" />
        </radialGradient>
        <radialGradient
          id="paint80_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.0743 28.2853) rotate(80.4476) scale(4.42026 3.60583)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint81_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.0126 29.504) rotate(-71.2657) scale(2.64528 1.98243)"
        >
          <stop offset="0.179" stopColor="#E6EACF" />
          <stop offset="0.25" stopColor="#E6EACF" />
          <stop offset="0.532" stopColor="#E4E8CD" />
          <stop offset="0.689" stopColor="#DFE1C5" />
          <stop offset="0.816" stopColor="#D6D5B7" />
          <stop offset="0.925" stopColor="#C8C5A3" />
          <stop offset="1" stopColor="#BCB591" />
        </radialGradient>
        <radialGradient
          id="paint82_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.8559 27.835) rotate(78.2056) scale(4.31904 4.30773)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint83_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.9918 28.9969) rotate(-50.9903) scale(2.59925 1.74431)"
        >
          <stop offset="0.179" stopColor="#E6EACF" />
          <stop offset="0.25" stopColor="#E6EACF" />
          <stop offset="0.532" stopColor="#E4E8CD" />
          <stop offset="0.689" stopColor="#DFE1C5" />
          <stop offset="0.816" stopColor="#D6D5B7" />
          <stop offset="0.925" stopColor="#C8C5A3" />
          <stop offset="1" stopColor="#BCB591" />
        </radialGradient>
        <radialGradient
          id="paint84_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.978 27.9257) rotate(71.2601) scale(3.67511 5.57357)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint85_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.2296 28.2296) rotate(-28.0621) scale(2.49891 1.3575)"
        >
          <stop offset="0.179" stopColor="#E6EACF" />
          <stop offset="0.25" stopColor="#E6EACF" />
          <stop offset="0.532" stopColor="#E4E8CD" />
          <stop offset="0.689" stopColor="#DFE1C5" />
          <stop offset="0.816" stopColor="#D6D5B7" />
          <stop offset="0.925" stopColor="#C8C5A3" />
          <stop offset="1" stopColor="#BCB591" />
        </radialGradient>
        <radialGradient
          id="paint86_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.2539 27.4224) rotate(60.0292) scale(2.84889 6.14563)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint87_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.7502 26.9778) rotate(-115.106) scale(1.48773 1.64232)"
        >
          <stop offset="0.25" stopColor="#E6EACF" />
          <stop offset="0.577" stopColor="#E4E8CD" />
          <stop offset="0.759" stopColor="#DEE0C5" />
          <stop offset="0.905" stopColor="#D4D2B7" />
          <stop offset="1" stopColor="#C9C4A9" />
        </radialGradient>
        <radialGradient
          id="paint88_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.8846 26.9089) rotate(74.9342) scale(1.82049 2.2777)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint89_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.7953 27.0974) rotate(-101.8) scale(1.3329 1.48063)"
        >
          <stop offset="0.25" stopColor="#E6EACF" />
          <stop offset="0.577" stopColor="#E4E8CD" />
          <stop offset="0.759" stopColor="#DEE0C5" />
          <stop offset="0.905" stopColor="#D4D2B7" />
          <stop offset="1" stopColor="#C9C4A9" />
        </radialGradient>
        <radialGradient
          id="paint90_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.8486 26.8059) rotate(77.5073) scale(1.89761 1.99772)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint91_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.83 27.1061) rotate(-88.0948) scale(1.25053 1.39111)"
        >
          <stop offset="0.25" stopColor="#E6EACF" />
          <stop offset="0.577" stopColor="#E4E8CD" />
          <stop offset="0.759" stopColor="#DEE0C5" />
          <stop offset="0.905" stopColor="#D4D2B7" />
          <stop offset="1" stopColor="#C9C4A9" />
        </radialGradient>
        <radialGradient
          id="paint92_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.8271 26.7352) rotate(77.7044) scale(1.92517 1.99675)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint93_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.8955 26.995) rotate(-88.0948) scale(1.21275 1.34908)"
        >
          <stop offset="0.25" stopColor="#E6EACF" />
          <stop offset="0.577" stopColor="#E4E8CD" />
          <stop offset="0.759" stopColor="#DEE0C5" />
          <stop offset="0.905" stopColor="#D4D2B7" />
          <stop offset="1" stopColor="#C9C4A9" />
        </radialGradient>
        <radialGradient
          id="paint94_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.8402 26.7204) rotate(76.9971) scale(1.8882 2.06345)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint95_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.7219 27.0875) rotate(-78.2001) scale(1.13817 1.26439)"
        >
          <stop offset="0.25" stopColor="#E6EACF" />
          <stop offset="0.577" stopColor="#E4E8CD" />
          <stop offset="0.759" stopColor="#DEE0C5" />
          <stop offset="0.905" stopColor="#D4D2B7" />
          <stop offset="1" stopColor="#C9C4A9" />
        </radialGradient>
        <radialGradient
          id="paint96_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.6588 26.7345) rotate(75.5478) scale(1.85696 2.23708)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint97_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.4053 27.0559) rotate(-57.0158) scale(1.27382 1.40035)"
        >
          <stop offset="0.25" stopColor="#E6EACF" />
          <stop offset="0.577" stopColor="#E4E8CD" />
          <stop offset="0.759" stopColor="#DEE0C5" />
          <stop offset="0.905" stopColor="#D4D2B7" />
          <stop offset="1" stopColor="#C9C4A9" />
        </radialGradient>
        <radialGradient
          id="paint98_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.2613 26.7123) rotate(69.9829) scale(1.70322 2.73063)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
        <radialGradient
          id="paint99_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.058 26.9586) rotate(-36.459) scale(1.25657 1.35922)"
        >
          <stop offset="0.25" stopColor="#E6EACF" />
          <stop offset="0.577" stopColor="#E4E8CD" />
          <stop offset="0.759" stopColor="#DEE0C5" />
          <stop offset="0.905" stopColor="#D4D2B7" />
          <stop offset="1" stopColor="#C9C4A9" />
        </radialGradient>
        <radialGradient
          id="paint100_radial_210_88312"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(30.6552 26.6496) rotate(61.8037) scale(1.47219 3.05595)"
        >
          <stop offset="0.179" stopColor="#E3D2BE" />
          <stop offset="0.25" stopColor="#E3D2BE" />
          <stop offset="0.535" stopColor="#E1D0BC" />
          <stop offset="0.693" stopColor="#D9C6B4" />
          <stop offset="0.821" stopColor="#CDBBA6" />
          <stop offset="0.932" stopColor="#C0AE92" />
          <stop offset="1" stopColor="#B59782" />
        </radialGradient>
      </defs>
    </Icon>
  );
});

export default MoneyIcon;
