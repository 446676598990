import {
  RedeemApiRequestBody,
  RedeemApiResponse,
  StripeConfirmProductPurchaseBodyRequest,
  StripeConfirmSetupBodyRequest,
  StripeConfirmSubscriptionBodyRequest,
  StripeCustomerPortal,
  StripeLogOpenStripeCheckoutModalRequest,
  StripePricesApiResponse,
  StripeProductPurchaseIntentApiResponse,
  StripeSubscriptionOrSetupIntentApiResponse,
  UserSubscriptionApiResponse,
} from './api.types';
import { getApiToken, getRequest, postRequest } from './apiRequest';
import {
  DEFAULT_TRIAL_PROMO_CODE,
  MOCKED_STRIPE_PRICES_RESPONSE,
} from 'utils/constants';

/** Retrieve Enabled Subscription for Current User */
export const getCurrentUserSubscription =
  (): Promise<UserSubscriptionApiResponse> => {
    const url = `/user/subscription/`;

    return getRequest(url, getApiToken({ allowDefault: true }));
  };

/** Retrieve Stripe Customer Portal link for the current user */
export const getStripeManageSubscriptionLink =
  (): Promise<StripeCustomerPortal> => {
    const url = '/iap/stripe/customer_portal_session/';

    return postRequest(url, getApiToken({ allowDefault: true }), undefined);
  };

/**
 * Api get call to fetch the current prices for the paywall
 */
export const getStripePrices = async ({
  promoCode,
  extraRequestParam,
  customToken,
}: {
  promoCode?: string | null;
  extraRequestParam?: string;
  customToken: string | null;
}) => {
  if (process.env.NEXT_PUBLIC_LIGHTHOUSE_ENV) {
    // Do not make the api call for lighthouse tests
    // Return a mocked response
    return MOCKED_STRIPE_PRICES_RESPONSE;
  }

  let url = '/iap/stripe/prices/?';
  // (promoCode || extraRequestParam) && (url = url += '?');
  promoCode && (url = url += `promo=${promoCode}`);
  !promoCode &&
    !extraRequestParam &&
    (url = url += `promo=${DEFAULT_TRIAL_PROMO_CODE}`);
  promoCode && extraRequestParam && (url = url += '&');
  extraRequestParam && (url = url += `exp=${extraRequestParam}`);

  return getRequest<StripePricesApiResponse>(
    url,
    customToken ?? getApiToken({ allowDefault: true }),
  );
};

interface postSubscriptionBodyRequest {
  price_id: string;
  promotion_code?: string;
}

interface postProductPurchaseBodyRequest {
  price_id: string;
  promotion_code?: string;
}

/**
 * Api post call for choosing a stripe subscription
 * @param priceId
 * @param promotionCode
 * @param customToken used for the quiz flow when the user is not yet logged in
 */
export const chooseStripeSubscription = ({
  priceId,
  promotionCode,
  customToken,
}: {
  priceId: string;
  promotionCode?: string;
  customToken?: string;
}) => {
  const url = `/iap/stripe/subscriptions/`;

  return postRequest<
    StripeSubscriptionOrSetupIntentApiResponse,
    postSubscriptionBodyRequest
  >(
    url,
    customToken ?? getApiToken(),
    {
      price_id: priceId,
      ...(promotionCode && { promotion_code: promotionCode }),
    },
    undefined,
    undefined,
    false,
  );
};

/**
 * Api post call for choosing a stripe product
 * @param priceId
 * @param promotionCode
 * @param customToken used for the quiz flow when the user is not yet logged in
 */
export const chooseStripeProduct = ({
  priceId,
  promotionCode,
  customToken,
}: {
  priceId: string;
  promotionCode?: string;
  customToken?: string;
}) => {
  const url = `/iap/stripe/products/`;

  return postRequest<
    StripeProductPurchaseIntentApiResponse,
    postProductPurchaseBodyRequest
  >(
    url,
    customToken ?? getApiToken(),
    {
      price_id: priceId,
      ...(promotionCode && { promotion_code: promotionCode }),
    },
    undefined,
    undefined,
    false,
  );
};

type ConfirmSubscriptionParams = {
  paymentIntent: string;
  paymentIntentClientSecret: string;
  subscriptionId: string;
  experimentName?: string;
  purchaseLocation: string;
  utmCampaign?: string;
  facebookFbp?: string;
  facebookFbc?: string;
  facebookLoginId?: string;
  clientUserAgent?: string;
  webpageUrl?: string;
  tiktokTtcid?: string;
  tiktokTtp?: string;
  snapchatClickId?: string;
  snapchatCookie?: string;
};
type ConfirmSetupParams = {
  setupIntent: string;
  setupIntentClientSecret: string;
  experimentName?: string;
  purchaseLocation: string;
  utmCampaign?: string;
  facebookFbp?: string;
  facebookFbc?: string;
  facebookLoginId?: string;
  clientUserAgent?: string;
  webpageUrl?: string;
  tiktokTtcid?: string;
  tiktokTtp?: string;
  snapchatClickId?: string;
  snapchatCookie?: string;
};
type ConfirmProductPurchaseParams = {
  paymentIntent: string;
  paymentIntentClientSecret: string;
  experimentName?: string;
  purchaseLocation: string;
  utmCampaign?: string;
  facebookFbp?: string;
  facebookFbc?: string;
  facebookLoginId?: string;
  clientUserAgent?: string;
  webpageUrl?: string;
  tiktokTtcid?: string;
  tiktokTtp?: string;
  snapchatClickId?: string;
  snapchatCookie?: string;
};

function isConfirmSubscriptionParams(
  obj: any,
): obj is ConfirmSubscriptionParams {
  return (obj as any).paymentIntent;
}

/** Api post call to confirm a subscription
 * @param paymentIntent -> the id of the payment intent created by stripe before completion
 * @param paymentIntentClientSecret -> the clientSecret for the subscription payment intent created when you chose which option type (monthly/yearly) from backend
 * @param subscriptionId -> the id of the subscription id created by the backend
 */

export const confirmStripeSubscription = (
  args: ConfirmSubscriptionParams | ConfirmSetupParams,
) => {
  let url = `/iap/stripe/subscriptions/confirm/`;
  const { experimentName, utmCampaign } = args;

  (experimentName || utmCampaign) && (url = url += '?');
  url += experimentName ? `exp=${experimentName}` : '';
  experimentName && utmCampaign && (url = url += '&');
  url += utmCampaign ? `utm_campaign=${utmCampaign}` : '';

  return postRequest<
    any,
    StripeConfirmSubscriptionBodyRequest | StripeConfirmSetupBodyRequest
  >(
    url,
    getApiToken(),
    isConfirmSubscriptionParams(args)
      ? {
          payment_intent: args.paymentIntent,
          payment_intent_client_secret: args.paymentIntentClientSecret,
          subscription_id: args.subscriptionId,
          purchase_location: args.purchaseLocation,
          facebook_fbp: args.facebookFbp,
          facebook_fbc: args.facebookFbc,
          client_user_agent: args.clientUserAgent,
          facebook_login_id: args.facebookLoginId,
          webpage_url: args.webpageUrl,
          tiktok_ttcid: args.tiktokTtcid,
          tiktok_ttp: args.tiktokTtp,
          snapchat_click_id: args.snapchatClickId,
          snapchat_cookie: args.snapchatCookie,
        }
      : {
          setup_intent: args.setupIntent,
          setup_intent_client_secret: args.setupIntentClientSecret,
          purchase_location: args.purchaseLocation,
          facebook_fbp: args.facebookFbp,
          facebook_fbc: args.facebookFbc,
          client_user_agent: args.clientUserAgent,
          facebook_login_id: args.facebookLoginId,
          webpage_url: args.webpageUrl,
          tiktok_ttcid: args.tiktokTtcid,
          tiktok_ttp: args.tiktokTtp,
          snapchat_click_id: args.snapchatClickId,
          snapchat_cookie: args.snapchatCookie,
        },
  );
};

export const confirmStripeProductPurchase = (
  args: ConfirmProductPurchaseParams,
) => {
  let url = `/iap/stripe/products/confirm/`;
  const { experimentName, utmCampaign } = args;

  (experimentName || utmCampaign) && (url = url += '?');
  url += experimentName ? `exp=${experimentName}` : '';
  experimentName && utmCampaign && (url = url += '&');
  url += utmCampaign ? `utm_campaign=${utmCampaign}` : '';

  return postRequest<any, StripeConfirmProductPurchaseBodyRequest>(
    url,
    getApiToken(),
    {
      payment_intent: args.paymentIntent,
      payment_intent_client_secret: args.paymentIntentClientSecret,
      purchase_location: args.purchaseLocation,
      facebook_fbp: args.facebookFbp,
      facebook_fbc: args.facebookFbc,
      client_user_agent: args.clientUserAgent,
      facebook_login_id: args.facebookLoginId,
      webpage_url: args.webpageUrl,
      tiktok_ttcid: args.tiktokTtcid,
      tiktok_ttp: args.tiktokTtp,
      snapchat_click_id: args.snapchatClickId,
      snapchat_cookie: args.snapchatCookie,
    },
  );
};

/** Api post call made when opening a checkout modal - used for Facebook Conversion tracking
 * @param priceId -> the id of the selected price
 * @param clientUserAgent -> browser user agent
 * @param facebookFbp -> cookie value
 * @param facebookFbc -> cookie value
 */
export const logOpenStripeCheckoutModal = ({
  priceId,
  clientUserAgent,
  facebookFbc,
  facebookFbp,
  facebookLoginId,
  webpageUrl,
  tiktokTtcid,
  tiktokTtp,
  snapchatClickId,
  snapchatCookie,
}: {
  priceId: string;
  clientUserAgent?: string;
  // Facebook pixel props:
  facebookFbp?: string;
  facebookFbc?: string;
  facebookLoginId?: string;
  // TikTok pixel props:
  webpageUrl?: string;
  tiktokTtcid?: string;
  tiktokTtp?: string;
  // Snapchat pixel props;
  snapchatClickId?: string;
  snapchatCookie?: string;
}) => {
  const url = `/iap/stripe/initiate_checkout/`;

  return postRequest<any, StripeLogOpenStripeCheckoutModalRequest>(
    url,
    getApiToken(),
    {
      price_id: priceId,
      client_user_agent: clientUserAgent,
      facebook_fbp: facebookFbp,
      facebook_fbc: facebookFbc,
      facebook_login_id: facebookLoginId,
      webpage_url: webpageUrl,
      tiktok_ttcid: tiktokTtcid,
      tiktok_ttp: tiktokTtp,
      snapchat_click_id: snapchatClickId,
      snapchat_cookie: snapchatCookie,
    },
  );
};

/**
 * Api post call to redeem a subscription code
 * @param coupon_code -> the code to redeem
 * @returns - `IAP-2017`: In case the coupon was redeemed already
            - `IAP-2018`: In case the coupon is not valid
            - `IAP-2019`: In case an error occurs while redeeming the coupon
 */
export const redeemCode = ({ coupon_code }: RedeemApiRequestBody) => {
  const url = `/iap/redeem_coupon/`;

  return postRequest<RedeemApiResponse, RedeemApiRequestBody>(
    url,
    getApiToken(),
    { coupon_code },
  );
};
