// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const isProd = process.env.NODE_ENV === 'production';

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://f2540e7970a142fdbb93038c341e2c13@o275653.ingest.sentry.io/5202832',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: isProd ? 0.001 : 0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  release: 'deepstash-web@' + process.env.WEBSITE_VERSION,
});
