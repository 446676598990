import { Icon } from 'deepstash-ui';
import React from 'react';
import { SVGProps } from 'theme/icons';

const SvgComponent: React.FC<SVGProps> = props => {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M3.92163 6.27119V13.7627C3.92163 15.8132 4.99032 17.7155 6.74155 18.7823L11.283 21.5486C11.7003 21.8028 12.2246 21.8028 12.6419 21.5486L17.1834 18.7823C18.9346 17.7155 20.0033 15.8132 20.0033 13.7627V6.27119C20.0033 5.44851 19.4893 4.71346 18.7166 4.43106L12.4108 2.12639C12.1213 2.02057 11.8036 2.02057 11.5141 2.12639L5.20828 4.43106C4.43559 4.71346 3.92163 5.44851 3.92163 6.27119Z"
        fill="url(#paint0_linear_1482_2994)"
      />
      <path
        d="M3.92163 6.27119V13.7627C3.92163 15.8132 4.99032 17.7155 6.74155 18.7823L11.283 21.5486C11.7003 21.8028 12.2246 21.8028 12.6419 21.5486L17.1834 18.7823C18.9346 17.7155 20.0033 15.8132 20.0033 13.7627V6.27119C20.0033 5.44851 19.4893 4.71346 18.7166 4.43106L12.4108 2.12639C12.1213 2.02057 11.8036 2.02057 11.5141 2.12639L5.20828 4.43106C4.43559 4.71346 3.92163 5.44851 3.92163 6.27119Z"
        fill="url(#paint1_linear_1482_2994)"
        fillOpacity="0.4"
      />
      <path
        d="M4.42163 13.7627V6.27119C4.42163 5.65847 4.80442 5.11101 5.37992 4.90067L11.6857 2.59601C11.8644 2.5307 12.0605 2.5307 12.2392 2.59601L18.545 4.90067C19.1205 5.11101 19.5033 5.65847 19.5033 6.27119V13.7627C19.5033 15.6388 18.5255 17.3793 16.9233 18.3553L12.3818 21.1216C12.1243 21.2785 11.8007 21.2785 11.5431 21.1216L7.00166 18.3553C5.3994 17.3793 4.42163 15.6388 4.42163 13.7627Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeLinejoin="bevel"
      />
      <path
        d="M17.3892 6.04826L12.7127 4.32897C12.6061 4.28979 12.4931 4.3664 12.4931 4.47995V11.8809V19.0583C12.4931 19.1861 12.6334 19.2636 12.7421 19.1964L16.1461 17.094C17.3259 16.3653 18.0441 15.0775 18.0441 13.6908V11.1054V6.98684C18.0441 6.56763 17.7826 6.19292 17.3892 6.04826Z"
        fill="white"
        fillOpacity="0.2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1482_2994"
          x1="9.14613"
          y1="19.7584"
          x2="20.0033"
          y2="9.35026"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stopColor="#9B07F6" />
          <stop offset="1" stopColor="#0085FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1482_2994"
          x1="4"
          y1="5"
          x2="20"
          y2="16.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.5" />
          <stop offset="0.131313" stopColor="white" stopOpacity="0.82" />
          <stop offset="0.208333" stopColor="white" />
          <stop offset="0.28125" stopColor="white" stopOpacity="0.78" />
          <stop offset="0.612059" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export const ProShieldSvg = React.memo(SvgComponent);
