import { AsyncActionMap } from 'src/providers/types/actions.types';
import * as Sentry from '@sentry/nextjs';
import { Severity } from '@sentry/nextjs';

/**
 * Bind the dispatch function of a context to the async actions of the same context
 * H - The hidden actions that can be used for that context
 * F - The async functions that we want to bind dispatch to.
 *     F is a mappying og AsyncActions onto some keys
 * @param asyncActions The async actions we are binding dispatch to.
 * @param dispatch The dispatch function to be bound
 * @param withLogging If the dispatch of the actions should log the action name and the args
 * @returns An object with the same keys as the asyncActions object.
 *          The return values holds the async actions bound to the dispatch function
 */
const createAsyncActions = <H>(
  dispatch: (action: H) => void,
  asyncActions?: AsyncActionMap<H>,
  withLogging?: boolean,
) => {
  if (!asyncActions) return undefined;
  const mappedActions: {
    [key in keyof typeof asyncActions]: (args: any) => void;
  } = {} as any;
  for (const key in asyncActions) {
    mappedActions[key as keyof typeof asyncActions] = (args: any) => {
      if (withLogging) {
        Sentry.addBreadcrumb({
          data: args,
          category: 'source',
          level: Severity.Log,
          message: `Dispatched ${key} with: `,
        });
      }
      return asyncActions[key](dispatch, args);
    };
  }
  return mappedActions;
};

export default createAsyncActions;
