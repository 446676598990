import { ActivityContext } from '../activity/ActivityProvider';
import {
  ActivityProviderAction,
  ActivityProviderAsyncActions,
  ActivityProviderContextType,
  ActivityProviderHiddenAction,
  ActivityProviderState,
} from '../activity/ActivityProviderTypes';
import useCreateContextConsumer from './useCreateContextConsumer';
import * as asyncActions from '../activity/ActivityActions';

const useActivity = () => {
  const { dispatch, ...contextConsumer } = useCreateContextConsumer<
    ActivityProviderState,
    ActivityProviderAction,
    ActivityProviderContextType,
    ActivityProviderHiddenAction,
    ActivityProviderAsyncActions
  >({ context: ActivityContext, asyncActions, withLogging: true });
  return {
    ...contextConsumer,
    activityDispatch: dispatch,
  };
};

export default useActivity;
