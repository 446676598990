import { AuthModalProps } from 'providers/auth';
import React, {
  createContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import AuthModal from 'src/components/modals/auth-modal/AuthModal';
import { AuthModalRefType, NsiContextType } from './NsiProviderTypes';
import createNsiReducer from './NsiReducer';

export const NsiContext = createContext({
  state: {
    email: '',
    password: '',
    setupGoal: '',
    likedSourceIds: [],
  },
  dispatch: () => {
    return;
  },
} as NsiContextType);

interface NsiProviderProps {}

NsiContext.displayName = 'NsiContext';

const NsiProvider: React.FC<NsiProviderProps> = ({ children }) => {
  const authModalRef = useRef<AuthModalRefType>(null);

  const onOpenAuth = (authModaLprops: AuthModalProps) => {
    if (authModalRef) {
      authModalRef.current?.onOpenAuth(authModaLprops);
    }
  };

  const onCloseAuth = () => {
    if (authModalRef) {
      authModalRef.current?.onCloseAuth();
    }
  };

  const [state, dispatch] = useReducer(
    createNsiReducer(onOpenAuth, onCloseAuth),
    {
      email: '',
      password: '',
      setupGoal:
        typeof window !== 'undefined'
          ? localStorage.getItem('setupGoal') ?? ''
          : '',
      likedSourceIds:
        typeof window !== 'undefined' &&
        JSON.parse(localStorage.getItem('likedSourceIds') ?? '[]'),
    },
  );

  useEffect(() => {
    const setupGoal = localStorage.getItem('setupGoal');
    // if (setupGoal) {
    dispatch({
      type: 'set-goal',
      payload: {
        setupGoal: setupGoal ?? undefined,
      },
      // });
    });

    const likedSourceIds = JSON.parse(
      localStorage.getItem('likedSourceIds') ?? '[]',
    );
    if (likedSourceIds && likedSourceIds.length > 0) {
      dispatch({ type: 'set-source-likes', payload: { likedSourceIds } });
    }
  }, []);

  useEffect(() => {
    if (state.likedSourceIds.length > 0) {
      localStorage.setItem(
        'likedSourceIds',
        JSON.stringify(state.likedSourceIds),
      );
    }
  }, [state.likedSourceIds]);

  useEffect(() => {
    if (state.setupGoal) {
      localStorage.setItem('setupGoal', state.setupGoal);
    }
  }, [state.setupGoal]);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <NsiContext.Provider value={contextValue}>
      {children}
      <AuthModal ref={authModalRef} />
    </NsiContext.Provider>
  );
};

export default NsiProvider;
