import { Router } from 'next/router';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  InfiniteListState,
  LayoutCacheType,
  NavigationProvideContextType,
} from './NavigationProviderTypes';
import { LayoutState } from '../../components/layout/Layout.types';

export const NavigationContext =
  React.createContext<NavigationProvideContextType>({
    dispatch: () => {
      return;
    },
    state: {},
    extra: {
      currentPageScroll: {
        current: 0,
      },
      scrollCache: {
        current: new Map(),
      },
      tabCache: {
        current: new Map(),
      },
      infiniteListCache: {
        current: new Map(),
      },
      layoutCache: {
        current: new Map(),
      },
      preserveLayoutState: () => {
        return;
      },
      invalidateScroll: () => {
        return;
      },
      preserveInfiniteList: () => {
        return;
      },
      preserveTab: () => {
        return;
      },
      updateScrollCache: () => {
        return;
      },
      offScrollRestoreComplete: () => {
        return;
      },
      onScrollRestoreComplete: () => {
        return;
      },
    },
  });

NavigationContext.displayName = 'NavigationContext';

const NavigationProvider: React.FC = ({ children }) => {
  //Keep track of the current scroll position
  const currentPageScroll = useRef(0);
  const scrollCache = useRef(new Map<string, number>());
  const tabCache = useRef(new Map<string, string>());
  const infiniteListCache = useRef(new Map());
  const layoutCache = useRef(new Map<string, LayoutCacheType>());
  const onScrollRestoreCallbacks = useRef<(() => void)[]>([]);

  useEffect(() => {
    const updateCurrentScroll = (ev: Event) => {
      currentPageScroll.current = (ev.currentTarget as Window).scrollY;
    };
    window.addEventListener('scroll', updateCurrentScroll);
    return () => {
      window.removeEventListener('scroll', updateCurrentScroll);
    };
  }, []);

  const updateScrollCache = (route: string, scroll: number) => {
    scrollCache.current.set(route, scroll);

    //Handle the alias
    if (route === '/') {
      {
        scrollCache.current.set('/recommended', scroll);
      }
    } else if (route === '/recommended') {
      scrollCache.current.set('/', scroll);
    }
  };

  const preserveTab = (key: string, tab: string) => {
    tabCache.current.set(key, tab);
  };

  const preserveInfiniteList = (key: string, listState: InfiniteListState) => {
    infiniteListCache.current.set(key, listState);
  };

  const invalidateScroll = (route: string) => {
    scrollCache.current.delete(route);
    infiniteListCache.current.delete(route);
  };

  const preserveLayoutState = (route: string, state: LayoutState) => {
    layoutCache.current.set(route, state);
  };

  const onScrollRestoreComplete = useCallback(
    (callback: () => void) => onScrollRestoreCallbacks.current.push(callback),
    [],
  );

  const offScrollRestoreComplete = useCallback((callback: () => void) => {
    onScrollRestoreCallbacks.current = onScrollRestoreCallbacks.current.filter(
      cb => cb !== callback,
    );
  }, []);

  const providerValue = useMemo(
    () => ({
      state: {},
      dispatch: () => {
        return;
      },
      extra: {
        currentPageScroll,
        scrollCache,
        tabCache,
        infiniteListCache,
        layoutCache,
        updateScrollCache,
        preserveTab,
        preserveInfiniteList,
        invalidateScroll,
        preserveLayoutState,
        offScrollRestoreComplete,
        onScrollRestoreComplete,
      },
    }),
    [],
  );

  useEffect(() => {
    //Reset scroll

    const resetScroll = (url: string) => {
      const baseDestPath = url.toString().split('#')[0].split('?')[0];
      const scroll = scrollCache.current?.get(baseDestPath);
      if (scroll) {
        window.scrollTo({
          top: scroll,
        });
      } else {
        window.scrollTo({
          top: 0,
        });
      }
      onScrollRestoreCallbacks.current.forEach(cb => cb());
    };

    Router.events.on('routeChangeComplete', resetScroll);

    return () => {
      Router.events.off('routeChangeComplete', resetScroll);
    };
  }, []);

  return (
    <NavigationContext.Provider value={providerValue}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
