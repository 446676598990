import * as React from 'react';
import { Icon, IconProps } from 'deepstash-ui';

export const HappinessIcon = React.memo<IconProps>(props => {
  return (
    <Icon width="48px" height="48px" viewBox="0 0 48 48" fill="none" {...props}>
      <path
        d="M24.9799 41.6395C34.7218 41.6395 42.6192 33.7421 42.6192 24.0002C42.6192 14.2582 34.7218 6.36084 24.9799 6.36084C15.238 6.36084 7.34058 14.2582 7.34058 24.0002C7.34058 33.7421 15.238 41.6395 24.9799 41.6395Z"
        fill="url(#paint0_radial_210_88379)"
      />
      <path
        d="M36.7394 31.8394C39.4455 31.8394 41.6392 29.6457 41.6392 26.9396C41.6392 24.2335 39.4455 22.0398 36.7394 22.0398C34.0333 22.0398 31.8396 24.2335 31.8396 26.9396C31.8396 29.6457 34.0333 31.8394 36.7394 31.8394Z"
        fill="url(#paint1_radial_210_88379)"
      />
      <path
        d="M13.2204 31.8394C15.9265 31.8394 18.1202 29.6457 18.1202 26.9396C18.1202 24.2335 15.9265 22.0398 13.2204 22.0398C10.5143 22.0398 8.32056 24.2335 8.32056 26.9396C8.32056 29.6457 10.5143 31.8394 13.2204 31.8394Z"
        fill="url(#paint2_radial_210_88379)"
      />
      <path
        d="M24.9801 32.8196C20.9563 32.8196 19.751 31.3928 19.751 31.3928C19.751 31.3928 21.0406 34.7796 24.9801 34.7796V34.7786V34.7796C28.9195 34.7796 30.2091 31.3928 30.2091 31.3928C30.2091 31.3928 29.0038 32.8196 24.9801 32.8196Z"
        fill="url(#paint3_radial_210_88379)"
      />
      <path
        d="M24.9799 34.7797C21.5402 34.7797 20.0801 32.0681 20.0801 32.0681C20.0801 32.0681 21.1169 36.7396 24.9799 36.7396C28.8429 36.7396 29.8797 32.0681 29.8797 32.0681C29.8797 32.0681 28.4196 34.7797 24.9799 34.7797Z"
        fill="url(#paint4_radial_210_88379)"
      />
      <path
        d="M20.3731 30.6688C19.8822 30.3983 19.3981 31.0196 19.7773 31.4322C20.8788 32.6297 22.5712 33.7998 24.9799 33.7998C27.3887 33.7998 29.0811 32.6297 30.1825 31.4322C30.5618 31.0196 30.0777 30.3983 29.5867 30.6688C28.4588 31.2921 26.9163 31.8399 24.9799 31.8399C23.0435 31.8399 21.5011 31.2921 20.3731 30.6688Z"
        fill="url(#paint5_radial_210_88379)"
      />
      <path
        d="M39.9466 6.36084C38.6207 6.36084 37.7191 7.25163 37.7191 7.25163C37.7191 7.25163 36.8225 6.36084 35.4917 6.36084C34.0159 6.36084 32.8193 7.55737 32.8193 9.0332C32.8193 11.7056 37.7191 14.3789 37.7191 14.3789C37.7191 14.3789 42.619 11.7065 42.619 9.0332C42.619 7.55737 41.4224 6.36084 39.9466 6.36084Z"
        fill="url(#paint6_radial_210_88379)"
      />
      <path
        d="M41.4609 32.8198C40.4006 32.8198 39.6794 33.5323 39.6794 33.5323C39.6794 33.5323 38.962 32.8198 37.8978 32.8198C36.7169 32.8198 35.7595 33.7772 35.7595 34.9581C35.7595 37.0964 39.6794 39.6796 39.6794 39.6796C39.6794 39.6796 43.5992 37.0964 43.5992 34.9581C43.5992 33.7772 42.6418 32.8198 41.4609 32.8198Z"
        fill="url(#paint7_radial_210_88379)"
      />
      <path
        d="M12.9528 28.8997C11.3623 28.8997 10.2804 29.9688 10.2804 29.9688C10.2804 29.9688 9.20441 28.8997 7.60805 28.8997C5.83628 28.8997 4.40063 30.3353 4.40063 32.1071C4.40063 35.3145 10.2804 38.5209 10.2804 38.5209C10.2804 38.5209 16.1602 35.3135 16.1602 32.1071C16.1602 30.3353 14.7245 28.8997 12.9528 28.8997Z"
        fill="url(#paint8_radial_210_88379)"
      />
      <path
        d="M35.2695 23.0198C35.5401 23.0198 35.7595 22.8004 35.7595 22.5298C35.7595 22.2592 35.5401 22.0398 35.2695 22.0398C34.9989 22.0398 34.7795 22.2592 34.7795 22.5298C34.7795 22.8004 34.9989 23.0198 35.2695 23.0198Z"
        fill="#212121"
      />
      <path
        d="M28.6469 22.9602C28.6469 22.9602 30.3383 22.0351 31.8396 22.0351C33.3409 22.0351 35.0324 22.9602 35.0324 22.9602L35.6507 22.2213C35.6507 22.2213 33.9211 20.0801 31.8396 20.0801C29.7582 20.0801 28.0286 22.2213 28.0286 22.2213L28.6469 22.9602Z"
        fill="#212121"
      />
      <path
        d="M28.4097 23.0198C28.6803 23.0198 28.8996 22.8004 28.8996 22.5298C28.8996 22.2592 28.6803 22.0398 28.4097 22.0398C28.139 22.0398 27.9197 22.2592 27.9197 22.5298C27.9197 22.8004 28.139 23.0198 28.4097 23.0198Z"
        fill="#212121"
      />
      <path
        d="M21.55 23.0198C21.8206 23.0198 22.04 22.8004 22.04 22.5298C22.04 22.2592 21.8206 22.0398 21.55 22.0398C21.2794 22.0398 21.0601 22.2592 21.0601 22.5298C21.0601 22.8004 21.2794 23.0198 21.55 23.0198Z"
        fill="#212121"
      />
      <path
        d="M14.9272 22.9602C14.9272 22.9602 16.6186 22.0351 18.1199 22.0351C19.6212 22.0351 21.3126 22.9602 21.3126 22.9602L21.931 22.2213C21.931 22.2213 20.2014 20.0801 18.1199 20.0801C16.0385 20.0801 14.3088 22.2213 14.3088 22.2213L14.9272 22.9602Z"
        fill="#212121"
      />
      <path
        d="M14.6904 23.0198C14.961 23.0198 15.1804 22.8004 15.1804 22.5298C15.1804 22.2592 14.961 22.0398 14.6904 22.0398C14.4198 22.0398 14.2004 22.2592 14.2004 22.5298C14.2004 22.8004 14.4198 23.0198 14.6904 23.0198Z"
        fill="#212121"
      />
      <defs>
        <radialGradient
          id="paint0_radial_210_88379"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.9799 24.0002) scale(17.6393)"
        >
          <stop stopColor="#FFCFA3" />
          <stop offset="0.999" stopColor="#F0AC29" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_210_88379"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.7394 26.9396) scale(4.89981)"
        >
          <stop stopColor="#FF8400" stopOpacity="0.6" />
          <stop offset="0.999" stopColor="#FF8400" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_210_88379"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.2204 26.9396) scale(4.89981)"
        >
          <stop stopColor="#FF8400" stopOpacity="0.5" />
          <stop offset="0.999" stopColor="#FF8400" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_210_88379"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.9683 29.3212) scale(5.46525 5.46525)"
        >
          <stop offset="0.914" stopColor="#FFB46E" />
          <stop offset="1" stopColor="#FFB46E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_210_88379"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.025 31.6222) scale(4.94293 4.94293)"
        >
          <stop offset="0.696" stopColor="#CC6200" />
          <stop offset="1" stopColor="#CC6E00" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_210_88379"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.9799 31.3077) rotate(180) scale(5.36911 2.87938)"
        >
          <stop stopColor="#1C1911" />
          <stop offset="0.999" stopColor="#171714" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_210_88379"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.7974 8.54322) scale(5.624)"
        >
          <stop stopColor="#FF9D66" />
          <stop offset="0.126" stopColor="#FB9359" />
          <stop offset="0.714" stopColor="#EC7723" />
          <stop offset="1" stopColor="#E6760E" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_210_88379"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.142 34.6869) scale(4.4992 4.81154)"
        >
          <stop stopColor="#FF9D66" />
          <stop offset="0.126" stopColor="#FB9359" />
          <stop offset="0.714" stopColor="#EC7723" />
          <stop offset="1" stopColor="#E6760E" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_210_88379"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.97436 31.5184) scale(6.7488 6.74853)"
        >
          <stop stopColor="#FF9D66" />
          <stop offset="0.126" stopColor="#FB9359" />
          <stop offset="0.714" stopColor="#EC7723" />
          <stop offset="1" stopColor="#E6760E" />
        </radialGradient>
      </defs>
    </Icon>
  );
});

export default HappinessIcon;
