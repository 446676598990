import {
  isStripeSubscriptionIntentApiResponse,
  StripePricesApiResponse,
  StripeProductApiResponse,
  StripeSubscriptionApiResponse,
  StripeSubscriptionOrSetupIntentApiResponse,
  SubscriptionDetailsApiResponse,
  UserSubscriptionApiResponse,
} from 'api/api.types';
import { PaymentProvider } from 'types/enums';
import {
  CurrentSubscription,
  StripePricesData,
  StripeSubscription,
  StripeSubscriptionOrSetupIntent,
  UserSubscriptionDetails,
} from 'types/models';
import { PaymentLocation, ProductType, SubscriptionType } from 'types/types';
import { Normalizer } from './types';

const normalizeInitialPaymentLocation = (
  provider: PaymentProvider,
): PaymentLocation => {
  switch (provider) {
    case PaymentProvider.GOOGLE:
    case PaymentProvider.APPLE:
      return 'mobile';
    case PaymentProvider.STRIPE:
      return 'web';
    case PaymentProvider.DEEPSTASH:
      return 'deepstash';
    default:
      return 'web';
  }
};
/**
 * Normalize the response from server when asking for subscription details
 * @param subscriptionDetails
 * @returns
 */
export const normalizeSubscriptionDetails = (
  subscriptionDetails: SubscriptionDetailsApiResponse,
): UserSubscriptionDetails => {
  return {
    startDate: subscriptionDetails.start_date,
    endDate: subscriptionDetails.end_date,
    isFreeTrial: subscriptionDetails.is_free_trial,
    userId: subscriptionDetails.user_id,
    token: subscriptionDetails.token,
    currencyAmount: subscriptionDetails.currency_amount,
    status: subscriptionDetails.status,
    currencyCode: subscriptionDetails.currency_code,
    endDateFreeTrial: subscriptionDetails.end_date_free_trial,
    inGraceEndDate: subscriptionDetails.in_grace_end_date,
    plan: subscriptionDetails.plan,
    initialPaymentLocation: normalizeInitialPaymentLocation(
      subscriptionDetails.provider,
    ),
  };
};

/**
 * Normalize the response from server when asking for subscription details
 * @param subscriptionDetails
 * @returns
 */
export const normalizeCurrentSubscription = (
  userSubscriptionsDetails: UserSubscriptionApiResponse,
): CurrentSubscription => {
  return {
    downgradeSkus: userSubscriptionsDetails.downgrade_skus,
    upgradeSkus: userSubscriptionsDetails.upgrade_skus,
    subscriptionDetails: userSubscriptionsDetails.subscription_details
      ? normalizeSubscriptionDetails(
          userSubscriptionsDetails.subscription_details,
        )
      : undefined,
    subscriptionType: userSubscriptionsDetails.subscription_type,
  };
};

/**
 * Normalize the response from server when asking for prices details
 * @param pricesApiResponse
 * @returns prices
 */
export const normalizeStripePrices: Normalizer<
  StripePricesApiResponse,
  StripePricesData
> = prices => {
  const subscriptionRecord = {} as Record<SubscriptionType, StripeSubscription>;

  prices.subscriptions.forEach(
    subscription =>
      (subscriptionRecord[
        (subscription.subscription_period_number +
          ' ' +
          subscription.subscription_period_unit) as SubscriptionType
      ] = normalizeStripeSubscription(subscription)),
  );

  const productRecord = {} as Record<ProductType, StripeProductApiResponse>;

  prices.products?.forEach(product => {
    // Manually added the Lifetime Subscription as the only supported product for now
    if (product.title === 'Lifetime Subscription')
      productRecord['LIFETIME'] = product;
  });

  return {
    publishableKey: prices.publishable_key,
    subscriptions: subscriptionRecord,
    products: productRecord,
    promotionCodeError: prices?.promotion_code_error ?? '',
    testMode: prices?.test_mode ?? false,
  };
};

/**
 * Normalize the response from server for a stripe subscription from prices
 * @param subscriptionApiResponse
 * @returns subscription
 */
export const normalizeStripeSubscription: Normalizer<
  StripeSubscriptionApiResponse,
  StripeSubscription
> = subscription => {
  return {
    freeTrial: subscription.trial_period_number
      ? {
          duration: subscription.trial_period_number,
          periodUnit: subscription.trial_period_unit ?? 'DAY',
        }
      : undefined,
    priceAmount: subscription.price_amount,
    priceCurrency: subscription.price_currency,
    priceId: subscription.price_id,
    sku: subscription.sku,
    periodNumber: subscription.subscription_period_number,
    periodUnit: subscription.subscription_period_unit,
    pricePerMonth: subscription.normalized_monthly_price,
    pricePerYear: subscription.normalized_yearly_price,
    yearlySavingsPercentage:
      subscription.promotion_yearly_savings_percentage_rounded ??
      subscription.yearly_savings_percentage ??
      0,
    yearlyBaselinePrice: subscription.yearly_baseline_price ?? 155.88,
    promotion: subscription.promotion_code
      ? {
          code: subscription.promotion_code ?? '',
          discountPercentage: subscription.promotion_discount_percentage ?? 0,
          pricePerMonth:
            subscription.promotion_normalized_monthly_price ??
            subscription.normalized_monthly_price,
          pricePerYear:
            subscription.promotion_normalized_yearly_price ??
            subscription.normalized_yearly_price,
          priceAmount:
            subscription.promotion_price_amount ?? subscription.price_amount,
          yearlySavingsPercentage:
            subscription.promotion_yearly_savings_percentage_rounded ??
            subscription.promotion_yearly_savings_percentage ??
            subscription.yearly_savings_percentage ??
            0,
        }
      : undefined,
  };
};

/**
 * Normalize the response from server when creating a subscriptionIntent
 * @param subscriptionIntentApiResponse
 * @returns subscriptionIntent
 */
export const normalizeStripeSubscriptionIntent: Normalizer<
  StripeSubscriptionOrSetupIntentApiResponse,
  StripeSubscriptionOrSetupIntent
> = stripeIntent => {
  if (isStripeSubscriptionIntentApiResponse(stripeIntent)) {
    return {
      clientSecret: stripeIntent.payment_intent_client_secret ?? stripeIntent,
      subscriptionId: stripeIntent.subscription_id,
    };
  } else {
    return {
      clientSecret: stripeIntent.setup_intent_client_secret,
    };
  }
};
