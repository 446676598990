import { color, Flex, PrimaryText, spacing, useColorMode } from 'deepstash-ui';
import React from 'react';
import LabelButton from 'src/components/buttons/LabelButton';
import { authType } from 'src/providers/nsi/NsiProviderTypes';
import actionStrings from 'utils/strings/actionStrings';
import pageStrings from 'utils/strings/pageStrings';

interface AuthModalTypeFooterProps {
  toggleAuthMode: () => void;
  authModalType: authType;
  closeModal: () => void;
}

const AuthModalTypeFooter: React.FC<AuthModalTypeFooterProps> = ({
  toggleAuthMode,
  authModalType,
  closeModal,
}) => {
  const { colorMode } = useColorMode();

  const openSetup = () => {
    closeModal();
    if (typeof window !== undefined) {
      window.open('/setup', '_blank');
    }
  };

  return (
    <>
      <Flex
        pt={spacing.S.rem}
        alignItems="center"
        justifyContent="center"
        w="100%"
      >
        <PrimaryText size="m" color={color[colorMode].textSecondary}>
          {authModalType === 'sign-up'
            ? `${pageStrings.authentication.haveAnAccount}?`
            : pageStrings.authentication.dontHaveAnAccount}
        </PrimaryText>
        <LabelButton
          onClick={authModalType === 'sign-in' ? openSetup : toggleAuthMode}
          color={color[colorMode].primary.default}
          colorMode={colorMode}
          _hover={undefined}
          _active={undefined}
          ml={spacing.XS.rem}
          p={0}
        >
          <PrimaryText type="bold">
            {authModalType === 'sign-up'
              ? actionStrings.signIn
              : pageStrings.authentication.tryForFree}
          </PrimaryText>
        </LabelButton>
      </Flex>
    </>
  );
};

export default AuthModalTypeFooter;
