import { useIsMobileView } from 'deepstash-ui';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import useProfile from 'providers/hooks/useProfile';
import React, { createContext, useEffect, useState } from 'react';
import { Analytics, Events } from 'services/analytics';
import { PaywallContainerAnalytics } from 'src/components/paywall/paywall-container/PaywallContainer';
import { PaywallModalProps } from 'src/components/paywall/PaywallModal';
import { UserSubscriptionType } from 'types/enums';
import { clearUrlParams } from 'utils/global';
import { PaywallProviderStateType } from './PaywallProviderTypes';

const PaywallModal = dynamic(
  () => import('src/components/paywall/PaywallModal'),
);

const PaywallMobile = dynamic(
  () => import('src/components/paywall/paywall-mobile/PaywallMobile'),
);

export const PaywallContext = createContext({
  isPaywallOpen: false,
  onPaywallModalOpen: () => {
    return;
  },
  onPaywallModalClose: () => {
    return;
  },
} as PaywallProviderStateType);

PaywallContext.displayName = 'PaywallContext';

interface PaywallProviderProps {
  children: React.ReactNode;
}

const PaywallProvider: React.FC<PaywallProviderProps> = ({ children }) => {
  const [isPaywallOpen, setIsPaywallOpen] = useState(false);
  const [extraProps, setExtraProps] = useState<Partial<PaywallModalProps>>({});
  const isMobileView = useIsMobileView();
  const router = useRouter();
  const { profile } = useProfile();

  useEffect(() => {
    if (!!router.query?.paywall) {
      if (profile?.subscriptionType === UserSubscriptionType.Premium) {
        // We want to make sure we don't show the paywall to Pro users
        clearUrlParams({ params: ['paywall'], router });
        setIsPaywallOpen(false);
      } else {
        onPaywallModalOpen({
          defaultSubscriptionType: (
            router.query.paywall as string
          ).toUpperCase() as '1 YEAR' | '1 MONTH',
          promoCode: router.query.promo_code as string,
          onClose: () => {
            clearUrlParams({ params: ['paywall'], router });
            setIsPaywallOpen(false);
          },
        });
      }
    }
  }, [router.query?.paywall, profile?.subscriptionType]);

  const onPaywallModalOpen = (extraProps?: Partial<PaywallModalProps>) => {
    setExtraProps(extraProps ?? {});
    setIsPaywallOpen(true);
    Analytics.logEvent({
      eventName: Events.payments.enterPaywall,
      properties: {
        location: extraProps?.location,
        url: router.asPath,
        type: PaywallContainerAnalytics[
          extraProps?.defaultSubscriptionType ?? '1 YEAR'
        ],
        device: isMobileView ? 'mobile' : 'desktop',
      },
      platforms: ['amplitude'],
    });
  };

  const onPaywallModalClose = () => {
    setIsPaywallOpen(false);
  };

  return (
    <PaywallContext.Provider
      value={{ isPaywallOpen, onPaywallModalClose, onPaywallModalOpen }}
    >
      {children}
      {!isMobileView && isPaywallOpen && (
        <PaywallModal
          isOpen={isPaywallOpen}
          onClose={onPaywallModalClose}
          {...extraProps}
        />
      )}
      {isMobileView && isPaywallOpen && (
        <PaywallMobile
          isOpen={isPaywallOpen}
          onClose={onPaywallModalClose}
          {...extraProps}
        />
      )}
    </PaywallContext.Provider>
  );
};

export default PaywallProvider;
