import {
  CloseSvg,
  color,
  Flex,
  PrimaryHeading,
  PrimaryText,
  spacing,
  StyleProps,
  useColorMode,
} from 'deepstash-ui';
import React, { useImperativeHandle, useState } from 'react';
import { FreeTrialData, StripePricesData } from 'types/models';
import { RequestStatus, SubscriptionType } from 'types/types';
import pageStrings from 'utils/strings/pageStrings';
import InvalidPromoCodeModal from '../InvalidPromoCodeModal';
import PaywallCheckoutModal from '../paywall-checkout-modal/PaywallCheckoutModal';
import usePaymentInternals from '../usePaymentInternals';
import WhatsIncludedDropdown from '../WhatsIncludedDropdown';
import PaymentLeftContent from './PaymentLeftContent';
import PaywallOptionButtons from './PaywallOptionButtons';

export const PaywallContainerAnalytics = {
  '1 YEAR': 'web-pick-yearly',
  '1 MONTH': 'web-pick-monthly',
  '3 MONTH': 'web-pick-3-months',
  '6 MONTH': 'web-pick-6-months',
  LIFETIME: 'web-pick-lifetime',
};

interface PaywallContainerProps {
  onClose?: () => void;
  showCloseButton?: boolean;
  isCheckoutModalOpen: boolean;
  onCheckoutModalClose: () => void;
  isFromSetup?: boolean;
  selectedSubscriptionType: SubscriptionType;
  setSelectedSubscriptionType: (val: SubscriptionType) => void;
  /**
   * On clicking lifetime option
   */
  onSelectLifetime: () => void;
  isLifeTimeSelected?: boolean;
  stripePrices?: StripePricesData;
  location?: string;
  freeTrial?: FreeTrialData;
  hasPromoCode?: boolean;
  /**
   * If prices were displayed with promo code, show prices without it
   * Used when the user can't redeem the offer (previously redeemed).
   * The function is called if the user decides to continue without the promo code
   */
  onDisablePromoCode?: () => void;
  errorMessage?: string;
}

export interface PaymentContainerRef {
  onCreateSubscription: (
    setSubscriptionRequestStatus: (status: RequestStatus) => void,
  ) => void;
}

const PaywallContainer = React.forwardRef<
  PaymentContainerRef,
  PaywallContainerProps & StyleProps
>(
  (
    {
      showCloseButton,
      onClose,
      isCheckoutModalOpen,
      onCheckoutModalClose: onCheckoutModalCloseProp,
      isFromSetup = false,
      selectedSubscriptionType,
      setSelectedSubscriptionType,
      stripePrices,
      freeTrial,
      location,
      onDisablePromoCode,
      hasPromoCode,
      errorMessage,
      onSelectLifetime,
      isLifeTimeSelected,
      ...props
    },
    forwardRef,
  ) => {
    const { colorMode } = useColorMode();

    const [isPromoCodeInvalid, setIsPromoCodeInvalid] = useState(false);

    const {
      clientSecret,
      subscriptionId,
      stripe,
      elementsOptions,
      chooseSubscriptionPlan,
      deleteStripeSubscriptionIntent,
    } = usePaymentInternals({
      selectedSubscriptionOrProductType: selectedSubscriptionType,
      promoCodes: {
        '1 MONTH': stripePrices?.subscriptions['1 MONTH']?.promotion?.code,
        '3 MONTH': stripePrices?.subscriptions['1 MONTH']?.promotion?.code,
        '6 MONTH': stripePrices?.subscriptions['1 MONTH']?.promotion?.code,
        '1 YEAR': stripePrices?.subscriptions['1 YEAR']?.promotion?.code,
        LIFETIME: stripePrices?.products['LIFETIME']?.promotion_code,
      },
      setInvalidPromo: () => setIsPromoCodeInvalid(true),
    });

    const onCheckoutModalClose = () => {
      onCheckoutModalCloseProp();
      // make sure to delete the data
      // otherwise the clientSecret will be corrupted (won't be changed)
      deleteStripeSubscriptionIntent();
    };

    const onClosePaywall = () => {
      onClose?.();
      onCheckoutModalClose();
    };

    const refetchPricesWithoutPromoAndClose = () => {
      onDisablePromoCode?.();
      setIsPromoCodeInvalid(false);
    };

    useImperativeHandle(
      forwardRef,
      () => ({
        onCreateSubscription: chooseSubscriptionPlan,
      }),
      [chooseSubscriptionPlan],
    );

    return (
      <Flex flexDir="column" pos="relative" {...props}>
        <InvalidPromoCodeModal
          refetchPricesWithoutPromoAndClose={refetchPricesWithoutPromoAndClose}
          onClosePaywall={onClosePaywall}
          isOpen={isPromoCodeInvalid}
        />
        <Flex
          flexDir="column"
          px={spacing.toRem(32)}
          pb={spacing.toRem(25)}
          gridGap={spacing.toRem(16)}
        >
          <Flex justifyContent="space-between">
            <PrimaryHeading
              size="h1"
              textSize="l"
              type="bold"
              h={spacing.toRem(40)}
              color={color[colorMode].pro.primary}
              textAlign="start"
              mt={spacing.toRem(-10)}
            >
              {hasPromoCode
                ? pageStrings.paywall.lastChance
                : freeTrial !== undefined
                ? pageStrings.paywall.tryDeepstashProFree
                : pageStrings.paywall.getDeepstashPro}
            </PrimaryHeading>
            {stripePrices?.testMode && (
              <PrimaryHeading
                size="h2"
                textSize="m"
                type="bold"
                px={spacing.XS.rem}
                borderRadius={spacing.XS.rem}
                h={spacing.toRem(36)}
                bgColor={color.failure.default}
                color={color[colorMode].textInverted}
                textAlign="start"
                mt={spacing.toRem(-10)}
              >
                TEST MODE
              </PrimaryHeading>
            )}
            <WhatsIncludedDropdown />
            {showCloseButton && <CloseSvg onClick={onClose} cursor="pointer" />}
          </Flex>

          <Flex
            gridGap={spacing.toRem(16)}
            mt={freeTrial !== undefined ? 'unset' : spacing.XL.rem}
          >
            <PaymentLeftContent />
            {errorMessage ? (
              <Flex
                w="100%"
                h={spacing.toRem(128)}
                alignItems="center"
                justifyContent="center"
                color={color[colorMode].text}
              >
                <PrimaryText type="bold">{errorMessage}</PrimaryText>
              </Flex>
            ) : (
              <PaywallOptionButtons
                prices={stripePrices}
                selectedSubscriptionType={selectedSubscriptionType}
                setSelectedSubscriptionType={setSelectedSubscriptionType}
                onSelectLifetime={onSelectLifetime}
                isLifeTimeSelected={isLifeTimeSelected}
              />
            )}
          </Flex>
        </Flex>
        {clientSecret && (
          <PaywallCheckoutModal
            isCheckoutModalOpen={isCheckoutModalOpen}
            onCheckoutModalClose={onCheckoutModalClose}
            clientSecret={clientSecret}
            paymentDetails={{
              priceAmount:
                stripePrices?.subscriptions[selectedSubscriptionType]?.promotion
                  ?.priceAmount ??
                stripePrices?.subscriptions[selectedSubscriptionType]
                  ?.priceAmount ??
                0,
              renewalPriceAmount:
                stripePrices?.subscriptions[selectedSubscriptionType]
                  ?.priceAmount,
              priceCurrency:
                stripePrices?.subscriptions[selectedSubscriptionType]
                  ?.priceCurrency ?? 'usd',
              subscriptionOrProductType: selectedSubscriptionType,
              freeTrial,
            }}
            isFromSetup={isFromSetup}
            subscriptionId={subscriptionId}
            location={location}
            stripe={stripe}
            elementsOptions={elementsOptions}
            type={'subscription'}
          />
        )}
      </Flex>
    );
  },
);

export default PaywallContainer;
