import useCreateContextConsumer from 'src/providers/hooks/useCreateContextConsumer';
import { NavigationContext } from '../navigation/NavigationProvider';
import {
  NavigationProvideContextType,
  NavigationProviderContextExtra,
  NavigationProviderState,
} from '../navigation/NavigationProviderTypes';

const useNavigation = () => {
  const { dispatch, ...contextConsumer } = useCreateContextConsumer<
    NavigationProviderState,
    never,
    NavigationProvideContextType,
    never,
    never,
    NavigationProviderContextExtra
  >({ context: NavigationContext });
  return { ...contextConsumer, navigationDispatch: dispatch };
};

export default useNavigation;
