import {
  CaretRightSvg,
  color,
  Divider,
  Flex,
  Link,
  spacing,
  StyleProps,
  Text,
  typography,
  useColorMode,
} from 'deepstash-ui';
import React, { useState } from 'react';
import { MENU_STRUCTURE, UNSORTED_TOPICS } from 'utils/metatopics.constants';
import actionStrings from 'utils/strings/actionStrings';
import NextLink from '../navigation/NextLink';
import { BASE_DEEPSTASH_URL } from 'utils/constants';

interface CategoryMenuProps {
  textColorOverride?: string;
}

const CategoryMenuMobile: React.FC<CategoryMenuProps & StyleProps> = ({
  textColorOverride,
  ...props
}) => {
  const { colorMode } = useColorMode();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openSubmenuIndex, setOpenSubmenuIndex] = useState(-1);

  const dropdownTextStyle = {
    ...typography.primaryTextFonts.medium.bold,
    fontWeight: 600,
    color: color[colorMode].textSecondary,
  };

  return (
    <nav>
      <Flex
        cursor="pointer"
        alignItems="center"
        gridGap={spacing.XXS.rem}
        zIndex={1001}
        {...props}
      >
        <Text
          color={textColorOverride ?? color[colorMode].text}
          onClick={() => {
            setIsMenuOpen(x => !x);
            setOpenSubmenuIndex(-1);
          }}
          {...typography.primaryTextFonts.small.semiBold}
          lineHeight="1em"
          textAlign="right"
        >
          Idea Categories
        </Text>
        <CaretRightSvg
          onClick={() => {
            setIsMenuOpen(x => !x);
            setOpenSubmenuIndex(-1);
          }}
          transform="rotate(90deg)"
        />
        <Flex
          position="absolute"
          display={isMenuOpen ? 'flex' : 'none'}
          top={64}
          left={0}
          bgColor={color[colorMode].surface}
          boxShadow="0px 6px 16px rgba(0, 0, 0, 0.2); 0px 8px 4px rgba(0, 0, 0, 0.1);"
          w="100vw"
          flexDirection="column"
          zIndex={1001}
        >
          {MENU_STRUCTURE.map((menuItem, index) => (
            <Flex
              _hover={{ backgroundColor: color[colorMode].underground }}
              flexDirection="column"
              key={`category-menu-item-${index}`}
              overflow={openSubmenuIndex === index ? 'visible' : 'hidden'}
              onClick={() => {
                openSubmenuIndex === -1
                  ? setOpenSubmenuIndex(index)
                  : setOpenSubmenuIndex(-1);
              }}
            >
              {index !== 0 && (
                <Divider
                  marginY={0}
                  marginX={spacing.M.rem}
                  flex={1}
                  color={color[colorMode].underground}
                />
              )}
              <Flex w="100%" alignItems="center" justifyContent="space-between">
                <Text padding={spacing.M.rem} {...dropdownTextStyle}>
                  {UNSORTED_TOPICS[menuItem.id]?.seo_title}
                </Text>
                <CaretRightSvg
                  flexShrink={0}
                  marginRight={spacing.M.rem}
                  boxSize="1rem"
                />
              </Flex>

              <Flex
                position="absolute"
                display={openSubmenuIndex === index ? 'flex' : 'none'}
                top={0}
                left={openSubmenuIndex === index ? 0 : '100vw'}
                transition="0.3s all ease-out"
                overflow="hidden"
                bgColor={color[colorMode].top}
                w="100vw"
                flexDirection="column"
                boxShadow="0px 6px 16px rgba(0, 0, 0, 0.2); 0px 8px 4px rgba(0, 0, 0, 0.1);"
                zIndex={1002}
              >
                <Flex
                  alignItems="center"
                  gridGap={spacing.XXS.rem}
                  w="100%"
                  color={color[colorMode].textDisabled}
                  _hover={{
                    backgroundColor: color[colorMode].underground,
                  }}
                >
                  <CaretRightSvg transform="scaleX(-1)" />
                  <Text
                    padding={spacing.XXS.rem}
                    paddingY={spacing.S.rem}
                    {...typography.primaryTextFonts.medium.bold}
                    fontWeight={600}
                  >
                    {actionStrings.back}
                  </Text>
                </Flex>
                {menuItem.subtopicsById.map((subtopicId, index) => (
                  <NextLink
                    as={`/topic/${subtopicId}`}
                    href={'/topic/[hashTag]'}
                    key={`category-menu-item-${index}`}
                    onClick={() => {
                      setIsMenuOpen(false);
                      setOpenSubmenuIndex(-1);
                    }}
                    passHref
                  >
                    <Flex
                      _hover={{
                        backgroundColor: color[colorMode].underground,
                      }}
                      flexDirection="column"
                    >
                      <Link href={BASE_DEEPSTASH_URL + `/topic/${subtopicId}`}>
                        <Text padding={spacing.S.rem} {...dropdownTextStyle}>
                          {UNSORTED_TOPICS[subtopicId]?.seo_title}
                        </Text>
                      </Link>
                    </Flex>
                  </NextLink>
                ))}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </nav>
  );
};

export default CategoryMenuMobile;
