import { useToast } from 'deepstash-ui';
import { useEffect } from 'react';

interface ToastDetails {
  title: string;
  description: string;
  duration?: number;
  status?: 'error' | 'warning' | 'success' | undefined;
}

interface ToastsControllerProps {
  showToast?: (toastDetails: ToastDetails) => void;
}

export const TOASTS_CONTROLLER: ToastsControllerProps = {};

/**
 * Exposing toast functionality in a global object
 *
 * Place this hook in a global provider, eg `ViewProvider`
 */
export const useToastsController = () => {
  const toast = useToast();

  const onShowToast = (toastDetails: ToastDetails) => {
    toast({
      isClosable: true,
      duration: toastDetails.duration ?? 3000,
      title: toastDetails.title,
      description: toastDetails.description,
      status: toastDetails.status,
    });
  };

  useEffect(() => {
    TOASTS_CONTROLLER.showToast = onShowToast;

    return () => (TOASTS_CONTROLLER.showToast = undefined);
  }, [onShowToast]);
};
