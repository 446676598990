import React from 'react';

const usePreloadImages = ({ imageSources }: { imageSources: string[] }) => {
  React.useEffect(() => {
    const preloadImages = () => {
      imageSources.forEach(imageSrc => {
        const img = new Image();
        img.src = imageSrc;
      });
    };

    preloadImages();
  }, []);
};

export default usePreloadImages;
