import {
  Flex,
  Button,
  color,
  ArrowLeftSvg,
  spacing,
  PrimaryText,
  PrimaryHeading,
  useColorMode,
  PrimaryButton,
  useIsMobileView,
} from 'deepstash-ui';
import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import actionStrings from 'utils/strings/actionStrings';
import pageStrings from 'utils/strings/pageStrings';
import {
  AuthSceneType,
  AuthRequestStatus,
} from '../auth-modal-content/AuthModalContent';
import { UserCredentials } from 'hooks/auth';
import useNsi from 'src/providers/hooks/useNsi';
import { validateEmail, validateSignUpPassword } from 'utils/global';
import SignUpAgreement from './components/SignUpAgreement';
import AuthEmailTextInput from './components/AuthEmailTextInput';
import AuthPasswordTextInput from './components/AuthPasswordTextInput';

const VARIANTS = {
  visible: {
    opacity: 1,
    x: 0,
    display: 'flex',
  },
  invisible: {
    opacity: 0,
    x: '50%',
    display: 'none',
  },
};
interface CreateAccountSceneProps {
  isVisible: boolean;
  setAuthSceneType: (val: AuthSceneType) => void;
  onSignUp: (values: UserCredentials) => Promise<void>;
  authErrorMessage: string;
  requestStatus: AuthRequestStatus;
}

const CreateAccountScene: React.FC<CreateAccountSceneProps> = ({
  isVisible,
  setAuthSceneType,
  onSignUp,
  authErrorMessage,
  requestStatus,
}) => {
  const { colorMode } = useColorMode();
  const isMobileView = useIsMobileView();
  const { nsiDispatch, email, password } = useNsi();
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    setEmailError('');
    setPasswordError('');
  }, [password, email]);

  const validateInputs = useCallback((email: string, password: string) => {
    if (!validateEmail(email)) {
      setEmailError(pageStrings.authentication.emailNotValid);
      return false;
    }
    if (!validateSignUpPassword(password)) {
      setPasswordError(pageStrings.authentication.passwordNotValid);
      return false;
    }

    return true;
  }, []);

  const onSignUpClick = useCallback(() => {
    if (!validateInputs(email, password)) return;

    onSignUp({
      password,
      email,
    });
  }, [onSignUp, email, password]);

  const onChangeEmailHandler = useCallback(
    (val: string) => {
      nsiDispatch({
        type: 'set-email',
        payload: {
          email: val,
        },
      });
    },
    [nsiDispatch],
  );

  const onChangePasswordHandler = useCallback(
    (val: string) => {
      nsiDispatch({
        type: 'set-password',
        payload: {
          password: val,
        },
      });
    },
    [nsiDispatch],
  );

  const onBackClick = useCallback(() => {
    setAuthSceneType('sign-up');
  }, [setAuthSceneType]);

  return (
    <motion.div
      initial={isVisible ? 'visible' : 'invisible'}
      animate={isVisible ? 'visible' : 'invisible'}
      exit={isVisible ? 'visible' : 'invisible'}
      variants={VARIANTS}
      style={{
        overflow: 'hidden',
        flexDirection: 'column',
        paddingLeft: isMobileView ? spacing.M.rem : spacing.XXXL.rem,
        paddingRight: isMobileView ? spacing.M.rem : spacing.XXXL.rem,
      }}
      inherit={false}
      transition={{
        ease: 'easeOut',
        duration: 0.2,
      }}
    >
      <Flex flexDir="column" align="flex-start" width="100%">
        <Flex
          flexDir={{ base: 'row', lg: 'column' }}
          alignItems={{ base: 'center', lg: 'flex-start' }}
          pos="relative"
          width="100%"
          mb={{ base: spacing.S.rem, lg: 'unset' }}
        >
          <Button
            onClick={onBackClick}
            color={color[colorMode].primary.default}
            px={0}
            pos={{ base: 'absolute', lg: 'relative' }}
          >
            <ArrowLeftSvg
              boxSize={spacing.M.rem}
              mr={spacing.XXS.rem}
              mt={spacing.toRem(2)}
            />
            <PrimaryText size="m" type="bold">
              {actionStrings.back}
            </PrimaryText>
          </Button>
          <PrimaryHeading
            size="h2"
            width="100%"
            textAlign={{ base: 'center', lg: 'start' }}
            textSize={isMobileView ? 's' : 'l'}
            type="bold"
          >
            {pageStrings.authentication.createAccount}
          </PrimaryHeading>
        </Flex>
        <AuthEmailTextInput
          onEnterPressed={onSignUpClick}
          emailError={emailError}
          email={email}
          onChange={onChangeEmailHandler}
        />
        <AuthPasswordTextInput
          onEnterPressed={onSignUpClick}
          passwordError={
            passwordError?.length ?? 0 ? passwordError : authErrorMessage
          }
          password={password}
          textInputProps={{
            placeholder: pageStrings.authentication.choosePassword,
          }}
          onChange={onChangePasswordHandler}
        />
        <SignUpAgreement />
        <Flex width="100%" mt={spacing.M.rem} pb={spacing.M.rem}>
          <PrimaryButton
            size="lg"
            onClick={onSignUpClick}
            isLoading={requestStatus === 'loading'}
            isDisabled={
              passwordError.length > 0 ||
              emailError.length > 0 ||
              email.length === 0 ||
              password.length === 0 ||
              requestStatus === 'loading'
            }
            colorMode={colorMode}
            width="100%"
            _disabled={
              requestStatus === 'loading'
                ? {
                    backgroundColor: color[colorMode].primary.default,
                  }
                : {
                    opacity: 0.4,
                  }
            }
          >
            <PrimaryText size="l" type="bold">
              {actionStrings.signUp}
            </PrimaryText>
          </PrimaryButton>
        </Flex>
      </Flex>
    </motion.div>
  );
};

export default CreateAccountScene;
