import { StyleProps, Flex, spacing, color } from 'deepstash-ui';
import { useColorMode } from 'deepstash-ui';
import { useIsMobileView } from 'deepstash-ui';
import useNsi from 'src/providers/hooks/useNsi';
import React, { useCallback, useEffect, useState } from 'react';
import {
  useOnLoginCallback,
  useOnSignUpCallback,
} from '../../../../hooks/auth';
import SignUpScene from '../auth-scenes/SignUpScene';
import SignInScene from '../auth-scenes/SignInScene';
import { AnimatePresence, motion } from 'framer-motion';
import AuthModalTypeFooter from './components/AuthModalTypeFooter';
import AuthModalTypeHeader from './components/AuthModalTypeHeader';
import ForgotPasswordScene from '../auth-scenes/ForgotPasswordScene';
import CreateAccountScene from '../auth-scenes/CreateAccountScene';
import { RequestStatus } from 'types/types';
import { authType } from 'src/providers/nsi/NsiProviderTypes';
import AnimatedHeightContainer from 'src/components/animation/AnimatedHeightContainer';
import { CurrentProfileApiResponse } from 'api/api.types';

const VARIANTS = {
  visible: {
    opacity: 1,
    x: 0,
    display: 'flex',
  },
  invisible: {
    opacity: 0,
    x: '-50%',
    display: 'none',
  },
};

interface AuthModalContentProps {
  authModalType: authType;
  trackLogin?: (email: string) => void;
  discountCode?: string;
  shouldOpenEmailFields?: boolean;
  onAuthSuccess?: (profile: CurrentProfileApiResponse) => void;
  shouldSkipOnboarding?: boolean;
  closeModal: () => void;
}

export type AuthSceneType = authType | 'forgot-password' | 'create-account';

/**
 * Request statuses used to signal that a specific social login button is loading
 */
export type AuthRequestStatus =
  | RequestStatus
  | 'loading-google'
  | 'loading-facebook'
  | 'loading-apple';

const AuthModalContent: React.FC<AuthModalContentProps & StyleProps> = ({
  authModalType,
  trackLogin,
  discountCode,
  shouldOpenEmailFields,
  onAuthSuccess,
  shouldSkipOnboarding,
  closeModal,
  ...props
}) => {
  const { colorMode } = useColorMode();

  const isMobileView = useIsMobileView();

  const [requestStatus, setRequestStatus] =
    useState<AuthRequestStatus>('success');

  useEffect(() => {
    if (shouldOpenEmailFields) {
      setAuthSceneType('create-account');
    }
  }, [shouldOpenEmailFields]);

  const { nsiDispatch } = useNsi();
  const [authErrorMessage, setAuthErrorMessage] = useState('');

  const [authSceneType, setAuthSceneType] = useState<AuthSceneType>(
    shouldOpenEmailFields ? 'create-account' : authModalType,
  );

  const onLogin = useOnLoginCallback({
    setRequestStatus,
    setAuthErrorMessage,
    discountCode,
    onAuthSuccess,
  });

  const onSignUp = useOnSignUpCallback({
    setRequestStatus,
    setAuthErrorMessage,
    discountCode,
    onAuthSuccess,
    shouldSkipOnboarding,
  });

  const toggleAuthType = useCallback(() => {
    nsiDispatch({
      type: 'openAuth',
      payload: {
        type: authModalType === 'sign-in' ? 'sign-up' : 'sign-in',
      },
    });
  }, [authModalType]);

  return (
    <AnimatedHeightContainer
      isVisible={true}
      backgroundColor={color[colorMode].top}
      borderRadius={spacing.L.rem}
      paddingTop={isMobileView ? spacing.L.rem : spacing.XXXL.rem}
      paddingBottom={isMobileView ? spacing.L.rem : spacing.XXXL.rem}
      display="flex"
      flexDirection={'column'}
    >
      <AnimatePresence>
        <motion.div
          key="container"
          initial={
            authSceneType === 'sign-in' || authSceneType === 'sign-up'
              ? 'visible'
              : 'invisible'
          }
          animate={
            authSceneType === 'sign-in' || authSceneType === 'sign-up'
              ? 'visible'
              : 'invisible'
          }
          exit={'invisible'}
          variants={VARIANTS}
          style={{ overflow: 'hidden', flexDirection: 'column' }}
          inherit={false}
          transition={{
            ease: 'easeOut',
            duration: 0.2,
          }}
        >
          <Flex width="100%" flexDir="column" alignItems="center" {...props}>
            <AuthModalTypeHeader
              authModalType={authModalType}
              requestStatus={requestStatus}
              setRequestStatus={setRequestStatus}
              discountCode={discountCode}
              shouldSkipOnboarding={shouldSkipOnboarding}
              onAuthSuccess={onAuthSuccess}
            />
            <AnimatePresence>
              <SignUpScene
                key="sign-up-container"
                isVisible={authModalType === 'sign-up'}
                setAuthSceneType={setAuthSceneType}
              />
              <SignInScene
                key="sign-in-container"
                onLogin={onLogin}
                requestStatus={requestStatus}
                authErrorMessage={authErrorMessage}
                setAuthErrorMessage={setAuthErrorMessage}
                trackLogin={trackLogin}
                isVisible={authModalType === 'sign-in'}
                setAuthSceneType={setAuthSceneType}
              />
            </AnimatePresence>
          </Flex>
          <AuthModalTypeFooter
            toggleAuthMode={toggleAuthType}
            authModalType={authModalType}
            closeModal={closeModal}
          />
        </motion.div>

        <ForgotPasswordScene
          key="password-container"
          isVisible={authSceneType === 'forgot-password'}
          setAuthSceneType={setAuthSceneType}
        />

        <CreateAccountScene
          key="create-account-container"
          isVisible={authSceneType === 'create-account'}
          onSignUp={onSignUp}
          setAuthSceneType={setAuthSceneType}
          authErrorMessage={authErrorMessage}
          requestStatus={requestStatus}
        />
      </AnimatePresence>
    </AnimatedHeightContainer>
  );
};

export default AuthModalContent;
