import {
  Box,
  CheckSvg,
  color,
  ColorMode,
  Flex,
  PrimaryText,
  spacing,
  StyleProps,
  StylingUIComponentProps,
  useColorMode,
} from 'deepstash-ui';
import React, { useCallback } from 'react';
import { SubscriptionType } from 'types/types';

export const getPaywallOptionDiscountStyles = (colorMode: ColorMode) =>
  ({
    DEFAULT: {
      px: spacing.toRem(8),
      py: spacing.toRem(4),
      bgColor: color[colorMode].pro.primary,
      borderRadius: spacing.toRem(8),
      pos: 'absolute',
      top: spacing.toRem(-16),
      left: spacing.toRem(20),
      color: color.light.top,
    },
    PROMOTION: {
      bgColor: color.failure.default,
    },
  } as Record<string, StylingUIComponentProps>);

export type PaywallOptionType = {
  topText: React.ReactChild;
  mainText: React.ReactChild;
  bottomText: React.ReactChild;
  discount?: React.ReactChild;
  styleDiscountComponent?: StyleProps;
  borderColor?: string;
  subscriptionType: SubscriptionType;
};

interface PaywallOptionButtonProps extends PaywallOptionType {
  isSelected: boolean;
  onClick?: () => void;
}

const PaywallOptionButton: React.FC<PaywallOptionButtonProps & StyleProps> = ({
  isSelected,
  topText,
  mainText,
  bottomText,
  discount,
  onClick,
  styleDiscountComponent,
  borderColor,
  ...props
}) => {
  const { colorMode } = useColorMode();

  const DiscountComponent = useCallback(() => {
    if (typeof discount === 'string') {
      return (
        <Box
          {...getPaywallOptionDiscountStyles(colorMode).DEFAULT}
          {...styleDiscountComponent}
        >
          <PrimaryText type="bold">{discount}</PrimaryText>
        </Box>
      );
    } else {
      return (
        <Box
          pos="absolute"
          top={spacing.toRem(-16)}
          {...styleDiscountComponent}
        >
          {discount}
        </Box>
      );
    }
  }, [discount]);

  const renderInnerText = useCallback(() => {
    const topTextComponent =
      typeof topText === 'string' && topText.length > 0 ? (
        <PrimaryText type="bold" color={color[colorMode].text}>
          {topText}
        </PrimaryText>
      ) : (
        topText
      );

    const mainTextComponent = mainText;

    const bottomTextComponent =
      typeof bottomText === 'string' ? (
        <PrimaryText color={color[colorMode].text}>{bottomText}</PrimaryText>
      ) : (
        bottomText
      );

    return (
      <>
        <Flex justifyContent="space-between" w="100%" h={spacing.toRem(20)}>
          {topTextComponent}
          {!topTextComponent && mainTextComponent}
          <Flex
            borderRadius="full"
            p={spacing.toRem(4)}
            bgColor={color[colorMode].pro.primary}
            visibility={isSelected ? 'visible' : 'hidden'}
          >
            <CheckSvg
              fill={color.light.top}
              stroke={color.light.top}
              strokeWidth={2.5}
              boxSize={spacing.toRem(12)}
            />
          </Flex>
        </Flex>
        {topTextComponent && mainTextComponent}
        <Box marginTop={{ base: spacing.toRem(2), lg: spacing.XS.rem }}>
          {bottomTextComponent}
        </Box>
      </>
    );
  }, [topText, mainText, bottomText, isSelected, colorMode]);

  return (
    <Box pos="relative">
      {discount && <DiscountComponent />}
      <Flex
        bgColor={color[colorMode].top}
        boxSizing="border-box"
        p={spacing.toRem(16)}
        borderRadius={spacing.toRem(16)}
        border={`3px solid ${
          isSelected
            ? color[colorMode].pro.primary
            : borderColor ?? props.bgColor ?? color[colorMode].underground
        }`}
        flexDir="column"
        alignItems="start"
        onClick={onClick}
        _hover={{
          cursor: 'pointer',
          // Hover: 0.25 opacity to the border color
          border: `3px solid ${color[colorMode].pro.primary}${
            isSelected ? '' : '40'
          }`,
        }}
        {...props}
      >
        {renderInnerText()}
      </Flex>
    </Box>
  );
};

export default PaywallOptionButton;
