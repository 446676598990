import { useMemo } from 'react';
import { getDomainName } from 'utils/global';
import { generateProxyImage } from 'utils/image-utils';

/**
 * Returns an image URL accepted by next/image
 *
 * Checking for the image to be in configured domains or generating a new url via private cloudfront
 *
 * If the url domain is not in configured domains, the app will crash
 * @param imageUrl
 * @param width
 * @param height
 */
const useNextImageConfiguredDomain = (imageUrl: string) => {
  const isImageDomainInConfiguredDomains = useMemo(() => {
    /**
     * Eg: `graph.facebook.com`
     */
    const imageDomainName = getDomainName(imageUrl);

    return (
      (process.env.NEXT_IMAGE_DOMAINS ?? '')
        .split(' ')
        .includes(imageDomainName) || imageDomainName === ''
    );
  }, [imageUrl]);

  return isImageDomainInConfiguredDomains
    ? imageUrl
    : generateProxyImage(imageUrl);
};

export default useNextImageConfiguredDomain;
