import useAuth from 'src/providers/hooks/useAuth';
import { useEffect } from 'react';
import { useGoogleOneTapLoginCallback } from './useAuthCallbacks';

const useGoogleOneTapLogin = () => {
  const callback = useGoogleOneTapLoginCallback();
  const { isLoggedIn } = useAuth();
  useEffect(() => {
    if (isLoggedIn) {
      //We are already logged in
      return;
    }
    //Display the prompt and handle the response
    window.onload = () => {
      // Any cast is needed to see the google object
      // We do eveything with optional-chaining to ensure the app won't crash

      (window as any).google?.accounts?.id?.initialize?.({
        client_id: process.env.GOOGLE_CLIENT_ID,
        callback: callback,
      });

      (window as any).google?.accounts?.id?.prompt?.();
    };
  }, [isLoggedIn, callback]);
};

export default useGoogleOneTapLogin;
