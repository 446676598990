import { NsiActivityProviderReducer } from './NsiActivityProviderTypes';
import {
  NSI_STASH_ID,
  stashSourceHandler,
  toggleIdeaStashStateHandler,
  unstashSourceHandler,
} from '../activity/ActivityReducerUtils';

const nsiActivityReducer: NsiActivityProviderReducer = (state, action) => {
  switch (action.type) {
    case 'init-state': {
      return {
        ...action.payload,
        reads: {
          totalReads: action.payload.reads.totalReads,
          sessionReads: new Set<number>(action.payload.reads.sessionReads),
        },
      };
    }
    case 'set-idea-stashed': {
      const { ideaId, source, willStash } = action.payload;
      const stashedIdeasIds = Object.keys(state.stash.stashedIdeas).map(el =>
        Number.parseInt(el),
      );
      // If there are no other ideas unstash, this will stash the whole source'
      // If this is an unstash the source can't be fully stashed
      const allIdeasStashed =
        willStash &&
        source.ideas.find(
          idea =>
            idea.id !== ideaId && !stashedIdeasIds.find(id => id === idea.id),
        ) === undefined;
      const newState = toggleIdeaStashStateHandler({
        ideaId,
        allIdeasStashed,
        stashId: willStash ? NSI_STASH_ID : undefined,
        sourceId: source.id,
        currentState: {
          stashedSources: state.stash.stashedSources,
          stashedIdeasIds,
        },
      });

      if (!newState) {
        return state;
      }

      return {
        ...state,
        stash: {
          stashedSources: newState.stashedSources,
          // map the ideas to their sources
          // if an idea doesnt have a source, then it is the idea we just stashed
          stashedIdeas: newState.stashedIdeasIds.reduce((acc, ideaId) => {
            acc[ideaId] = {
              ideaId,
              sourceId: state.stash.stashedIdeas[ideaId]?.sourceId ?? source.id,
            };
            return acc;
          }, {} as Record<number, { ideaId: number; sourceId: number }>),
        },
      };
    }
    case 'stash-source': {
      const { source, ideasIds } = action.payload;
      const stashedIdeasIds = Object.keys(state.stash.stashedIdeas).map(el =>
        Number.parseInt(el),
      );

      const newState = stashSourceHandler({
        source,
        stashId: NSI_STASH_ID,
        ideasIds,
        currentState: {
          stashedSources: state.stash.stashedSources,
          stashedIdeasIds,
        },
      });

      if (!newState) {
        return state;
      }

      return {
        ...state,
        stash: {
          stashedSources: newState.stashedSources,
          // map the ideas to their sources
          // if an idea doesnt have a source, then it is the idea we just stashed
          stashedIdeas: newState.stashedIdeasIds.reduce((acc, ideaId) => {
            acc[ideaId] = {
              ideaId,
              sourceId: state.stash.stashedIdeas[ideaId]?.sourceId ?? source.id,
            };
            return acc;
          }, {} as Record<number, { ideaId: number; sourceId: number }>),
        },
      };
    }

    case 'unstash-source': {
      const { ideasIds, source } = action.payload;
      const stashedIdeasIds = Object.keys(state.stash.stashedIdeas).map(el =>
        Number.parseInt(el),
      );

      const newState = unstashSourceHandler({
        currentState: {
          stashedSources: state.stash.stashedSources,
          stashedIdeasIds,
        },
        source,
        ideasIds,
      });

      if (!newState) {
        return state;
      }

      return {
        ...state,
        stash: {
          stashedSources: newState.stashedSources,
          // map the ideas to their sources
          // if an idea doesnt have a source, then it is the idea we just stashed
          stashedIdeas: newState.stashedIdeasIds.reduce((acc, ideaId) => {
            acc[ideaId] = {
              ideaId,
              sourceId: state.stash.stashedIdeas[ideaId]?.sourceId ?? source.id,
            };
            return acc;
          }, {} as Record<number, { ideaId: number; sourceId: number }>),
        },
      };
    }
    case 'read-idea': {
      const { ideaId } = action.payload;

      const id = ideaId;
      if (id < 0 || state.reads?.sessionReads?.has(id)) {
        return state;
      }
      return {
        ...state,
        reads: {
          totalReads: state.reads.totalReads + 1,
          sessionReads: state.reads?.sessionReads?.add(id) ?? new Set([id]),
        },
      };
    }

    case 'bulk-read-increment': {
      const { reads } = action.payload;

      const totalNewReadsCount = Object.values(reads).reduce(
        (a, b) => a + b,
        0,
      );

      const readIdeaIds = Object.keys(reads).map(key => Number(key));

      return {
        ...state,
        reads: {
          totalReads: (state.reads?.totalReads ?? 0) + totalNewReadsCount,
          sessionReads: new Set([
            ...(state.reads?.sessionReads ?? []),
            ...readIdeaIds,
          ]),
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default nsiActivityReducer;
