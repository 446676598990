import { fetchCurrent } from 'api/Profile';
import { Analytics } from 'src/services/analytics';
import { AsyncAction } from '../types/actions.types';
import {
  ProfileProviderHiddenAction,
  RevalidateProfileArgs,
} from './ProfileProviderTypes';

export const revalidateProfile: AsyncAction<
  ProfileProviderHiddenAction,
  RevalidateProfileArgs
> = async (dispatch, args) => {
  //Start the data fetching
  dispatch({ type: 'revalidate/loading' });
  try {
    const profile = await fetchCurrent();
    args?.onSuccess?.(profile);

    if (profile) {
      Analytics.setUserIdAndProperties({ profile });
    }

    dispatch({ type: 'revalidate/success', payload: { profile } });
  } catch (e) {
    dispatch({ type: 'revalidate/fail' });
  }
};
