// Utility functions to log app events for facebook
// Requires Pixel script from Pixel.ts to be on pages that triggers these events

export const logCompleteRegistration = async ({
  eventID,
  fbp,
  fbc,
  client_user_agent,
  fb_login_id,
}: {
  eventID?: string;
  fbp?: string;
  fbc?: string;
  client_user_agent?: string;
  fb_login_id?: string;
}) => {
  if (typeof window !== undefined && (<any>window).fbq) {
    await (<any>window).fbq('track', 'CompleteRegistration', {
      event_id: eventID,
      eventID,
      fbp,
      fbc,
      client_user_agent,
      fb_login_id,
    });
  }
  return;
};

/**
 * Sends to Facebook Pixel the Logged In state.
 */
export const logLoginEvent = async () => {
  if (typeof window !== undefined && (<any>window).fbq) {
    await (<any>window).fbq('trackCustom', 'Logged In');
  }
  return;
};

/**
 * Sends to Facebook Pixel the Purchase event.
 */
export const logPurchase = async ({
  currency = 'USD',
  value,
  eventID,
  fbp,
  client_user_agent,
}: {
  currency?: string;
  value: number;
  eventID?: string;
  fbp?: string;
  client_user_agent?: string;
}) => {
  if (typeof window !== undefined && (<any>window).fbq) {
    await (<any>window).fbq('track', 'Purchase', {
      currency,
      value,
      event_id: eventID,
      eventID,
      fbp,
      client_user_agent,
    });
  }
};
