const actionStrings = {
  tryForFree: 'Try for free',
  signIn: 'Sign In',
  moreIdeas: 'More ideas',
  signUp: 'Sign Up',
  getStartedForFree: 'Get started for FREE',
  startFreeCta: 'Start Reading for Free',
  getTheFreeApp: 'Get the free app',
  signUpFree: 'Sign up for free',
  signUpFree2: 'Sign Up for FREE',
  saveProgressForFree: 'Save your progress for FREE',
  signOut: 'Sign Out',
  getLifetimePlan: 'Get Lifetime Plan',
  logIn: 'Log In',
  delete: 'Delete',
  save: 'Save',
  saved: 'Saved',
  saves: 'Saves',
  saveAll: 'Save all',
  saveIt: 'save it',
  unsave: 'Unsave',
  newStash: '+ New Stash',
  saveIn: 'Save in',
  in: 'in',
  createStash: 'Create Stash',
  orderByPicker: 'Sort',
  readArticle: 'Read Article',
  readIdeas: 'Read Ideas',
  hideArticle: 'Hide Article',
  showDescription: 'Show Description',
  hideDescription: 'Hide Description',
  configure: 'Configure',
  cancel: 'Cancel',
  publish: 'Publish',
  write: 'Write',
  writeInStudio: 'Write in Studio',
  finish: 'Finish',
  edit: 'Edit ',
  editPost: 'Edit Post',
  update: 'Update',
  logout: 'Log Out',
  rate: 'Rate app',
  recommendToAFriend: 'Recommend to a friend',
  recommendToAFriendMessage: 'Hey, check out this cool app I found: ',
  deleteAccount: 'Delete account',
  darkMode: 'Switch to Dark Theme',
  lightMode: 'Switch to Light Theme',
  copy: 'Copy',
  get: 'Get',
  shareLink: 'Share Link',
  shareAsImage: 'Share as image',
  preview: 'Preview',
  copyText: 'Copy the text',
  copyLink: 'Copy Link to Idea',
  shareIdea: 'Share Idea',
  moveToStash: 'Move to a stash',
  move: 'Move',
  removeIdea: 'Remove Idea',
  report: 'Report',
  saveToStash: 'Save Idea to a Stash',
  deleteStash: {
    title: 'Are you sure?',
    messagePart1: 'You will lose all ',
    messagePart2: ' of your saved ideas.',
    cancelButton: 'cancel',
    deleteButton: 'delete',
  },
  more: 'More',
  back: 'Back',
  addLinkOrBook: 'Add Link or Book',
  finishEdit: 'Finish Editing',
  // Creation
  create: 'Create',
  addIdea: 'Add Idea',
  quickAddIdeas: 'Quick Add Ideas',
  view: 'View',
  tryTheApp: 'Try the App',
  editProfile: 'Edit',
  getDeepstashApp: 'Get the Deepstash app',
  reset: 'Reset',
  redeemDiscount: 'Redeem Discount',
  redeem: 'Redeem',
  claimNow: 'Claim Now',
  seeOfferDetails: 'See offer details',
  start7DayFreeTrial: 'Start 7-day free trial',
  startTrial: 'Start trial',
  start: 'Start',
  claimOffer: 'Claim Offer',
  startReading: 'Start reading',
  signUpToReadForFree: 'Sign up to read for FREE',
  continue: 'Continue',
  continueReading: 'Continue reading',
  seeAll: 'See All',
  seeMore: 'See More',
  seeAllCollections: 'See all collections',
  seeAllTopics: 'See all topics',
  seeAllStashers: 'See all stashers',
  read: 'Read',
  getTheApp: 'Get the app',
  completeCollection: 'Complete collection',
  allRead: 'All read',
  readWithDeepstashPro: 'Read with Deepstash Pro',
  getDeepstashPro: 'Get Deepstash Pro',
  getLifetime: 'Get Lifetime',
  add: 'Add',
  send: 'SEND',
  getFreeTrial: 'Start your 7-day free trial',
  followAll: 'Follow All',
  exploreAllIdeas: 'Explore all ideas',
  savedIn: 'Saved in',
  getPro: 'Get Pro',
  keepReading: 'Keep reading for FREE',
  next: 'Next',
  continueWithEmail: 'Continue with Email',
  // User acquisition
  continueFree: 'Continue Free',
  readLikeAPro: 'Read Like a Pro',
  signUpAndClaimOffer: 'Sign Up & Claim Offer',
  claimYourLimitedOffer: 'Claim Your Limited Offer',
  getOnePlusOneFree: 'Get 1 + 1 FREE',
  getProDiscount: 'Get Pro Discount',
  getAccess: 'Get Access',
  createAccount: 'Create Account',
  apply: 'Apply',
  addImage: 'Add Image',
  startToday: 'Start Today',
  openApp: 'Open App',
  getDeepstash: 'Get Deepstash',
  exportLibrary: 'Export Library',
};

export default actionStrings;
