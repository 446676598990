import { resetPasswordRequest } from 'api/Auth';
import {
  Flex,
  Button,
  color,
  ArrowLeftSvg,
  spacing,
  PrimaryText,
  PrimaryHeading,
  PrimaryButton,
  useColorMode,
  useToast,
} from 'deepstash-ui';
import { motion } from 'framer-motion';
import React, { useCallback, useState } from 'react';
import { TOAST_TIME } from 'utils/constants';
import { validateEmail } from 'utils/global';
import actionStrings from 'utils/strings/actionStrings';
import pageStrings from 'utils/strings/pageStrings';
import { AuthSceneType } from '../auth-modal-content/AuthModalContent';
import AuthEmailTextInput from './components/AuthEmailTextInput';
import { Analytics, Events } from 'src/services/analytics';

const VARIANTS = {
  visible: {
    opacity: 1,
    x: 0,
    display: 'flex',
  },
  invisible: {
    opacity: 0,
    x: '50%',
    display: 'none',
  },
};

interface ForgotPasswordSceneProps {
  isVisible: boolean;
  setAuthSceneType: (val: AuthSceneType) => void;
}

const ForgotPasswordScene: React.FC<ForgotPasswordSceneProps> = ({
  isVisible,
  setAuthSceneType,
}) => {
  const { colorMode } = useColorMode();
  const toast = useToast();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const onResetClick = useCallback(() => {
    if (validateEmail(email)) {
      Analytics.logEvent({
        eventName: Events.configuration.resetPasswordRequest,
        properties: { email: email },
        platforms: ['amplitude'],
      });

      resetPasswordRequest({ email });
      toast({
        title: 'Email Sent!',
        status: 'success',
        duration: TOAST_TIME,
        isClosable: true,
      });
      setEmail('');
    } else {
      setEmailError(pageStrings.authentication.emailNotValid);
    }
  }, [email]);

  const onEmailChangeHandler = useCallback(
    (val: string) => {
      setEmail(val);
      setEmailError('');
    },
    [setEmail, setEmailError],
  );

  return (
    <motion.div
      initial={isVisible ? 'visible' : 'invisible'}
      animate={isVisible ? 'visible' : 'invisible'}
      exit={isVisible ? 'visible' : 'invisible'}
      variants={VARIANTS}
      style={{
        overflow: 'hidden',
        flexDirection: 'column',
        paddingLeft: spacing.XXXL.rem,
        paddingRight: spacing.XXXL.rem,
      }}
      inherit={false}
      transition={{
        ease: 'easeOut',
        duration: 0.2,
      }}
    >
      <Flex flexDir="column" align="flex-start" width="100%">
        <Button
          onClick={() => setAuthSceneType('sign-in')}
          color={color[colorMode].primary.default}
          px={0}
        >
          <ArrowLeftSvg
            boxSize={spacing.M.rem}
            mr={spacing.XXS.rem}
            mt={spacing.toRem(2)}
          />
          <PrimaryText size="m" type="bold">
            {actionStrings.back}
          </PrimaryText>
        </Button>
        <PrimaryHeading size="h2" textSize="l" type="bold">
          {pageStrings.authentication.forgotPassword}
        </PrimaryHeading>
        <PrimaryText size="s" mt={spacing.M.rem}>
          Don't worry, it happens to the best of us. 🤗
          <br />
          <br />
          Enter the email associated with your account. We'll send you an email
          with a password reset link.
        </PrimaryText>
        <AuthEmailTextInput
          onEnterPressed={onResetClick}
          email={email}
          onChange={onEmailChangeHandler}
          emailError={emailError}
        />
        <Flex width="100%" pb={spacing.M.rem}>
          <PrimaryButton
            size="lg"
            onClick={onResetClick}
            isDisabled={email.length === 0 || emailError.length > 0}
            colorMode={colorMode}
            width="100%"
          >
            <PrimaryText size="l" type="bold">
              {actionStrings.reset}
            </PrimaryText>
          </PrimaryButton>
        </Flex>
      </Flex>
    </motion.div>
  );
};

export default ForgotPasswordScene;
