import { KNOW_LIB_COLLECTION_COUNT } from 'utils/constants';
import {
  CollectionApiResponse,
  CollectionsResponse,
  ReadApiResponse,
  RecommendedCollectionsResponse,
} from './api.types';
import { getApiToken, getRequest, postRequest } from './apiRequest';

/**
 * Api call to fetch a collection by id
 * @param id The id of the collection
 * @param token The token for the request
 * @param nextFetchUrl
 */
export const getCollection = ({
  id,
  token,
  nextFetchUrl,
  similarCount,
}: {
  id: number;
  token?: string;
  nextFetchUrl?: string;
  similarCount?: number;
}): Promise<CollectionApiResponse> => {
  try {
    if (isNaN(id)) {
      throw new Error('Collection ID is not a number!');
    }
    let url = `/collection/${id}/?expanded&article_progress&limit=30`;

    if (similarCount !== undefined && similarCount > 0) {
      url += `&similar_count=${similarCount}`;
    }

    if (nextFetchUrl) {
      url = nextFetchUrl.substring(nextFetchUrl.indexOf('collection'));
    }
    return getRequest<CollectionApiResponse>(
      url,
      token ?? getApiToken({ allowDefault: true }),
    );
  } catch (e) {
    return Promise.reject();
  }
};

/**
 * used to get a list of all collections for the KL (NSI)
 * @param url
 * @returns
 */
export const getKlCollections = ({
  offset = 0,
}: {
  offset: number;
}): Promise<RecommendedCollectionsResponse> => {
  try {
    const url = `/collection/kl/?limit=${KNOW_LIB_COLLECTION_COUNT}&offset=${offset}`;
    return getRequest<CollectionsResponse>(
      url,
      getApiToken({ forceDefault: true }),
    );
  } catch (e) {
    return Promise.reject();
  }
};

/**
 * Api call to fetch recommended collections (Collections for you)
 * It is paginated, but we only need the first page of 5 elems for the feed
 * The collection_of_the_week parameter prepends the collection of the week
 */
export const getFeedRecommendedCollections = ({
  token,
}: {
  token?: string;
}): Promise<RecommendedCollectionsResponse> => {
  try {
    const url = `/collection/recommended/?collection_of_the_week&limit=3`;

    return getRequest<RecommendedCollectionsResponse>(
      url,
      token ?? getApiToken(),
    );
  } catch (e) {
    return Promise.reject();
  }
};

/**
 * Api call to fetch collections in reading progress (Continue reading)
 */
export const getStartedCollections = ({
  nextFetchUrl,
}: {
  nextFetchUrl?: string;
}): Promise<RecommendedCollectionsResponse> => {
  try {
    let url = `/collection/?status=started`;
    if (nextFetchUrl) {
      url = nextFetchUrl.substring(nextFetchUrl.indexOf('collection'));
    }
    return getRequest<RecommendedCollectionsResponse>(url, getApiToken());
  } catch (e) {
    return Promise.reject();
  }
};

/**
 * Api call to fetch completed collections
 */
export const getCompletedCollections = ({
  nextFetchUrl,
}: {
  nextFetchUrl?: string;
}): Promise<RecommendedCollectionsResponse> => {
  try {
    let url = `/collection/?status=completed`;
    if (nextFetchUrl) {
      url = nextFetchUrl.substring(nextFetchUrl.indexOf('collection'));
    }
    return getRequest<RecommendedCollectionsResponse>(url, getApiToken());
  } catch (e) {
    return Promise.reject();
  }
};

/**
 * Api call to fetch new and all collections
 */
export const getNewCollections = ({
  nextFetchUrl,
}: {
  nextFetchUrl?: string;
}): Promise<RecommendedCollectionsResponse> => {
  try {
    let url = `/collection/?status=new`;
    if (nextFetchUrl) {
      url = nextFetchUrl.substring(nextFetchUrl.indexOf('collection'));
    }
    return getRequest<RecommendedCollectionsResponse>(
      url,
      getApiToken({ allowDefault: true }),
    );
  } catch (e) {
    return Promise.reject();
  }
};

/**
 * Api call to update collection
 * @param data A Record of idea IDs and their respective read counts inside the collection
 */
export const updateCollectionReads = ({
  data,
  collectionId,
}: {
  data: Record<number, number>;
  collectionId: number;
}) => {
  try {
    const token = getApiToken();
    const url = `/block/reads/?collection=${collectionId}`;
    return postRequest<ReadApiResponse, Record<number, number>>(
      url,
      token,
      data,
    );
  } catch (e) {
    return Promise.reject();
  }
};
