import { CurrentProfileApiResponse } from 'api/api.types';
import dynamic from 'next/dynamic';
import React, { useCallback, useRef, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import SIMobileWebChecker from 'src/components/layout/components/SIMobileWebChecker';
import AuthProvider from 'src/providers/auth/AuthProvider';
import useProfile from 'src/providers/hooks/useProfile';
import NavigationProvider from 'src/providers/navigation/NavigationProvider';
import { normalizeUserActivity } from 'utils/normalizers/activity';
import { normalizeProfile } from 'utils/normalizers/profile';
import ExperimentProvider from './experiments/ExperimentProvider';
import useAuth from './hooks/useAuth';
import NsiActivityProvider from './nsi-activity/NsiActivityProvider';
import PaywallProvider from './paywall/PaywallProvider';

const ActivityProvider = dynamic(
  () => import('src/providers/activity/ActivityProvider'),
);
const NsiProvider = dynamic(() => import('./nsi/NsiProvider'));
const IntersectionObserverProvider = dynamic(
  () => import('./reads-autoincrement/IntersectionObserverProvider'),
);
const ProfileProvider = dynamic(
  () => import('src/providers/profile/ProfileProvider'),
);
const OnboardingProvider = dynamic(
  () => import('src/providers/onboarding/OnboardingProvider'),
);

/**
 * Here we render the global providers
 * The rendering happens in 3 stages
 * 1. Render the auth provider
 * 2. If we are logged in render profile provider
 * 3. Render all the rest of the providers.
 */

const ContextProvider: React.FC<any> = ({ children, ...props }) => {
  const { isLoggedIn } = useAuth();
  const { profile } = useProfile();
  if (isLoggedIn && profile) {
    return (
      <ActivityProvider activity={props.activity}>
        <OnboardingProvider>
          <IntersectionObserverProvider>
            {children}
          </IntersectionObserverProvider>
        </OnboardingProvider>
      </ActivityProvider>
    );
  } else {
    return (
      <NsiProvider>
        <NsiActivityProvider>
          <IntersectionObserverProvider>
            {children}
          </IntersectionObserverProvider>
        </NsiActivityProvider>
      </NsiProvider>
    );
  }
};

const ContextProviderWithProfile = (props: any) => {
  //This state is used to keep the Activity and Profile in  sync.
  //If something happens in the ProfileProvider
  //and some side effect needs to propagate to the ActivityProvider it should be done through this state
  const [activity, setActivity] = useState(
    props.profile ? normalizeUserActivity(props.profile) : undefined,
  );

  /**
   * Update the activity context after rechecking `/current`
   * @param profile The latest profile value from API
   */
  const profileRevalidated = useCallback(
    (profile?: CurrentProfileApiResponse) => {
      setActivity(profile ? normalizeUserActivity(profile) : undefined);
    },
    [],
  );

  const { isLoggedIn } = useAuth();
  if (isLoggedIn) {
    return (
      <ProfileProvider
        profile={props.profile ? normalizeProfile(props.profile) : undefined}
        notifyProfileRevalidation={profileRevalidated}
      >
        <PaywallProvider>
          <SIMobileWebChecker>
            <ContextProvider activity={activity} {...props} />
          </SIMobileWebChecker>
        </PaywallProvider>
      </ProfileProvider>
    );
  } else {
    return <ContextProvider {...props} />;
  }
};

const ContextProviderWithAuth = (props: any) => {
  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          cacheTime: 10 * 60 * 1000,
          staleTime: 5 * 1000,
          refetchOnMount: true,
        },
      },
    }),
  );

  return (
    <QueryClientProvider client={queryClient.current}>
      <NavigationProvider>
        <AuthProvider
          token={props.token}
          cookieAgreement={props.cookieAgreement}
        >
          <ExperimentProvider>
            <ContextProviderWithProfile {...props} />
          </ExperimentProvider>
        </AuthProvider>
      </NavigationProvider>
    </QueryClientProvider>
  );
};

export default ContextProviderWithAuth;
