import { useEffect } from 'react';
import jsCookie from 'js-cookie';
import { useRouter } from 'next/router';
import { Analytics, Events } from 'services/analytics';
import { clearUrlParams } from 'utils/global';
import useLocation from './useLocation';

const useUtmCampaign = () => {
  const router = useRouter();
  const location = useLocation();
  const {
    utm_campaign,
    utm_source,
    utm_medium,
    utm_content,
    utm_term,
    ttclid,
  } = router.query;

  useEffect(() => {
    if (utm_campaign) {
      jsCookie.set('utm_campaign', utm_campaign, {
        expires: 1,
      });
      // Don't spam the event for web funnel users
      if (location !== 'growth-plan') {
        Analytics.logEvent({
          eventName: Events.consumption.webOpenFromLink,
          properties: {
            location: location ?? '/',
            asPath: router.asPath,
            ...router.query,
          },
          platforms: ['amplitude-mobile'],
        });
        clearUrlParams({
          params: [
            'utm_campaign',
            'utm_source',
            'utm_medium',
            'utm_content',
            'utm_term',
          ],
          router,
        });
      }
    }
  }, [utm_campaign]);

  useEffect(() => {
    if (utm_source) {
      jsCookie.set('utm_source', utm_source, {
        expires: 1,
      });
    }
  }, [utm_source]);

  useEffect(() => {
    if (utm_medium) {
      jsCookie.set('utm_medium', utm_medium, {
        expires: 1,
      });
    }
  }, [utm_medium]);

  useEffect(() => {
    if (utm_content) {
      jsCookie.set('utm_content', utm_content, {
        expires: 1,
      });
    }
  }, [utm_content]);

  useEffect(() => {
    if (utm_term) {
      jsCookie.set('utm_term', utm_term, {
        expires: 1,
      });
    }
  }, [utm_term]);

  useEffect(() => {
    if (ttclid) {
      jsCookie.set('ttclid', ttclid, {
        expires: 1,
      });
    }
  }, [ttclid]);

  return {
    utmCampaign: jsCookie.get('utm_campaign'),
    utmSource: jsCookie.get('utm_source'),
    utmContent: jsCookie.get('utm_content'),
    utmMediun: jsCookie.get('utm_medium'),
    utmTerm: jsCookie.get('utm_term'),
    ttclid: jsCookie.get('ttclid'),
  };
};

export default useUtmCampaign;
