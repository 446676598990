import { Flex, FlexProps } from 'deepstash-ui';
import React from 'react';
import { GAP_SIZE, GAP_SIZE_REM, getRemWidthFromColumns } from 'utils/global';

export interface FullWidthSectionWrapperProps {
  children?: React.ReactNode;
  containerProps?: FlexProps;
}

const FullWidthSectionWrapper = React.forwardRef<
  HTMLDivElement | null,
  FullWidthSectionWrapperProps & FlexProps
>(({ children, containerProps, ...props }, ref) => {
  return (
    <Flex width="100%" justifyContent="center" ref={ref} {...props}>
      <Flex
        width={{
          base: '100%',
          md: getRemWidthFromColumns(8),
          lg: getRemWidthFromColumns(12),
        }}
        paddingX={{ base: GAP_SIZE, md: 0 }}
        gridGap={GAP_SIZE_REM}
        {...containerProps}
      >
        {children}
      </Flex>
    </Flex>
  );
});

export default FullWidthSectionWrapper;
