import {
  Button,
  color,
  Flex,
  PrimaryText,
  spacing,
  useColorMode,
} from 'deepstash-ui';

import React from 'react';
import AnimatedHeightContainer from 'src/components/animation/AnimatedHeightContainer';
import pageStrings from 'utils/strings/pageStrings';
import { AuthSceneType } from '../auth-modal-content/AuthModalContent';
import SignUpAgreement from './components/SignUpAgreement';

interface SignUpSceneProps {
  isVisible: boolean;
  setAuthSceneType: (val: AuthSceneType) => void;
}

const SignUpScene: React.FC<SignUpSceneProps> = ({
  isVisible,
  setAuthSceneType,
}) => {
  const { colorMode } = useColorMode();

  return (
    <AnimatedHeightContainer isVisible={isVisible}>
      <Flex
        alignItems={'center'}
        width="100%"
        flexDir={'column'}
        pb={spacing.M.rem}
      >
        <Button
          onClick={() => setAuthSceneType('create-account')}
          color={color[colorMode].primary.default}
          px={0}
          mb={spacing.M.rem}
          id="auth-modal-signup-button"
        >
          <PrimaryText size="m" type="bold" id="auth-modal-signup-button-text">
            {pageStrings.authentication.continue}
          </PrimaryText>
        </Button>
        <SignUpAgreement />
      </Flex>
    </AnimatedHeightContainer>
  );
};

export default SignUpScene;
