import {
  PaymentProvider,
  ReviewStatus,
  SourceSubtype,
  SourceType,
  SubscriptionPlan,
  UserSubscriptionStatus,
  UserSubscriptionType,
} from './../types/enums';

import {
  ArticleStatus,
  IdeaType,
  ListOrder,
  NotificationScreen,
  NotificationTrackingID,
  OnboardingTooltips,
  PeriodUnit,
  ProfileTopicRanking,
  RecentSavedHashtag,
  TopicsPublishedIn,
  UserType,
} from 'types';

export type ResponseMeta = {
  limit: number;
  offset: number;
  total_count: number;
  next: string | null;
  previous: string | null;
};

export interface TopicApiResponse {
  author_order?: number;
  category_order: number;
  color: string;
  deeptopic?: boolean;
  description?: string;
  id: number;
  image_height: number;
  image_url: string;
  image_secondary_url?: string;
  image_width: number;
  name: string;
  resource_uri?: string;
}

export interface PaginatedApiResponse<T> {
  meta: ResponseMeta;
  objects: T[];
}

export interface UserDataApi {
  bio: string | null;
  first_name: string;
  id: number;
  last_name: string;
  photo: string;
  photo_blurhash?: string;
  url: string | null;
  username: string;
  has_profile?: boolean;
  image_preview?: string;
  subscription_type?: number;
}

export interface CreateSourceApiResponse {
  author?: string;
  created_at: string;
  description?: string;
  id: number;
  image?: string;
  status: ArticleStatus;
  title: string;
  type: number;
  source_type: SourceType;
  source_subtype: SourceSubtype;
  updated_at: string;
  url?: string;
}

export interface SeoArticleApiResponse {
  source?: BookApiResponse;
  recommended_collection?: CollectionApiResponse;
  similar_collections?: CollectionApiResponse[];
}

export interface ArticleApiResponse {
  author?: string;
  blocks?: InsightApiResponse[];
  content?: string;
  content2?: string;
  created: number;
  created_at: string;
  description?: string;
  hashtags?: string[];
  id: number;
  image?: string;
  image_compressed?: string;
  image_preview?: string | null;
  image_color?: string | null;
  /**
   * Blur Loading Image
   */
  image_blurhash?: string;
  images?: string[];
  publishers?: string[];
  status: ArticleStatus;
  seo?: SeoArticleApiResponse;
  suggested_blocks?: SuggestedBlockApiResponse[];
  suggested_hashtags?: string[];
  title: string;
  title_in_collection?: string;
  /** @deprecated */
  type: number;
  source_type: SourceType;
  source_subtype: SourceSubtype;
  updated_at: string;
  url?: string;
  user_data: UserDataApi;
  resource_uri?: string;
  theme?: number;
  topics?: TopicApiResponse[];
  insights?: InsightApiResponse[];
  saved?: boolean;
  similar_articles?: ArticleApiResponse[];
  stash_published_in: StashApiResponse;
  context?: string;
  total_comments: number;
  total_reactions: number;
  top_reactions: SourceTopReactionApiResponse[];
  total_reads?: number;
  total_blocks: number;
  total_likes: number;
  total_saves: number;
}

export interface RelatedPostsResponse {
  same_source_articles?: ArticleApiResponse[];
  similar_articles?: ArticleApiResponse[];
}

export interface PublisherBookApiResponse {
  created_at: string;
  description?: string;
  id: number;
  identifier: string;
  image?: string;
  image_blurhash?: string;
  image_compressed?: string;
  name: string;
  source_subtype: number;
  source_type: number;
  url?: string;
  top_sources?: BookApiResponse[];
}

export interface BookApiResponse {
  id: number;
  created_at: string;
  description: string;
  identifier: string;
  image: string;
  image_blurhash: string;
  image_compressed: string;
  published_at: string;
  publishers: PublisherBookApiResponse[];
  source_subtype: number;
  source_type: number;
  title: string;
  top_posts?: ArticleApiResponse[];
  url?: string;
}

export interface UnknownSourceApiResponse {
  created_at: string;
  id: number;
  status: ArticleStatus;
  title: string;
  source_type: SourceType;
  source_subtype: SourceSubtype;
  url: string;
}

export interface InsightApiResponse {
  article?: ArticleApiResponse;
  articleId?: number;
  article_id?: number;
  author_name?: string | null;
  background_color?: string;
  saved_by_user: boolean;
  search_vector?: string;
  content: string;
  created_at: string;
  decorative?: boolean;
  deleted: boolean;
  hashtags?: string[];
  id: number;
  image?: string;
  image_compressed?: string;
  image_preview?: string;
  image_blurhash?: string;
  image_position?: number;
  insights?: InsightApiResponse[];
  insightsById?: number[];
  is_featured_in_collection?: boolean;
  resource_uri: string;
  selection?: string;
  source_id?: string;
  stash: string;
  stashes?: string[];
  subtitle?: string;
  theme?: number;
  title?: string;
  total_saves: number;
  total_reads: number;
  // [Like functionality]
  total_likes: number;
  url: string;
  url_description?: string;
  url_icon?: string;
  url_image?: string;
  url_title: string;
  user?: string;
  /**
   * @deprecated
   */
  topics: TopicApiResponse[];
  order: number;
  number_of_reads: number;
  time_to_read: number | string;
  status?: number;
  quality?: number;
  type: IdeaType;
}

export interface CreateMultipleIdeasReq {
  article_id: number;
  blocks: CreateInsightRequestData[];
}

export interface ReadApiResponse {
  reads: Record<number, number>;
  read_goal: number;
  read_today: number;
  total_reads: number;
}

export interface StashApiResponse {
  article_count?: number;
  book_count: number;
  color?: string;
  created_at?: string;
  daily_stash?: boolean;
  articles?: ArticleApiResponse[];
  blocks?: InsightApiResponse[];
  deepstash?: boolean;
  description?: string;
  id: number;
  image?: string;
  emoji: string;
  image_compressed?: string;
  image_preview?: string | null;
  insights_count: number;
  last_promoted_at?: string;
  name: string;
  resource_uri?: string;
  thematic?: boolean;
  type?: number;
  user: string;
  user_data?: UserDataApi;
  stash_ideas?: number[];
  sort?: ListOrder;
  meta_articles?: {
    article_limit: number;
    article_offset: number;
    next: string | null;
    previous: string | null;
    total_count: number;
  };
}

export interface SuggestedBlockApiResponse {
  id: string;
  is_header: boolean;
  content: string;
  tag?: string;
}

export interface ActivityApiResponse {
  activity_id: string;
  actor: number;
  date: string;
  object: number;
  verb: string;
  extra: {
    article?: ArticleApiResponse;
    blocks_ids?: number[];
    articles?: ArticleApiResponse[];
    text?: string;
    article_count?: number;
    idea_count?: number;
    image?: string;
    name?: string;
    topic_id?: number;
    topic_image_height?: number;
    topic_image_url?: string;
    topic_image_width?: number;
    topic_name?: string;
    users?: UserDataApi[];
    user_data?: UserDataApi;
    hashtag?: string;
  };
}

export interface SearchedHashtagApiResponse {
  articles: ArticleApiResponse[];
  name: string;
  total_ideas: number;
}

export interface ArticlesResponse {
  meta: ResponseMeta;
  objects: ArticleApiResponse[];
}

export interface KLArticlesResponse {
  meta: ResponseMeta;
  objects: KLArticleApiResponse[];
}

export interface InsightsResponse {
  meta: ResponseMeta;
  objects: InsightApiResponse[];
}

export interface StashesResponse {
  meta: ResponseMeta;
  objects: StashApiResponse[];
}

export interface ActivitiesResponse {
  meta: ResponseMeta;
  objects: ActivityApiResponse[];
}

export interface SearchedHashtagsResponse {
  meta: ResponseMeta;
  objects: SearchedHashtagApiResponse[];
}

export interface UpdateElementResponse {
  save_all: boolean;
}

export type ProfilePhotoUpdateResponse = {
  photo: string;
};

export interface UnsubscribeElementResponse {
  message: string;
  status: string;
}

export interface ResetPasswordResponse {
  message: string;
  status: string;
}

export type SearchResponse =
  | ArticlesResponse
  | StashesResponse
  | InsightsResponse
  | SearchedHashtagsResponse
  | UsersResponse;

export interface UpdateStashRequestData {
  name?: string;
  emoji?: string;
}

export interface SaveArticleData {
  stash: string;
}

export interface SaveInsightData {
  stash: string;
}

export interface SignUpRequestData {
  email: string;
  password: string;
  topics?: string;
  block?: number;
  onboarding?: boolean;
}

export interface LoginResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
}

interface SignUpRequestError {
  user: [string];
}

export type SignUpResponse = UpdateElementResponse | SignUpRequestError;

export interface TokenSignUpResponse {
  is_new: boolean;
  token: string;
  oauth_token: {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    scope: string;
    token_type: string;
  };
}

// export interface UserTopicResponse {
//   id: number;
//   num_blocks: number;
// }

export interface UserStashResponse {
  id: number;
  name: string;
  type: number;
  insightById?: number[];
}

export interface UserPreferencesRequestData {
  daily_emails?: boolean;
  subscribed?: boolean;
  product_emails?: boolean;
  daily_reading_goal?: number;
  reading_goal_alert?: boolean;
}

export interface UpdateProfileRequestData {
  first_name?: string;
  last_name?: string;
  email?: string;
  username?: string;
  photo?: string;
  bio?: string;
  url?: string;
  location?: string;
  did_onboarding_stashing?: boolean;
  department?: string;
  creation_onboarding?: OnboardingTooltips;
}

export interface UpdateTopicsRequestData {
  topics: string;
}

export interface CreateInsightRequestData {
  article: string;
  source_id?: string;
  title?: string;
  author_name?: string;
  content: string;
  image?: string;
  image_position?: number;
  status?: number;
  quality?: number;
  type: IdeaType;
  order?: number;
}

export interface EditIdeaReqBlock {
  content?: string;
  author_name: string | null;
  title: string | null;
  image: string | null;
  quality?: number;
  order?: number;
  status?: number;
  type?: IdeaType;
  /**
   * Mandatory for editing a list of ideas
   */
  id?: number;
}

export interface CreateMultipleIdeasResponse {
  blocks: InsightApiResponse[];
}

export interface UnsubscribeUserData {
  email?: string;
  id?: string;
  key: string;
}

export interface ResetPasswordData {
  new_password: string;
}

export interface SetPasswordData {
  key: string;
  password: string;
}

export interface EmailOnlySignupResponse {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
  token_type: 'Bearer';
  login_link?: string;
}

export interface EmailOnlySignupData {
  email: string;
}

export interface ReadsByDayApiResponse {
  date: string;
  goal_achieved: boolean;
  reads: number;
}

export type ReadsByMonth = ReadsByDayApiResponse[];

export interface GoogleBookApiResponse {
  items: GoogleBookItem[];
}

export interface GoogleBookItem {
  id: string;
  volumeInfo: {
    authors?: string[];
    imageLinks: {
      thumbnail: string;
      smallThumbnail: string;
    };
    title: string;
    canonicalVolumeLink: string;
    description?: string;
  };
}

export interface ProfileApiResponse {
  bio: string | null;
  current_streak: number;
  daily_reading_goal: number;
  date_joined: string;
  first_name: string;
  hashtag_rankings: ProfileTopicRanking[];
  id: number;
  image_preview: string | null;
  last_name: string;
  location: string | null;
  num_followers: number;
  num_following: number;
  num_published_articles: number;
  num_published_ideas: number;
  num_reads: number;
  num_saved_ideas: number;
  num_saves_on_published_ideas: number;
  num_stashes: number;
  photo: string;
  photo_blurhash?: string;
  published_ideas_hashtags: TopicsPublishedIn[];
  read_today: number;
  recent_saved_hashtags: RecentSavedHashtag[];
  twitter_username: string | null;
  url: string | null;
  username: string;
  received_reactions: ReceivedReactionApiResponse[];
  subscription_type: number;
}

export interface UsersResponse {
  meta: ResponseMeta;
  objects: UserDataApi[];
}

export interface InviteDynamicLinkApiResponse {
  link: string;
  h: string;
  u: number;
}

export interface Notification {
  avatar_url: string;
  body: string | null;
  category: number;
  created_at: string;
  destination_app_screen: NotificationScreen;
  id: number;
  resource_id: number;
  resource_name: string | null;
  resource_type: string;
  title: string | null;
  tracking_id: NotificationTrackingID;
  was_clicked: boolean;
}

export interface NotificationApiResponse {
  meta: ResponseMeta;
  notifications: Notification[];
}

export interface LatestTopic {
  id: number;
  image_height: number;
  image_width: number;
  name: string;
  image_url: string;
}

//Profile

export interface ProfileApiResponseStash {
  count: number;
  id: number;
  image: string | null;
  image_compressed: string | null;
  name: string;
  type: number;
  emoji: string;
}

export interface ProfileApiResponseAchievement {
  data: string[];
  type: string;
}

export interface CurrentProfileApiResponse {
  achievements: ProfileApiResponseAchievement[];
  created_articles: number[];
  created_articles_count: number;
  created_at: string;
  created_insights_count: number;
  current_streak: number;
  daily_emails: boolean;
  daily_reading_goal: number;
  did_onboarding: boolean;
  did_onboarding_stashing: boolean;
  drafts_count: number;
  email: string;
  first_name: string;
  followers: number[];
  following: number[];
  followed_hashtags: string[];
  id: number;
  insight_count: number;
  language: number;
  last_name: string;
  longest_streak: number;
  name: string;
  num_reads: number;
  new_notifications_count: number;
  photo: string;
  photo_blurhash?: string;
  promotional_sale?: SaleApiResponse;
  image_preview: string;
  published_articles_count: number;
  product_emails: boolean;
  reading_goal_alert: boolean;
  read_ideas: Record<number, number>;
  read_today: number;
  saved_articles: number[];
  saved_by_id: number[];
  show_rate: boolean;
  /**
   * 0 - idea id
   * 1 - stash id
   */
  stashed_insights: [number, number][];
  stashes: ProfileApiResponseStash[];
  stashes_count: number;
  subscribed: boolean;
  drafts_new: number;
  bio: string | null;
  url: string | null;
  username: string;
  location: string | null;
  suggested_username?: string | null;
  liked_blocks: number[];
  twitter_username: string | null;
  department?: string;
  user_type?: UserType;
  hashtag_rankings: ProfileTopicRanking[];
  published_ideas_hashtags: TopicsPublishedIn[];
  recent_saved_hashtags: RecentSavedHashtag[];
  num_saves_on_published_ideas: number;
  num_published_ideas: number;
  all_reactions: ReactionApiResponse[];
  received_reactions?: ReceivedReactionApiResponse[];
  subscription_type: UserSubscriptionType;
  creation_onboarding?: OnboardingTooltips;
}

export interface SaleApiResponse {
  sale_id: string;
  title: string;
  discount_percentage: number;
  start_date: string;
  end_date: string;
}

export interface HashtagsApiResponse {
  hashtags: HashtagData[];
  suggested_hashtags: HashtagData[];
}

export interface HashtagSeoApiResponse extends HashtagData {
  headings: string[];
  descriptions: string[];
  img_urls: string[];
  blocks: InsightApiResponse[];
  collections: CollectionApiResponse[];
}

export interface HashtagApiResponse {
  name: string;
  title: string;
  is_meta_hashtag: boolean;
  id: number;
  description: string;
  seo?: HashtagSeoApiResponse;
  seo_title?: string;
  seo_description?: string;
}

export interface HashtagData {
  name: string;
}

export interface HashtagsOnboardingApiResponse {
  hashtags: HashtagApiResponse[];
}

export interface UserTopicApiResponse {
  topics: {
    name: string;
    image_url: string;
  }[];
}

export interface KLArticleApiResponse {
  author: string | null;
  created_at: string;
  description: string;
  id: number;
  image: string;
  image_color?: string | null;
  images: string[];
  stash_published_in: {
    description: string | null;
    emoji: string;
    id: number;
    image: string;
    name: string;
  } | null;
  status: number;
  suggested_blocks: {
    content: string;
    id: string;
    is_header: boolean;
  }[];
  title: string;
  type: number;
  updated_at: string;
  url: string;
  blocks: InsightApiResponse[];
  user_data: UserDataApi;
  context?: string;
  total_comments: number;
  total_reactions: number;
  top_reactions: SourceTopReactionApiResponse[];
  total_reads: number;
}

export interface HashtagSourcesApiResponse {
  hashtag: string;
  meta: ResponseMeta;
  objects: ArticleApiResponse[];
  related_hashtags: string[];
  top_stashers: UserDataApi[];
  total_ideas: number;
  total_related_hashtags: number;
  total_top_stashers: number;
  seo_title?: string;
  seo_description?: string;
}

// [Like functionality]
// TO BE DELETED
export interface LikedArticlesApiResponse {
  meta: ResponseMeta;
  objects: ArticleApiResponse[];
}

export interface DepartmentsApiResponse {
  departments: ProfessionApiResponse[];
}

export interface ProfessionApiResponse {
  name: string;
  personal_hashtags: string[];
  trending_hashtags: string[];
  work_hashtags: string[];
}

export interface UnsplashaApiResponse {
  urls: {
    small: string;
    regular: string;
  };
  user: {
    username: string;
    name: string;
  };
}

export interface ScrappedArticleApiResponse extends ArticleApiResponse {
  review_status: ReviewStatus;
}

export interface ScrappedArticlesResponse {
  meta: ResponseMeta;
  objects: ScrappedArticleApiResponse[];
}

export interface RelatedTopicResponse {
  id: number;
  name: string;
}

export interface UserCommentApiResponse {
  id: number;
  created_at: string;
  text_html: string;
  user_data: UserDataApi;
  article?: ArticleApiResponse;
}

export interface UserCommentsApiResponse {
  meta: ResponseMeta;
  objects: UserCommentApiResponse[];
}

export interface ReactionApiResponse {
  id: number;
  name: string;
  reaction_url: string;
}

export interface ReceivedReactionApiResponse {
  reaction_id: number;
  total_users: number;
}

export interface SourceTopReactionApiResponse {
  reaction_id: number;
  total_users: number;
}

export interface SourceReactionsApiResponse {
  article_reactions?: Record<string, number>;
  meta: ResponseMeta;
  objects: SourceReactionObjectApiResponse[];
}

export interface SourceReactionObjectApiResponse {
  reaction: {
    id: number;
  };
  user: UserDataApi;
}

export interface AllReactionsApiResponse {
  all_reactions: ReactionApiResponse[];
}

export interface SourcesTopReactionsApiResponse {
  top_reactions: Record<string, SourceTopReactionApiResponse[]>;
}

export interface InfluencerApiResponse {
  article?: ArticleApiResponse;
  channel_image_url: string;
  channel_name: string;
  channel_type: number;
  creator_name: string;
  creator_tag: string;
  label_discount: string;
  link: string;
  stripe_promotion_code: string;
  user: UserDataApi;
}

/** Model for a stripe subscription option */
export interface StripeSubscriptionApiResponse {
  // free_trial_duration_days: number;
  // free_trial_eligibility: boolean;
  normalized_monthly_price: number;
  normalized_yearly_price: number;
  price_amount: number;
  // price_amount_unit: number;
  price_currency: string; // TO-DO map with  currency_symbol
  price_id: string;
  promotion_code?: string;
  promotion_discount_percentage?: number;
  promotion_normalized_monthly_price?: number;
  promotion_normalized_yearly_price?: number;
  promotion_price_amount?: number;
  promotion_yearly_savings_percentage?: number;
  promotion_yearly_savings_percentage_rounded?: number;
  sku: string;
  subscription_period_number: number;
  subscription_period_unit: PeriodUnit;
  trial_period_number?: number;
  trial_period_unit?: PeriodUnit;
  yearly_savings_percentage?: number;
  yearly_baseline_price: number;
}

export interface StripeProductApiResponse {
  title: string;
  price_id: string;
  price_currency: string;
  price_amount: number;
  promotion_code: string;
  promotion_discount_percentage: number;
  promotion_price_amount: number;
}

/** Model that has the data to show the subscription options */
export interface StripePricesApiResponse {
  publishable_key: string;
  subscriptions: StripeSubscriptionApiResponse[];
  products?: StripeProductApiResponse[];
  promotion_code_error?: string;
  test_mode?: boolean;
}

/** Model for the data received when we make a subscription intent (a.k.a. choose a subscription option and move to the CheckoutModal) */
export interface StripeSubscriptionIntentApiResponse {
  payment_intent_client_secret: string;
  subscription_id: string;
}

export interface StripeSetupIntentApiResponse {
  setup_intent_client_secret: string;
}

export type StripeSubscriptionOrSetupIntentApiResponse =
  | StripeSubscriptionIntentApiResponse
  | StripeSetupIntentApiResponse;

export interface StripeProductPurchaseIntentApiResponse {
  payment_intent_client_secret: string;
}

export function isStripeSubscriptionIntentApiResponse(
  obj: any,
): obj is StripeSubscriptionIntentApiResponse {
  return (
    (obj as StripeSubscriptionIntentApiResponse)
      .payment_intent_client_secret !== undefined
  );
}

export interface StripeConfirmSubscriptionBodyRequest {
  payment_intent: string;
  payment_intent_client_secret: string;
  subscription_id: string;
  purchase_location: string;
  facebook_fbp?: string;
  facebook_fbc?: string;
  facebook_login_id?: string;
  client_user_agent?: string;
  exp_variant?: string;
  webpage_url?: string;
  tiktok_ttcid?: string;
  tiktok_ttp?: string;
  snapchat_click_id?: string;
  snapchat_cookie?: string;
}

export interface StripeConfirmSetupBodyRequest {
  setup_intent: string;
  setup_intent_client_secret: string;
  purchase_location: string;
  facebook_fbp?: string;
  facebook_fbc?: string;
  facebook_login_id?: string;
  client_user_agent?: string;
  exp_variant?: string;
  webpage_url?: string;
  tiktok_ttcid?: string;
  tiktok_ttp?: string;
  snapchat_click_id?: string;
  snapchat_cookie?: string;
}

export interface StripeConfirmProductPurchaseBodyRequest {
  payment_intent: string;
  payment_intent_client_secret: string;
  purchase_location: string;
  facebook_fbp?: string;
  facebook_fbc?: string;
  facebook_login_id?: string;
  client_user_agent?: string;
  exp_variant?: string;
  webpage_url?: string;
  tiktok_ttcid?: string;
  tiktok_ttp?: string;
  snapchat_click_id?: string;
  snapchat_cookie?: string;
}

export interface StripeLogOpenStripeCheckoutModalRequest {
  price_id: string;
  client_user_agent?: string;

  // Facebook pixel params
  facebook_fbc?: string;
  facebook_fbp?: string;
  facebook_login_id?: string;

  // Tiktok pixel params
  webpage_url?: string;
  tiktok_ttcid?: string;
  tiktok_ttp?: string;

  // Snapchat pixel params
  snapchat_click_id?: string;
  snapchat_cookie?: string;
}

export interface SubscriptionDetailsApiResponse {
  start_date: string;
  end_date: string;
  is_free_trial: boolean;
  user_id: string;
  token: string;
  currency_amount: number;
  /**
   * [0 -> Active] |
   * [1 -> Canceled] |
   * [2 -> On Hold (subscription free w/o details)] |
   * [3 -> in grace (another field in_grace_end_date)] |
   * [4 -> paused (subscription, also free)]
   * [5 -> expired]
   * [6 -> revoked (apple only)]
   */
  status: UserSubscriptionStatus;
  currency_code: string;
  end_date_free_trial?: string;
  in_grace_end_date?: string;
  plan: SubscriptionPlan;
  /**
   * Identify on which platform the user made the payment
   * [1 -> Google == Android] |
   * [2 -> Apple == iOS] |
   * [3 -> Stripe == web] |
   * [4 -> Deepstash == free subscription given by us]
   */
  provider: PaymentProvider;
}

export interface UserSubscriptionApiResponse {
  downgrade_skus?: string[];
  upgrade_skus?: string[];
  subscription_details?: SubscriptionDetailsApiResponse;
  subscription_type?: number;
}

export interface StripeCustomerPortal {
  session_url: string;
}

export interface CollectionsResponse {
  meta: ResponseMeta;
  objects: CollectionApiResponse[];
}

export interface SourceCollectionProgressApiResponse {
  read_blocks: number[];
  completed: boolean;
}

export interface CollectionProgressApiResponse {
  total_blocks_read?: number;
  completed?: boolean;
  article_progress?: Record<number, SourceCollectionProgressApiResponse>;
}

export interface CollectionApiResponse {
  articles?: ArticleApiResponse[];
  description?: string;
  emoji?: string;
  hashtags?: string[];
  id: number;
  is_collection_of_the_week?: boolean;
  image?: string;
  image_blurhash?: string;
  image_compressed?: string;
  image_preview?: string;
  image_color?: string;
  my_progress?: CollectionProgressApiResponse;
  name: string;
  /** Time to read in seconds */
  time_to_read?: number;
  total_blocks: number;
  total_article_sources?: number;
  total_book_sources?: number;
  total_podcast_sources?: number;
  total_selfpost_sources?: number;
  total_video_sources?: number;
  meta?: ResponseMeta;
  seo?: {
    featured_publishers: { image: string; name: string }[];
    featured_sources: { image: string; title: string }[];
  };
  similar_collections?: CollectionApiResponse[];
  skills?: string[];
}

export interface RecommendedCollectionsResponse {
  objects: CollectionApiResponse[];
  meta: ResponseMeta;
}

export interface StoryListApiResponse {
  objects: StoryApiResponse[];
  meta: ResponseMeta;
}

export interface ExploreSectionItemApiResponse {
  destination_path: string;
  emoji: string | null;
  id: string;
  subtitle: string | null;
  title: string;
}

export interface ExploreSectionApiResponse {
  id: string;
  title: string;
  objects: ExploreSectionItemApiResponse[];
}

export interface ExploreApiResponse {
  objects: ExploreSectionApiResponse[];
}

export type SectionItemApiResponseWrapper<TObject, TObjectType> = {
  _object_type: TObjectType;
} & TObject;

type ExploreSourceTypeSectionItemType = 'user' | 'article';

export interface ExploreSourceTypeSectionApiResponse {
  id: string;
  link: string;
  name: string;
  objects: SectionItemApiResponseWrapper<
    ArticleApiResponse | UserDataApi,
    ExploreSourceTypeSectionItemType
  >[];
  type: string;
}

export interface ExploreSourceTypeApiResponse {
  objects: ExploreSourceTypeSectionApiResponse[];
  total_ideas: number;
}

export function isExploreSourceTypeSectionSourceItemApiResponse(
  item: SectionItemApiResponseWrapper<any, ExploreSourceTypeSectionItemType>,
): item is SectionItemApiResponseWrapper<
  ArticleApiResponse,
  ExploreSourceTypeSectionItemType
> {
  return item._object_type === 'article';
}

export function isExploreSourceTypeSectionUserItemApiResponse(
  item: SectionItemApiResponseWrapper<any, ExploreSourceTypeSectionItemType>,
): item is SectionItemApiResponseWrapper<
  UserDataApi,
  ExploreSourceTypeSectionItemType
> {
  return item._object_type === 'user';
}

export type DailyPicksSectionItemType = 'article' | 'block';

export interface DailyPicksSectionApiResponse {
  id: string;
  /**
   * The link to route the user to when clicking "See All"
   */
  link: string;
  name: string;
  objects: {
    _object_type: string;
    date: string;
    object: SectionItemApiResponseWrapper<
      ArticleApiResponse | InsightApiResponse,
      DailyPicksSectionItemType
    >;
  }[];
}

export interface DailyPicksApiResponse {
  objects: DailyPicksSectionApiResponse[];
}

export interface DailyPicksHistoryObjectApiResponse {
  date: string;
  object: SectionItemApiResponseWrapper<
    ArticleApiResponse | InsightApiResponse,
    DailyPicksSectionItemType
  >;
}

export type DailyPicksHistoryApiResponse =
  PaginatedApiResponse<DailyPicksHistoryObjectApiResponse>;

export type SectionSimpleTypeModel = 'section';
export type SectionGroupTypeModel = 'section-group';
export type SectionStasherOfTheDayTypeModel = 'stasher-of-the-day';
export type SectionReadingProgressTypeModel = 'reading-progress';

export type SectionTypeModel =
  | SectionSimpleTypeModel
  | SectionGroupTypeModel
  | SectionStasherOfTheDayTypeModel
  | SectionReadingProgressTypeModel;

export interface FeedWeekProgress {
  day: number;
  progress: 'checked' | 'recover' | 'today' | 'empty';
}

export type SectionFirstObjectLabelModel =
  | 'book-of-the-day'
  | 'collection-of-the-week';

export type UserSectionType = 'user';
export type SourceSectionType = 'article';
export type HashtagSectionType = 'hashtag';
export type CollectionSectionType = 'collection';

export interface UserDataSectionApiResponse extends UserDataApi {
  _object_type: UserSectionType;
}

/** Interface used to extend related hashtag interface */
export interface HashtagRelatedSectionApiResponse extends HashtagApiResponse {
  _object_type: HashtagSectionType;
}

/** Interface used to extend article interface */
export interface SourcesSectionApiResponse extends ArticleApiResponse {
  _object_type: SourceSectionType;
}

/** Interface used to extend collection interface */
export interface CollectionsSectionApiResponse extends CollectionApiResponse {
  _object_type: CollectionSectionType;
}

export type SectionObjectTypeApiResponse =
  | SourcesSectionApiResponse
  | CollectionsSectionApiResponse
  | UserDataSectionApiResponse
  | HashtagRelatedSectionApiResponse;

export interface BaseSectionApiResponse<T extends SectionTypeModel> {
  id: string;
  link?: string;
  name: string;
  type: T;
}

export interface BaseSectionSimpleApiResponse
  extends BaseSectionApiResponse<SectionSimpleTypeModel> {
  first_object_label?: SectionFirstObjectLabelModel;
  meta?: ResponseMeta;
  objects?: SectionObjectTypeApiResponse[];
}

export interface SectionReadingProgressApiResponse
  extends BaseSectionApiResponse<SectionReadingProgressTypeModel> {
  current_streak: number;
  description?: string;
  week_progress: FeedWeekProgress[];
}

export interface SectionStasherOfTheDayApiResponse
  extends BaseSectionApiResponse<SectionStasherOfTheDayTypeModel> {
  user: UserDataApi;
  top_achievement: {
    hashtag_name: string;
    rank: number;
  };
}

export interface BaseSectionGroupApiResponse
  extends BaseSectionApiResponse<SectionGroupTypeModel> {
  description?: string;
  sections: SectionApiResponse[];
}

export type SectionGroupFeedApiResponse = BaseSectionGroupApiResponse;

export type SectionSimpleApiResponse = BaseSectionSimpleApiResponse;

export type SectionApiResponse =
  | SectionGroupFeedApiResponse
  | SectionSimpleApiResponse
  | SectionReadingProgressApiResponse
  | SectionStasherOfTheDayApiResponse;

export interface ReadFeedApiResponse {
  meta: ResponseMeta;
  objects: SectionApiResponse[];
}

export interface RedeemApiRequestBody {
  coupon_code: string;
}

export interface RedeemApiResponse {
  subscription_details?: any;
  downgrade_skus?: any;
  upgrade_skus?: any;
}

export interface RedeemApiError {
  error: string;
}

export interface StoryApiResponse {
  collections?: CollectionApiResponse[];
  content: string;
  featured_idea: string;
  id: number;
  image: string;
  image_blurhash: string;
  image_compressed: string;
  intro: string;
  table_of_content: string;
  time_to_read: number;
  title: string;
  similar_stories?: StoryApiResponse[];
}

export interface IdeaSuggestionApiResponse {
  title?: string;
  content: string;
}
export interface IdeaSuggestionsApiResponse {
  suggestions: IdeaSuggestionApiResponse[];
}

export interface IdeaImageAIGenerateApiResponse {
  images: string[];
  user_available_tokens: number;
}

export interface EditorSettingsApiResponse {
  assistant: {
    suggestion_labels: string[];
  };
  image_generation: {
    user_available_tokens: number;
  };
}
