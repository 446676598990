import * as React from 'react';
import { Icon, IconProps } from 'deepstash-ui';

export const ProductivityIcon = React.memo<IconProps>(props => {
  return (
    <Icon width="48px" height="48px" viewBox="0 0 48 48" fill="none" {...props}>
      <path
        d="M31.2416 20.8916L42.1666 3.6416L26.8333 13.7999L30.4749 7.0916C30.4749 7.0916 12.4583 16.8666 7.28325 22.2333C2.68325 26.8333 2.68325 34.1166 7.28325 38.7166C11.8833 43.3166 19.1666 43.3166 23.7666 38.7166C29.1333 33.3499 37.1833 18.7833 37.1833 18.7833L31.2416 20.8916Z"
        fill="#864903"
      />
      <path
        d="M31.1621 20.9635L42.0564 3.75757L26.7461 13.9015L30.3868 7.1884C30.3868 7.1884 12.4641 16.9299 7.31014 22.2745C2.7293 26.8553 2.7293 34.108 7.31014 38.6888C11.891 43.2696 19.1436 43.2696 23.7245 38.6888C29.0691 33.3442 37.1172 18.8427 37.1172 18.8427L31.1621 20.9635Z"
        fill="#985609"
      />
      <path
        d="M30.5238 21.5403L41.1709 4.68799L26.0465 14.7179L29.6824 7.96932C29.6824 7.96932 12.5081 17.4434 7.52767 22.6079C3.10113 27.0344 3.10113 34.0437 7.52767 38.4702C11.9542 42.8967 18.9635 42.8967 23.39 38.4702C28.5545 33.3057 36.5882 19.3237 36.5882 19.3237L30.5238 21.5403Z"
        fill="#A55F0E"
      />
      <path
        d="M29.0088 22.91L39.0694 6.89819L24.3849 16.6569L28.0074 9.82494C28.0074 9.82494 12.6117 18.6627 8.04241 23.3997C3.98196 27.4602 3.98196 33.8906 8.04241 37.9511C12.1029 42.0115 18.5333 42.0115 22.5937 37.9511C27.3317 33.2131 35.33 20.4644 35.33 20.4644L29.0088 22.91Z"
        fill="#B36914"
      />
      <path
        d="M27.7335 24.0629L37.3005 8.7583L22.9859 18.2889L26.5979 11.3861C26.5979 11.3861 12.6992 19.6871 8.47771 24.0658C4.72488 27.8186 4.72488 33.7603 8.47771 37.5131C12.2305 41.2659 18.1722 41.2659 21.925 37.5131C26.3037 33.1345 34.2732 21.4246 34.2732 21.4246L27.7335 24.0629Z"
        fill="#CB711A"
      />
      <path
        d="M26.5369 25.1439L35.6411 10.5034L21.6743 19.8203L25.2757 12.8513C25.2757 12.8513 12.781 20.6503 8.88441 24.6915C5.42004 28.1559 5.42004 33.6405 8.88441 37.1039C12.3488 40.5683 17.8333 40.5683 21.2967 37.1039C25.338 33.0626 33.2797 22.3264 33.2797 22.3264L26.5369 25.1439Z"
        fill="#DA7B1F"
      />
      <path
        d="M25.5 26.0811L34.2026 12.0146L20.5368 21.1456L24.1296 14.1191C24.1296 14.1191 12.8519 21.483 9.23614 25.232C6.02285 28.4453 6.02285 33.534 9.23614 36.7483C12.4494 39.9616 17.5382 39.9616 20.7524 36.7483C24.5014 32.9993 32.4201 23.1064 32.4201 23.1064L25.5 26.0811Z"
        fill="#E98B30"
      />
      <path
        d="M24.0607 27.23L31.8989 14.4861L19.2546 22.9501L22.651 16.2322C22.651 16.2322 13.2047 22.767 10.0652 26.0225C7.2745 28.8132 7.2745 33.2311 10.0652 36.0208C12.8558 38.8105 17.2738 38.8115 20.0635 36.0208C23.3189 32.7653 30.7987 24.2515 30.7987 24.2515L24.0607 27.23Z"
        fill="#EE8F33"
      />
      <path
        d="M14.9886 39.1049C19.2466 39.1049 22.6984 35.6531 22.6984 31.3951C22.6984 27.1371 19.2466 23.6853 14.9886 23.6853C10.7306 23.6853 7.27881 27.1371 7.27881 31.3951C7.27881 35.6531 10.7306 39.1049 14.9886 39.1049Z"
        fill="url(#paint0_radial_210_88248)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_210_88248"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.9886 31.3951) scale(7.70979)"
        >
          <stop stopColor="#FFEFD6" />
          <stop offset="0.065" stopColor="#FFE0C4" />
          <stop offset="0.196" stopColor="#FFC896" />
          <stop offset="0.378" stopColor="#FFA24D" />
          <stop offset="0.537" stopColor="#FF971E" />
          <stop offset="1" stopColor="#FF7E07" stopOpacity="0" />
        </radialGradient>
      </defs>
    </Icon>
  );
});

export default ProductivityIcon;
