import { AuthProviderAction } from './AuthProviderTypes';
import { INITIAL_STATE } from './AuthProvider';
import jsCookie from 'js-cookie';
import { Analytics } from 'src/services/analytics';
import { DEFAULT_COOKIE_EXPIRATION_DATE } from 'utils/constants';

const authReducer = (state = INITIAL_STATE, action: AuthProviderAction) => {
  switch (action.type) {
    case 'sign-in': {
      // Set the auth cookie
      jsCookie.set('token', action.payload.token, {
        expires: DEFAULT_COOKIE_EXPIRATION_DATE,
        sameSite: 'lax',
        secure:
          process.env.NODE_ENV === 'production' &&
          window.location.hostname !== 'localhost',
      });
      // Set the refresh token cookie
      jsCookie.set('refresh_token', action.payload.refresh_token, {
        expires: DEFAULT_COOKIE_EXPIRATION_DATE + 1,
        sameSite: 'lax',
        secure:
          process.env.NODE_ENV === 'production' &&
          window.location.hostname !== 'localhost',
      });
      return { ...state, isLoggedIn: true };
    }
    case 'logout': {
      jsCookie.remove('token');
      jsCookie.remove('refresh_token');
      jsCookie.remove('colorMode');
      jsCookie.remove('chakra-ui-color-mode');

      // Set anonymous user for Analytics
      Analytics.setAnonymousUser();
      return { ...state, isLoggedIn: false };
    }
    case 'agree-cookies': {
      return { ...state, hasAgreedCookies: true };
    }
    default: {
      return state;
    }
  }
};

export default authReducer;
