import {
  AppleSvg,
  Button,
  color,
  FacebookSvg,
  GoogleSvg,
  spacing,
  StyleProps,
  Flex,
  ButtonProps,
  IconProps,
  Text,
  typography,
} from 'deepstash-ui';
import React, { useEffect, useMemo, useState } from 'react';

//The typing for this package is wrong, that's why we use ts-ignore for this import
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login/';
import { onGoogleAutoLoadFinished, onGoogleFailure } from 'hooks/auth';
import {
  AppleCallback,
  FacebookCallback,
  GoogleCallback,
  SocialAuthCallback,
} from 'types/types';
import AppleLogin from 'react-apple-login';
import { AuthRequestStatus } from '../modals/auth-modal/auth-modal-content/AuthModalContent';
import Spinner from '../animation/Spinner';

export type SocialNetwork = 'Facebook' | 'Google' | 'Apple';

export interface SocialLoginProps {
  network: SocialNetwork;
  callback: SocialAuthCallback;
  isSignup: boolean;
  requestStatus: AuthRequestStatus;
  size?: 'small' | 'large';
  buttonProps?: ButtonProps;
  svgProps?: IconProps;
}

const SocialLogin: React.FC<Omit<StyleProps, 'onClick'> & SocialLoginProps> = ({
  network,
  callback,
  isSignup,
  requestStatus,
  size = 'large',
  buttonProps,
  svgProps,
  ...props
}) => {
  const socialIcon = useMemo(() => {
    switch (network) {
      case 'Facebook':
        return (
          <FacebookSvg
            id={`social-auth-facebook-${isSignup ? 'signup' : 'login'}-icon`}
            color={'#0165E1'}
            boxSize={spacing.XL.rem}
            {...svgProps}
          />
        );
      case 'Apple':
        return (
          <AppleSvg
            id={`social-auth-apple-${isSignup ? 'signup' : 'login'}-icon`}
            boxSize={spacing.XL.rem}
            color={color.light.text}
            {...svgProps}
          />
        );
      case 'Google':
        return (
          <GoogleSvg
            id={`social-auth-google-${isSignup ? 'signup' : 'login'}-icon`}
            boxSize={spacing.XL.rem}
            color={color.light.text}
            {...svgProps}
          />
        );
      default:
        return undefined;
    }
  }, [network]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setKey] = useState(Math.random());

  // Have no idea what is the reason,
  // but we need to re-render button once more to load sdk properly.
  useEffect(() => {
    if (network === 'Facebook') setTimeout(() => setKey(Math.random()), 100);
  }, [network]);

  const renderButton = ({
    onClick,
    statusWhenLoading,
  }: {
    onClick: () => void;
    statusWhenLoading: AuthRequestStatus;
  }) => (
    <Flex width="100%" {...props}>
      <Button
        backgroundColor={color.light.top}
        height="unset"
        width="100%"
        minW="unset"
        px={spacing.S.rem}
        py={spacing.M.rem}
        borderRadius={spacing.XXXL.rem}
        color={color.light.text}
        justifyContent="center"
        border={`1px solid ${color.light.textSecondary}`}
        onClick={onClick}
        flexDir="row"
        leftIcon={
          size === 'large' && requestStatus !== statusWhenLoading
            ? socialIcon
            : undefined
        }
        disabled={requestStatus !== 'success' && requestStatus !== 'failure'}
        id={`social-auth-${network.toLowerCase()}-${
          isSignup ? 'signup' : 'login'
        }-button`}
        {...typography.primaryTextFonts.medium.bold}
        {...buttonProps}
      >
        {requestStatus !== statusWhenLoading ? (
          size === 'small' ? (
            socialIcon
          ) : (
            <Text
              id={`social-auth-${network.toLowerCase()}-${
                isSignup ? 'signup' : 'login'
              }-text`}
            >
              {isSignup ? 'Continue' : 'Sign in'} with {network}
            </Text>
          )
        ) : (
          <Spinner
            spinnerContainerProps={{
              minHeight:
                buttonProps?.lineHeight ||
                typography.primaryTextFonts.medium.bold.lineHeight,
            }}
            spinnerProps={{
              color:
                svgProps?.color !== undefined
                  ? (svgProps.color as string)
                  : color.light.text,
            }}
          />
        )}
      </Button>
    </Flex>
  );

  switch (network) {
    case 'Facebook':
      return (
        <FacebookLogin
          appId={process.env.FACEBOOK_APP_ID}
          callback={(res: any) => (callback as FacebookCallback)(res, isSignup)}
          render={(renderProps: { onClick: () => void }) =>
            renderButton({
              onClick: renderProps.onClick,
              statusWhenLoading: 'loading-facebook',
            })
          }
          redirectUri="deepstash.com"
          isMobile={false}
        />
      );
    case 'Google':
      return (
        <GoogleLogin
          onAutoLoadFinished={onGoogleAutoLoadFinished}
          clientId={process.env.GOOGLE_CLIENT_ID ?? ''}
          onFailure={onGoogleFailure}
          onSuccess={res => (callback as GoogleCallback)(res, isSignup)}
          render={renderProps =>
            renderButton({
              onClick: renderProps.onClick,
              statusWhenLoading: 'loading-google',
            })
          }
        />
      );
    case 'Apple':
      return (
        <AppleLogin
          clientId={process.env.APPLE_SIGN_IN_CLIENT_ID ?? ''}
          redirectURI={
            typeof window !== 'undefined' && window?.location?.origin
              ? 'https://deepstash.com'
              : ''
          }
          responseType={'code id_token'}
          responseMode={'form_post'}
          render={renderProps =>
            renderButton({
              onClick: renderProps.onClick,
              statusWhenLoading: 'loading-apple',
            })
          }
          usePopup
          callback={res => {
            (callback as AppleCallback)(res, isSignup);
          }}
        />
      );
    default:
      return null;
  }
};

export default SocialLogin;
