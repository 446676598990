import React from 'react';
import {
  Flex,
  SpinnerProps as OldSpinnerProps,
  Spinner as OldSpinner,
  FlexProps,
} from 'deepstash-ui';

interface SpinnerProps {
  spinnerContainerProps?: FlexProps;
  spinnerProps?: OldSpinnerProps;
}
const Spinner: React.FC<SpinnerProps> = ({
  spinnerContainerProps,
  spinnerProps,
}) => (
  <Flex
    transform={
      'scale(1.5) translateY(2px)' // The Spinner has a 2px padding bottom for some reason
    }
    {...spinnerContainerProps}
  >
    <OldSpinner width={'1rem'} height={'1rem'} {...spinnerProps} />
  </Flex>
);

export default Spinner;
