import { useRouter as useNextRouter } from 'next/router';
import { useCallback } from 'react';
import { UrlObject } from 'url';
import useNavigation from '../providers/hooks/useNavigation';

//Exported next/router types
interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
}

declare type Url = UrlObject | string;

const useRouter = () => {
  const router = useNextRouter();
  const { currentPageScroll, updateScrollCache, invalidateScroll } =
    useNavigation();

  /**
   * Navigate to another route
   * @param url : The route we are navigating towards
   * @param as :What we are showing in the browser navbar
   * @param options: How the transition should be made
   * @param keepScroll: If true, the scroll position of the router we are navigating towards
   *  won't be invalidated before navigation
   */
  const push = useCallback(
    (
      url: Url,
      as?: string,
      options?: TransitionOptions,
      keepScroll?: boolean,
    ) => {
      //We are navigating to url with push
      //Save the current scroll position
      //And invalidate the scroll for url

      const baseDestPath = url.toString().split('#')[0].split('?')[0];

      updateScrollCache(location.pathname, currentPageScroll.current);
      if (!keepScroll) {
        invalidateScroll(baseDestPath);
      }

      return router.push(url, as, { ...options, scroll: false });
    },
    [router.push],
  );

  /**
   * Navigate to another route without pushing a new entry in the browser history
   * @param url : The route we are navigating towards
   * @param as :What we are showing in the browser navbar
   * @param options: How the transition should be made
   * @param keepScroll: If true, the scroll position of the router we are navigating towards
   *  won't be invalidated before navigation
   */
  const replace = useCallback(
    (
      url: Url,
      as?: string,
      options?: TransitionOptions,
      keepScroll?: boolean,
    ) => {
      //We are navigating to url with push
      //Save the current scroll position
      //And invalidate the scroll for url

      const baseDestPath = url.toString().split('#')[0].split('?')[0];

      updateScrollCache(location.pathname, currentPageScroll.current);
      if (!keepScroll) {
        invalidateScroll(baseDestPath);
      }
      router.replace(url, as, { ...options, scroll: false });
    },
    [router.replace],
  );

  const hasHash = (hash: string) => {
    return router.asPath.split('#')[1] === hash;
  };
  return {
    ...router,
    push,
    replace,
    hasHash,
  };
};

export default useRouter;
