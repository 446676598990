import {
  deleteRequest,
  getApiToken,
  getRequest,
  postRequest,
  putRequest,
} from './apiRequest';
import {
  HashtagApiResponse,
  HashtagsOnboardingApiResponse,
  HashtagSourcesApiResponse,
  PaginatedApiResponse,
  RelatedTopicResponse,
  UpdateElementResponse,
  UsersResponse,
} from './api.types';
import getConfig from 'next/config';

/**
 * Get suggested users by hashtags
 * @param topics
 */
export const getSuggestedUsersByHashtags = (
  hashtags: string[],
): Promise<UsersResponse | undefined> => {
  try {
    const token = getApiToken();
    const url = `/user/suggested_followings/`;
    const data = { hashtags: hashtags };
    return postRequest(url, token, data);
  } catch (e) {
    return Promise.resolve(undefined);
  }
};

/**
 * Api call to fetch all hashtags used in onboarding
 */
export function getOnboardingHashtags(): Promise<any> {
  try {
    const token = getApiToken();
    const url = `/hashtag/onboarding/setup/`;
    return getRequest<HashtagsOnboardingApiResponse>(url, token);
  } catch (e) {
    return Promise.resolve();
  }
}

/**
 * Unfollow a specific hashtag
 * @param hashtagName
 */
export function unfollowHashtag(hashtagName: string): Promise<any> {
  try {
    const token = getApiToken();
    const url = `/hashtag/follow/${hashtagName}/`;
    return deleteRequest(url, token);
  } catch (e) {
    return Promise.resolve();
  }
}

/**
 * Follow a specific hashtag
 * @param hashtagName
 */
export function followHashtag(hashtagName: string): Promise<any> {
  try {
    const token = getApiToken();
    const url = `/hashtag/follow/${hashtagName}/`;
    return postRequest(url, token);
  } catch (e) {
    return Promise.resolve();
  }
}

/**
 * Api call to update all hashtags selected in onboarding
 */
export function updateUserHashtags(
  topics: string[],
  queryParams?: { isNewUser?: boolean },
): Promise<any> {
  try {
    const token = getApiToken();
    let url = `/hashtag/followed/`;

    if (queryParams?.isNewUser) {
      url += '?new_user=true';
    }

    const data = {
      hashtags: topics,
    };
    return putRequest<UpdateElementResponse, any>(url, token, data);
  } catch (e) {
    return Promise.resolve();
  }
}

/**
 * Api call to get all hashtags, including followed or not
 */
export function getUserHashtags(): Promise<any> {
  try {
    const token = getApiToken();
    const url = `/hashtag/followed/`;

    return getRequest<HashtagApiResponse>(url, token);
  } catch (e) {
    return Promise.resolve();
  }
}

/**
 * Api call to get a hashtag and related sources
 */
export const getHashtagApiResponse = ({
  name,
  nextFetchUrl,
  offset,
  limit,
}: {
  name: string;
  nextFetchUrl?: string;
  offset?: number;
  limit?: number;
}): Promise<HashtagSourcesApiResponse> => {
  let url = `/hashtag/name/${name}/?sort_by=new${
    offset ? `&offset=${offset}` : ''
  }${limit ? `&limit=${limit}` : ''}`;
  if (nextFetchUrl) {
    const startIndex = nextFetchUrl.indexOf('hashtag');
    url = nextFetchUrl.substring(startIndex);
  }
  return getRequest(url, getConfig().publicRuntimeConfig.API_TOKEN ?? '');
};

/**
 * Api call to get a hashtag and related sources
 */
export const getHashtagApiResponseNsi = ({
  name,
}: {
  name: string;
}): Promise<HashtagApiResponse> => {
  const url = `/hashtag/name/${name}/nsi/`;
  return getRequest(url, getConfig().publicRuntimeConfig.API_TOKEN ?? '');
};

export const getKLHashtags = (
  offset: number,
  limit: number,
): Promise<PaginatedApiResponse<string>> => {
  const url = `hashtag/all/?limit=${limit}&offset=${offset}`;

  return getRequest(url, getApiToken({ allowDefault: true }));
};

export const getRelatedTopics = (
  hashtag: string,
): Promise<PaginatedApiResponse<RelatedTopicResponse>> | null => {
  const url = `/hashtag/related/${hashtag}/?limit=100`;

  return getRequest(url, getApiToken({ allowDefault: true }));
};
