import * as React from 'react';
import { Icon, IconProps } from 'deepstash-ui';

export const HabitsIcon = React.memo<IconProps>(props => {
  return (
    <Icon width="48px" height="48px" viewBox="0 0 48 48" fill="none" {...props}>
      <path
        d="M20.467 19.052C18.875 19.429 17.224 19.188 15.518 18.345C12.509 20.137 9.97802 22.309 7.77002 24.739L15.517 28.244L20.467 19.052Z"
        fill="url(#paint0_linear_210_88284)"
      />
      <path
        d="M28.952 27.5359C28.575 29.1279 28.816 30.7789 29.659 32.4849C27.867 35.4939 25.695 38.0249 23.265 40.2329L19.76 32.4859L28.952 27.5359Z"
        fill="url(#paint1_linear_210_88284)"
      />
      <path
        d="M41.0662 7.78199C41.0162 7.33799 40.6651 6.98699 40.2211 6.93699C35.3731 6.38799 28.8411 6.70699 24.8001 12.07L13.4521 27.593L16.9311 31.072L20.4101 34.551L35.9331 23.204C41.2961 19.163 41.6152 12.631 41.0662 7.78199Z"
        fill="url(#paint2_radial_210_88284)"
      />
      <path
        d="M19.0529 36.0221L11.9819 28.9511L14.1029 28.2441L19.7599 33.9001L19.0529 36.0221Z"
        fill="url(#paint3_linear_210_88284)"
      />
      <path
        d="M17.259 34.2231L15.52 32.4841L13.781 30.7451C13.781 30.7451 9.88901 30.7341 8.05001 34.0381C9.00701 34.0871 8.81601 34.8011 7.83601 35.6411C7.26301 36.1331 6.90801 37.0441 6.90601 37.8571C7.85801 37.5801 7.61901 39.4381 7.00101 41.0021C8.56501 40.3841 10.424 40.1451 10.146 41.0971C10.96 41.0941 11.867 40.7371 12.359 40.1641C13.199 39.1851 13.916 38.9961 13.965 39.9541C17.27 38.1151 17.259 34.2231 17.259 34.2231Z"
        fill="url(#paint4_linear_210_88284)"
      />
      <path
        d="M16.992 33.956L15.52 32.484L14.048 31.012C13.112 31.1 10.502 31.981 8.84296 33.983C9.35996 34.299 9.20796 35.102 8.81496 35.579C8.49796 35.963 7.58596 36.544 7.23096 37.663C7.99896 37.512 8.28896 38.52 7.59696 40.408C9.48496 39.716 10.493 40.006 10.342 40.774C11.461 40.419 12.042 39.507 12.426 39.19C12.903 38.796 13.707 38.645 14.022 39.162C16.024 37.502 16.904 34.892 16.992 33.956Z"
        fill="url(#paint5_radial_210_88284)"
      />
      <path
        d="M14.3999 33.5708C12.6879 35.8428 10.4299 35.7108 8.44092 39.5438C10.8479 36.7438 13.4279 36.8788 14.3999 33.5708Z"
        fill="#F4A52F"
      />
      <path
        d="M10.8701 39.9479C12.3321 36.6479 14.1231 37.5939 15.8791 35.1729C14.7651 38.5529 12.7651 38.2549 10.8701 39.9479Z"
        fill="#F4A52F"
      />
      <path
        d="M10.9971 35.6862C11.8271 33.5512 13.0391 34.0292 14.1051 32.4922C13.5111 34.6932 12.1891 34.6382 10.9971 35.6862Z"
        fill="#F4A52F"
      />
      <path
        d="M8.28003 40.255C9.31703 38.535 10.215 39.16 11.341 38.002C10.482 39.821 9.42303 39.545 8.28003 40.255Z"
        fill="url(#paint6_linear_210_88284)"
      />
      <path
        d="M8.28003 40.255C9.31703 38.535 10.215 39.16 11.341 38.002C10.482 39.821 9.42303 39.545 8.28003 40.255Z"
        fill="url(#paint7_linear_210_88284)"
      />
      <path
        d="M7.68286 38.4419C9.40686 38.0469 9.05486 36.9549 10.3079 36.2749C8.53586 36.4609 8.54786 37.5859 7.68286 38.4419Z"
        fill="url(#paint8_linear_210_88284)"
      />
      <path
        d="M13.4829 36.5879C15.4129 35.6159 14.8369 34.5669 16.1789 33.4429C14.1599 34.2089 14.3489 35.4009 13.4829 36.5879Z"
        fill="#F4A52F"
      />
      <path
        d="M8.13379 36.827C11.4088 35.381 10.9458 33.67 13.3958 31.947C10.0678 33.058 9.89279 34.976 8.13379 36.827Z"
        fill="#F4A52F"
      />
      <path
        d="M15.5361 33.079L14.8281 32.372L21.9001 25.301L22.6071 26.008L15.5361 33.079Z"
        fill="url(#paint9_linear_210_88284)"
      />
      <path
        d="M24.2652 24.3485L23.5581 23.6414L21.8992 25.3002L22.6063 26.0073L24.2652 24.3485Z"
        fill="#D77C28"
      />
      <path
        d="M40.5219 16.4791C41.3979 13.5281 41.3629 10.4091 41.0659 7.78205C41.0159 7.33805 40.6649 6.98705 40.2209 6.93705C37.5949 6.64005 34.4749 6.60505 31.5239 7.48105L40.5219 16.4791Z"
        fill="url(#paint10_linear_210_88284)"
      />
      <path
        d="M39.837 18.32L29.683 8.16602C29.57 8.21702 29.456 8.26302 29.345 8.31802L39.686 18.659C39.74 18.547 39.786 18.433 39.837 18.32Z"
        fill="url(#paint11_linear_210_88284)"
      />
      <path
        d="M39.9791 18.0039L29.9991 8.02393C29.8851 8.07193 29.7731 8.12393 29.6611 8.17593L39.8271 18.3419C39.8781 18.2299 39.9311 18.1179 39.9791 18.0039Z"
        fill="url(#paint12_linear_210_88284)"
      />
      <path
        d="M29.7851 27.698L20.3051 18.219L20.0981 18.502L29.5021 27.905L29.7851 27.698Z"
        fill="url(#paint13_linear_210_88284)"
      />
      <path
        d="M30.049 27.5051L20.498 17.9541L20.291 18.2371L29.766 27.7121L30.049 27.5051Z"
        fill="url(#paint14_linear_210_88284)"
      />
      <path
        d="M30.0689 25.36C33.4759 25.36 36.2379 22.598 36.2379 19.191C36.2379 15.7839 33.4759 13.022 30.0689 13.022C26.6619 13.022 23.8999 15.7839 23.8999 19.191C23.8999 22.598 26.6619 25.36 30.0689 25.36Z"
        fill="url(#paint15_radial_210_88284)"
      />
      <path
        d="M29.6591 23.7989C32.6718 23.7989 35.1141 21.3566 35.1141 18.3439C35.1141 15.3312 32.6718 12.8889 29.6591 12.8889C26.6464 12.8889 24.2041 15.3312 24.2041 18.3439C24.2041 21.3566 26.6464 23.7989 29.6591 23.7989Z"
        fill="url(#paint16_linear_210_88284)"
      />
      <path
        d="M29.6591 22.8012C32.1207 22.8012 34.1161 20.8057 34.1161 18.3442C34.1161 15.8827 32.1207 13.8872 29.6591 13.8872C27.1976 13.8872 25.2021 15.8827 25.2021 18.3442C25.2021 20.8057 27.1976 22.8012 29.6591 22.8012Z"
        fill="url(#paint17_linear_210_88284)"
      />
      <path
        d="M29.6589 22.344C31.8681 22.344 33.6589 20.5531 33.6589 18.344C33.6589 16.1349 31.8681 14.344 29.6589 14.344C27.4498 14.344 25.6589 16.1349 25.6589 18.344C25.6589 20.5531 27.4498 22.344 29.6589 22.344Z"
        fill="url(#paint18_linear_210_88284)"
      />
      <path
        d="M30.428 14.8861C30.665 15.1231 29.026 15.4231 27.872 16.5771C26.718 17.7311 26.486 19.3021 26.249 19.0651C26.012 18.8281 25.606 17.1631 27.045 15.7241C28.327 14.4421 30.192 14.6491 30.428 14.8861Z"
        fill="url(#paint19_radial_210_88284)"
      />
      <path
        d="M33.62 9.57687C35.276 8.83187 37.215 8.22287 39.495 7.89687C39.495 7.89687 36.171 7.03087 32.218 8.17587L33.62 9.57687Z"
        fill="url(#paint20_linear_210_88284)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_210_88284"
          x1="14.2915"
          y1="22.5042"
          x2="16.7981"
          y2="31.3268"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9870B" />
          <stop offset="0.628" stopColor="#744726" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_210_88284"
          x1="28.6124"
          y1="40.0178"
          x2="25.0232"
          y2="31.5777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D97A0B" />
          <stop offset="1" stopColor="#744B26" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_210_88284"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(30.5427 24.3722) rotate(47.1274) scale(15.9927 57.2899)"
        >
          <stop offset="0.17" stopColor="#999999" />
          <stop offset="0.481" stopColor="#EEEEEE" />
          <stop offset="0.707" stopColor="#ECECEC" />
          <stop offset="0.788" stopColor="#E5E5E5" />
          <stop offset="0.846" stopColor="#DADADA" />
          <stop offset="0.893" stopColor="#C9C9C9" />
          <stop offset="0.933" stopColor="#B3B3B3" />
          <stop offset="0.968" stopColor="#989898" />
          <stop offset="0.999" stopColor="#797979" />
          <stop offset="1" stopColor="#787878" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_210_88284"
          x1="13.8077"
          y1="29.3293"
          x2="18.7368"
          y2="34.3393"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D96E0B" />
          <stop offset="1" stopColor="#744226" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_210_88284"
          x1="14.5388"
          y1="33.497"
          x2="7.65359"
          y2="40.2858"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D77C28" />
          <stop offset="1" stopColor="#D9870C" />
        </linearGradient>
        <radialGradient
          id="paint5_radial_210_88284"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.5572 32.5391) rotate(45.001) scale(9.096 10.9807)"
        >
          <stop stopColor="white" />
          <stop offset="0.254" stopColor="#F0B45B" />
          <stop offset="0.699" stopColor="#FFA800" />
          <stop offset="0.975" stopColor="#BD6D0E" />
        </radialGradient>
        <linearGradient
          id="paint6_linear_210_88284"
          x1="16.1091"
          y1="35.5215"
          x2="7.86731"
          y2="40.3437"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.28" stopColor="#FFDA4E" />
          <stop offset="0.536" stopColor="#FF7900" />
          <stop offset="0.943" stopColor="#E5721F" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_210_88284"
          x1="14.7891"
          y1="34.8426"
          x2="8.12863"
          y2="41.5936"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.28" stopColor="#FFDA4E" />
          <stop offset="0.491" stopColor="#F2943F" />
          <stop offset="0.943" stopColor="#D9870C" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_210_88284"
          x1="13.2648"
          y1="33.2361"
          x2="6.89068"
          y2="38.9966"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.28" stopColor="#FFDA4E" />
          <stop offset="0.491" stopColor="#F2943F" />
          <stop offset="0.943" stopColor="#D9870C" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_210_88284"
          x1="18.3637"
          y1="28.8365"
          x2="19.0708"
          y2="29.5436"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#744B26" />
          <stop offset="1" stopColor="#744B26" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_210_88284"
          x1="35.7276"
          y1="6.91648"
          x2="41.0839"
          y2="12.2729"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6CE81" />
          <stop offset="0.547" stopColor="#E68022" />
          <stop offset="0.903" stopColor="#8E5909" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_210_88284"
          x1="29.4676"
          y1="8.19548"
          x2="39.8082"
          y2="18.5364"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.8" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_210_88284"
          x1="29.7832"
          y1="8.05398"
          x2="39.9491"
          y2="18.2202"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.293" stopColor="#CBCBCB" />
          <stop offset="1" stopColor="#666666" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_210_88284"
          x1="20.1819"
          y1="18.3409"
          x2="29.6619"
          y2="27.8212"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.8" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_210_88284"
          x1="20.3755"
          y1="18.0765"
          x2="29.9263"
          y2="27.6276"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.293" stopColor="#CBCBCB" />
          <stop offset="1" stopColor="#666666" />
        </linearGradient>
        <radialGradient
          id="paint15_radial_210_88284"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(30.1038 19.1556) rotate(45.001) scale(5.887)"
        >
          <stop stopOpacity="0.8" />
          <stop offset="0.384" stopColor="#020202" stopOpacity="0.491" />
          <stop offset="0.53" stopColor="#090909" stopOpacity="0.373" />
          <stop offset="0.636" stopColor="#151515" stopOpacity="0.288" />
          <stop offset="0.722" stopColor="#262626" stopOpacity="0.218" />
          <stop offset="0.797" stopColor="#3C3C3C" stopOpacity="0.158" />
          <stop offset="0.863" stopColor="#585858" stopOpacity="0.105" />
          <stop offset="0.923" stopColor="#797979" stopOpacity="0.057" />
          <stop offset="0.976" stopColor="#9E9E9E" stopOpacity="0.014" />
          <stop offset="0.993" stopColor="#ABABAB" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint16_linear_210_88284"
          x1="26.3189"
          y1="15.0041"
          x2="33.7286"
          y2="22.414"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E7E7E7" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_210_88284"
          x1="32.972"
          y1="21.5926"
          x2="26.8585"
          y2="15.5976"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C8C8C8" />
          <stop offset="1" stopColor="#AAAAAA" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_210_88284"
          x1="26.8307"
          y1="15.5161"
          x2="32.4874"
          y2="21.173"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4B478" />
          <stop offset="0.846" stopColor="#D9870B" />
        </linearGradient>
        <radialGradient
          id="paint19_radial_210_88284"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.1223 16.1565) rotate(-44.998) scale(6.32309 1.20671)"
        >
          <stop stopColor="white" stopOpacity="0.5" />
          <stop offset="0.141" stopColor="white" stopOpacity="0.429" />
          <stop offset="0.999" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint20_linear_210_88284"
          x1="25.529"
          y1="0.601866"
          x2="45.044"
          y2="14.9969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.8" />
          <stop offset="0.794" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Icon>
  );
});

export default HabitsIcon;
