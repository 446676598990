import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React from 'react';

/**
 * Pixel won't be initialized on these pages
 */
const BLACKLIST_PAGE_PATHS = ['/email_unsubscribe', '/otp_auth'];

const Pixel: React.FC = () => {
  const router = useRouter();

  const pixelFragment = (
    <>
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('dataProcessingOptions', ['LDU'], 0, 0);
      fbq('init', '1381387911997807');
      fbq('consent', 'grant'); 
      fbq('track', 'PageView'); 
      fbq('track', 'ViewContent');`,
        }}
      />
      <Head>
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=1381387911997807&ev=PageView&noscript=1&vdpo=LDU&dpoco=0&dpost=0" />`,
          }}
        />
      </Head>
    </>
  );

  return process.env.NODE_ENV === 'production' &&
    !BLACKLIST_PAGE_PATHS.includes(router.pathname)
    ? pixelFragment
    : null;
};

export default Pixel;
