import { getAuthUrl } from 'utils/global';
import {
  EmailOnlySignupData,
  EmailOnlySignupResponse,
  LoginResponse,
  ResetPasswordData,
  ResetPasswordResponse,
  SetPasswordData,
  SignUpRequestData,
  SignUpResponse,
  TokenSignUpResponse,
  UpdateTopicsRequestData,
} from './api.types';
import {
  client,
  getApiToken,
  getRequest,
  onRequestError,
  onRequestSuccess,
  postFormRequest,
  postRequest,
  socialAuthRequest,
} from './apiRequest';

interface SocialAuthRequestProps {
  token: string;
  signUpExtraData?: UpdateTopicsRequestData;
  block?: number;
  shouldSkipOnboarding?: boolean;
}

export const signUp = async ({
  email,
  password,
  signUpExtraData,
  token = getApiToken({ allowDefault: true }),
  block,
  shouldSkipOnboarding,
}: {
  email: string;
  password: string;
  signUpExtraData?: UpdateTopicsRequestData;
  token?: string;
  block?: number;
  shouldSkipOnboarding?: boolean;
}): Promise<SignUpResponse> => {
  const topics = signUpExtraData && signUpExtraData.topics;
  const curatedVersion = process.env.WEBSITE_VERSION?.split('.').join('');

  const url = `/user/?version=web-${curatedVersion}`;

  return postRequest<SignUpResponse, SignUpRequestData>(url, token, {
    email,
    password,
    topics,
    block,
    ...(shouldSkipOnboarding ? { onboarding: true } : {}),
  });
};

export const fbauth = async ({
  token,
  signUpExtraData,
  block,
  shouldSkipOnboarding,
}: SocialAuthRequestProps): Promise<TokenSignUpResponse> => {
  const curatedVersion = process.env.WEBSITE_VERSION?.split('.').join('');

  let url = `/fbauth/?token=${token}&version=web-${curatedVersion}`;

  if (signUpExtraData && signUpExtraData.topics) {
    url += `&topics=${signUpExtraData.topics}`;
  }
  if (block) {
    url += `&block=${block}`;
  }
  if (shouldSkipOnboarding) {
    url += '&onboarding';
  }

  return socialAuthRequest(url);
};

export const gauth = async ({
  token,
  signUpExtraData,
  block,
  shouldSkipOnboarding,
}: SocialAuthRequestProps): Promise<TokenSignUpResponse> => {
  const curatedVersion = process.env.WEBSITE_VERSION?.split('.').join('');

  let url = `/gauth/?type=web&id_token=${token}&version=web-${curatedVersion}`;
  if (signUpExtraData && signUpExtraData.topics) {
    url += `&topics=${signUpExtraData.topics}`;
  }
  if (block) {
    url += `&block=${block}`;
  }
  if (shouldSkipOnboarding) {
    url += '&onboarding';
  }

  return socialAuthRequest(url);
};

export const appleAuth = async ({
  token,
  signUpExtraData,
  block,
  shouldSkipOnboarding,
}: SocialAuthRequestProps): Promise<TokenSignUpResponse> => {
  const curatedVersion = process.env.WEBSITE_VERSION?.split('.').join('');

  let url = `/apple_auth/?type=web&id_token=${token}&version=web-${curatedVersion}`;
  if (signUpExtraData && signUpExtraData.topics) {
    url += `&topics=${signUpExtraData.topics}`;
  }
  if (block) {
    url += `&block=${block}`;
  }
  if (shouldSkipOnboarding) {
    url += '&onboarding';
  }

  return socialAuthRequest(url);
};

export const resetPasswordRequest = ({ email }: { email: string }) => {
  const url = `/user/forgot_password/?email=${encodeURIComponent(email)}`;
  postRequest(url, getApiToken({ allowDefault: true }));
};

export const resetPasswordCheck = ({
  uid,
  token,
}: {
  uid: string;
  token: string;
}): Promise<ResetPasswordResponse> => {
  const url = `/user/reset_password/?uid=${uid}&token=${token}`;
  return getRequest<ResetPasswordResponse>(
    url,
    getApiToken({ allowDefault: true }),
  );
};

export const resetPassword = ({
  uid,
  token,
  password,
}: {
  uid: string;
  token: string;
  password: string;
}): Promise<ResetPasswordResponse> => {
  const url = `/user/reset_password/?uid=${uid}&token=${token}`;
  const data: ResetPasswordData = {
    new_password: password,
  };
  const qs = require('qs');

  return postFormRequest<ResetPasswordResponse, ResetPasswordData>(
    url,
    getApiToken({ allowDefault: true }),
    qs.stringify(data),
  );
};

/**
 * Function to be called when the access token is no longer valid, to get a new one.
 * @param refreshToken = string, representing the token to be used for the call
 */
export const refreshAuthToken = async ({
  refreshToken,
}: {
  refreshToken: string;
}): Promise<LoginResponse> => {
  if (!refreshToken) {
    throw new Error('No refresh token!');
  }
  const urlParams =
    '?client_id=' +
    process.env.OAUTH_CLIENT_ID +
    '&grant_type=refresh_token&refresh_token=' +
    refreshToken;

  return client
    .post('/' + urlParams, undefined, {
      baseURL: getAuthUrl(),
      headers: {
        Authorization: null,
      },
    })
    .then(onRequestSuccess)
    .catch(onRequestError);
};

/**
 * Function to create a user with just the email address.
 * @param email The email to be used
 */
export const signUpEmailOnly = async ({
  email,
  facebookFbp,
  facebookFbc,
  clientUserAgent,
  facebookLoginId,
  webpageUrl,
  tiktokTtcid,
  tiktokTtp,
  snapchatClickId,
  snapchatCookie,
}: {
  email: string;
  facebookFbp?: string;
  facebookFbc?: string;
  clientUserAgent?: string;
  facebookLoginId?: string;
  webpageUrl?: string;
  tiktokTtcid?: string;
  tiktokTtp?: string;
  snapchatClickId?: string;
  snapchatCookie?: string;
}): Promise<EmailOnlySignupResponse> => {
  const url = '/user/unconfirmed_user/';
  // Deprecated parameter "isForAccessToken"
  // const url = '/user/unconfirmed_user/' + (isForAccessToken ? '?access_token=true' : '');
  const data = {
    email,
    facebook_fbp: facebookFbp,
    facebook_fbc: facebookFbc,
    client_user_agent: clientUserAgent,
    facebook_login_id: facebookLoginId,
    webpage_url: webpageUrl,
    tiktok_ttcid: tiktokTtcid,
    tiktok_ttp: tiktokTtp,
    snapchat_click_id: snapchatClickId,
    snapchat_cookie: snapchatCookie,
  };

  return postRequest<EmailOnlySignupResponse, EmailOnlySignupData>(
    url,
    getApiToken({ allowDefault: true }),
    data,
  );
};

/**
 * Function to confirm a user created with the email only
 * (with the function above)
 * @param key The secret key used to confirm the user
 */
export const confirmEmailOnlySignUp = async ({
  key,
  password,
}: SetPasswordData) => {
  const url = '/user/confirm_user/ ';
  const data: SetPasswordData = {
    key,
    password,
  };

  return postRequest<ResetPasswordResponse, SetPasswordData>(
    url,
    getApiToken({ allowDefault: true }),
    data,
  );
};

/**
 * Add an email to the newsletter
 */
export const addEmailToNewsletter = async ({ email }: { email: string }) => {
  const url = '/user/mailing_list/';
  const data = { email };

  return postRequest(url, getApiToken({ allowDefault: true }), data);
};

/**
 * Log in using OTP token
 */
export const otpAuth = async ({
  email,
  token,
}: {
  email: string;
  token: string;
}): Promise<LoginResponse> => {
  const url = `/otp_auth/?email=${encodeURIComponent(email)}&token=${token}`;
  return postRequest(url, getApiToken({ allowDefault: true }));
};
