import {
  CollectionSectionType,
  FeedWeekProgress,
  HashtagSectionType,
  ProfileApiResponseAchievement,
  ResponseMeta,
  SectionFirstObjectLabelModel,
  SectionGroupTypeModel,
  SectionReadingProgressTypeModel,
  SectionSimpleTypeModel,
  SectionStasherOfTheDayTypeModel,
  SectionTypeModel,
  SeoArticleApiResponse,
  SourceSectionType,
  StripeProductApiResponse,
  SuggestedBlockApiResponse,
  UserSectionType,
} from 'api/api.types';
import React from 'react';
import {
  ArticleStatus,
  IdeaStatus,
  IdeaType,
  ReviewStatus,
  SourceSubtype,
  SourceType,
  SubscriptionPlan,
  UserSubscriptionStatus,
  UserSubscriptionType,
} from './enums';
import {
  ListOrder,
  PaymentLocation,
  PeriodUnit,
  ProductType,
  SubscriptionType,
  UserType,
} from './types';

export interface OnboardingTooltips {
  // We only use:
  achieveQuestionAnswers?: string[];
}

export interface UserProfile {
  firstName: string;
  lastName: string;
  id: number;
  createdAt?: string;
  email: string;
  bio?: string;
  username?: string;
  location?: string;
  emailSubscribed?: {
    daily?: boolean;
    weekly?: boolean;
    product?: boolean;
  };
  dailyReadingGoal: number;
  readingGoalAlert: boolean;
  photo?: string;
  /**
   * imagePreview it's used for the metaData for the Profile
   */
  imagePreview?: string;
  url?: string;
  newNotificationsCount: number;

  didOnboarding: boolean;
  /**
   * Used on mobile
   */
  didOnboardingStashing: boolean;
  twitterUsername: string;
  department?: string;
  userType?: UserType;
  hashtagRankings: ProfileTopicRanking[];
  readIdeas: Record<number, number>;
  publishedIdeasTopics: TopicsPublishedIn[];
  recentSavedTopics: RecentSavedHashtag[];
  savesOnPublishedIdeas: number;
  publishedIdeas: number;
  allReactions: Reaction[];
  receivedReactions: Reaction[];
  /**
   * Payments
   */
  subscriptionType: UserSubscriptionType;
  showOnboardingTooltips?: OnboardingTooltips;
  /**
   * Object present if sale ongoing
   */
  promotionalSale?: Sale;
}

/**
 * Interface used to identify overlaps between current and other user data types
 */
export interface UserProfileCommonFields {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  photo?: string;
  followers: number;
  following: number;
  dailyReadingGoal: number;
  readToday: number;
  numReads: number;
  currentStreak: number;
  bio?: string;
  location?: string;
  url?: string;
  twitterUsername?: string;
  hashtagRankings: ProfileTopicRanking[];
  publishedIdeasTopics: TopicsPublishedIn[];
  recentSavedTopics: RecentSavedHashtag[];
  savesOnPublishedIdeas: number;
  numPublishedIdeas: number;
  numStashedIdeas: number;
  subscriptionType: UserSubscriptionType;
}

export type UserActivity = {
  stashedArticles: number[];
  /**
   * All the articles the user created, both published and drafts
   */
  createdArticles: number[];
  stashedIdeasIds: number[];
  /**
   * Pairs that hold info about the stashes where you stashed each idea
   * The first element of the pair is the id of the idea and the second element is the id of the stash
   */
  stashedIdeas: [number, number][];
  /**
   * How many drafts articles does the user have
   */
  draftCount: number;
  /**
   * How may articles did the user published
   */
  publishedArticlesCount: number;
  /**
   * How many ideas did the user create. Drafts are excluded
   */
  createdIdeasCount: number;
  /**
   * The ids of the ideas the user read in this session
   */
  sessionReads: Set<number>;
  /**
   * All the ideas the user has read since we last synced local and backend
   */
  readIdeas: Set<number>;

  readToday: number;
  totalReads: number;
  reads: Record<number, number>;

  stashes: UserStash[];

  achievements: ProfileApiResponseAchievement[];
  /**
   * Daily goal streak
   */
  currentStreak: number;
  longestStreak: number;
  /**
   * The ids of the users that you follow
   */
  following: number[];
  /**
   * The ids of the users that follow you
   */
  followers: number[];
  /**
   * The hashtags the user follows
   */
  hashtags: string[];
  /**
   * Liked ideas by the user
   */
  // [Like functionality]
  // likedIdeas: number[];
};

export interface Topic {
  id: number;
  image: string;
  name: string;
  categoryOrder: number;
}

export interface MetaTopic extends Topic {
  /**
   * The id field from the explore endpoint
   * Used to map between hashtags from the explore endpoint and the illustartions
   */
  exploreId: string;
}

interface AchievementsData {
  data: string[];
  type: string;
}

export type AchievementsType = AchievementsData[];

export enum IdeaSyncStatus {
  NOT_SYNCED,
  SYNCING,
  SYNCED,
}

export enum SyncStatus {
  DEFAULT = '',
  NOT_SAVED = 'Unsaved Changes',
  SAVING = 'Saving',
  SAVED = 'Saved',
  ERROR = 'Error',
}
export interface Idea {
  /**
   * The name of the author of the quote
   * This property misses if the idea is a text one
   */
  authorName?: string;
  /**
   * The source of this idea
   * All ideas have a source, but some API endpoints don't provide it on the Idea so it might miss on the model as well
   */
  source?: Source;
  /**
   * The id of the source of this idea
   * All ideas have a source, but some API endpoints don't provide it on the Idea so it might miss on the model as well
   */
  sourceId?: number;
  content: string;
  createdAt: string;
  /**
   * unique identification key
   * if the value is > 0 than the idea is stored in DB
   * otherwise, it means the idea is just on local
   */
  id: number;
  /**
   * the current local id of this idea
   * We need a property that doesn't change its value when saving the idea to the backend to avoid bad behavior due to rerenders
   * This property should be used instead of `id` whenever working with images in the editor.
   * Published ideas can use this as well since by then localId === id
   */
  localId: number;
  image?: string;
  /**
   * Loading blur for the image
   */
  imageBlurhash?: string;
  isDraft?: boolean;
  isFeaturedInCollection?: boolean;
  /**
   * Whether or not this idea is synced with the backend
   */
  syncStatus: IdeaSyncStatus;
  totalSaves: number;
  totalReads: number;

  // [Like functionality]
  // totalLikes: number;

  /**
   * The id of the user that created this idea
   */
  userId?: number;
  /**
   * @deprecated
   */
  topics: Topic[];
  /**
   * The title of this text idea
   * This property misses if the idea is a quote
   */
  title?: string;
  /**
   * The order of the idea in its source
   */
  order: number;
  timeToRead: number;
  status?: IdeaStatus;
  quality?: number;
  type: IdeaType;
  hashtags?: string[];
}

/**
 * Cut down version of the Stash model with only the basic properties
 */
export interface UserStash {
  id: number;
  ideaCount: number;
  emoji: string;
  name: string;
  description?: string;
  imageUrl?: string;
  imageBlurhash?: string;
  imagePreview?: string;
}

export interface SourceCollectionProgress {
  readIdeas: number[];
  completed: boolean;
}

export interface CollectionProgress {
  totalReadIdeas: number;
  completed: boolean;
  sourcesProgress: Record<number, SourceCollectionProgress>;
}

export interface CollectionSourceListData {
  source: Source;
  sourceProgress: SourceCollectionProgress;
}

export interface CollectionData extends UserStash {
  sources: Source[];
  hashtags: string[];
  progress: CollectionProgress;
  articlesCount: number;
  booksCount: number;
  podcastCount: number;
  independentsCount: number;
  videosCount: number;
}

export interface Stash extends UserStash {
  /** The sources that are stashed here */
  sources?: Source[];
  /**
   * The ideas stashed here
   */
  ideas?: Idea[];
  /**
   * The user that created this stash
   */
  user?: UserData;
  /**
   * Shows how this stash is curently sorted
   */
  sort?: ListOrder;
}

/**
 * Data about users that are not the current user
 */
export type UserData = {
  bio?: string;
  firstName: string;
  id: number;
  lastName: string;
  photo?: string;
  photo_blurhash?: string;
  url?: string;
  username?: string;
};

export type Sale = {
  id: string;
  title: string;
  discountPercentage: number;
  startDate: string;
  endDate: string;
};

export type Source = {
  /**
   * The author of the source, if the source is a book
   */
  author?: string;
  ideas: Idea[];
  /**
   * Used if no ideas are fetched
   */
  ideaCount?: number;
  /**
   * Used to slugify the article for URLs
   */
  content?: string;
  description?: string;
  hashtags?: string[];
  id: number;
  image?: string;
  /**
   * Blur Loading Image
   */
  imageBlurhash?: string;
  /**
   * The accent color of the image
   */
  imageColor: string;
  publishers?: string[];
  status: ArticleStatus;
  title: string;
  titleInCollection?: string;
  seo?: SeoArticleApiResponse;
  sourceType: SourceType;
  sourceSubtype: SourceSubtype;
  suggestedBlocks?: SuggestedBlockApiResponse[];
  suggestedTopics: string[];
  /**
   * The url to the source
   */
  url?: string;
  /**
   * The user that added this source to Deepstash
   */
  userData: UserData;
  topics?: Topic[];
  similarArticles?: Source[];
  /**
   * The stash that this source was first published into
   */
  publishStash?: Stash;
  context?: string;
  totalComments: number;
  totalReactions: number;
  topReactions: SourceTopReaction[];
  totalReads?: number;
};

/**
 * This model describes a book query result from the google API
 * Used for importing books
 */
export interface BookResultModel {
  id: string;
  authors: string[];
  title?: string;
  description: string;
  image?: string;
  bookLink: string;
}

export type HashtagSources = {
  hashtag: string;
  meta: ResponseMeta;
  objects: Source[];
  relatedTopics: string[];
  topStashers: UserData[];
  totalIdeas: number;
  totalRelatedTopics: number;
  totalTopStashers: number;
};

export type SearchedHashtag = {
  sources: Source[];
  name: string;
  totalIdeas: number;
};

export interface AccordionData {
  title: React.ReactChild;
  text: React.ReactChild;
}

export interface ScrappedSource extends Source {
  reviewStatus: ReviewStatus;
}

export interface ProfileTopicRanking {
  rank: number;
  name: string;
}

export interface TopicsPublishedIn {
  total_ideas: number;
  name: string;
}

export interface RecentSavedHashtag {
  name: string;
}

export interface ChipData {
  name: string;
  counter?: number;
}

export interface UserComment {
  id: number;
  createdAt: string;
  textHtml: string;
  userData: UserData;
  source?: Source;
}

export interface Reaction {
  id: number;
  url: string;
  name: string;
  count?: number;
}

export interface SourceTopReaction {
  reactionId: number;
  usersCount: number;
}

export interface UserReaction {
  reaction: {
    id: number;
  };
  user: UserData;
}

export interface ProfessionWithHashtagsType {
  name: string;
  personalHashtags: string[];
  trendingHashtags: string[];
  workHashtags: string[];
}

export interface Influencer {
  source?: Source;
  channelImageUrl: string;
  channelName: string;
  channelType: number;
  creatorName: string;
  creatorTag: string;
  link: string;
  promoCode: string;
  user: UserData;
}

export interface StripePromotion {
  code: string;
  discountPercentage: number;
  pricePerMonth: number;
  pricePerYear: number;
  priceAmount: number;
  yearlySavingsPercentage: number;
}

export interface FreeTrialData {
  duration: number;
  periodUnit: PeriodUnit; // DAY
}

export interface StripeSubscription {
  freeTrial?: FreeTrialData;
  periodNumber: number; // number of months / years
  periodUnit: PeriodUnit; // MONTH / YEAR
  priceAmount: number;
  priceCurrency: string;
  priceId: string;
  pricePerMonth: number;
  pricePerYear: number;
  promotion?: StripePromotion;
  sku: string;
  yearlySavingsPercentage: number;
  yearlyBaselinePrice: number;
}

export interface StripePricesData {
  publishableKey: string;
  subscriptions: Record<SubscriptionType, StripeSubscription | undefined>;
  products: Record<ProductType, StripeProductApiResponse>;
  promotionCodeError: string;
  testMode: boolean;
}

export interface StripeSubscriptionOrSetupIntent {
  clientSecret: string;
  subscriptionId?: string;
}

export interface UserSubscriptionDetails {
  startDate: string;
  endDate: string;
  isFreeTrial: boolean;
  userId: string;
  token: string;
  currencyCode: string;
  currencyAmount: number;
  /**
   * [0 -> Active] |
   * [1 -> Canceled] |
   * [2 -> On Hold (subscription free w/o details)] |
   * [3 -> in grace (another field in_grace_end_date)] |
   * [4 -> paused (subscription, also free)]
   * [5 -> expired]
   * [6 -> revoked (apple only)]
   */
  status: UserSubscriptionStatus;
  endDateFreeTrial?: string;
  inGraceEndDate?: string;
  plan: SubscriptionPlan;
  /**
   * Identify on which platform the user made the payment
   * [1 -> Android] |
   * [2 -> iOS] |
   * [3 -> Stripe == web] |
   * [4 -> Deepstash == free subscription given by us]
   */
  initialPaymentLocation: PaymentLocation;
}

export interface CurrentSubscription {
  downgradeSkus?: string[];
  upgradeSkus?: string[];
  subscriptionDetails?: UserSubscriptionDetails;
  /**
   * [1 -> free]
   * [2 -> pro]
   */
  subscriptionType?: UserSubscriptionType;
}

export type ExploreSectionItemHighlightType =
  | 'daily-pick'
  | 'highlight-hashtag';

export type ExploreSectionItemType =
  | 'hashtag'
  | 'source_type'
  // To handle a default case if backend adds a new type and we don't implement it
  | 'not-implemented'
  | ExploreSectionItemHighlightType;

export interface ExploreSectionItem {
  illustration: string;
  id: string;
  type: ExploreSectionItemType;
  subtitle?: string;
  title: string;
}

export interface ExploreSectionHashtagItem extends ExploreSectionItem {
  hashtagName: string;
}

export type HashtagPage2SectionId =
  | 'hashtag-read-next'
  | 'hashtag-top-stashers'
  | 'hashtag-related'
  | 'hashtag-collections'
  | 'hashtag-popular-articles'
  | 'hashtag-newest-articles'
  | 'hashtag-books'
  | 'hashtag-articles'
  | 'hashtag-videos'
  | 'hashtag-podcasts';

export interface ExploreSectionHashtagHighlightItem
  extends ExploreSectionHashtagItem {
  type: 'highlight-hashtag';
  initialSection: HashtagPage2SectionId;
}

export function isExploreSectionHashtagItem(
  exploreSectionItem: ExploreSectionItem,
): exploreSectionItem is ExploreSectionHashtagItem {
  return (
    (exploreSectionItem.type === 'hashtag' ||
      exploreSectionItem.type === 'highlight-hashtag') &&
    (exploreSectionItem as ExploreSectionHashtagItem).hashtagName !== undefined
  );
}

export function isExploreSectionHashtagHighlightItem(
  exploreSectionItem: ExploreSectionItem,
): exploreSectionItem is ExploreSectionHashtagHighlightItem {
  return (
    exploreSectionItem.type === 'highlight-hashtag' &&
    (exploreSectionItem as ExploreSectionHashtagHighlightItem).hashtagName !==
      undefined &&
    (exploreSectionItem as ExploreSectionHashtagHighlightItem)
      .initialSection !== undefined
  );
}

export interface ExploreSection {
  id: string;
  title: string;
  items: ExploreSectionItem[];
}

export interface ExploreSourceTypeSection {
  id: string;
  /**
   * The link to route the user to when clicking "See All"
   * The source type at the end of the url is normalized in plural
   * For the API GET we'll pass it back as singular
   */
  link: string;
  name: string;
  items: Source[] | UserData[];
  itemType: 'article' | 'user';
}

export interface ExploreSourceTypeSourceSection
  extends ExploreSourceTypeSection {
  items: Source[];
  itemType: 'article';
}

export interface ExploreSourceTypeUserSection extends ExploreSourceTypeSection {
  items: UserData[];
  itemType: 'user';
}

export interface ExploreSourceTypeData {
  ideaCount: number;
  sections: ExploreSourceTypeSection[];
}

export function isExploreSourceTypeSourceSection(
  section: ExploreSourceTypeSection,
): section is ExploreSourceTypeSourceSection {
  return section.itemType === 'article';
}

export function isExploreSourceTypeUserSection(
  section: ExploreSourceTypeSection,
): section is ExploreSourceTypeUserSection {
  return section.itemType === 'user';
}

export enum DailyPicksSectionType {
  BOOK_OF_THE_DAY,
  BOOK_OF_THE_DAY_HISTORY,
  QUOTE_OF_THE_DAY,
  QUOTE_OF_THE_DAY_HISTORY,
  PICKS,
  PICKS_HISTORY,
}

export interface DailyPicksSection {
  id: string;
  /**
   * The link to route the user to when clicking "See All"
   */
  link: string;
  name: string;
  items: Source[] | Idea[];
  itemType: 'article' | 'block';
  sectionType: DailyPicksSectionType;
}

export interface DailyPicksSourceSection extends DailyPicksSection {
  items: Source[];
  itemType: 'article';
}

export interface DailyPicksIdeaSection extends DailyPicksSection {
  items: Idea[];
  itemType: 'block';
}

export function isDailyPicksSourceSection(
  section: DailyPicksSection,
): section is DailyPicksSourceSection {
  return section.itemType === 'article';
}

export function isDailyPicksIdeaSection(
  section: DailyPicksSection,
): section is DailyPicksIdeaSection {
  return section.itemType === 'block';
}

type SectionModelObjectType = Source | CollectionData | UserData | string;

export interface BaseSectionModel<T extends SectionTypeModel> {
  id: string;
  link?: string;
  name: string;
  type: T;
}

type BaseSectionType =
  | UserSectionType
  | SourceSectionType
  | HashtagSectionType
  | CollectionSectionType;

export interface BaseSectionSimpleModel<
  T extends SectionModelObjectType,
  U extends BaseSectionType,
> extends BaseSectionModel<SectionSimpleTypeModel> {
  objects?: T[];
  firstObjectLabel?: SectionFirstObjectLabelModel;
  meta?: ResponseMeta;
  /** used to know what type of items to show in section */
  sectionType?: U;
}

export interface BaseSectionSimpleSourcesModel
  extends BaseSectionSimpleModel<Source, SourceSectionType> {}

export interface BaseSectionSimpleCollectionModel
  extends BaseSectionSimpleModel<CollectionData, CollectionSectionType> {}

export interface BaseSectionSimpleHashtagModel
  extends BaseSectionSimpleModel<string, HashtagSectionType> {}

export interface BaseSectionSimpleUserModel
  extends BaseSectionSimpleModel<UserData, UserSectionType> {}

export interface SectionReadingProgressModel
  extends BaseSectionModel<SectionReadingProgressTypeModel> {
  currentStreak: number;
  description?: string;
  weekProgress: FeedWeekProgress[];
}

export interface SectionStasherOfTheDayModel
  extends BaseSectionModel<SectionStasherOfTheDayTypeModel> {
  user: UserData;
  topAchievement: ProfileTopicRanking;
}

export interface BaseSectionGroupModel
  extends BaseSectionModel<SectionGroupTypeModel> {
  description?: string;
  sections?: SectionModel[];
}

export type SectionSimpleModel =
  | BaseSectionSimpleSourcesModel
  | BaseSectionSimpleCollectionModel
  | BaseSectionSimpleHashtagModel
  | BaseSectionSimpleUserModel;

export type SectionGroupModel = BaseSectionGroupModel;

export type SectionModel =
  | SectionSimpleModel
  | SectionGroupModel
  | SectionStasherOfTheDayModel
  | SectionReadingProgressModel;

export interface MetaHashtag {
  id: number;
  name: string;
  is_meta_hashtag?: boolean;
  title?: string;
  seo_title?: string;
  description?: string;
  emoji?: string;
}

export interface OnboardingMetaHashtag extends MetaHashtag {
  priority: number;
}
