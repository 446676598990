import { motion, MotionStyle, Variants } from 'framer-motion';
import React from 'react';

interface AnimatedHeightContainerProps {
  ease?: string;
  variants?: Variants;
  isVisible: boolean;
}

const AnimatedHeightContainer: React.FC<
  AnimatedHeightContainerProps & MotionStyle
> = ({
  ease = 'easeOut',
  variants = {
    open: {
      opacity: 1,
      height: 'auto',
    },
    collapsed: { opacity: 0, height: 0 },
  },
  isVisible,
  children,
  ...props
}) => {
  return (
    <motion.div
      style={{
        overflow: 'hidden',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...props,
      }}
      initial={isVisible ? 'open' : 'collapsed'}
      animate={isVisible ? 'open' : 'collapsed'}
      inherit={false}
      variants={variants}
      transition={{
        ease: ease,
        duration: 0.2,
      }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedHeightContainer;
