import { ArticleApiResponse, InsightApiResponse } from 'api/api.types';
import { StyleProps, color, spacing } from 'deepstash-ui';
import jsCookie from 'js-cookie';
import getConfig from 'next/config';
import { NextRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import ReactHtmlParser, {
  Options,
  convertNodeToElement,
} from 'react-html-parser';
import { SourceTitleByTypeSize } from 'src/page-components/source/components/header/SourceTitleByType';
import { SourceType, UserSubscriptionType } from 'types/enums';
import { InsightQuality } from 'types/globalTypes';
import { Idea, Source, UserStash } from 'types/models';
import { ColorScheme } from 'types/types';
import {
  LOGGED_OUT_CUTOFF_LENGTH,
  WHITELIST_FULLSCREEN_PAGES,
} from './constants';
import { renderLink, trimIdeaNewLines } from './html-utils';

export const SQUARE_WIDTH = 32;
export const SQUARE_WIDTH_REM = spacing.toRem(32);
export const BLOCK_WIDTH = 2 * SQUARE_WIDTH;
export const BLOCK_WIDTH_REM = spacing.toRem(BLOCK_WIDTH);
export const COLUMN_WIDTH = BLOCK_WIDTH;
export const COLUMN_WIDTH_REM = spacing.toRem(COLUMN_WIDTH);
export const GAP_SIZE = SQUARE_WIDTH;
export const GAP_SIZE_REM = spacing.toRem(GAP_SIZE);
export const SECTION_GAP_REM = spacing.toRem(3 * GAP_SIZE);

export function validateEmail(email: string) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export const validateEmailAdvanced = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    );
};

export const validateBusinessEmail = (email: string) => {
  let isBusinessEmail = true;
  const commonEmailDomains = [
    '@icloud.com',
    '@me.com',
    '@gmail.com',
    '@yahoo.com',
    '@outlook.com',
  ];
  commonEmailDomains.forEach(emailDomain => {
    if (email.endsWith(emailDomain)) {
      isBusinessEmail = false;
    }
  });
  return isBusinessEmail;
};

export const validateSignUpPassword = (password: string) =>
  password.length >= 4 && password.length <= 128;

export const validatePassword = (password: string) =>
  password.length > 0 && password.length <= 128;

/**
 *
 * @param currentRoute route of the current page/component
 * @returns true if the current page should have no header or footer
 */
export const isOnFullscreenPage = (currentRoute: string): boolean => {
  const splitRoute = currentRoute.split('/')[1];
  return (
    WHITELIST_FULLSCREEN_PAGES.filter(route => splitRoute?.startsWith(route))
      .length !== 0
  );
};

export const getPixelWidthFromColumns = (columnCount: number): number => {
  return columnCount * COLUMN_WIDTH + Math.ceil(columnCount - 1) * GAP_SIZE;
};

export const getRemWidthFromColumns = (columnCount: number): string => {
  return spacing.toRem(
    columnCount * COLUMN_WIDTH + Math.ceil(columnCount - 1) * GAP_SIZE,
  );
};

/**
 *
 * @param element
 * @returns
 */
export const scrollToElement = ({
  element,
  offset = 0,
}: {
  element: HTMLDivElement;
  offset?: number;
}) => {
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = (elementRect ?? 0) - (bodyRect ?? 0);
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
};

/**
 * Builds the custom text for Public Search Results in NSI
 * @param param0 Parsed Url Query of the route path
 * @returns formatted text and query
 */
export const buildSearchNSITextFromQuery = ({
  q: query,
  tab,
}: ParsedUrlQuery) => {
  if (tab === undefined) {
    return {
      specialText: '',
      specialQuery: '',
    };
  }
  const verb = tab === 'people' ? 'named' : 'related to';
  const capitalTab = `${tab[0].toUpperCase()}${tab?.slice(1)}`;
  return {
    specialText: `${capitalTab} ${verb}`,
    specialQuery: `“${query}”`,
  };
};

export type SourceTypeTitles =
  | 'article'
  | 'book'
  | 'podcast'
  | 'video'
  | 'yourself';

/**
 *
 * @param sourceType type of source
 * @returns string version of type
 */
export const getSourceTypeTitle = (
  sourceType: SourceType,
): SourceTypeTitles => {
  switch (sourceType) {
    case SourceType.ARTICLE: {
      return 'article';
    }
    case SourceType.BOOK: {
      return 'book';
    }
    case SourceType.PODCAST: {
      return 'podcast';
    }
    case SourceType.VIDEO: {
      return 'video';
    }
    case SourceType.YOURSELF: {
      return 'article';
    }
  }
  return 'article';
};

export type SourceTypeActions = 'read' | 'listened' | 'watched';

/**
 *
 * @param sourceType type of source
 * @returns  version of type
 */
export const getSourceTypeAction = (
  sourceType: SourceType,
): SourceTypeActions => {
  switch (sourceType) {
    case SourceType.PODCAST: {
      return 'listened';
    }
    case SourceType.VIDEO: {
      return 'watched';
    }
  }
  return 'read';
};

export const makeTransform = (
  colorMode: 'light' | 'dark',
  isSmallIdea?: boolean,
) => {
  const transform = (node: any, index: number) => {
    if (node.attribs) node.attribs.style = '';
    if (node === undefined) {
      return null;
    }
    if (node.type === 'tag' && node.name === 'html') {
      return null;
    }

    if (node.type === 'tag' && node.name === 'gwmw') {
      return null;
    }

    if (node.type === 'tag' && (node.name === 'ul' || node.name === 'ol')) {
      node.attribs.style = `margin-left: 24px;color:${color[colorMode].text}; margin-top:16px; margin-bottom:16px;`;
      return convertNodeToElement(node, index, transform);
    }

    if (node.type === 'tag' && node.name === 'button') {
      return null;
    }

    if (node.type === 'tag' && node.name === 'p') {
      const isEmpty =
        node.children.length !== 0 && node.children[0].name === 'br';
      if (isEmpty) {
        node.attribs.style += `display: none`;
      } else {
        if (isSmallIdea) {
          node.attribs.style += `margin-bottom:2px`;
        } else {
          node.attribs.style += `margin-bottom:12px`;
        }
      }
      return convertNodeToElement(node, index, transform);
    }

    if (node.type === 'tag' && node.name === 'li') {
      return convertNodeToElement(node, index, transform);
    }

    if (node.type === 'tag' && node.name === 'a') {
      const className =
        colorMode === 'light' ? 'idea-content-link' : 'idea-content-link-dark';
      const target = '_blank';
      const rel = 'noopener nofollow noreferrer';
      const { href } = node.attribs;

      // We further process the node's children, in case they contain
      // transformed text (e.g. underlined or italic text). In order not to
      // have duplicate <a> tags, the tag surrounding the children will
      // be a <span>. Thus, we will have: <a><span>{children}</span></a>
      node.name = 'span';
      node.attribs.href = undefined;
      const data = convertNodeToElement(node, index, transform);

      return renderLink({ href, data, className, rel, target });
    }

    return undefined;
  };
  return transform;
};

// export const hexToRgb = (hex: string) => {
//   const r = parseInt(hex.slice(1, 3), 16);
//   const g = parseInt(hex.slice(3, 5), 16);
//   const b = parseInt(hex.slice(5, 7), 16);
//   const t = 1;
//   return r && g && b
//     ? {
//         r,
//         g,
//         b,
//         t,
//       }
//     : null;
// };

export function isDescription(
  article: Source | ArticleApiResponse,
  parsedDescription: any,
): boolean {
  return (
    !!article.description && !!(parsedDescription[0] || parsedDescription[1])
  );
}

// ---- URL FUNCTIONS ----

export const shrinkTitle = (title: string | undefined): string => {
  if (!title) return '';
  const words = title.split(' ');
  if (words.length < 5) return title;
  return [words[0], words[1] + '...', words[words.length - 1]].join(' ');
};

export const shrinkContent = (content: string | undefined): string => {
  if (!content) return '';
  return content
    .replace(/(&quot\;)/g, ' ')
    .replace(/(&#39\;)/g, "'")
    .replace(/<\/?\s*[a-z]+[^>]*>/gi, ' ')
    .split(' ')
    .splice(0, 10)
    .join(' ')
    .concat('...');
};

export const slugify = (
  title: string | undefined,
  content?: string,
): string => {
  const text = title && title.trim() ? title.trim() : content?.trim();
  const isShortened = !(title && title.trim());
  const a =
    'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
  const b =
    'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');
  if (!text) return '404';
  let finalText: string;
  finalText = text
    .replace(/(&quot\;)/g, ' ')
    .replace(/(&#39\;)/g, "'")
    .replace(/<\/?\s*[a-z]+[^>]*>/gi, ' ')
    .replace(/[^a-zA-Z0-9 -]+/g, '')
    .trim();
  if (!isShortened) finalText = finalText.split(' ').join('-');
  else finalText = finalText.split(' ').splice(0, 10).join('-');
  finalText = finalText
    .toLowerCase()
    .replace(p, c => b.charAt(a.indexOf(c)))
    .replace(/&/g, '-and-')
    .replace(/\-\-+/g, '-');
  if (!finalText) return '404';
  return finalText;
};

export const cleanInsightTitle = (insight: InsightApiResponse): string => {
  let newTitle = '';
  if (insight.title?.trim()) newTitle += insight.title.trim();
  else newTitle += shrinkContent(insight.content);

  return newTitle;
};

// ---- IDEA CONTENT FUNCTIONS ----

export const getTrimmedContent = ({
  isSmallIdea,
  trimLength,
  content,
  colorMode,
}: {
  content: string;
  colorMode: 'light' | 'dark';
  isSmallIdea?: boolean;
  trimLength: number;
}) => {
  return ReactHtmlParser(
    (content || '').substring(0, trimLength) +
      ((content || '').length > trimLength ? '...' : ''),
    {
      decodeEntities: true,
      transform: makeTransform(colorMode, isSmallIdea),
    },
  );
};

/**
 * if condition is true, return trimmed content.
 * In both cases, returned text will be parsed by ReactHtmlParser.
 * isSmallIdea is a parameter that is sent further down to transform paragraphs
 * in a different way fro SmallIdeaCards.
 * By default, condition is true
 * @returns React.ReactElement
 */
export const getConditionallyTrimmedContent = ({
  condition,
  content,
  colorMode,
  options,
  isSmallIdea = false,
  trimLength = LOGGED_OUT_CUTOFF_LENGTH,
  additionalParsing = val => val,
}: {
  condition?: boolean;
  content: string;
  colorMode: 'light' | 'dark';
  options?: Options;
  isSmallIdea?: boolean;
  /**
   * How many characters to trim the idea to
   */
  trimLength?: number;
  /**
   * A function to parse the HTML content after the new lines have been trimmed,
   * but before the trimming of the content if `condition` is true
   * @param content
   */
  additionalParsing?: (content: string) => string;
}) => {
  return condition
    ? getTrimmedContent({
        content: additionalParsing(trimIdeaNewLines(content)),
        colorMode,
        trimLength,
        isSmallIdea,
      })
    : ReactHtmlParser(additionalParsing(trimIdeaNewLines(content)), options);
};

// ---- READ FUNCTIONS ----

export const selectHighestPriorityIdea = (insights: Idea[]) => {
  let highestPriority = 10000;
  let highestPriorityIdea = undefined;
  let i = 0;
  for (const insight of insights) {
    const priority = 1000 + i;
    i += 1;
    if (priority < highestPriority) {
      highestPriority = priority;
      highestPriorityIdea = insight;
    }
  }
  return highestPriorityIdea;
};

// ----

export const getQuality = (ideaQuality: InsightQuality) => {
  const trueValues = Object.values(ideaQuality).filter((quality: boolean) => {
    return quality === true;
  });
  return trueValues.length;
};

export const convertNumberToInsightQuality = (num?: number): InsightQuality => {
  const insightQuality: InsightQuality = {
    isShort: false,
    isTitled: false,
    isUnique: false,
    isVisual: false,
  };
  if (!num) {
    return insightQuality;
  }
  let binaryStr = convertIntegerToBinaryString(num);
  if (num > 15 || num < 0 || binaryStr === '') {
    return insightQuality;
  }
  while (binaryStr.length < 4) {
    binaryStr = '0' + binaryStr;
  }

  for (let i = 0; i < 4; i++) {
    if (binaryStr[i] === '0') {
      continue;
    }
    if (i === 0) {
      insightQuality.isShort = true;
    } else if (i === 1) {
      insightQuality.isTitled = true;
    } else if (i === 2) {
      insightQuality.isUnique = true;
    } else if (i === 3) {
      insightQuality.isVisual = true;
    }
  }

  return insightQuality;
};

export const convertIntegerToBinaryString = (num: number) => {
  if (!Number.isInteger(num)) {
    return '';
  }
  return num.toString(2);
};

export const convertInsightQualityToNumber = (
  insightQuality: InsightQuality,
): number => {
  let result = 0;

  if (insightQuality.isShort) {
    result += Math.pow(2, 3);
  }

  if (insightQuality.isUnique) {
    result += Math.pow(2, 2);
  }

  if (insightQuality.isTitled) {
    result += Math.pow(2, 1);
  }

  if (insightQuality.isVisual) {
    result += 1;
  }

  return result;
};

export const createThrottleFunction = () => {
  let timerId: ReturnType<typeof setTimeout> | undefined;

  const throttleFunction = function (func: () => void, delay: number) {
    if (timerId) return;

    timerId = setTimeout(function () {
      func();
      timerId = undefined;
    }, delay);
  };

  return throttleFunction;
};

export const handleHtmlToText = (content: string) => {
  let text = '' + content;

  // remove BR tags and replace them with line break
  text = text.replace(/<br>/gi, '\n');
  text = text.replace(/<br\s\/>/gi, '\n');
  text = text.replace(/<br\/>/gi, '\n');

  // remove P and A tags but preserve what's inside of them
  text = text.replace(/<p.*?>/gi, '\n');
  text = text.replace(/<a.*href="(.*?)".*>(.*?)<\/a>/gi, ' $2 ($1)');

  // remove all inside SCRIPT and STYLE tags
  text = text.replace(/<script.*>[\w\W]{1,}(.*?)[\w\W]{1,}<\/script>/gi, '');
  text = text.replace(/<style.*>[\w\W]{1,}(.*?)[\w\W]{1,}<\/style>/gi, '');

  // remove all else
  text = text.replace(/<(?:.|\s)*?>/g, '');

  // get rid of more than 2 multiple line breaks:
  text = text.replace(/(?:(?:\r\n|\r|\n)\s*){2,}/gim, '\n\n');

  // get rid of more than 2 spaces:
  text = text.replace(/ +(?= )/g, '');

  // get rid of html-encoded characters:
  text = text.replace(/&nbsp;/gi, ' ');
  text = text.replace(/&amp;/gi, '&');
  text = text.replace(/&quot;/gi, '"');
  text = text.replace(/&lt;/gi, '<');
  text = text.replace(/&gt;/gi, '>');
  return text;
};

const urlPattern = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '\\/?\\S*$', // the rest (no spaces)
);

export const isValidUrl = (stringToTest: string) => {
  return urlPattern.test(stringToTest);
};

export type Procedure = (...args: any[]) => void;

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce<F extends Procedure>(
  func: F,
  waitMilliseconds = 50,
  isImmediate?: boolean,
): (this: ThisParameterType<F>, ...args: Parameters<F>) => Promise<void> {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;

  return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    return new Promise(resolve => {
      const doLater = function () {
        timeoutId = undefined;
        if (isImmediate !== true) {
          func.apply(context, args);
          resolve();
        }
      };

      const shouldCallNow = isImmediate && timeoutId === undefined;

      if (timeoutId !== undefined) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(doLater, waitMilliseconds);

      if (shouldCallNow) {
        func.apply(context, args);
        resolve();
      }
    });
  };
}

export class PausableTimeout {
  private timeout: ReturnType<typeof setTimeout> | null = null;
  private startTime = -1;
  private remainingTime = 0;
  private isPaused = false;
  private callback: () => void = () => {
    return;
  };

  start(callback: () => void, delay: number) {
    this.callback = () => {
      this.remainingTime = 0;
      this.timeout = null;
      callback();
    };
    if (!this.timeout) {
      this.startTime = Date.now();
      this.isPaused = false;
      this.remainingTime = delay;
      this.timeout = setTimeout(this.callback, delay);
    }
  }

  pause() {
    if (this.startTime !== -1 && this.timeout) {
      this.remainingTime -= Date.now() - this.startTime;
      clearTimeout(this.timeout);
      this.timeout = null;
      this.isPaused = true;
    }
  }

  resume() {
    if (this.remainingTime > 0 && this.isPaused) {
      this.startTime = Date.now();
      this.timeout = setTimeout(this.callback, this.remainingTime);
    }
  }

  stop() {
    if (this.timeout) {
      this.remainingTime = 0;
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }
}

export function getRandomInt(min?: number, max?: number): number {
  if (min && max) return Math.floor(Math.random() * (max - min + 1)) + min;
  return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
}

export function getRandomNegativeInt(): number {
  return (
    Math.floor(Math.random() * -Number.MIN_SAFE_INTEGER) +
    Number.MIN_SAFE_INTEGER
  );
}

export type ObservableSubscriber<T> = (newVal: T, oldVal: T) => void;

type UpdateFunction<T> = (oldData: T) => T | Partial<T>;

function isFunction<T>(a: any): a is UpdateFunction<T> {
  return (a as UpdateFunction<T>).call !== undefined;
}

export class BaseObservable<T> {
  protected data: T;
  protected subscribers: Set<ObservableSubscriber<T>> = new Set();

  constructor(initialData: T) {
    this.data = initialData;
  }

  protected notify(oldData: T): void {
    this.subscribers.forEach(subscriber => subscriber(this.data, oldData));
  }

  get Data(): Readonly<T> {
    return this.data;
  }
}

export class ImmutableObservable<T> extends BaseObservable<T> {
  constructor(initialData: T) {
    super(initialData);
  }

  public subscribe(
    subscriber: ObservableSubscriber<T>,
  ): ImmutableObservable<T> {
    this.subscribers.add(subscriber);
    subscriber(this.Data, this.Data);
    return this;
  }

  public unsubscribe(subscriber: ObservableSubscriber<T>): void {
    this.subscribers.delete(subscriber);
  }
}

export class Observable<T> extends ImmutableObservable<T> {
  constructor(initialData: T) {
    super(initialData);
  }

  public updateData(_newValue: T | Partial<T> | UpdateFunction<T>): void {
    const newValue: T | Partial<T> = isFunction<T>(_newValue)
      ? _newValue(this.data)
      : _newValue;

    const oldValue = { ...this.Data };
    if (typeof newValue === 'object') {
      this.data = { ...oldValue, ...newValue } as T;
    } else {
      this.data = newValue as T;
    }
    this.notify(oldValue);
  }

  public get Subscribers(): Set<ObservableSubscriber<T>> {
    return this.subscribers;
  }
}

export const numberFormatter = (num: number) => {
  const si = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'K' },
    { value: 1, symbol: '' },
  ];

  const scale = si.find(({ value }) => value <= num) || {
    value: 1,
    symbol: '',
  };
  const val = (num / scale.value).toString().substr(0, 4);

  return `${+val.toString()}${scale.symbol}`;
};

export function trimStashName(
  val: string | undefined,
  isMobileView: boolean | undefined,
): string | undefined {
  if (!val) return val;

  if (!isMobileView) {
    if (val.length <= 22) return val;
    else return val.substring(0, 18) + '...';
  } else {
    if (val.length <= 15) return val;
    else return val.substring(0, 12) + '...';
  }
  // if (val.length <= 22) return val;

  // return val.substring(0, 18) + '...';
}

export const isStashPage = (path: string): boolean => {
  return path.split('/')[1] === 'stash';
};

export const updateStashes = (
  stashes: UserStash[],
  id: number,
  newEmoji: string,
  newName: string,
): UserStash[] => {
  for (let i = 0; i < stashes.length; i++) {
    if (stashes[i].id === id) {
      stashes[i].name = newName;
      stashes[i].emoji = newEmoji;
      break;
    }
  }
  return stashes;
};

export const formatPictureUrl = (url?: string) =>
  url?.replace(/^http:\/\//i, 'https://') ?? '';

/**
 * @deprecated useDiffSinceTimestamp for getting the timestamp
 */
export const makeUTC = (dateString: string) => {
  const date = new Date(dateString);
  return Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  );
};

export const getApiUrl = () => {
  return getConfig().publicRuntimeConfig.API_URL;
};

export const getAuthUrl = () => {
  return getConfig().publicRuntimeConfig.AUTH_URL;
};

export const isSourceEmpty = (source: Source): boolean =>
  source.ideas.length === 0;

export const replaceAll = (
  string: string,
  search: string,
  replace: string,
): string => string.split(search).join(replace);

export const removeWhitespaces = (str: string) => {
  return str.replace(/ /g, '');
};

/*
 * cleaning the html from the idea to make it safe for rendering
 */
export const curateIdeaHtml = (content: string) => {
  //Replace the pre tags
  return content.replace(/(<\/?pre>)|(<pre .*>(?=\w))/g, '');
};

/**
 * Getting the shorter version for the domain name from an url (ex: without the ".org")
 * @param url
 */
export const getShorterDomainName = (url: string) => {
  let result;
  let match;
  if (
    (match = url.match(
      // eslint-disable-next-line no-useless-escape
      /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im,
    ))
  ) {
    result = match[1];
    // eslint-disable-next-line no-useless-escape
    if ((match = result.match(/^[^\.]+\.(.+\..+)$/))) {
      result = match[1];
    }
  }
  result = result?.slice(0, result?.lastIndexOf('.'));
  return result || '';
};

/**
 * Getting the domain name from an url
 * @param url
 */
export const getDomainName = (url: string) => {
  try {
    const domain = new URL(url);
    return domain.hostname.replace('www.', '');
  } catch {
    return '';
  }
};

/**
 *  Returns an emoji icon based on the ranking
 * @param position
 * @returns '' | 🥉 | 🥈 | 🥇
 */
export const getAchievementIconByRank = (rank: number) => {
  let achievementIcon;
  switch (rank) {
    case 1: {
      achievementIcon = '🥇';
      break;
    }
    case 2: {
      achievementIcon = '🥈';
      break;
    }
    case 3: {
      achievementIcon = '🥉';
      break;
    }
    default:
      achievementIcon = '';
  }
  return achievementIcon;
};

/**
 *  Function used for smart memoization, to firstly check the id
 */
export const arePropsEqual = <T>(
  prevProps: T & StyleProps,
  nextProps: T & StyleProps,
  id1: number,
  id2: number,
): boolean => {
  // compare id change
  if (id1 !== id2) {
    return false;
  }

  //Compare the rest of the props

  for (const key in prevProps) {
    if ((prevProps as any)[key] !== (nextProps as any)[key]) return false;
  }

  return true;
};

/**
 * Calculates the impact based on hashtags length.
 * @param hashtags
 * @returns
 */
export const getImpactByTopics = (topics: string[]) => {
  let allTopicsLength = 0;
  topics.forEach(hashtag => {
    allTopicsLength += hashtag.length;
  });
  const offset = allTopicsLength % 6;
  const difference = offset > 3 ? offset - 3 : offset;
  switch (topics.length) {
    case 0:
      return 0;
    case 1:
      return 15 + difference;
    case 2:
      return 40 + difference;
    case 3:
      return 60 + difference;
    case 4:
      return 80 + difference;
    case 5:
      return 95 + difference;
    default:
      return 50;
  }
};
/**
 * Get the impact color
 * @param impact
 * @param colorScheme
 * @returns the color for impact
 */
export const getImpactColor = (impact: number, colorScheme: ColorScheme) => {
  if (impact < 30) {
    return color.failure.default;
  } else if (impact < 70) {
    return color[colorScheme].secondary.default;
  } else {
    return color.success;
  }
};

export const onLinkClick: React.MouseEventHandler<HTMLAnchorElement> = e => {
  if (e.metaKey || e.ctrlKey) {
    //The link will be open in another tab
    //We don't need to preserve the scroll
    //Stop the propagation so it won't also start a navigation on the current tab
    e.stopPropagation();
  }
};

/**
 * Verifies if the browser is Safari
 * @returns boolean
 */
export const isSafariBrowser = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  const isSafari =
    /constructor/i.test(window.HTMLElement as any) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]';
    })(
      !window['safari' as any] ||
        (typeof window['safari' as any] !== 'undefined' &&
          (window['safari' as any] as any).pushNotification),
    );

  return isSafari;
};

export const getSourceTitleLeftItemSize = (
  size: SourceTitleByTypeSize,
  isMobileView: boolean,
) => {
  switch (size) {
    case 'lg': {
      return isMobileView ? spacing.toRem(100) : spacing.toRem(118);
    }
    case 'md': {
      return isMobileView ? spacing.toRem(64) : spacing.toRem(80);
    }
    default: {
      //size == 'sm'
      return isMobileView ? spacing.toRem(50) : spacing.toRem(50);
    }
  }
};

/*
 * Returns the subscription type as string.
 */
export const subscriptionTypeToString = ({
  subscriptionType,
}: {
  subscriptionType?: UserSubscriptionType;
}) => {
  switch (subscriptionType) {
    case UserSubscriptionType.Free:
      return 'free';
    case UserSubscriptionType.Premium:
      return 'pro';
    case UserSubscriptionType.Legacy:
      return 'legacy';
    default:
      return '';
  }
};

export const getCurrentDateAfterXDays = (offset = 0) => {
  const date = new Date();
  date.setDate(date.getDate() + offset);
  return date;
};

/**
 * Get the values of teh properties from a that are different from b
 * @param a The object we are diffing
 * @param b The reference object
 */
export const getObjectDiff = <T extends { [key: string]: any }>(a: T, b: T) => {
  const diff: Partial<T> = {};
  for (const key in a) {
    if (a[key as keyof T] !== b[key as keyof T]) {
      diff[key as keyof T] = a[key as keyof T] as any;
    }
  }

  return diff;
};

/**
 * Used to transform a formatted Meta Topic Name into a hashtagname
 * @param s The string to format
 * @returns The formatted string
 */
export const formatStringToHashtagCase = (s: string) => {
  return s.replace(/ /g, '').toLowerCase();
};

/**
 * menuStyle used by ShareArticleDropdown, SourceCommentsDropdown and SourceReactionsDropdown
 */
export const dropdownMenuStyle = {
  boxShadow:
    '0px 10px 32px -4px rgba(0,0,0, 0.2), 0px 6px 14px -6px rgba(0,0,0, 0.12)',
};

export const getSourceTypeAsString = (sourceType: SourceType) => {
  switch (sourceType) {
    case SourceType.ARTICLE: {
      return 'article';
    }
    case SourceType.BOOK: {
      return 'book';
    }
    case SourceType.PODCAST: {
      return 'podcast';
    }
    case SourceType.VIDEO: {
      return 'video';
    }
    case SourceType.UNKNOWN: {
      return 'unknown';
    }
    case SourceType.YOURSELF: {
      return 'yourself';
    }
    default: {
      return 'article';
    }
  }
};

export const getSourceTypeAsEnum = (sourceType: string): SourceType => {
  const sourceTypeStrings = [
    'article',
    'book',
    'video',
    'podcast',
    'yourself',
    'unknown',
  ];

  return Math.max(
    // This index will be the index of the enum value
    // ex. SourceType.ARTICLE = 0 = indexOf('article')
    sourceTypeStrings.findIndex(el => el === sourceType),
    0,
  );
};

/**
 * Function to clear specific URL params
 * @param params The params to clear from the URL
 * @param router The router object
 * @param forceRefresh Whether to force a refresh of the page
 * @returns void
 */
export const clearUrlParams = ({
  params,
  router,
  forceRefresh = false,
}: {
  params: string[];
  router: NextRouter;
  /**
   * @default false
   */
  forceRefresh?: boolean;
}) => {
  const query = { ...router.query };

  params.forEach(param => {
    delete query[param];
  });

  router.replace(
    {
      pathname: router.pathname,
      query,
    },
    undefined,
    { shallow: !forceRefresh },
  );
};

/**
 * Function to determine whether the user is on a landing page
 * Used for example to determine whether to show the search input
 * @param pathname {string} The pathname of the current page
 */
const LANDING_PAGES = ['/', '/producthunt'];
export const isOnLandingPage = (pathname: string) => {
  return LANDING_PAGES.includes(pathname);
};

export const shouldRedirectIdeaPage = (id: number) => {
  const modulo = id % 10;
  return modulo < 3;
};

type rgbColor = {
  r: number;
  g: number;
  b: number;
};
type colorSchemeType = 'light' | 'dark';

const hexToRgb = (hexString: string): rgbColor => {
  const integer = parseInt(hexString, 16);
  return {
    r: (integer >> 16) & 255,
    g: (integer >> 8) & 255,
    b: integer & 255,
  };
};

const rgbToHex = (rgbColor: rgbColor) => {
  return (
    '#' +
    Object.values(rgbColor)
      .map(color => {
        const hexColor = color.toString(16);
        return hexColor.length === 1 ? '0' + hexColor : hexColor;
      })
      .join('')
  );
};

const getTint = ({ value, opacity }: { value: number; opacity: number }) =>
  Math.floor(value + (255 - value) * opacity);
const getShade = ({ value, opacity }: { value: number; opacity: number }) =>
  Math.floor(value * (1 - opacity));
const getShiftedColor = ({
  value,
  opacity,
  colorScheme,
}: {
  value: number;
  opacity: number;
  colorScheme: colorSchemeType;
}) => {
  return colorScheme === 'light'
    ? getTint({ value, opacity })
    : getShade({ value, opacity });
};

/**
 * Function that grabs the color, opacity (between 0 and 1) and the colorScheme to give the shaded/tinded color.
 */
export const getTintHex = ({
  ogColor,
  opacity,
  colorScheme,
}: {
  ogColor: string;
  opacity: number;
  colorScheme: colorSchemeType;
}) => {
  const ogColorRgb: rgbColor = hexToRgb(ogColor.substring(1));
  return rgbToHex({
    r: getShiftedColor({ value: ogColorRgb.r, opacity, colorScheme }),
    g: getShiftedColor({ value: ogColorRgb.g, opacity, colorScheme }),
    b: getShiftedColor({ value: ogColorRgb.b, opacity, colorScheme }),
  });
};

export const recordToArray = <T>(record: Record<string, T>): T[] =>
  Object.values(record);

/**
 * Transform in array into a matrix
 */
export const transformArrayToMatrix = <T>({
  items,
  rowSize,
}: {
  items: T[];
  rowSize: number;
}): T[][] => {
  const rowList: T[][] = [];
  for (let i = 0; i < items.length; i += rowSize) {
    rowList.push(items.slice(i, i + rowSize));
  }
  return rowList;
};

/**
 * Check whether an image URL comes from one of our free image providers
 */
export const isStockPhoto = (url: string) => {
  return (
    url.startsWith('https://images.unsplash.com') ||
    url.startsWith('https://images.pexels.com') ||
    url.startsWith('https://pixabay.com')
  );
};

/**
 * Used to conditionally retrieve Facebook tracking params
 */
export const getFacebookTrackingParams = () => {
  if (
    ['tiktok', 'snapchat'].includes(
      (localStorage?.getItem('growthPlanSource') ?? '').toLowerCase(),
    )
  )
    return {};
  else
    return {
      facebookFbc: jsCookie?.get('_fbc'),
      facebookFbp: jsCookie?.get('_fbp'),
      facebookLoginId: localStorage?.getItem('facebookLoginId') ?? undefined,
    };
};

/**
 * Used to conditionally retrieve TikTok tracking params
 */
export const getTikTokTrackingParams = () => {
  if (
    (localStorage?.getItem('growthPlanSource') ?? '').toLowerCase() === 'tiktok'
  ) {
    return {
      tiktokTtcid: jsCookie?.get('ttclid') ?? jsCookie?.get('_ttc'),
      tiktokTtp: jsCookie?.get('_ttp'),
    };
  } else return {};
};

/**
 * Used to conditionally retrieve Snapchat tracking params
 */
export const getSnapchatTrackingParams = () => {
  if (
    (localStorage?.getItem('growthPlanSource') ?? '').toLowerCase() ===
    'snapchat'
  ) {
    return {
      snapchatClickId: localStorage?.getItem('snapchatClickId') ?? undefined,
      snapchatCookie: jsCookie?.get('_scid'),
    };
  } else return {};
};

/**
 * Used to conditionally retrieve Google tracking params
 */
export const getUtmParams = () => {
  return {
    utm_source: jsCookie?.get('utm_source'),
    utm_mediun: jsCookie?.get('utm_medium'),
    utm_campaign: jsCookie?.get('utm_campaign'),
    utm_term: jsCookie?.get('utm_term'),
    utm_content: jsCookie?.get('utm_content'),
  };
};

export const getCurrencySymbolByCode = (currencyCode: string) => {
  switch (currencyCode) {
    case 'usd':
      return '$';
    case 'gbp':
      return '£';
    case 'ron':
      return 'RON ';
    case 'eur':
      return '€';
    case 'cad':
      return 'C$';
    case 'aud':
      return 'A$';
    case 'chf':
      return 'CHF ';
    case 'cny':
      return 'CN¥';
    case 'jpy':
      return 'JP¥';
    case 'rub':
      return '₽';
    case 'brl':
      return 'R$';
    case 'inr':
      return '₹';
    case 'krw':
      return '₩';
    case 'mxn':
      return 'MX$';
    case 'zar':
      return 'R';
    case 'hkd':
      return 'HK$';
    case 'idr':
      return 'Rp';
    case 'nzd':
      return 'NZ$';
    case 'sek':
      return 'kr';
    case 'sgd':
      return 'S$';
    case 'thb':
      return '฿';
    case 'php':
      return '₱';
    case 'try':
      return '₺';
    case 'myr':
      return 'RM';
    case 'czk':
      return 'Kč';
    case 'pln':
      return 'zł';
    case 'huf':
      return 'Ft';
    case 'dkk':
      return 'kr';
    case 'nok':
      return 'kr';
    case 'ils':
      return '₪';
    case 'clp':
      return 'CLP$';
    default:
      return (currencyCode?.toUpperCase() ?? 'USD') + ' ';
  }
};
