import { PaymentElement } from '@stripe/react-stripe-js';
import {
  color,
  Flex,
  PrimaryButton,
  PrimaryHeading,
  spacing,
  Spinner,
  TextInput,
  typography,
  StyleProps,
  Text,
} from 'deepstash-ui';
import React, { useState } from 'react';
import actionStrings from 'utils/strings/actionStrings';
import pageStrings from 'utils/strings/pageStrings';
import usePaymentCheckoutInternals from './usePaymentCheckoutInternals';
import { PaymentCheckoutDetails } from './PaywallCheckoutModal';
import { PaywallAnalyticsLocation } from 'types/enums';
import { PAYWALL, PurchaseType } from 'types';

interface PaywallCheckoutModalContentProps {
  clientSecret: string;
  paymentDetails: PaymentCheckoutDetails;
  isPaymentIntentLoading: boolean;
  setIsPaymentIntentLoading?: (val: boolean) => void;
  isFromSetup: boolean;
  subscriptionId?: string;
  productType?: string;
  location?: PaywallAnalyticsLocation | string;
  containerStyleProps?: StyleProps;
  buttonStyleProps?: StyleProps;
  buttonTextStyle?: StyleProps;
  ctaText?: string;
  extraRequestParam?: string;
  type: PurchaseType;
}

const PaywallCheckoutModalContent: React.FC<
  PaywallCheckoutModalContentProps
> = ({
  clientSecret,
  paymentDetails,
  isPaymentIntentLoading,
  setIsPaymentIntentLoading,
  isFromSetup,
  subscriptionId,
  location,
  containerStyleProps,
  buttonStyleProps,
  buttonTextStyle,
  ctaText,
  extraRequestParam,
  productType,
  type,
}) => {
  const [nameOnCard, setNameOnCard] = useState<string>('');

  const { freeTrial, priceAmount, priceCurrency } = paymentDetails;
  const { handleSubmit, paymentStatus } = usePaymentCheckoutInternals({
    clientSecret,
    subscriptionId,
    productType,
    nameOnCard,
    isFromSetup,
    freeTrial,
    location,
    priceAmount,
    priceCurrency,
    extraRequestParam,
    type,
  });

  return (
    <Flex
      flexDir="column"
      width="100%"
      justifyContent={'space-between'}
      {...containerStyleProps}
    >
      <form
        id="payment-form"
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'space-between',
          height: 'auto',
        }}
      >
        {!isPaymentIntentLoading && (
          <Flex my={spacing.M.rem} flexGrow={1}>
            <Flex flexDir={'column'} width="100%">
              <Text
                {...typography.primaryTextFonts.medium.regular}
                color={color.light.textSecondary}
              >
                {pageStrings.checkout.nameOnCard}
              </Text>
              <TextInput
                width="100%"
                className="name-input"
                borderRadius={spacing.XXXL.rem}
                color={color.light.text}
                height={spacing.toRem(50)}
                px={spacing.S.rem}
                backgroundColor={color.light.top}
                border={'1px solid'}
                borderColor={color.light.textDisabled}
                placeholder="Name"
                paddingX="0.75rem"
                _focus={{
                  color: color.light.text,
                  border: '2px solid',
                  px: spacing.toRem(spacing.S.unit - 1), //we increase the border, so need to decrease the padding so that the content doesn't move
                  borderColor: color.light.pro.primary,
                }}
                _placeholder={{
                  ...typography.primaryTextFonts.medium.regular,
                  color: color.light.textDisabled,
                }}
                outline="none"
                {...typography.primaryTextFonts.medium.regular}
                _hover={undefined}
                _active={undefined}
                value={nameOnCard}
                onChange={setNameOnCard}
                isInvalid={paymentStatus?.requestStatus === 'failure'}
                errorText={paymentStatus?.error}
              />
            </Flex>
          </Flex>
        )}
        <PaymentElement
          id="payment-element"
          onReady={() => setIsPaymentIntentLoading?.(false)}
        />

        {/* <Flex flexDir={'column'}>
          {!isPaymentIntentLoading && (
            <PrimaryText
              size="s"
              type="bold"
              color={color[colorMode].textSecondary}
              mb={spacing.XXS.rem}
            >
              {pageStrings.checkout.cardInformation}
            </PrimaryText>
          )}
        </Flex> */}
        {isPaymentIntentLoading && (
          <Flex
            width="100%"
            height="100%"
            flexDir={'column'}
            align="center"
            mt="20%"
          >
            <Spinner
              height="40px"
              width="40px"
              color={color.light.pro.primary}
              secondaryColor={color.light.pro.background}
            />
          </Flex>
        )}
      </form>
      <PrimaryButton
        mt="auto"
        size="lg"
        onClick={handleSubmit}
        disabled={!nameOnCard}
        isLoading={paymentStatus?.requestStatus === 'loading'}
        bgColor={color.light.pro.primary}
        color={color.light.top}
        _active={{ bgColor: color.light.pro.primary, opacity: 0.9 }}
        loadingMessage={pageStrings[PAYWALL].loadingMessage}
        loadingMessageStyle={typography.primaryTextFonts.small.bold}
        _disabled={{
          opacity: paymentStatus?.requestStatus === 'loading' ? 1 : 0.4,
        }}
        {...buttonStyleProps}
      >
        <PrimaryHeading size="h5" textSize="s" type="bold" {...buttonTextStyle}>
          {ctaText ??
            (freeTrial !== undefined
              ? actionStrings.startTrial
              : actionStrings.start)}
        </PrimaryHeading>
      </PrimaryButton>
    </Flex>
  );
};

export default PaywallCheckoutModalContent;
