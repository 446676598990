import { PaymentIntent } from '@stripe/stripe-js';
import * as PurchaseApi from 'api/Purchases';
import { useDisclosure } from 'deepstash-ui';
import useStripePrices from 'hooks/purchases/useStripePrices';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import TagManager from 'react-gtm-module';
import { Analytics, Events } from 'services/analytics';
import useProfile from 'src/providers/hooks/useProfile';
import { UserSubscriptionType } from 'types/enums';
import { event, initGTM } from 'utils/analytics/gtag';
import {
  BASE_DEEPSTASH_URL,
  CLEAN_URL_AFTER_CONFIRM_REGEX,
} from 'utils/constants';
import {
  getSnapchatTrackingParams,
  getFacebookTrackingParams,
  getTikTokTrackingParams,
} from 'utils/global';

export const useSubscriptionDoneInternals = () => {
  const router = useRouter();
  const { profile, profileDispatch } = useProfile();
  const { fetchPrices } = useStripePrices({});

  const {
    isOpen: isSubscriptionDoneOpen,
    onClose: onSubscriptionDoneClose,
    onOpen: onSubscriptionDoneOpen,
  } = useDisclosure();

  const {
    isSubscriptionCompleted,
    isSubscriptionCompletedFromSetup,
    isSubscriptionTrialEligible,
    freeTrialDuration,
  } = useMemo(() => {
    return {
      isSubscriptionCompleted:
        (router?.query?.redirect_status as PaymentIntent.Status) ===
        'succeeded',
      isSubscriptionCompletedFromSetup: router?.query?.is_from_setup === 'true',
      isSubscriptionTrialEligible: router?.query?.has_free_trial === 'true',
      freeTrialDuration: router?.query?.free_trial_duration?.toString() ?? '0',
    };
  }, [router.query]);

  /** This useEffect is used for coming back from a subscription payment */
  useEffect(() => {
    /**
     * Example:
     *
     * ?subscription_id=sub_1Kb295BnN2mMSWQdXYdi4LIr
     * &is_from_setup=false&has_free_trial=false
     * &free_trial_duration=undefined
     * &payment_intent=pi_3Kb295BnN2mMSWQd3r5MyvZx
     * &payment_intent_client_secret=pi_3Kb295BnN2mMSWQd3r5MyvZx_secret_atHd5lwoUm4OfSyVN8NjUodEd
     * &redirect_status=succeeded
     */

    const {
      payment_intent,
      payment_intent_client_secret,
      redirect_status,
      subscription_id,
      setup_intent,
      setup_intent_client_secret,
      location,
      utm_campaign,
      extra_request_param,
      product_type,
    } = router.query;

    // Remove the stripe query params from the url
    // This avoids resending the confirmation request at the backend on reload
    if (payment_intent || setup_intent) {
      const clearedUrl = router.asPath
        .replace(CLEAN_URL_AFTER_CONFIRM_REGEX, '')
        .replace(/[?&]$/, '');
      router.replace(clearedUrl, undefined, { shallow: true });
    }

    const onConfirmSent = () => {
      Analytics.logEvent({
        eventName: Events.payments.subscribeEventSuccess,
        properties: {
          location,
          url: router.asPath,
          subscription_id,
          product_type,
          profile_id: profile?.id,
          profile_email: profile?.email,
          utm_campaign,
          amount: router.query.price_amount,
          currency: router.query.price_currency,
          experimentName: extra_request_param,
          'exp-variant': localStorage?.getItem('expVariant'),
        },
        platforms: [
          localStorage?.getItem('quizAccessToken')
            ? 'amplitude-mobile'
            : 'amplitude',
        ],
      });

      if (localStorage?.getItem('quizAccessToken')) {
        // Analytics.logEvent({
        //   eventName: Events.onboarding.viewQuizSetup,
        //   platforms: ['amplitude-mobile'],
        //   properties: {
        //     step: 'activate-account',
        //     email: localStorage?.getItem('quizEmail'),
        //     'exp-variant': extra_request_param,
        //   },
        // });
        event({
          action: 'conversion',
          category: '',
          label: 'activate-account',
          value: router.query.price_amount as string,
          transaction_id: subscription_id as string,
          currency: router.query.price_currency as string,
        });
        initGTM();
        TagManager.dataLayer({
          dataLayer: {
            ecommerce: null,
          },
        });
        TagManager.dataLayer({
          dataLayer: {
            event: 'purchase',
            ecommerce: {
              transaction_id: payment_intent,
              value: parseFloat((router.query.price_amount as string) ?? '0'),
              currency: (
                (router.query.price_currency as string) ?? 'usd'
              ).toUpperCase(),
              coupon: extra_request_param,
              items: [
                {
                  item_id:
                    (product_type ?? subscription_id) +
                    ((router.query.price_currency as string)
                      ? (('_' + router.query.price_currency) as string)
                      : '') +
                    ((router.query.price_amount as string)
                      ? (('_' + router.query.price_amount) as string)
                      : ''),
                  item_name: product_type ?? subscription_id,
                  coupon: extra_request_param,
                  item_brand: 'Deepstash',
                  item_category: 'Subscription',
                  price: router.query.price_amount as string,
                  quantity: 1,
                },
              ],
            },
          },
        });
        localStorage.removeItem('quizAccessToken');
        localStorage.removeItem('quizAccountIsDev');
        localStorage.removeItem('quizEmail');
        localStorage.removeItem('facebookLoginId');
        localStorage.removeItem('snapchatClickId');
      }

      profileDispatch({
        type: 'mutate',
        payload: {
          profile: { subscriptionType: UserSubscriptionType.Premium },
        },
      });

      // Ensure we don't cache prices with trial
      fetchPrices();
    };

    if (product_type) {
      onSubscriptionDoneOpen();
      PurchaseApi.confirmStripeProductPurchase({
        paymentIntent: payment_intent as string,
        paymentIntentClientSecret: payment_intent_client_secret as string,
        purchaseLocation: (location as string | undefined) ?? 'none',
        utmCampaign: utm_campaign as string | undefined,
        ...getFacebookTrackingParams(),
        ...getTikTokTrackingParams(),
        ...getSnapchatTrackingParams(),
        experimentName: extra_request_param as string | undefined,
        clientUserAgent: navigator?.userAgent,
        webpageUrl: BASE_DEEPSTASH_URL + router.asPath,
      }).then(onConfirmSent);
    } else if (
      payment_intent &&
      payment_intent_client_secret &&
      redirect_status &&
      subscription_id &&
      profile?.subscriptionType !== UserSubscriptionType.Premium
    ) {
      onSubscriptionDoneOpen();
      PurchaseApi.confirmStripeSubscription({
        paymentIntent: payment_intent as string,
        paymentIntentClientSecret: payment_intent_client_secret as string,
        subscriptionId: subscription_id as string,
        purchaseLocation: (location as string | undefined) ?? 'none',
        utmCampaign: utm_campaign as string | undefined,
        ...getFacebookTrackingParams(),
        ...getTikTokTrackingParams(),
        ...getSnapchatTrackingParams(),
        experimentName: extra_request_param as string | undefined,
        clientUserAgent: navigator?.userAgent,
        webpageUrl: BASE_DEEPSTASH_URL + router.asPath,
      }).then(onConfirmSent);
    } else if (setup_intent && setup_intent_client_secret) {
      onSubscriptionDoneOpen();

      PurchaseApi.confirmStripeSubscription({
        setupIntentClientSecret: setup_intent_client_secret as string,
        setupIntent: setup_intent as string,
        purchaseLocation: (location as string | undefined) ?? 'none',
        utmCampaign: utm_campaign as string | undefined,
        ...getFacebookTrackingParams(),
        ...getTikTokTrackingParams(),
        ...getSnapchatTrackingParams(),
        experimentName: extra_request_param as string | undefined,
        clientUserAgent: navigator?.userAgent,
        webpageUrl: BASE_DEEPSTASH_URL + router.asPath,
      }).then(onConfirmSent);
    }
  }, []);

  return {
    isSubscriptionCompleted,
    isSubscriptionCompletedFromSetup,
    isSubscriptionTrialEligible,
    freeTrialDuration,
    isSubscriptionDoneOpen,
    onSubscriptionDoneClose,
  };
};
