import React, { useEffect, useRef } from 'react';
import GoogleAnalytics from './GoogleAnalytics';
import Pixel from './Pixel';
import TikTokPixel from './TikTokPixel';
import SnapchatPixel from './SnapchatPixel';

const TrackingPlatforms: React.FC = () => {
  const hasMounted = useRef(false);

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  return hasMounted ? (
    <>
      <GoogleAnalytics />
      <Pixel />
      <TikTokPixel />
      <SnapchatPixel />
    </>
  ) : (
    <></>
  );
};

export default TrackingPlatforms;
