// Metatopics
import { MetaTopic, OnboardingMetaHashtag } from 'types';

export const UNSORTED_TOPICS: Record<string, OnboardingMetaHashtag> = {
  personaldevelopment: {
    id: 1,
    title: 'Personal Development',
    name: 'personaldevelopment',
    seo_title: 'Personal Development Tips & Ideas',
    emoji: '🙏',
    priority: 1,
  },
  health: {
    id: 2,
    title: 'Health',
    name: 'health',
    seo_title: 'Facts about Health',
    emoji: '💊',
    priority: 2,
  },
  productivity: {
    id: 3,
    title: 'Productivity',
    name: 'productivity',
    seo_title: 'Productivity Tips & Facts',
    emoji: '🏗',
    priority: 1,
  },
  moneyandinvestments: {
    id: 4,
    title: 'Money & Investments',
    name: 'moneyandinvestments',
    seo_title: 'Facts about Money',
    emoji: '💰',
    priority: 3,
  },
  loveandrelationships: {
    id: 5,
    title: 'Love & Relationships',
    name: 'loveandrelationships',
    seo_title: 'Facts about Love and Relationships',
    emoji: '❤️',
    priority: 4,
  },
  psychology: {
    id: 6,
    title: 'Psychology',
    name: 'psychology',
    seo_title: 'Psychology Facts',
    emoji: '💝',
    priority: 4,
  },
  philosophy: {
    id: 7,
    title: 'Philosophy',
    name: 'philosophy',
    seo_title: 'Philosophical Ideas',
    emoji: '🧐',
    priority: 7,
  },
  career: {
    id: 8,
    title: 'Career',
    name: 'career',
    seo_title: 'Career Tips & Facts',
    emoji: '👔',
    priority: 5,
  },
  startup: {
    id: 9,
    title: 'Startups',
    name: 'startup',
    // seo_title: '',
    emoji: '🚀',
    priority: 5,
  },
  mindfulness: {
    id: 10,
    title: 'Mindfulness',
    name: 'mindfulness',
    seo_title: 'Mindfulness Tips & Facts',
    emoji: '🧘‍♀️',
    priority: 5,
  },
  technologyandthefuture: {
    id: 11,
    title: 'Technology & The Future',
    name: 'technologyandthefuture',
    seo_title: 'Facts about Technology',
    emoji: '🤖',
    priority: 6,
  },
  food: {
    id: 12,
    title: 'Food',
    name: 'food',
    seo_title: 'Food Facts',
    emoji: '🍔',
    priority: 10,
  },
  music: {
    id: 13,
    title: 'Music',
    name: 'music',
    seo_title: 'Facts about Music',
    emoji: '🥁',
    priority: 9,
  },
  sports: {
    id: 14,
    title: 'Sports',
    name: 'sports',
    seo_title: 'Sports Facts',
    emoji: '⚽️',
    priority: 10,
  },
  travel: {
    id: 15,
    title: 'Travel',
    name: 'travel',
    seo_title: 'Travel Facts',
    emoji: '✈️',
    priority: 10,
  },
  leadershipandmanagement: {
    id: 16,
    title: 'Leadership & Management',
    name: 'leadershipandmanagement',
    seo_title: 'Facts about Leadership',
    emoji: '🎩',
    priority: 3,
  },
  history: {
    id: 19,
    title: 'History',
    name: 'history',
    seo_title: 'History Facts',
    emoji: '🎨',
    priority: 7,
  },
  scienceandnature: {
    id: 20,
    title: 'Science & Nature',
    name: 'scienceandnature',
    seo_title: 'Science Facts',
    emoji: '🔬',
    priority: 6,
  },
  economics: {
    id: 21,
    title: 'Economics',
    name: 'economics',
    // seo_title: '',
    emoji: '📈',
    priority: 2,
  },
  marketingandsales: {
    id: 22,
    title: 'Marketing & Sales',
    name: 'marketingandsales',
    seo_title: 'Facts about Marketing & Sales',
    emoji: '🎯',
    priority: 5,
  },
  education: {
    id: 24,
    title: 'Learning & Education',
    name: 'education',
    seo_title: 'Facts about Education',
    priority: 2,
  },
  computerscience: {
    id: 25,
    title: 'Computer Science',
    name: 'computerscience',
    // seo_title: '',
    priority: 6,
  },
  entertainment: {
    id: 26,
    title: 'Entertainment',
    name: 'entertainment',
    seo_title: 'Facts about Entertainment',
    priority: 1,
  },
  communication: {
    id: 27,
    title: 'Communication',
    name: 'communication',
    seo_title: 'Facts about Communication',
    priority: 6,
  },
  problemsolving: {
    id: 28,
    title: 'Problem Solving',
    name: 'problemsolving',
    // seo_title: '',
    priority: 6,
  },
  timemanagement: {
    id: 29,
    title: 'Time Management',
    name: 'timemanagement',
    seo_title: 'Tips for Time Management & Facts',
    priority: 1,
  },
  habits: {
    id: 30,
    title: 'Habits',
    name: 'habits',
    seo_title: 'Facts about Habits',
    priority: 1,
  },
  mentalhealth: {
    id: 31,
    title: 'Mental Health',
    name: 'mentalhealth',
    seo_title: 'Mental Health Insights & Facts',
    priority: 2,
  },
  business: {
    id: 32,
    title: 'Business',
    name: 'business',
    seo_title: 'Business Facts',
    priority: 5,
  },
  motivationandinspiration: {
    id: 33,
    title: 'Motivation & Inspiration',
    name: 'motivationandinspiration',
    seo_title: 'Motivation Tips & Ideas',
    priority: 1,
  },
  teamwork: {
    id: 34,
    title: 'Teamwork',
    name: 'teamwork',
    // seo_title: '',
    priority: 5,
  },
  writing: {
    id: 35,
    title: 'Reading & Writing',
    name: 'writing',
    // seo_title: '',
    priority: 6,
  },
  entrepreneurship: {
    id: 36,
    title: 'Entrepreneurship',
    name: 'entrepreneurship',
    seo_title: 'Entrepreneurship Tips & Facts',
    priority: 5,
  },
  corporateculture: {
    id: 37,
    title: 'Corporate Culture',
    name: 'corporateculture',
    // seo_title: '',
    priority: 5,
  },
  remotework: {
    id: 38,
    title: 'Remote Work',
    name: 'remotework',
    seo_title: 'Remote Work Ideas & Tips',
    priority: 5,
  },
  product: {
    id: 39,
    title: 'Product & Design',
    name: 'product',
    // seo_title: '',
    priority: 6,
  },
  exerciseandfitness: {
    id: 40,
    title: 'Exercise & Fitnesss',
    name: 'exerciseandfitness',
    seo_title: 'Exercise and Fitness Facts',
    priority: 2,
  },
  strategy: {
    id: 41,
    title: 'Strategy',
    name: 'strategy',
    // seo_title: '',
    priority: 5,
  },
  creativity: {
    id: 42,
    title: 'Creativity',
    name: 'creativity',
    seo_title: 'Facts about Creativity & Ideas',
    priority: 7,
  },
  crypto: {
    id: 43,
    title: 'Cryptocurrency',
    name: 'crypto',
    // seo_title: '',
    priority: 8,
  },
  artsandculture: {
    id: 44,
    title: 'Society, Arts & Culture',
    name: 'artsandculture',
    seo_title: 'Facts about Art',
    priority: 7,
  },
  movies: {
    id: 45,
    title: 'Movies & Shows',
    name: 'movies',
    seo_title: 'Facts about Movies',
    priority: 9,
  },
  meditation: {
    id: 46,
    title: 'Meditation',
    name: 'meditation',
    seo_title: 'Facts about Meditation',
    priority: 2,
  },
  cybersecurity: {
    id: 47,
    title: 'Cybersecurity',
    name: 'cybersecurity',
    // seo_title: '',
    priority: 8,
  },
  fashion: {
    id: 48,
    title: 'Fashion & Beauty',
    name: 'fashion',
    // seo_title: '',
    priority: 10,
  },
  religionandspirituality: {
    id: 49,
    title: 'Religion & Spirituality',
    name: 'religionandspirituality',
    seo_title: 'Religion and Spirituality Facts',
    priority: 10,
  },
  videos: {
    id: 50,
    title: 'Videos',
    name: 'videos',
    // seo_title: '',
    priority: 9,
  },
  podcasts: {
    id: 51,
    title: 'Podcasts',
    name: 'podcasts',
    // seo_title: '',
    priority: 9,
  },
  parenting: {
    id: 52,
    title: 'Parenting',
    name: 'parenting',
    seo_title: 'Parenting Facts & Tips',
    priority: 10,
  },
  books: {
    id: 53,
    title: 'Books',
    name: 'books',
    // seo_title: '',
    priority: 10,
  },
  artificialintelligence: {
    id: 54,
    title: 'Artificial Intelligence',
    name: 'artificialintelligence',
    // seo_title: '',
    priority: 6,
  },
  sciencefiction: {
    id: 55,
    title: 'Science Fiction',
    name: 'sciencefiction',
    seo_title: 'Science Fiction Facts',
    priority: 10,
  },
  humanresources: {
    id: 56,
    title: 'Human Resources',
    name: 'humanresources',
    // seo_title: '',
    priority: 5,
  },
  politics: {
    id: 57,
    title: 'Politics',
    name: 'politics',
    // seo_title: '',
    priority: 10,
  },
  longevity: {
    id: 58,
    title: 'Longevity',
    name: 'longevity',
    // seo_title: '',
    priority: 2,
  },
  anime: {
    id: 59,
    title: 'Anime',
    name: 'anime',
    // seo_title: '',
    priority: 9,
  },
  space: {
    id: 60,
    title: 'Space',
    name: 'space',
    // seo_title: '',
    priority: 8,
  },
  softwareengineering: {
    id: 61,
    title: 'Software Engineering',
    name: 'softwareengineering',
    // seo_title: '',
    priority: 6,
  },
  personalfinance: {
    // fake topic
    id: 62,
    title: 'Personal Finance',
    name: 'personalfinance',
    seo_title: 'Personal Finance Tips',
    priority: 2,
  },
  culture: {
    // fake topic
    id: 63,
    title: 'Culture',
    name: 'culture',
    seo_title: 'Culture Facts',
    priority: 10,
  },
  society: {
    // fake topic
    id: 64,
    title: 'Society',
    name: 'society',
    seo_title: 'Facts about Society & Community',
    priority: 4,
  },
  wellness: {
    // fake topic
    id: 65,
    title: 'Wellness',
    name: 'wellness',
    seo_title: 'Wellness Tips',
    priority: 4,
  },
};

export const MENU_STRUCTURE = [
  {
    id: 'personaldevelopment',
    subtopicsById: [
      'personaldevelopment',
      'mentalhealth',
      'mindfulness',
      'psychology',
      'meditation',
      'motivationandinspiration',
      'habits',
      'productivity',
      'communication',
      'timemanagement',
    ],
  },
  {
    id: 'business',
    subtopicsById: [
      'business',
      'entrepreneurship',
      'leadershipandmanagement',
      'marketingandsales',
      'career',
      'remotework',
    ],
  },
  {
    id: 'personalfinance',
    subtopicsById: [
      'personalfinance',
      // 'investment',
      'moneyandinvestments',
    ],
  },
  {
    id: 'culture',
    subtopicsById: [
      'culture',
      'education',
      'history',
      'philosophy',
      'music',
      'movies',
      'creativity',
      'artsandculture',
      'food',
    ],
  },
  {
    id: 'scienceandnature',
    subtopicsById: [
      'scienceandnature',
      'sciencefiction',
      'technologyandthefuture',
    ],
  },
  {
    id: 'society',
    subtopicsById: [
      'society',
      'loveandrelationships',
      'religionandspirituality',
      'parenting',
      'entertainment',
    ],
  },
  {
    id: 'wellness',
    subtopicsById: [
      'wellness',
      'sports',
      'travel',
      'health',
      'exerciseandfitness',
      // 'lifestyle',
    ],
  },
];

export const SORTED_TOPICS = Object.values(UNSORTED_TOPICS).sort(
  (a, b) => a.priority - b.priority,
);

export const TOPIC_GROUPS = [
  'Content Types',
  'Personal Development',
  'Health & Wellness',
  'Finance & Economics',
  'Psychology & Relationships',
  'Business & Career',
  'Knowledge & Learning',
  'Arts & Culture',
  'Technology & the Future',
  'Entertainment & Media',
  'Lifestyle & Society',
];

export const METATOPICS: MetaTopic[] = [
  {
    exploreId: 'hashtag-personaldevelopment',
    id: 0,
    name: 'Personal Development',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/personaldevelopment.svg',
    categoryOrder: 0,
  },
  {
    id: 1,
    exploreId: 'hashtag-productivity',
    name: 'Productivity',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/productivity.svg',
    categoryOrder: 0,
  },
  {
    id: 2,
    exploreId: 'hashtag-moneyandinvestments',
    name: 'Money and Investments',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/moneyandinvestments.svg',
    categoryOrder: 0,
  },
  {
    id: 3,
    exploreId: 'hashtag-health',
    name: 'Health',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/health.svg',
    categoryOrder: 0,
  },
  {
    id: 4,
    exploreId: 'hashtag-loveandrelationships',
    name: 'Love and Relationships',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/loveandrelationships.svg',
    categoryOrder: 0,
  },
  {
    id: 5,
    exploreId: 'hashtag-career',
    name: 'Career',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/career.svg',
    categoryOrder: 0,
  },
  {
    id: 6,
    exploreId: 'hashtag-psychology',
    name: 'Psychology',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/psychology.svg',
    categoryOrder: 0,
  },
  {
    id: 7,
    exploreId: 'hashtag-startup',
    name: 'Startup',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/startups.svg',
    categoryOrder: 0,
  },
  {
    id: 8,
    exploreId: 'hashtag-mindfulness',
    name: 'Mindfulness',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/mindfulness.svg',
    categoryOrder: 0,
  },
  {
    id: 9,
    exploreId: 'hashtag-philosophy',
    name: 'Philosophy',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/philosophy.svg',
    categoryOrder: 0,
  },
  {
    id: 10,
    exploreId: 'hashtag-artsandculture',
    name: 'Arts and Culture',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/artsandculture.svg',
    categoryOrder: 0,
  },
  {
    id: 11,
    exploreId: 'hashtag-marketingandsales',
    name: 'Marketing and Sales',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/marketingandsales.svg',
    categoryOrder: 0,
  },
  {
    id: 12,
    exploreId: 'hashtag-technologyandthefuture',
    name: 'Technology and the Future',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/technology.svg',
    categoryOrder: 0,
  },
  {
    id: 13,
    exploreId: 'hashtag-leadershipandmanagement',
    name: 'Leadership and Management',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/leadership.svg',
    categoryOrder: 0,
  },
  {
    id: 14,
    exploreId: 'hashtag-food',
    name: 'Food',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/food.svg',
    categoryOrder: 0,
  },
  {
    id: 15,
    name: 'Music',
    exploreId: 'hashtag-music',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/music.svg',
    categoryOrder: 0,
  },
  {
    id: 16,
    exploreId: 'hashtag-sports',
    name: 'Sports',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/sports.svg',
    categoryOrder: 0,
  },
  {
    id: 17,
    exploreId: 'hashtag-travel',
    name: 'Travel',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/travel.svg',
    categoryOrder: 0,
  },
  {
    id: 18,
    exploreId: 'hashtag-education',
    name: 'Education',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/education.svg',
    categoryOrder: 0,
  },
  {
    id: 19,
    exploreId: 'hashtag-history',
    name: 'History',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/history.svg',
    categoryOrder: 0,
  },
  {
    id: 20,
    exploreId: 'hashtag-scienceandnature',
    name: 'Science and Nature',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/scienceandnature.svg',
    categoryOrder: 0,
  },
  {
    id: 21,
    exploreId: 'hashtag-economics',
    name: 'Economics',
    image:
      'https://static.deepstash.com/illustrations/topics/svg/202305/economics.svg',
    categoryOrder: 0,
  },
];
