import router from 'next/router';
import { CollectionStatus } from 'types/enums';
import { CollectionData, Source } from 'types/models';
import { slugify } from './global';

/**
 * Get the progress of the source of a collection (# of ideas)
 * @param collection The collection we're computing this for
 * @param sourceId The ID of the source we're computing this for
 * @returns {number} The number of ideas read from a source in
 * a collection, or the number of ideas in the source if marked
 * completed
 */
export const getCollectionSourceIdeasRead = ({
  collection,
  source,
}: {
  collection: CollectionData;
  source: Source;
}): number => {
  if (collection.progress.sourcesProgress[source.id]?.completed) {
    return source.ideas.length;
  } else {
    return (
      collection.progress.sourcesProgress[source.id]?.readIdeas.length ?? 0
    );
  }
};

/**
 * Get the URL for the next source that's not completed in the
 * current collection. To be memoized.
 */
export const getNextSourceToReadFromCollectionUrl = ({
  collection,
  source,
}: {
  collection: CollectionData;
  source?: Source;
}): string => {
  let currentSourceIndex = source ? 0 : -1;
  if (source) {
    for (let i = 0; i < collection.sources.length; i++) {
      if (collection.sources[i].id === source.id) {
        currentSourceIndex = i;
        break;
      }
    }
  }
  for (
    let i = currentSourceIndex + 1;
    i < currentSourceIndex + 1 + collection.sources.length;
    i++
  ) {
    const nextSource = collection.sources[i % collection.sources.length];
    if (!collection.progress.sourcesProgress[nextSource.id]?.completed) {
      return `/article/${nextSource.id}/${slugify(
        nextSource.title,
      )}?collection=${collection.id}`;
    }
  }
  return '';
};

/**
 * Find out the status of a collection.
 */
export const getCollectionStatus = ({
  collection,
}: {
  collection: CollectionData;
}): CollectionStatus => {
  if (!collection.progress || collection.progress.totalReadIdeas === 0) {
    return CollectionStatus.NEW;
  } else if (collection.progress.completed) {
    return CollectionStatus.COMPLETED;
  } else {
    return CollectionStatus.STARTED;
  }
};

/**
 * Group collections into subgroups of size [rowSize]
 * @param collections The list of collections to group
 * @param rowSize The size of a subgroup
 * @returns A list of rows
 */
export const groupCollectionCollapsedIntoRows = ({
  collections,
  rowSize,
}: {
  collections: CollectionData[];
  rowSize: number;
}): CollectionData[][] => {
  const rowList: CollectionData[][] = [];
  for (let i = 0; i < collections.length; i += rowSize) {
    rowList.push(collections.slice(i, i + rowSize));
  }
  return rowList;
};

/**
 * Returns the ID of the collection on whose page we are on, or -1
 * if we are not on a page belonging to a collection.
 * @returns {number} Collection ID or -1 if none.
 */
export const getCollectionIdOnSourcePage = (): number | undefined => {
  const routerPaths = router.asPath.split('/');

  if (routerPaths?.[1] !== 'read') return undefined;

  let collectionId = undefined;

  try {
    collectionId = parseInt(routerPaths?.[2]);
  } catch (e) {
    return undefined;
  }

  return collectionId;
};
