import { CurrentProfileApiResponse } from 'api/api.types';
import { Reducer } from 'react';
import { UserProfile } from 'types/models';
import { normalizeProfile } from 'utils/normalizers/profile';
import { INITIAL_STATE } from './ProfileProvider';
import {
  ProfileProviderAction,
  ProfileProviderHiddenAction,
  ProfileProviderStateType,
} from './ProfileProviderTypes';
import equal from 'fast-deep-equal';

/**
 * A function that creates the profile reducer
 * @param notifyRevalidation - Notifies the ActivityProvider that the profile revalidated and should update its state as well
 */
export const createProfileReducer = (
  notifyRevalidation: (profile?: CurrentProfileApiResponse) => void,
): Reducer<
  ProfileProviderStateType,
  ProfileProviderAction | ProfileProviderHiddenAction
> => {
  return (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'mutate': {
        return {
          ...state,
          profile: {
            ...state.profile,
            ...action.payload.profile,
          } as UserProfile,
        };
      }

      case 'clear': {
        //Also clear the Activity
        notifyRevalidation();
        return { ...state, profile: undefined };
      }
      case 'revalidate/loading': {
        // avoid useless re-renders
        if (state.errorOccurred === false) {
          return state;
        }
        return { ...state, errorOccurred: false };
      }
      case 'revalidate/success': {
        const updatedProfile = normalizeProfile(action.payload.profile);
        // avoid useless re-renders
        if (equal(state.profile, updatedProfile)) {
          return state;
        }

        notifyRevalidation(action.payload.profile);
        return {
          ...state,
          profile: updatedProfile,
        };
      }
      case 'revalidate/fail': {
        notifyRevalidation();
        return { ...state, errorOccurred: true, profile: undefined };
      }
      default: {
        return state;
      }
    }
  };
};
