import {
  deleteArticle,
  deleteScrappedSource,
  stashArticleIdeas,
  unsaveArticleIdeas,
} from 'api/Article';
import { updateCollectionReads } from 'api/Collection';
import {
  deleteInsight as deleteIdeaApiCall,
  updateReadInsights,
} from 'api/Insight';
import { UserActivity, UserStash } from 'types/models';
import { updateStashes } from 'utils/global';
import {
  ActivityProviderReducer,
  ActivityProviderState,
} from './ActivityProviderTypes';
import {
  stashSourceHandler,
  toggleIdeaStashStateHandler,
  unstashSourceHandler,
} from './ActivityReducerUtils';

const activityReducer: ActivityProviderReducer = (state, action) => {
  switch (action.type) {
    case 'set-activity': {
      if (action.payload.keepSessionReads && state.activity?.sessionReads) {
        return state;
      } else {
        return { ...state, activity: action.payload.activity };
      }
    }

    case 'delete-idea/success': {
      if (!state.activity) return state;
      const newState: UserActivity = { ...state.activity };
      if (action.payload.sourceId) {
        const sourceId = action.payload.sourceId;

        //Delete the article from the state of the context
        newState.createdArticles = state.activity.createdArticles.filter(
          articleId => articleId !== sourceId,
        );
        newState.publishedArticlesCount--;
        newState.stashedArticles = state.activity.stashedArticles.filter(
          id => id !== sourceId,
        );
      }
      //Remove the idea from the new state

      //If this idea is in a stash we need to also decrement the ideaCount of that stash
      const stashId = (newState.stashedIdeas.find(
        ([ideaId]) => ideaId === action.payload.ideaId,
      ) ?? [undefined, undefined])[1];

      if (stashId) {
        const index = newState.stashes.findIndex(stash => stash.id === stashId);
        newState.stashes[index].ideaCount--;
      }

      newState.createdIdeasCount--;
      newState.stashedIdeas = newState.stashedIdeas.filter(
        ([ideaId]) => ideaId !== action.payload.ideaId,
      );
      newState.stashedIdeasIds = newState.stashedIdeasIds.filter(
        ideaId => ideaId !== action.payload.ideaId,
      );

      return {
        ...state,
        activity: newState,
      };
    }
    case 'delete-source': {
      if (!state.activity) return state;
      const { ideaIds, sourceId, codeSource } = action.payload;

      //Make the API calls to delete the ideas and the article
      //We don't need to await these api calls so we can keep this action sync

      deleteArticle(sourceId);
      // Also delete the ideas
      ideaIds.forEach(ideaId => {
        if (ideaId && ideaId > 0) deleteIdeaApiCall(ideaId, codeSource);
      });
      return {
        ...state,
        activity: {
          ...state.activity,
          createdArticles: state.activity.createdArticles.filter(
            articleId => articleId !== sourceId,
          ),

          publishedArticlesCount: state.activity.publishedArticlesCount--,
          stashedArticles: state.activity.stashedArticles.filter(
            id => id !== sourceId,
          ),
          createdIdeasCount: state.activity.createdIdeasCount - ideaIds.length,
          stashedIdeas: state.activity.stashedIdeas.filter(
            ([ideaId]) => !ideaIds.includes(ideaId),
          ),
          stashedIdeasIds: state.activity.stashedIdeasIds.filter(ideaId =>
            ideaIds.includes(ideaId),
          ),
        },
      };
    }
    case 'increment-read': {
      if (!state.activity) return state;

      const { ideaId, ammount, readingGoal, collectionId } = action.payload;

      const id = ideaId;
      if (id < 0 || state.activity?.sessionReads?.has(id)) {
        return state;
      }
      const amount = ammount ?? 1;
      let newState: ActivityProviderState = {
        ...state,
        activity: {
          ...state.activity,
          readToday: (state.activity?.readToday ?? 0) + amount,
          totalReads: (state.activity?.totalReads ?? 0) + amount,
          reads: {
            ...state.activity?.reads,
            [id]: (state.activity?.reads?.[id] ?? 0) + amount,
          },
          readIdeas: state.activity?.readIdeas?.add(id) ?? new Set([id]),
          sessionReads: state.activity?.sessionReads?.add(id) ?? new Set([id]),
        },
      };

      //If this amount of reads got us over the daily goal (or modulo daily goal) sync the reads with the backend
      if (
        readingGoal - (state.activity.readToday % readingGoal) <= amount &&
        !collectionId
      ) {
        newState = syncReads({
          state: newState,
        });
      }
      return newState;
    }
    case 'bulk-read-increment': {
      if (!state.activity) return state;

      const { reads, readingGoal, collectionId } = action.payload;

      const totalNewReadsCount = Object.values(reads).reduce(
        (a, b) => a + b,
        0,
      );

      Object.keys(reads).forEach(id => {
        reads[Number(id)] += state.activity?.reads?.[Number(id)] ?? 0;
      });

      const readIdeaIds = Object.keys(reads).map(key => Number(key));

      let newState: ActivityProviderState = {
        ...state,
        activity: {
          ...state.activity,
          readToday: (state.activity?.readToday ?? 0) + totalNewReadsCount,
          totalReads: (state.activity?.totalReads ?? 0) + totalNewReadsCount,
          reads: {
            ...state.activity?.reads,
            ...reads,
          },
          readIdeas: new Set([
            ...(state.activity?.readIdeas ?? []),
            ...readIdeaIds,
          ]),
          sessionReads: new Set([
            ...(state.activity?.sessionReads ?? []),
            ...readIdeaIds,
          ]),
        },
      };

      //If this amount of reads got us over the daily goal (or modulo daily goal) sync the reads with the backend
      if (
        readingGoal - (state.activity.readToday % readingGoal) <=
          totalNewReadsCount &&
        !collectionId
      ) {
        newState = syncReads({
          state: newState,
        });
      }
      return newState;
    }
    case 'publish-source/success': {
      if (!state.activity) return state;
      const { stashId, ideasIds, sourceId } = action.payload;
      //Add the ideas to the publish stash
      const stashedIdeas =
        stashId ?? 0 > 0
          ? [
              ...state.activity.stashedIdeas,
              ...(ideasIds.map(
                ideaId => [ideaId, stashId] as [number, number],
              ) || []),
            ]
          : state.activity.stashedIdeas;
      return {
        ...state,
        activity: {
          ...state.activity,
          stashedIdeas,
          createdIdeasCount: state.activity.createdIdeasCount + ideasIds.length,
          publishedArticlesCount: state.activity.publishedArticlesCount + 1,
          stashes: state.activity.stashes.map(stash => {
            if (stash.id === stashId) {
              stash.ideaCount += ideasIds.length;
            }
            return stash;
          }),
          stashedArticles: [...state.activity.stashedArticles, sourceId],
          stashedIdeasIds: [...state.activity.stashedIdeasIds, ...ideasIds],
          draftCount: state.activity.draftCount - 1,
        },
      };
    }
    case 'stash-source': {
      if (!state.activity) return state;
      const { source, stashId, ideasIds, onSuccess } = action.payload;

      const newState = stashSourceHandler({
        source,
        stashId,
        ideasIds,
        currentState: {
          stashedIdeasIds: state.activity.stashedIdeasIds,
          stashedIdeas: state.activity.stashedIdeas,
          stashes: state.activity.stashes,
          stashedSources: state.activity.stashedArticles,
        },
      });

      if (!newState) {
        return state;
      }

      //Make the API call to stash this article
      // Now need to wait for it though so this action can be sync

      //Save the insight to the API
      stashArticleIdeas(source.id, stashId !== -1 ? stashId : undefined);

      if (!newState.stashedIdeas || !newState.stashes) {
        // These 2 properties shouldn't be undefined for SI
        // Return the current state and discard the local update
        // The API call was already made so the actual stashing / unstashing of the source won't be lost
        return state;
      }

      onSuccess?.();
      return {
        ...state,
        activity: {
          ...state.activity,
          stashedIdeasIds: newState.stashedIdeasIds,
          stashedArticles: newState.stashedSources,
          stashedIdeas: newState.stashedIdeas,
          stashes: newState.stashes,
        },
      };
    }

    case 'toggle-idea-stash/success': {
      if (!state.activity) return state;
      const { ideaId, stashId, allIdeasStashed, sourceId } = action.payload;

      const newState = toggleIdeaStashStateHandler({
        currentState: {
          stashedIdeasIds: state.activity.stashedIdeasIds,
          stashedIdeas: state.activity.stashedIdeas,
          stashes: state.activity.stashes,
          stashedSources: state.activity.stashedArticles,
        },
        ideaId,
        stashId,
        allIdeasStashed: !!allIdeasStashed,
        sourceId,
      });

      if (!newState) {
        return state;
      }

      if (!newState.stashedIdeas || !newState.stashes) {
        // These 2 properties shouldn't be undefined for SI
        // Return the current state and discard the local update
        // The API call was already made so the actuall stashing /unstashing of the idea won't be lost
        return state;
      }

      //Save the insight
      return {
        ...state,
        activity: {
          ...state.activity,
          stashedIdeasIds: newState.stashedIdeasIds,
          stashedArticles: newState.stashedSources,
          stashedIdeas: newState.stashedIdeas,
          stashes: newState.stashes,
        },
      };
    }
    case 'unstash-source ': {
      if (!state.activity) return state;
      const { source, ideasIds, onSuccess } = action.payload;

      //Make the API call to unstash this source
      // No need to await the response so this action can be sync
      unsaveArticleIdeas(source.id);

      const newState = unstashSourceHandler({
        currentState: {
          stashedIdeasIds: state.activity.stashedIdeasIds,
          stashedIdeas: state.activity.stashedIdeas,
          stashes: state.activity.stashes,
          stashedSources: state.activity.stashedArticles,
        },
        source,
        ideasIds,
      });

      if (!newState) {
        return state;
      }

      if (!newState.stashedIdeas || !newState.stashes) {
        // These 2 properties shouldn't be undefined for SI
        // Return the current state and discard the local update
        // The API call was already made so the actuall stashing /unstashing of the idea won't be lost
        return state;
      }

      onSuccess?.();
      return {
        ...state,
        activity: {
          ...state.activity,
          stashedIdeasIds: newState.stashedIdeasIds,
          stashedArticles: newState.stashedSources,
          stashedIdeas: newState.stashedIdeas,
          stashes: newState.stashes,
        },
      };
    }
    case 'mutate': {
      if (!state.activity) return state;
      return {
        ...state,
        activity: {
          ...state.activity,
          ...action.payload.activity,
        },
      };
    }

    case 'sync-reads': {
      const newState = syncReads({
        state,
        collectionId: action.payload.collectionId,
      });
      return newState;
    }

    case 'toggle-follow-hashtag': {
      if (!state.activity) return state;
      const { hashtags } = state.activity;

      const hashtag = action.payload.hashtag;

      if (hashtags.includes(hashtag)) {
        return {
          ...state,
          activity: {
            ...state.activity,
            hashtags: hashtags.filter(tag => tag !== hashtag),
          },
        };
      } else {
        return {
          ...state,
          activity: {
            ...state.activity,
            hashtags: [...hashtags, hashtag],
          },
        };
      }
    }
    case 'toggle-follow-user': {
      if (!state.activity) return state;
      const { following } = state.activity;

      const userId = action.payload.userId;

      if (following.includes(userId)) {
        return {
          ...state,
          activity: {
            ...state.activity,
            following: following.filter(id => id !== userId),
          },
        };
      } else {
        return {
          ...state,
          activity: {
            ...state.activity,
            following: [...following, userId],
          },
        };
      }
    }
    // [Like functionality]
    // case 'toggle-like-idea': {
    //   if (!state.activity) return state;
    //   const { likedIdeas } = state.activity;
    //   const { ideaId } = action.payload;

    //   if (likedIdeas.includes(ideaId)) {
    //     return {
    //       ...state,
    //       activity: {
    //         ...state.activity,
    //         likedIdeas: likedIdeas.filter(id => id !== ideaId),
    //       },
    //     };
    //   } else {
    //     return {
    //       ...state,
    //       activity: {
    //         ...state.activity,
    //         likedIdeas: [...likedIdeas, ideaId],
    //       },
    //     };
    //   }
    // }
    case 'create-stash-success': {
      if (!state.activity) return state;
      const { stash } = action.payload;

      const updatedStashes: UserStash[] = [...state.activity.stashes, stash];

      return {
        ...state,
        activity: {
          ...state.activity,
          stashes: updatedStashes,
        },
      };
    }

    case 'update-stash-success': {
      if (!state.activity) return state;
      const { id, name, emoji } = action.payload.stash;

      const newStashes = updateStashes(state.activity.stashes, id, emoji, name);
      return {
        ...state,

        activity: {
          ...state.activity,
          stahes: newStashes,
        },
      };
    }
    case 'delete-stash-success': {
      if (!state.activity) return state;
      const { id } = action.payload;

      return {
        ...state,
        activity: {
          ...state.activity,
          stashes: state.activity.stashes.filter(
            filteredStash => filteredStash.id !== id,
          ),
          stashedIdeas: state.activity.stashedIdeas.filter(
            ([stashId]) => stashId !== id,
          ),
          stashedIdeasIds: state.activity.stashedIdeasIds.filter(
            stashedIdeadId => stashedIdeadId !== id,
          ),
        },
      };
    }

    case 'set-idea-stash': {
      if (!state.activity) return state;
      const { ideaId, stashId, source } = action.payload;
      const stashedIdeasIds = [...state.activity.stashedIdeasIds, ideaId];
      const stashedArticles = [...state.activity.stashedArticles];
      const stashedInsights: [number, number][] = [
        ...state.activity.stashedIdeas,
        [ideaId, stashId],
      ];
      const stashes = [...state.activity.stashes];

      //Add this idea to a stash
      const destStashIndex = stashes.findIndex(({ id }) => id === stashId);
      if (destStashIndex !== -1) {
        stashes[destStashIndex].ideaCount++;
      }

      //Check if the article is in the same stash as this idea. If it is, the article remains fully stashed
      const sourceStash = (stashedInsights.find(
        ([ideaId]) => source.ideas[0].id === ideaId,
      ) ?? [0, 0])[1];
      const allIdeasStashed = stashId === sourceStash;

      //Save the idea
      return {
        ...state,
        activity: {
          ...state.activity,
          stashedIdeasIds: stashedIdeasIds,
          stashedArticles: !allIdeasStashed
            ? // If this was an unstash we definetely don't want this article in savedArticles
              // If this was a stash, we know this article is not fully saved so the filter won't do anything
              stashedArticles.filter(id => id !== source.id)
            : stashedArticles,
          stashedIdeas: stashedInsights,
          stashes,
        },
      };
    }
    case 'delete-draft/success': {
      const { id: sourceId } = action.payload;
      if (!state.activity) {
        return state;
      }
      return {
        ...state,
        activity: {
          ...state.activity,
          createdArticles: state.activity.createdArticles.filter(
            id => id !== sourceId,
          ),
          draftCount: state.activity.draftCount - 1,
        },
      };
    }
    case 'set-banner-open': {
      return {
        ...state,
        isBannerOpen: action.payload.value,
      };
    }
    case 'delete-scrapped-source': {
      if (!state.activity) return state;
      const { sourceId } = action.payload;

      deleteScrappedSource(sourceId);
      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
};

const syncReads = ({
  state,
  collectionId,
}: {
  state: ActivityProviderState;
  collectionId?: number;
}): ActivityProviderState => {
  if (!state.activity) return state;
  const readsToSend: Record<number, number> = {};
  let size = 0;
  state.activity?.readIdeas?.forEach(id => {
    readsToSend[id] = 1;
    size++;
  });
  if (size > 0) {
    if (collectionId) {
      updateCollectionReads({ data: readsToSend, collectionId });
    } else {
      updateReadInsights(readsToSend);
    }
  }
  return {
    ...state,
    activity: {
      ...state.activity,
      readIdeas: new Set<number>(),
    },
  };
};

export default activityReducer;
