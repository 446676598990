import useLocation from 'hooks/useLocation';
import Script from 'next/script';
import React from 'react';

/**
 * Pixel will only be initialized on these pages
 */
const WHITELIST_PAGE_PATHS = ['growth-plan'];

const TikTokPixel: React.FC = () => {
  const location = useLocation();

  const tikTokPixelFragment = (
    <>
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function (w, d, t) {w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
                ttq.load('CO0OU3RC77UBM1BSI7OG');
                ttq.page();
                ttq.track('ViewContent', {
	                "contents": [
		                {
			                "content_id": "growth-plan", // string. ID of the product. Example: "1077218".
			                "content_type": "product", // string. Either product or product_group.
			                "content_name": "growth-plan" // string. The name of the page or product. Example: "shirt".
		                }
	                ],
                });
              }(window, document, 'ttq');`,
        }}
      />
    </>
  );

  return process.env.NODE_ENV === 'production' &&
    WHITELIST_PAGE_PATHS.includes(location)
    ? tikTokPixelFragment
    : null;
};

export default TikTokPixel;
