import axios from 'axios';
import {
  AddUrlExtraData,
  ImportBookExtraData,
  ImportKindleExtraData,
  MetaHashtag,
  Source,
} from 'types';
import { KNOW_LIB_ARTICLE_COUNT } from 'utils/constants';
import {
  ArticleApiResponse,
  ArticlesResponse,
  KLArticlesResponse,
  RelatedPostsResponse,
  SaveArticleData,
  SourcesTopReactionsApiResponse,
  UnknownSourceApiResponse,
  UpdateElementResponse,
} from './api.types';
import {
  AddUrlRequestData,
  ApiResponseWithStatusCode,
  deleteRequest,
  getApiToken,
  getRequest,
  ImportLinkData,
  ImportSelfpost,
  NextServerRequestObject,
  patchRequest,
  postRequest,
  putRequest,
  returnDataAndResponseStatus,
} from './apiRequest';

export function getLatestArticles(offset = 0): Promise<KLArticlesResponse> {
  const url = `/article/latest/?limit=${KNOW_LIB_ARTICLE_COUNT}&offset=${offset}&expanded`;
  return getRequest<KLArticlesResponse>(
    url,
    getApiToken({ allowDefault: true }),
  );
}

export function getArticleByInsightId(
  insightId: number,
): Promise<ArticleApiResponse> {
  const url = `/article/by_block/${insightId}/?expanded`;
  return getRequest<ArticleApiResponse>(
    url,
    getApiToken({ allowDefault: true }),
  );
}

export function addUrl(
  token: string,
  urlToAdd: string,
  extraData: AddUrlExtraData,
): Promise<ArticleApiResponse> {
  const url = `/article/`;
  const data: AddUrlRequestData = {
    url: urlToAdd,
    type: extraData.type,
    author: extraData.author,
    image: extraData.image,
    title: extraData.title,
    content: extraData.content,
    description: extraData.description,
  };
  return postRequest<ArticleApiResponse, AddUrlRequestData>(url, token, data);
}

export function importLink(
  urlToAdd: string,
): Promise<ApiResponseWithStatusCode<ArticleApiResponse>> {
  const token = getApiToken();
  const requestUrl = `/article/import_link/?content`;
  const data: ImportLinkData = {
    url: urlToAdd,
  };
  return postRequest<
    ApiResponseWithStatusCode<ArticleApiResponse>,
    ImportLinkData
  >(requestUrl, token, data, undefined, returnDataAndResponseStatus);
}

/**
 * Migrate a source from UNKNOWN to LINK
 * @param sourceId the id of the unknown source
 * @param urlToAdd The url of the new source
 */
export function migrateUnknownSourceToLink(
  sourceId: number,
  urlToAdd: string,
): Promise<ApiResponseWithStatusCode<ArticleApiResponse>> {
  const token = getApiToken();

  const requestUrl = `/article/${sourceId}/add_source_link/?content`;
  const data: ImportLinkData = {
    url: urlToAdd,
  };
  return postRequest<
    ApiResponseWithStatusCode<ArticleApiResponse>,
    ImportLinkData
  >(requestUrl, token, data, undefined, returnDataAndResponseStatus);
}

export function importBook(
  extraData: ImportBookExtraData,
): Promise<ApiResponseWithStatusCode<ArticleApiResponse>> {
  const token = getApiToken();

  const requestUrl = `/article/import_book/`;
  const data: ImportBookExtraData = extraData;
  return postRequest<
    ApiResponseWithStatusCode<ArticleApiResponse>,
    ImportBookExtraData
  >(requestUrl, token, data, undefined, returnDataAndResponseStatus);
}

/**
 * Migrate a source from UNKNOWN to BOOK
 * @param sourceId the id of the unknown source
 * @param extraData The data from Google Books
 */
export function migrateUnknownSourceToBook(
  sourceId: number,
  extraData: ImportBookExtraData,
): Promise<ApiResponseWithStatusCode<ArticleApiResponse>> {
  const token = getApiToken();

  const requestUrl = `/article/${sourceId}/add_source_book/`;
  const data: ImportBookExtraData = extraData;
  return postRequest<
    ApiResponseWithStatusCode<ArticleApiResponse>,
    ImportBookExtraData
  >(requestUrl, token, data, undefined, returnDataAndResponseStatus);
}

export function importKindle(
  bookExtraData: ImportBookExtraData,
  kindle_notebook: string,
): Promise<ArticleApiResponse> {
  const token = getApiToken();

  const requestUrl = `/article/import_kindle/`;
  const data: ImportKindleExtraData = { ...bookExtraData, kindle_notebook };
  return postRequest<ArticleApiResponse, ImportKindleExtraData>(
    requestUrl,
    token,
    data,
  );
}

export function importIndependent(
  title: string,
  description: string,
): Promise<ApiResponseWithStatusCode<ArticleApiResponse>> {
  const token = getApiToken();

  const requestUrl = `/article/import_selfpost/`;
  const data = {
    title,
    description,
  };
  return postRequest<
    ApiResponseWithStatusCode<ArticleApiResponse>,
    ImportSelfpost
  >(requestUrl, token, data, undefined, returnDataAndResponseStatus);
}

/**
 * Migrate a source from UNKNOWN to INDEPENDENT
 * @param sourceId the id of the unknown source
 * @param title The title of independent source
 * @param description The description of the source
 */
export function migrateUnknownSourceToIndependent({
  nextRequest,
  sourceId,
  title,
  description,
}: {
  nextRequest?: NextServerRequestObject;
  sourceId: number;
  title: string;
  description: string;
}): Promise<ApiResponseWithStatusCode<ArticleApiResponse>> {
  const token = getApiToken({ requestServer: nextRequest });

  const requestUrl = `/article/${sourceId}/add_source_selfpost/`;
  const data = {
    title,
    description,
  };
  return postRequest<
    ApiResponseWithStatusCode<ArticleApiResponse>,
    ImportSelfpost
  >(requestUrl, token, data, undefined, returnDataAndResponseStatus);
}

/**
 * Used to create an UNKNOWN source on the backend
 */
export function createUnknownSource(): Promise<UnknownSourceApiResponse> {
  const token = getApiToken();

  const requestUrl = `/article/import_unknown_source/`;
  return postRequest<UnknownSourceApiResponse, never>(requestUrl, token);
}

/**
 * Get books from add drafts functionality
 * @param input = input from search field
 */
export const getBooks = (token: string, input: string): Promise<any> => {
  const url = `/googlebooks/?q=${input}`;
  return getRequest(url, token);
};

// NO REDUX

/**
 * Save all ideas of an article to a specified stash
 * @param articleId
 * @param stashId
 */
export function stashArticleIdeas(
  articleId: number,
  stashId?: number,
): Promise<UpdateElementResponse> {
  const token = getApiToken();

  const url = `article/${articleId}/saved_by/`;
  const data: SaveArticleData | null = stashId
    ? {
        stash: `${stashId}`,
      }
    : null;
  return putRequest<UpdateElementResponse, SaveArticleData | null>(
    url,
    token,
    data,
  );
}

/**
 * Remove all ideas of an article from saved
 * @param articleId
 */
export function unsaveArticleIdeas(
  articleId: number,
): Promise<UpdateElementResponse> {
  const token = getApiToken();

  const url = `article/${articleId}/saved_by/`;
  return deleteRequest<UpdateElementResponse>(url, token);
}

/**
 * Get a list of articles similar to the one with the provided article id.
 * @param articleId
 */
export function getSimilarArticles(
  articleId: number,
): Promise<ArticlesResponse> {
  const token = getApiToken();

  const url = `/article/${articleId}/similar/?count=3&expanded`;

  return getRequest<ArticlesResponse>(url, token);
}

/**
 * Fetch an article and its ideas based on an article id and a user id. The ideas are going to be the user's with the corresponding user id.
 * @param articleId
 * @param userId
 */
export function getArticle({
  articleId,
  simple,
  similarSourcesCount,
  nextRequest,
  requestDraft,
}: {
  articleId: number;
  simple?: boolean;
  similarSourcesCount?: number;
  nextRequest?: NextServerRequestObject;
  requestDraft?: boolean;
}): Promise<ArticleApiResponse> {
  try {
    const token = getApiToken({
      requestServer: nextRequest,
      allowDefault: !requestDraft,
    });

    const url = `/article/${articleId}/${
      !simple
        ? '?expanded&content' +
          (similarSourcesCount ? '&similar_count=' + similarSourcesCount : '')
        : ''
    }`;

    return getRequest<ArticleApiResponse>(url, token);
  } catch (e) {
    return Promise.reject();
  }
}

/**
 * Get similar posts
 * @param postId
 */
export function getRelatedPosts({
  postId,
}: {
  postId: number;
}): Promise<RelatedPostsResponse> {
  const token = getApiToken();

  const url = `/article/${postId}/related/`;
  return getRequest(url, token);
}

/**
 * Delete user article
 * @param articleId
 */
export function deleteArticle(
  articleId: number,
): Promise<UpdateElementResponse> {
  const token = getApiToken();

  const url = `/article/${articleId}/`;
  return deleteRequest<UpdateElementResponse>(url, token);
}

/**
 * Publish article
 * @param articleId
 * @param stashId = add created insights to a stash, option
 */
export const publishArticle = (
  articleId: number,
  stashId?: number,
  hashtags?: string[],
  context?: string,
) => {
  const token = getApiToken();

  const url = `/article/${articleId}/publish/`;
  const data = {
    // send the stash only if exists
    ...(stashId && { stash: stashId }),
    hashtags: hashtags ?? [],
    context,
  };

  return patchRequest(url, token, data);
};

/**
 * Send article for review to the Deepstash editors
 * @param articleId
 */
export function sendArticleForReview(articleId: number) {
  const token = getApiToken();

  const url = `/article/${articleId}/submit/`;
  return putRequest(url, token, null);
}

export const getUnsplashImage = (input: string): Promise<any> => {
  const url = `/unsplash/?q=${input}`;
  const token = getApiToken();

  return getRequest(url, token);
};

export const getRecommendedImages = (input: string): Promise<any> => {
  const url = `/editor/image/search/?q=${input}`;
  const token = getApiToken();

  return getRequest(url, token);
};

export const updateAssignedArticleReview = (id: number): Promise<any> => {
  try {
    const token = getApiToken();
    const url = `/article/assigned_article/${id}/submit_review/`;

    return postRequest(url, token);
  } catch (e) {
    return Promise.resolve();
  }
};

type LinkShorteningResponse = {
  previewLink: string;
  shortLink: string;
  warning: any[];
};
export const getShortenedLink = async ({
  source,
  socialImage,
  socialTitle,
  extraParams,
}: {
  source: Source;
  socialImage: string;
  socialTitle?: string;
  extraParams?: Record<string, string>;
}): Promise<LinkShorteningResponse> => {
  const extraParamsString = extraParams
    ? '?' +
      Object.keys(extraParams)
        .map(key => `${key}=${extraParams[key]}`)
        .join('&')
    : '';
  return axios
    .post<LinkShorteningResponse>(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.FIREBASE_API_KEY}`,
      {
        dynamicLinkInfo: {
          domainUriPrefix: 'https://dsta.sh',
          link: `https://deepstash.com/article/${source.id}${extraParamsString}`,
          androidInfo: {
            androidPackageName: 'com.deepstash',
            androidFallbackLink: `https://deepstash.com/article/${source.id}${extraParamsString}`,
          },
          iosInfo: {
            iosBundleId: 'com.deepstash.application',
            iosAppStoreId: '1445023295',
            iosFallbackLink: `https://deepstash.com/article/${source.id}${extraParamsString}`,
          },
          navigationInfo: {
            enableForcedRedirect: true,
          },
          socialMetaTagInfo: {
            socialTitle: socialTitle,
            socialImageLink: socialImage,
          },
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(res => res.data);
};

/**
 * This actually returns something, but for the time being the response is irrelevant so we don't return it
 */
export async function updateArticleData({
  sourceId,
  title,
  context,
  image,
}: {
  sourceId: number;
  title?: string;
  context?: string;
  image?: string;
}): Promise<void> {
  const token = getApiToken();

  const url = `/article/${sourceId}/`;
  const data = { title, context, image };

  return patchRequest(url, token, data);
}

/**
 * This endpoint is used to reorder the insights of a post
 * @param sourceId The id of the post
 * @param insightsById The ids of the insights in the order they should be
 * @param status
 * @returns
 */
export const reorderArticleInsights = ({
  sourceId,
  ideasById,
  status,
}: {
  sourceId: number;
  ideasById: number[];
  status?: string;
}) => {
  const token = getApiToken();

  const url = `/article/${sourceId}/reorder/`;
  const data = {
    order: ideasById,
    status: status,
  };

  return patchRequest(url, token, data);
};

export async function updateIndependentArticle(
  id: number,
  title: string,
  description: string,
): Promise<void> {
  const token = getApiToken();

  const url = `/article/${id}/`;
  const data = { title, description };

  patchRequest(url, token, data);
}

/**
 * Delete scrapped article
 * @param sourceId
 */
export function deleteScrappedSource(
  sourceId: number,
): Promise<UpdateElementResponse> {
  const token = getApiToken();

  const url = `/article/assigned_article/${sourceId}/`;
  return deleteRequest<UpdateElementResponse>(url, token);
}

export const getSourcesTopReactions = (sourcesByIds: number[]) => {
  const sourcesByIdsUrl = sourcesByIds.join();

  const url = `/article/top_reactions/?article_ids=${sourcesByIdsUrl}`;

  return getRequest<SourcesTopReactionsApiResponse>(
    url,
    getApiToken({ allowDefault: true }),
  );
};

type UpdateArticleHashtagPayload = { hashtags: string[] };
export const updateSourceHashtags = (hashtags: string[], sourceId: number) => {
  const token = getApiToken();

  const url = `article/${sourceId}/hashtags/`;
  return putRequest<void, UpdateArticleHashtagPayload>(url, token, {
    hashtags,
  });
};

type SuggestedTopicsObject = { suggested_hashtag_objects: MetaHashtag[] };
export const getSuggestedTopics = (
  sourceId: number,
): Promise<SuggestedTopicsObject> => {
  const url = `/article/${sourceId}/publish_suggestions/`;
  return getRequest<SuggestedTopicsObject>(url, getApiToken());
};
