import {
  CurrentProfileApiResponse,
  ProfileApiResponseStash,
} from 'api/api.types';
import { UserActivity, UserStash } from 'types/models';
import { Normalizer } from './types';
export const normalizeStash: Normalizer<
  ProfileApiResponseStash,
  UserStash
> = stash => {
  return {
    emoji: stash.emoji,
    id: stash.id,
    ideaCount: stash.count,
    name: stash.name,
    imageUrl: stash.image ?? undefined,
  };
};

export const normalizeUserActivity: Normalizer<
  CurrentProfileApiResponse,
  UserActivity
> = profile => {
  return {
    stashedArticles: profile.saved_articles,
    createdArticles: profile.created_articles,
    stashedIdeasIds: profile.saved_by_id,
    draftCount: profile.drafts_count,
    stashedIdeas: profile.stashed_insights,
    publishedArticlesCount: profile.published_articles_count,
    createdIdeasCount: profile.created_insights_count,
    sessionReads: new Set(),
    readIdeas: new Set(),
    stashes: profile.stashes.map(value => normalizeStash(value)),
    readToday: profile.read_today,
    totalReads: profile.num_reads,
    reads: profile.read_ideas,
    achievements: profile.achievements,
    currentStreak: profile.current_streak,
    longestStreak: profile.longest_streak,
    following: profile.following,
    followers: profile.followers,
    hashtags: profile.followed_hashtags,
    // [Like functionality]
    // likedIdeas: profile.liked_blocks,
  };
};
