import { Source, UserStash } from 'types';

export const NSI_STASH_ID = -1;

type ActivityStashState = {
  stashedIdeasIds: number[];
  stashedSources: number[];
  /**
   * ideaId-stashId pairs
   * If this is undefined we can assume the function was called for NSI
   */
  stashedIdeas?: [number, number][];
  stashes?: UserStash[];
};

type UpdateStashStateArgs = {
  ideaId: number;
  sourceId: number;
  stashId?: number;
  allIdeasStashed: boolean;
  currentState: ActivityStashState;
};
/**
 * Get the new stash state for the activity provider after stashing/unstashing an idea
 * This is used both for SI and NSI
 * @param currentState The current state from the activity provider
 * @param stashId The target stash of the idea. It's undefined if this is an unstash
 * @param allIdeasStashed Whether the source should be marked as stashed after this action
 * @param sourceId The source id of the idea
 * @param ideaId The idea id that is stashed/unstashed
 * @return The new state or undefined if the state didn't need updating
 */
export const toggleIdeaStashStateHandler = ({
  currentState,
  stashId,
  allIdeasStashed,
  sourceId,
  ideaId,
}: UpdateStashStateArgs): ActivityStashState | undefined => {
  let stashedIdeasIds = [...currentState.stashedIdeasIds];
  const stashedSources = [...currentState.stashedSources];
  let stashedIdeas = currentState.stashedIdeas
    ? [...currentState.stashedIdeas]
    : undefined;
  const stashes = currentState.stashes ? [...currentState.stashes] : undefined;

  //If the idea is already saved to a stash
  const isIdeaStashed = !!ideaId && stashedIdeasIds.includes(ideaId);
  const isNsi = stashedIdeas === undefined;

  if (isIdeaStashed) {
    const ideaSrcStashId = isNsi
      ? NSI_STASH_ID
      : stashedIdeas?.find(ideaStashPair => ideaStashPair[0] === ideaId)?.[1] ??
        undefined;

    if (ideaSrcStashId === stashId) {
      // We are putting the idea in the same stash, nothing left to do
      return undefined;
    }

    // Remove the idea from its current stash
    stashedIdeas = stashedIdeas?.filter(
      ideaStashPair => ideaStashPair[0] !== ideaId,
    );
    if (stashId) {
      //Add it to the new dest stash
      stashedIdeas = stashedIdeas?.concat([[ideaId, stashId]]);
    } else {
      //This is unstashing, so remove the idea id
      stashedIdeasIds = stashedIdeasIds.filter(id => id !== ideaId);
    }

    if (stashes) {
      // Change the idea count for the source and dest stashes

      const destStashIndex = stashes.findIndex(({ id }) => id === stashId);
      const srcStashIndex = stashes.findIndex(
        ({ id }) => id === ideaSrcStashId,
      );
      if (destStashIndex !== -1) {
        stashes[destStashIndex].ideaCount++;
      }
      if (srcStashIndex !== -1) {
        stashes[srcStashIndex].ideaCount--;
      }
    }
  } else {
    //Add this idea to a stash
    if (!stashId) return undefined;

    stashedIdeasIds = [...new Set([...stashedIdeasIds, ideaId])];
    stashedIdeas = stashedIdeas?.concat([[ideaId, stashId]]);

    if (stashes) {
      const destStashIndex = stashes.findIndex(({ id }) => id === stashId);

      if (destStashIndex !== -1) {
        stashes[destStashIndex].ideaCount++;
      }
    }
  }

  return {
    stashedIdeasIds,
    stashedIdeas,
    stashes,
    stashedSources: !allIdeasStashed
      ? // If this was an unstash we definitely don't want this article in savedArticles
        // If this was a stash, we know this article is not fully saved so the filter won't do anything
        stashedSources.filter(id => id !== sourceId)
      : [...stashedSources, sourceId],
  };
};

export const stashSourceHandler = ({
  source,
  stashId,
  ideasIds,
  currentState,
}: {
  source: Source;
  stashId: number;
  ideasIds: number[];
  currentState: ActivityStashState;
}): ActivityStashState | undefined => {
  const isNsi = currentState.stashedIdeas === undefined;
  const isArticleSaved = currentState.stashedSources.includes(source.id);

  //All the ideas this profile stashed with their stashes
  // If this is undefined, we are on NSI
  let stashedIdeas = currentState.stashedIdeas
    ? [...currentState.stashedIdeas]
    : undefined;

  //All the ideas in this article that the user already stashed
  const alreadyStashedIdeasWithStashes = (
    currentState.stashedIdeas ?? []
  ).filter(([ideaId]) => ideasIds.find(id => ideaId === id));

  //Get the stash the article is currently stashed into
  //aka get the stash of the first stashed idea in this article
  // This info will be valid only if isArticleSaved is true
  const articleSrcStashId: number | undefined = isNsi
    ? NSI_STASH_ID
    : alreadyStashedIdeasWithStashes[0]?.[1];

  const stashes = currentState.stashes ? [...currentState.stashes] : undefined;

  if (isArticleSaved && articleSrcStashId === stashId) {
    // We are trying to put the article in the same stash, so nothing to do
    return undefined;
  }

  // All the saved ideas ids of this user
  const stashedIdeasIds = [
    ...new Set([...currentState.stashedIdeasIds, ...ideasIds]),
  ];
  const stashedSources = [
    ...new Set([...currentState.stashedSources, source.id]),
  ];

  if (stashes && stashedIdeas) {
    //No need to do any of this in NSI

    // In case this article is not already stashed, but some of its ideas are, remove those ideas from their stashes
    for (const stashEntry of alreadyStashedIdeasWithStashes) {
      const stashId = stashEntry[1];
      const index = stashes.findIndex(({ id }) => id === stashId);
      if (index !== -1) {
        stashes[index].ideaCount--;
      }
    }

    // add to new stash
    const stash = stashes.find(({ id }) => id === stashId);
    if (stash) {
      stash.ideaCount += ideasIds.length;
    }

    // Remove the [idea,stash] pairs already existing for the ideas of this article that were already saved.
    // Then add the pairs for all the ideas of this source
    stashedIdeas = stashedIdeas.filter(
      ([ideaId]) => !ideasIds.includes(ideaId),
    );
    stashedIdeas = stashedIdeas.concat(
      ideasIds.map(ideaId => [ideaId, stashId]),
    );
  }

  return {
    stashedSources,
    stashedIdeas,
    stashes,
    stashedIdeasIds,
  };
};

export const unstashSourceHandler = ({
  source,
  ideasIds,
  currentState,
}: {
  source: Source;
  ideasIds: number[];
  currentState: ActivityStashState;
}): ActivityStashState | undefined => {
  //Mark all the ideas in this article as not saved
  const stashedIdeasIds = currentState.stashedIdeasIds.filter(
    id => !ideasIds.find(ideaId => ideaId === id),
  );
  const stashedSources = currentState.stashedSources.filter(
    x => x !== source.id,
  );

  if (currentState.stashedIdeas && currentState.stashes) {
    //All the ideas in this article that the user already stashed
    const stashedIdeasWithStash = currentState.stashedIdeas
      ? [
          ...currentState.stashedIdeas.filter(([ideaId]) =>
            ideasIds.find(id => ideaId === id),
          ),
        ]
      : [];

    // Get the stash the article is currently stashed into
    // aka get the stash of the first stashed idea in this article
    // This info will be valid only if isArticleSaved is true
    const articleSrcStashId = stashedIdeasWithStash[0][1];
    // Remove all the [ideaId,stashId] pairs from the profile that correspond to pairs for this article
    // Aka mark all this ideas as not saved
    const remainingStashedIdeasWithStash = currentState.stashedIdeas.filter(
      ([ideaId, stashId]) =>
        !stashedIdeasWithStash.find(
          ([stashedIdeaId, stashedIdeaStashId]) =>
            ideaId === stashedIdeaId && stashedIdeaStashId === stashId,
        ),
    );
    const stashes = [...currentState.stashes];
    // Decrement the number of ideas in the article stash
    const stash = stashes.find(({ id }) => id === articleSrcStashId);
    if (stash) {
      stash.ideaCount -= stashedIdeasWithStash.length;
    }

    return {
      stashedIdeas: remainingStashedIdeasWithStash,
      stashedSources,
      stashes,
      stashedIdeasIds,
    };
  }

  return {
    stashedSources,
    stashedIdeasIds,
  };
};
