import useLocation from 'hooks/useLocation';
import Script from 'next/script';
import React from 'react';

/**
 * Pixel will only be initialized on these pages
 */
const WHITELIST_PAGE_PATHS = ['growth-plan'];

const SnapchatPixel: React.FC = () => {
  const location = useLocation();

  const snapchatPixelFragment = (
    <>
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
{a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
r.src=n;var u=t.getElementsByTagName(s)[0];
u.parentNode.insertBefore(r,u);})(window,document,
'https://sc-static.net/scevent.min.js');

snaptr('init', '86242d39-3428-43f6-9b2d-7e4acd3dbd71');
snaptr('track', 'PAGE_VIEW');`,
        }}
      />
    </>
  );

  return process.env.NODE_ENV === 'production' &&
    WHITELIST_PAGE_PATHS.includes(location)
    ? snapchatPixelFragment
    : null;
};

export default SnapchatPixel;

/*
snaptr('init', '86242d39-3428-43f6-9b2d-7e4acd3dbd71', {
'user_email': '__INSERT_USER_EMAIL__'
});
*/
