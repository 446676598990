import {
  color,
  Flex,
  PrimaryHeading,
  PrimaryText,
  spacing,
  useColorMode,
} from 'deepstash-ui';
import React from 'react';
import commonStrings from 'utils/strings/commonStrings';
import pageStrings from 'utils/strings/pageStrings';

const PaymentLeftContent: React.FC = () => {
  const { colorMode } = useColorMode();

  return (
    <Flex flexDir="column" textAlign="start">
      <PrimaryHeading
        size="h2"
        textSize="s"
        type="extraBold"
        fontWeight={900}
        mb={spacing.XS.rem}
        color={color[colorMode].text}
      >
        {pageStrings.paywall.itsTimeTo.toUpperCase()}
      </PrimaryHeading>
      <Flex flexDir={'column'} pos="relative">
        <Flex>
          <PrimaryHeading
            size="h2"
            textSize="l"
            fontSize={spacing.toRem(80)}
            type="extraBold"
            letterSpacing={'-0.02em'}
            color={color[colorMode].secondary.default}
          >
            READ
          </PrimaryHeading>
          <PrimaryHeading
            size="h2"
            ml={spacing.XS.rem}
            textSize="l"
            fontSize={spacing.toRem(80)}
            type="extraBold"
            letterSpacing={'-0.02em'}
            color={color.failure.default}
          >
            LIKE
          </PrimaryHeading>
        </Flex>
        <PrimaryHeading
          size="h2"
          textSize="l"
          pos="relative"
          bottom={spacing.toRem(-4)}
          fontSize={spacing.toRem(80)}
          type="extraBold"
          color={color[colorMode].pro.primary}
        >
          A PRO
        </PrimaryHeading>
      </Flex>
      <PrimaryText size="m" mt={spacing.XXXL.rem} color={color[colorMode].text}>
        Jump-start your{' '}
        <b style={{ color: color[colorMode].secondary.default }}>
          reading habits
        </b>
        , gather your <b style={{ color: color.failure.default }}>knowledge</b>,{' '}
        <b style={{ color: color[colorMode].pro.primary }}>
          remember what you read
        </b>{' '}
        and stay ahead of the crowd!
      </PrimaryText>
      <PrimaryText
        size="m"
        type="bold"
        mt={spacing.XS.rem}
        color={color[colorMode].text}
      >
        {commonStrings.itTakesJust5MinutesADay}
      </PrimaryText>
    </Flex>
  );
};

export default PaymentLeftContent;
