const commonStrings = {
  appName: 'Deepstash',
  brainstash: '❤️ Brainstash Inc.',
  mobileAppName: 'Deepstash: Discover Key Ideas',
  stashes: 'Stashes',
  stashedIdeas: 'saved ideas',
  stories: 'Stories',
  ideas: 'Ideas',
  idea: 'Idea',
  topics: 'Topics',
  articles: 'Articles',
  sources: 'Sources',
  people: 'People',
  recommended: 'Recommended',
  recommendedIn: 'Recommended in ',
  trendingStory: 'Trending Story',
  featuredByEditors: "Editor's pick",
  pro: 'Pro',
  getPro: 'Get Pro',
  tryDeepstashPro: 'Try Deepstash Pro',
  filter: 'Filter',
  oneTimePayment: 'One-Time Payment',
  createArticle: {
    youAdded: 'You added an article to ',
    yourList: 'Your List',
  },
  importedArticle: 'Draft',
  featuredQuote: 'Quote of the Day',
  dialog: {
    rate: {
      title: 'Can you review us?',
      description:
        'We are happy you are enjoying Deepstash!\n\nWould you mind taking a moment to rate us in the Store?',
      actionButton: 'Rate the App',
      cancelButton: 'Later',
    },
    didYouKnow: 'Did you know?',
  },
  recommend: {
    title: 'Sharing is caring',
    description: 'Would you take a moment and recommend us to a friend?',
    actionButton: 'Recommend us',
    cancelButton: 'No, thanks',
  },
  lightMode: 'Light Mode',
  darkMode: 'Dark Mode',
  contactUs: 'Contact Us at ',
  contactEmail: 'support@deepstash.com',
  privacyPolicy: 'Privacy Policy',
  termsService: 'Terms of Service',
  appearance: 'Appearance',
  notifications: 'Notifications',
  dailyRecommendations: 'Daily Recommendations',
  notificationsSettings: 'Time for Daily Notifications',
  emails: 'Emails',
  weeklyReview: 'Weekly Review',
  and: 'and',
  profileConfig: {
    title: 'Configure Your Account',
    name: 'NAME',
    placeHolderFirstName: 'First (eg: John)',
    placeHolderLastName: 'Last (eg: Doe)',
    email: 'EMAIL',
    placeHolderEmail: 'eg: john_doe@mail.com',
    error: {
      emailInvalid: 'Invalid Email Address',
    },
  },
  notification: {
    title: 'Enable Notifications',
    description:
      'Are you enjoying your Daily Stash? You may want to be notified when a new one is ready so you don’t miss it.',
    actionButton: 'Yes, Notify Me',
    cancelButton: 'Not yet, thanks',
  },
  filterOrderOptions: {
    recent: 'RECENT FIRST',
    oldest: 'OLDEST',
    popular: 'MOST POPULAR',
    random: 'RANDOM',
    mostRead: 'MOST READ',
  },
  snackbar: {
    stashed: {
      title: 'Saved!',
      description1: '',
      description2: '',
      description3: 'saved ideas',
    },
    copied: {
      title: 'Copied to Clipboard!',
      description1: '',
      description2: '',
      description3: '',
    },
    link: {
      title: 'Link Copied to clipboard! ',
      description1: '',
      description2: '',
      description3: '',
    },
  },
  followers: 'Followers',
  following: 'Following',
  forYou: 'For You',
  nothingPublished: 'Nothing published yet.',
  nothingStashed: 'This user has no saved ideas',
  photo: 'Photo',
  currentNoStashes: 'You have no stashes created',
  userNoStashes: 'This user has no stashes created',
  // [Like functionality]
  // to be deleted
  likedIdeas: 'LIKED IDEAS',
  searchFor: 'Search for',
  homePage: {
    pageTitle: 'Deepstash: Smarter Every Day!',
    pageDescription:
      'Learn something new in just 5 minutes, with daily bite-sized knowledge from books, articles, videos, podcasts & more.',
  },
  boostYourCareer: 'Ready to boost your career?',
  mobileApp: {
    pageTitle: 'Get the app',
  },
  or: 'or',
  appRating: '4.7 Rating',
  appScore: '4.7 App Rating',
  installCount: '3M+ Installs',
  readerCount: '3M+ Readers',
  collectionOfTheWeek: 'Collection of the week',
  metacontent: {
    curatedFrom: 'CURATED FROM',
    curatorNoteSectionHeader: "CURATOR'S NOTE",
    curatorSectionHeader: 'IDEAS CURATED BY',
    relatedTopicsSectionHeader: 'RELATED TOPICS',
    similarSourcesSectionHeader: 'Similar ideas',
    differentPerspectives: 'Different Perspectives Curated by Others from',
    similarBooksSectionHeader:
      'Discover Key Ideas from Books on Similar Topics',
    topStashersSectionHeader: 'TOP STASHERS',
    upNext: 'UP NEXT',
  },
  search: 'Ideas, topics & more...',
  read: 'Read',
  faster: 'Faster',
  think: 'Think',
  better: 'Better',
  minuteShort: 'm',
  secondShort: 's',
  saved: 'saved',
  appLink: 'dsta.sh/app',
  loading: 'Loading...',
  follow: 'follow',
  // Component strings
  curatorsNote: "CURATOR'S NOTE",
  ideaCuratedFrom: 'Idea curated from',
  trending: 'Trending',
  allIdeas: 'All Ideas',
  // Payment strings
  dollarSign: '$',
  percentOff: '% OFF',
  perMonthShort: '/mo',
  billedYearly: 'billed yearly',
  itTakesJust5MinutesADay: 'It takes just 5 minutes a day.',
  unknownAuthor: 'Unknown Author',
  from: 'From',
  emptyFollowingCard: {
    firstLine: 'Discover ideas from other stashers based on your interests.',
    openModalButtonText: 'See suggested stashers',
  },
  draft: 'Draft',
  percentageOff: '% off',
  deepstashIsBetterWithTheApp: 'Deepstash is better in the app!',
  lastChance: 'Last Chance!',
  limitedOffer: 'Limited Offer',
  of: 'of',
  with: 'with',
  without: 'without',
  faq: 'FAQ',
  paywall: {
    incite: 'Ready for the next level?',
    ratings: {
      stars: 'Stars',
      ios: {
        title: 'App Store',
        rating: '4.8',
        reviewCount: '14,500+ Reviews',
      },
      android: {
        title: 'Google Play',
        rating: '4.6',
        reviewCount: '92,000+ Reviews',
      },
    },
    perPeriod: {
      '1 MONTH': '/month',
      '1 YEAR': '/year',
      '3 MONTH': '/3 months',
      '6 MONTH': '/6 months',
      LIFETIME: ' paid once',
    },
  },
  benefits: [
    ['Access to ', '200,000+ ideas'],
    ['Access to ', 'the mobile app'],
    ['Unlimited ', 'idea saving'],
    ['Unlimited ', 'history'],
    ['Unlimited ', 'listening', ' to ideas'],
    ['Downloading & ', 'offline', ' access'],
    ['Personalized ', 'microlearning'],
    ['100+ ', 'Learning Journeys'],
  ],
};

export default commonStrings;
