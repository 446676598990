import { Box, Flex, Text, useColorMode } from 'deepstash-ui';
import * as React from 'react';
import { color, EyeSvg } from 'deepstash-ui';
import { typography } from 'theme';
import styles from 'src/page-components/source/components/style/DraftIdeasLayout.module.scss';
import { getSingularOrPlural } from 'utils/StringUtils';

interface OwnProps {
  currentReadsCount: number;
}

const GoalToast: React.FC<OwnProps> = ({ currentReadsCount }) => {
  const [progressState, setProgressState] = React.useState('0');
  const { colorMode } = useColorMode();
  React.useEffect(() => {
    setTimeout(() => setProgressState('100'), 200);
  }, []);

  return (
    <Flex
      bg={color[colorMode].primary.default}
      p="20px"
      px="30px"
      mb="20px"
      borderRadius="15px"
      align="center"
    >
      <Box position="relative" ml="-10px" mt="-50px">
        <Box position="absolute" ml="15px" mt="15px" zIndex={20}>
          <EyeSvg
            color={colorMode === 'dark' ? color[colorMode].text : '#47104E'}
          />
        </Box>
        <Box
          position="absolute"
          left="-3px"
          top="-3px"
          className={styles.radial_progress}
          backgroundColor={color[colorMode].underground}
          data-progress={progressState}
          zIndex={19}
        >
          <Box className={styles.circle}>
            <Box className={`${styles.mask} ${styles.full}`}>
              <Box
                className={styles.fill}
                bg={colorMode === 'dark' ? color[colorMode].text : '#47104E'}
              />
            </Box>
            <Box className={styles.mask}>
              <Box
                className={styles.fill}
                bg={colorMode === 'dark' ? color[colorMode].text : '#47104E'}
              />
            </Box>
          </Box>
          <Box className={styles.inset} bg={color[colorMode].primary.default} />
        </Box>
      </Box>
      <Flex flexDir="column" textAlign="left" ml="70px">
        <Text color={color[colorMode].top} {...typography.UISectionHeaderBig}>
          DAILY READING GOAL REACHED
        </Text>
        <Text
          color={color[colorMode].underground}
          {...typography.contentSubtitles}
        >
          You read {currentReadsCount}{' '}
          {getSingularOrPlural(currentReadsCount, 'idea', 'ideas')} today. Good
          job!
        </Text>
      </Flex>
    </Flex>
  );
};

export default GoalToast;
