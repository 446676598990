import {
  PrimaryHeading,
  color,
  PrimaryText,
  spacing,
  useColorMode,
  Flex,
} from 'deepstash-ui';
import React, { useMemo } from 'react';
import { StripePricesData } from 'types/models';
import { SubscriptionType } from 'types/types';
import { getSingularOrPlural } from 'utils/StringUtils';
import PaywallOptionButton, {
  getPaywallOptionDiscountStyles,
  PaywallOptionType,
} from '../PaywallOptionButton';
import { getCurrencySymbolByCode } from 'utils/global';

interface PaywallOptionButtonsProps {
  prices?: StripePricesData;
  selectedSubscriptionType: SubscriptionType;
  setSelectedSubscriptionType: (val: SubscriptionType) => void;
  /**
   * On clicking lifetime option
   */
  onSelectLifetime: () => void;
  isLifeTimeSelected?: boolean;
}

const PaywallOptionButtons: React.FC<PaywallOptionButtonsProps> = ({
  prices,
  // selectedSubscriptionType,
  setSelectedSubscriptionType,
  onSelectLifetime,
  isLifeTimeSelected = false,
}) => {
  const { colorMode } = useColorMode();

  // const monthlySubscription = prices?.subscriptions['1 MONTH'];

  const yearlySubscription = prices?.subscriptions['1 YEAR'];

  // const hasMonthlyFreeTrial = monthlySubscription?.freeTrial;
  const hasYearlyFreeTrial = yearlySubscription?.freeTrial;

  // Here we assume that the first item from prices.subscriptions will be the yearly subscriptions
  /// TODO: need to improve this to be more generic
  /// TODO: calculate the billing monthly for the year subscription, it doesn't come yet in the api
  const paywallOptions: PaywallOptionType[] = useMemo(() => {
    const yearlyOption = yearlySubscription
      ? {
          topText: yearlySubscription?.freeTrial
            ? `${yearlySubscription.freeTrial.duration} ${getSingularOrPlural(
                yearlySubscription.freeTrial.duration,
                yearlySubscription.freeTrial.periodUnit,
                yearlySubscription.freeTrial.periodUnit + 'S',
              )} FREE`
            : '',
          subscriptionType: '1 YEAR' as SubscriptionType,
          mainText: (
            <PrimaryHeading
              size="h2"
              textSize="m"
              type="bold"
              pos={hasYearlyFreeTrial ? 'unset' : 'relative'}
              bottom={hasYearlyFreeTrial ? 'unset' : '0.5rem'}
              color={color[colorMode].pro}
            >
              {`${getCurrencySymbolByCode(
                yearlySubscription?.priceCurrency ?? 'usd',
              )}${(
                yearlySubscription?.promotion?.pricePerMonth ??
                yearlySubscription?.pricePerMonth ??
                0
              ).toFixed(2)}/month`}
            </PrimaryHeading>
          ),
          bottomText: (
            <PrimaryText color={color[colorMode].text}>
              Billed yearly -{' '}
              <div
                style={{
                  textDecoration: 'line-through',
                  display: 'inline-flex',
                }}
              >
                {`${getCurrencySymbolByCode(
                  yearlySubscription?.priceCurrency ?? 'usd',
                )}${yearlySubscription?.yearlyBaselinePrice}`}
              </div>{' '}
              <b>
                {`${getCurrencySymbolByCode(
                  yearlySubscription?.priceCurrency ?? 'usd',
                )}${
                  yearlySubscription?.promotion?.priceAmount ??
                  yearlySubscription?.priceAmount
                }/${yearlySubscription?.periodUnit.toLowerCase()}`}
              </b>
            </PrimaryText>
          ),
          discount: `${
            yearlySubscription?.promotion?.yearlySavingsPercentage ??
            yearlySubscription?.yearlySavingsPercentage
          }% OFF`,
        }
      : undefined;
    // const lifetimeOption: PaywallOptionType = {
    //   topText: 'LIFETIME',
    //   mainText: (
    //     <PrimaryHeading
    //       size="h2"
    //       textSize="m"
    //       type="bold"
    //       pos={hasYearlyFreeTrial ? 'unset' : 'relative'}
    //       bottom={hasYearlyFreeTrial ? 'unset' : '0.5rem'}
    //       color={color[colorMode].pro}
    //     >
    //       {`$299.99`}
    //     </PrimaryHeading>
    //   ),
    //   bottomText: 'One-Time Payment',
    //   // just for handle errors...
    //   subscriptionType: '1 MONTH',
    // };
    // const monthlyOption = monthlySubscription
    //   ? {
    //       topText: monthlySubscription?.freeTrial
    //         ? `${monthlySubscription.freeTrial?.duration} ${getSingularOrPlural(
    //             monthlySubscription.freeTrial?.duration,
    //             monthlySubscription.freeTrial?.periodUnit,
    //             monthlySubscription.freeTrial?.periodUnit + 'S',
    //           )} FREE`
    //         : '',
    //       subscriptionType: '1 MONTH' as SubscriptionType,
    //       mainText: (
    //         <PrimaryHeading
    //           size="h2"
    //           textSize="s"
    //           type="bold"
    //           pos={hasMonthlyFreeTrial ? 'unset' : 'relative'}
    //           bottom={hasMonthlyFreeTrial ? 'unset' : '0.25rem'}
    //           color={color[colorMode].text}
    //           h={spacing.toRem(28)}
    //         >
    //           {`$${
    //             monthlySubscription?.promotion?.priceAmount ??
    //             monthlySubscription?.priceAmount
    //           }/${monthlySubscription?.periodUnit.toLowerCase()}`}
    //         </PrimaryHeading>
    //       ),
    //       bottomText: 'Billed monthly',
    //     }
    //   : undefined;
    const subscriptionOptions = [];
    yearlyOption && subscriptionOptions.push(yearlyOption);
    // subscriptionOptions.push(lifetimeOption);
    // monthlyOption && subscriptionOptions.push(monthlyOption);
    return subscriptionOptions;
  }, [colorMode, prices]);

  return (
    <Flex
      flexDir="column"
      px={spacing.toRem(20)}
      justifyContent="center"
      minW={spacing.toRem(303)}
      borderRadius={spacing.toRem(16)}
      flex={1}
      gridGap={spacing.toRem(16)}
    >
      {paywallOptions.map((option, index) => {
        return (
          <PaywallOptionButton
            key={`paywall-option-${index}`}
            topText={option.topText}
            mainText={option.mainText}
            bottomText={option.bottomText}
            // isSelected={selectedSubscriptionType === option.subscriptionType}
            // Hack change, revert logic when removing lifetime from paywall
            isSelected={
              option.subscriptionType === '1 YEAR'
                ? !isLifeTimeSelected
                : isLifeTimeSelected
            }
            discount={option.discount}
            onClick={() => {
              if (option.subscriptionType === '1 YEAR') {
                setSelectedSubscriptionType(option.subscriptionType);
              } else {
                onSelectLifetime();
              }
            }}
            subscriptionType={option.subscriptionType}
            styleDiscountComponent={
              prices?.subscriptions[option.subscriptionType]?.promotion
                ? getPaywallOptionDiscountStyles(colorMode).PROMOTION
                : undefined
            }
          />
        );
      })}
    </Flex>
  );
};

export default PaywallOptionButtons;
