import {
  DeleteIdeaCodeSource,
  EntityReportType,
  Idea,
  InsightDraft,
} from 'types';
import { KNOW_LIB_INSIGHT_COUNT } from 'utils/constants';
import * as apiTypes from './api.types';
import { EditIdeaReqBlock } from './api.types';
import {
  deleteRequest,
  getApiToken,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from './apiRequest';
import { Analytics, Events } from 'src/services/analytics';

/**
 * Fetch latest insights
 *
 * @param offset The offset from which to deliver saved insights
 */
export function getLatestInsights(
  offset = 0,
): Promise<apiTypes.InsightsResponse> {
  const url = `/block/latest/?limit=${KNOW_LIB_INSIGHT_COUNT}&offset=${offset}`;
  return getRequest<apiTypes.InsightsResponse>(
    url,
    getApiToken({ allowDefault: true }),
  );
}

/**
 * Save an insight to a stash
 * @param insightId
 * @param stashId
 */
export function stashIdea(
  insightId: number,
  stashId?: number,
): Promise<apiTypes.UpdateElementResponse> {
  const token = getApiToken();

  const url = `/block/${insightId}/saved_by/`;
  const data: apiTypes.SaveInsightData | null = stashId
    ? {
        stash: `${stashId}`,
      }
    : null;
  return putRequest<
    apiTypes.UpdateElementResponse,
    apiTypes.SaveInsightData | null
  >(url, token, data);
}

/**
 * Remove a saved insight from the user collection
 * @param insightId The id of the insight to be removed
 */
export function removeInsight(
  insightId: number,
): Promise<apiTypes.UpdateElementResponse> {
  const token = getApiToken();

  const url = `/block/${insightId}/saved_by/`;
  return deleteRequest<apiTypes.UpdateElementResponse>(url, token);
}

/**
 * Delete insight api call
 * @param insightId
 * @param codeSource A string that references what part of the code deleted the idea
 */
export function deleteInsight(
  insightId: number,
  codeSource: DeleteIdeaCodeSource,
): Promise<apiTypes.UpdateElementResponse> {
  const token = getApiToken();

  const url = `/block/${insightId}/`;

  Analytics.logEvent({
    eventName: Events.creation.deleteIdeaApiCall,
    properties: {
      codeSource: codeSource,
      id: insightId,
    },
    platforms: ['amplitude'],
  });

  return deleteRequest<apiTypes.UpdateElementResponse>(url, token);
}

export function createInsight(
  insightDraft: InsightDraft,
): Promise<apiTypes.InsightApiResponse> {
  const token = getApiToken();

  const url = `/block/`;
  const data: apiTypes.CreateInsightRequestData = {
    article: `${insightDraft.articleId}`,
    source_id: insightDraft.source_id,
    title: insightDraft.title,
    author_name: insightDraft.authorName,
    image: insightDraft.image,
    image_position: insightDraft.imagePosition,
    content: insightDraft.content,
    status: insightDraft.status,
    quality: insightDraft.quality,
    type: insightDraft.type,
  };

  return postRequest<
    apiTypes.InsightApiResponse,
    apiTypes.CreateInsightRequestData
  >(url, token, data);
}

const EDIT_IDEA_REQ_BLOCK_KEYS = [
  'content',
  'author_name',
  'title',
  'image',
  'order',
  'quality',
  'status',
  'title',
  'type',
  'id',
];

export function editInsight(
  insightId: number,
  idea?: Partial<Idea>,
): Promise<apiTypes.InsightApiResponse> {
  const token = getApiToken();

  if (!idea) throw new Error('No data!');

  const url = `/block/${insightId}/`;
  //Send only the relevant idea data
  const mapContent = (content: string) => {
    const replacedContent = content
      .replace(/<p><\/p>/g, '<p><br/></p>')
      .replace(/\n/g, '');
    return replacedContent === '<p><br/></p>' ? '' : replacedContent;
  };

  //Build the API call body
  const block: Partial<EditIdeaReqBlock> = { id: insightId };
  for (const key in idea) {
    if ((key as keyof Idea) === 'authorName') {
      //Special case, the name doesn't match
      block.author_name = idea.authorName;
    } else if ((key as keyof Idea) === 'content' && idea.content) {
      //Special case, we need to assign the result of a function instead of the property directly
      block.content = mapContent(idea.content);
    } else if (EDIT_IDEA_REQ_BLOCK_KEYS.includes(key)) {
      // This key is a name match for a key in the body type so we assign it directly
      //We can't send undefined to the backend, so in case of undefined we fallback to null
      block[key as keyof EditIdeaReqBlock] = (idea as any)[key] ?? null;
    }
  }

  return patchRequest<apiTypes.InsightApiResponse, Partial<EditIdeaReqBlock>>(
    url,
    token,
    block,
  );
}

export function updateReadInsights(data: Record<number, number>) {
  try {
    const token = getApiToken();
    const url = `/block/reads/`;
    return postRequest<apiTypes.ReadApiResponse, Record<number, number>>(
      url,
      token,
      data,
    );
  } catch (e) {
    return Promise.resolve();
  }
}

/**
 * Fetch the insight and article for an insight page
 * @param token
 * @param insightId
 */
export function getInsight(
  insightId: number,
): Promise<apiTypes.InsightApiResponse> {
  const token = getApiToken();

  const url = `/block/${insightId}/?expanded`;

  return getRequest<apiTypes.InsightApiResponse>(url, token);
}

export function reportContentOrUser(data: {
  type: EntityReportType;
  id: number;
  resource_owner: number;
  reason?: string;
}): Promise<string> {
  const token = getApiToken();

  return postRequest('/report/submit/', token, data);
}

export function createMultipleIdeas(
  sourceId: number,
  ideas: Idea[],
): Promise<apiTypes.CreateMultipleIdeasResponse> {
  const token = getApiToken();

  const url = `/block/list/`;
  // Send only the relevant idea data to the backend
  const ideaData: apiTypes.CreateInsightRequestData[] = ideas.map(idea => ({
    article: sourceId.toString(),
    title: idea.title,
    author_name: idea.authorName,
    image: idea.image,
    content: idea.content,
    status: idea.status,
    quality: idea.quality,
    type: idea.type,
  }));

  return postRequest<any, apiTypes.CreateMultipleIdeasReq>(url, token, {
    article_id: sourceId,
    blocks: ideaData,
  });
}

export function editMultipleIdeas(ideas: Idea[]) {
  const token = getApiToken();

  if (ideas.length === 0) return;

  const url = `/block/list/`;

  //Send only the relevant idea data
  const mapContent = (content: string) => {
    const replacedContent = content
      .replace(/<p><\/p>/g, '<p><br/></p>')
      .replace(/\n/g, '');
    return replacedContent === '<p><br/></p>' ? '' : replacedContent;
  };
  const blocks: apiTypes.EditIdeaReqBlock[] = ideas.map(idea => ({
    content: mapContent(idea.content),
    author_name: idea.authorName || null,
    title: idea.title || null,
    image: idea.image || null,
    order: idea.order,
    quality: idea.quality,
    status: idea.status,
    type: idea.type,
    id: idea.id,
  }));

  return patchRequest<
    apiTypes.InsightApiResponse,
    { blocks: apiTypes.EditIdeaReqBlock[] }
  >(url, token, { blocks });
}

/**
 * Get a list of ideas similar to the one with the provided idea id.
 * @param ideaId
 */
export function getSimilarIdeas(
  ideaId: number,
): Promise<apiTypes.InsightsResponse> {
  const token = getApiToken();

  const url = `/block/${ideaId}/similar/`;

  return getRequest<apiTypes.InsightsResponse>(url, token);
}

/**
 * Get idea suggestions from the AI
 * @param title the title of the idea
 * @param content the content of the idea
 * @returns
 */
export const getAISuggestions = ({
  title,
  content,
}: {
  title?: string;
  content?: string;
}): Promise<apiTypes.IdeaSuggestionsApiResponse> => {
  const url = `/block/suggestions/?content=${
    content ? encodeURIComponent(content) : ''
  }${title ? '&title=' + encodeURIComponent(title) : ''}`;
  const token = getApiToken();
  return getRequest(url, token);
};

/**
 * Get AI-generated image
 * @param prompt the prompt to use
 */
export const generateAIImage = ({
  prompt,
}: {
  prompt: string;
}): Promise<apiTypes.IdeaImageAIGenerateApiResponse> => {
  const url = `/editor/image/generate/`;
  const body = {
    prompt,
  };
  const token = getApiToken();
  return postRequest(url, token, body);
};

/**
 * Get idea editor settings
 */
export const getEditorSettings =
  (): Promise<apiTypes.EditorSettingsApiResponse> => {
    const url = `/editor/settings/`;
    const token = getApiToken();
    return getRequest(url, token);
  };
