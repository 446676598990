import { Context, useCallback, useContext } from 'react';
import { AsyncActionMap } from '../types/actions.types';
import { GlobalContext } from '../types/context.types';
import createAsyncActions from './utils/createAsyncActions';
import * as Sentry from '@sentry/nextjs';
import { Severity } from '@sentry/nextjs';

/**
 * This hook is used to create the consumer hook for out contexts
 * It takes all the information about the context you want to consume through generic arguments
 *
 * S - A type defining the properties held by the context
 *
 * A - The actions that can be dispatched for this context
 *
 * C - The interface of the context to be consumed. It has to extends GlobalContext
 *
 * H - Optional generic argument for contexts that have asycn actions.
 *      It should denote the hidden actions of the context
 *
 * AS - Optional generic argument for contexts that have asycn actions.
 *      It should denote the async actions of the context.
 *      It's a mapping of AsyncActions onto some keys
 * E - Typing for extra properties to be sent as part of the context. These could be refs or states that are not in the main reducer
 * @param context The context to be consumed
 * @param asyncActions The async actions that can be dispatched for this context, if any
 * @param extra properties to be sent as part of the context
 *  @param withLogging If the dispatch of the actions should log the action name and the args
 * @returns The values that should be used throughout the code to consume the data in the context
 */
const useCreateContextConsumer = <
  S,
  A,
  C extends GlobalContext<S, A, E>,
  H = never,
  AS = never,
  E = never,
>({
  context,
  withLogging,
  asyncActions,
}: {
  context: Context<C>;
  asyncActions?: AsyncActionMap<H>;
  withLogging?: boolean;
}): [E] extends [never]
  ? S extends undefined
    ? { dispatch: (action: A) => void }
    : [AS] extends [never]
    ? { dispatch: (action: A) => void } & S
    : { dispatch: (action: A) => void } & S & AS
  : S extends undefined
  ? { dispatch: (action: A) => void } & E
  : [AS] extends [never]
  ? { dispatch: (action: A) => void } & S & E
  : { dispatch: (action: A) => void } & S & AS & E => {
  const contextState = useContext(context);

  const dispatchWithLogs = useCallback(
    (action: A) => {
      contextState.dispatch(action);
      if (withLogging) {
        Sentry.addBreadcrumb({
          data: action,
          category: 'dispatch',
          level: Severity.Log,
          message: 'Dispatched action',
        });
      }
    },
    [contextState.dispatch],
  );
  if ((contextState as any).extra) {
    return {
      dispatch: dispatchWithLogs,
      ...contextState.state,
      ...(contextState as any).extra,
      ...createAsyncActions<H>(
        contextState.dispatch as unknown as (action: H) => void,
        asyncActions,
      ),
    } as any;
  } else {
    return {
      dispatch: dispatchWithLogs,
      ...contextState.state,
      ...createAsyncActions<H>(
        contextState.dispatch as unknown as (action: H) => void,
        asyncActions,
      ),
    } as any;
  }
};

export default useCreateContextConsumer;
