import * as React from 'react';
import { Icon, IconProps } from 'deepstash-ui';

export const GiftColorIcon = React.memo<IconProps>(props => {
  return (
    <Icon width="96px" height="96px" viewBox="0 0 96 96" fill="none" {...props}>
      <path
        d="M85.086 68.5161C85.0801 69.0279 84.9411 69.5294 84.6826 69.9712C84.4242 70.413 84.0552 70.78 83.612 71.0361L50.052 90.5101C49.5264 90.8079 48.9653 91.0384 48.382 91.1961C48.1133 91.2667 47.8409 91.3228 47.566 91.3641V44.3861L86.034 27.0381C85.89 40.8001 85.268 54.7361 85.086 68.5161Z"
        fill="url(#paint0_linear_797_114129)"
      />
      <path
        d="M47.566 91.3641C47.22 91.324 46.8776 91.2571 46.542 91.1641C45.9881 91.005 45.4576 90.7736 44.964 90.4761C44.076 89.9441 31.844 82.9821 14.82 73.2861C14.3608 73.0253 13.9758 72.6517 13.7014 72.2006C13.427 71.7494 13.2723 71.2357 13.252 70.7081L11.76 29.3621L47.56 44.3861V91.3641H47.566Z"
        fill="url(#paint1_linear_797_114129)"
      />
      <path
        d="M87.8919 37.9397C87.877 38.3212 87.7605 38.6917 87.5545 39.0131C87.3484 39.3344 87.0603 39.595 86.7199 39.7677L47.7599 59.5177C47.6607 59.4845 47.5649 59.4416 47.4739 59.3897C47.4041 59.3477 47.3373 59.3009 47.2739 59.2497V43.2777L87.9219 24.5737C87.9791 24.6367 88.0319 24.7035 88.0799 24.7737C88.2318 25.0053 88.3352 25.2651 88.3839 25.5377C88.2199 29.6751 88.0559 33.8091 87.8919 37.9397Z"
        fill="url(#paint2_linear_797_114129)"
      />
      <path
        d="M47.5659 59.6161L11.3799 41.6001C10.9267 41.3747 10.5425 41.0314 10.2676 40.6063C9.9928 40.1812 9.83746 39.69 9.81791 39.1841L9.35791 27.1841C9.3922 26.9458 9.51857 26.7304 9.70991 26.5841C9.81794 26.5061 9.9425 26.4541 10.0739 26.4321L47.5439 43.1801L47.5659 59.6161Z"
        fill="url(#paint3_linear_797_114129)"
      />
      <path
        d="M87.6541 24.3238C87.7048 24.427 87.7354 24.539 87.7441 24.6538L47.2701 43.2778L9.83606 26.3418L10.0541 26.1298L10.5401 25.6618L48.6781 11.8098C49.3411 11.5901 50.0356 11.4806 50.7341 11.4858C51.3022 11.4484 51.8726 11.5093 52.4201 11.6658L87.2741 23.9318C87.4379 24.0205 87.5705 24.1573 87.6541 24.3238V24.3238Z"
        fill="url(#paint4_linear_797_114129)"
      />
      <path
        d="M21.42 30.346C21.4716 30.5465 21.4503 30.7587 21.3598 30.9448C21.2693 31.131 21.1155 31.2789 20.926 31.362C20.9026 31.5293 20.8279 31.6852 20.712 31.808C20.6156 31.9 20.4997 31.9691 20.373 32.0103C20.2463 32.0515 20.112 32.0637 19.98 32.046L9.36198 27.276C9.33987 27.1499 9.33516 27.0214 9.34798 26.894C9.36834 26.7163 9.41837 26.5432 9.49598 26.382C9.63994 26.1638 9.82941 25.9793 10.0514 25.8412C10.2734 25.7031 10.5226 25.6147 10.782 25.582L21.42 30.346Z"
        fill="url(#paint5_linear_797_114129)"
      />
      <path
        d="M48.62 43.7263C48.4942 43.978 48.2783 44.1732 48.0153 44.2732C47.7523 44.3732 47.4613 44.3707 47.2 44.2663L32.32 37.5863C32.3 37.1063 32.3 36.6463 32.28 36.1663C32.64 36.0063 33 35.8663 33.36 35.7063L48.08 42.3063C48.3393 42.4238 48.5415 42.639 48.6427 42.905C48.7438 43.171 48.7357 43.4663 48.62 43.7263V43.7263Z"
        fill="url(#paint6_linear_797_114129)"
      />
      <path
        d="M47.568 44.3522C47.3237 44.3517 47.0868 44.2678 46.8966 44.1144C46.7063 43.961 46.574 43.7474 46.5216 43.5087C46.4692 43.2701 46.4997 43.0206 46.6081 42.8016C46.7166 42.5827 46.8964 42.4072 47.118 42.3042L87.142 23.8862C87.5073 23.9594 87.8344 24.1609 88.064 24.4542C88.1925 24.6281 88.2841 24.8263 88.3333 25.0367C88.3825 25.2472 88.3882 25.4655 88.35 25.6782L48.014 44.2542C47.8742 44.3186 47.722 44.352 47.568 44.3522V44.3522Z"
        fill="url(#paint7_linear_797_114129)"
      />
      <path
        d="M47.5659 59.6159C47.3792 59.6058 47.1965 59.5576 47.029 59.4744C46.8615 59.3912 46.7128 59.2747 46.5919 59.1319L46.5599 58.7319L46.4919 43.5179C46.4914 43.2336 46.6034 42.9606 46.8036 42.7586C47.0037 42.5566 47.2756 42.4421 47.5599 42.4399V42.4399C47.8438 42.4399 48.116 42.5523 48.3173 42.7524C48.5185 42.9525 48.6324 43.2241 48.6339 43.5079C48.6526 48.6866 48.6699 53.8646 48.6859 59.0419C48.557 59.2184 48.3886 59.3622 48.1941 59.4619C47.9996 59.5616 47.7845 59.6143 47.5659 59.6159V59.6159Z"
        fill="url(#paint8_linear_797_114129)"
      />
      <path
        d="M32.2781 36.1562L20.6161 31.2002C20.6019 31.1045 20.6095 31.0067 20.6381 30.9142C20.6715 30.8201 20.7244 30.734 20.7934 30.6617C20.8623 30.5893 20.9457 30.5323 21.0381 30.4942L62.1341 15.0942L72.8461 18.8662L32.2781 36.1562Z"
        fill="url(#paint9_linear_797_114129)"
      />
      <path
        d="M27.4539 19.52L38.1159 15.646L76.2659 28.824C76.3485 28.8516 76.4216 28.9022 76.4765 28.9698C76.5314 29.0375 76.5659 29.1194 76.5759 29.206C76.5805 29.308 76.5494 29.4085 76.4879 29.49L63.7319 35.474L27.4539 19.52Z"
        fill="url(#paint10_linear_797_114129)"
      />
      <path
        d="M27.4539 19.52L38.1159 15.646L76.4179 28.836C76.4384 28.9857 76.4337 29.1378 76.4039 29.286C76.3832 29.3863 76.3502 29.4837 76.3059 29.576L63.7319 35.474L27.4539 19.52Z"
        fill="url(#paint11_linear_797_114129)"
      />
      <path
        d="M32.6121 36.3202C32.4998 36.4788 32.4152 36.6553 32.3621 36.8422C32.2954 37.0844 32.2811 37.338 32.3201 37.5862L19.9801 32.0462C19.9611 31.8604 19.9858 31.6728 20.0521 31.4982C20.12 31.3377 20.2162 31.1907 20.3361 31.0642C20.4479 30.9277 20.5755 30.8049 20.7161 30.6982C20.9272 30.5387 21.1658 30.4194 21.4201 30.3462L33.3601 35.7062C33.2156 35.7616 33.0806 35.8392 32.9601 35.9362C32.8222 36.0425 32.7043 36.1725 32.6121 36.3202V36.3202Z"
        fill="white"
      />
      <path
        d="M72.84 18.8459L33.36 35.7059L21.42 30.3459C34.98 25.2659 48.5534 20.1793 62.14 15.0859C65.7 16.3459 69.28 17.5999 72.84 18.8459Z"
        fill="url(#paint12_linear_797_114129)"
      />
      <path
        d="M48.3999 19.7059C47.6739 18.8339 38.1499 7.57794 31.9319 8.53194C30.9819 8.67788 30.0753 9.03013 29.2759 9.56394C27.0459 11.1999 26.8219 14.1479 26.4759 17.4779C25.3459 28.4779 24.3599 29.8919 25.5819 30.7939C27.3819 32.1279 30.0479 29.6179 37.4399 27.9939C40.3622 27.3571 43.3397 27.0074 46.3299 26.9499"
        fill="url(#paint13_linear_797_114129)"
      />
      <path
        d="M25.022 30.0381C25.1716 30.3929 25.3836 30.7181 25.648 30.9981C25.9803 31.3351 26.3697 31.6107 26.798 31.8121C26.8617 31.8494 26.9286 31.8809 26.998 31.9061C27.5393 32.1365 28.1138 32.2796 28.7 32.3301C28.86 32.3521 29.008 32.3621 29.17 32.3721C29.2111 32.3781 29.2529 32.3781 29.294 32.3721C29.494 32.3721 29.706 32.3901 29.908 32.3861C29.9491 32.3921 29.9909 32.3921 30.032 32.3861C30.2 32.3741 30.374 32.3761 30.542 32.3641C31.6365 32.298 32.7259 32.1644 33.804 31.9641C34.236 31.8861 34.67 31.8081 35.09 31.7281C35.69 31.6101 36.358 31.4621 37.11 31.2541C37.362 31.1981 37.632 31.1221 37.91 31.0341C38.8931 30.7397 39.8581 30.3878 40.8 29.9801C41.9081 29.5281 42.9828 28.9981 44.016 28.3941C44.458 28.1661 44.91 27.8921 45.368 27.5941C45.692 27.3941 46.016 27.1741 46.342 26.9581C46.742 26.6961 47.142 26.4081 47.53 26.1081C47.73 25.9761 47.93 25.8201 48.13 25.6641C48.188 25.6261 48.238 25.5741 48.298 25.5261C48.3014 25.5267 48.3049 25.5261 48.3079 25.5242C48.3109 25.5224 48.313 25.5195 48.314 25.5161H48.29C48.2278 25.539 48.1643 25.5583 48.1 25.5741C47.752 25.6721 47.404 25.7561 47.072 25.8321C45.964 26.1221 44.902 26.3601 43.912 26.5621C42.502 26.8461 41.22 27.0601 40.04 27.2201C37.6581 27.5393 35.2483 27.5963 32.854 27.3901C31.5275 27.2074 30.1828 27.1987 28.854 27.3641C28.7636 27.3678 28.6745 27.386 28.59 27.4181C27.4671 27.5725 26.4346 28.1178 25.674 28.9581C25.3992 29.2801 25.1789 29.645 25.022 30.0381V30.0381Z"
        fill="url(#paint14_linear_797_114129)"
      />
      <path
        d="M47.384 19.3802C47.7158 19.7375 47.9243 20.1919 47.9786 20.6765C48.033 21.1611 47.9304 21.6503 47.686 22.0722C46.136 23.6302 38.55 19.0222 38.84 18.2722C38.914 18.0722 39.44 18.2982 42.04 18.5142C45.666 18.8142 46.664 18.5562 47.384 19.3802Z"
        fill="url(#paint15_linear_797_114129)"
      />
      <path
        d="M49.964 20.1061C50.59 19.1601 58.812 6.92208 65.098 7.18408C66.0584 7.22398 66.9985 7.47384 67.852 7.91608C70.252 9.29808 70.798 12.2021 71.504 15.4761C73.84 26.2761 74.974 27.5801 73.862 28.6101C72.214 30.1361 69.292 27.9341 61.766 27.1441C58.7917 26.8331 55.7941 26.8137 52.816 27.0861"
        fill="url(#paint16_linear_797_114129)"
      />
      <path
        d="M74.344 27.7999C74.2364 28.1691 74.0622 28.5155 73.83 28.8219C73.5369 29.1939 73.18 29.5108 72.776 29.7579C72.7198 29.8021 72.6595 29.841 72.596 29.8739C72.0824 30.1595 71.5269 30.3621 70.95 30.4739C70.796 30.5139 70.648 30.5399 70.49 30.5679C70.45 30.5809 70.4081 30.587 70.366 30.5859C70.166 30.6159 69.966 30.6479 69.766 30.6659C69.7258 30.6779 69.684 30.684 69.642 30.6839C69.474 30.6839 69.3 30.7119 69.134 30.7159C68.0382 30.7696 66.9401 30.7556 65.846 30.6739C65.408 30.6439 64.968 30.6139 64.54 30.5799C63.94 30.5299 63.252 30.4559 62.482 30.3339C62.224 30.3059 61.948 30.2579 61.658 30.2039C60.6445 30.0199 59.6427 29.7762 58.658 29.4739C57.5072 29.1469 56.381 28.7385 55.288 28.2519C54.7999 28.0614 54.3219 27.8457 53.856 27.6059C53.512 27.4399 53.166 27.2599 52.818 27.0819C52.392 26.8659 51.97 26.6239 51.544 26.3679C51.344 26.2579 51.122 26.1239 50.908 25.9919C50.8443 25.9564 50.7828 25.917 50.724 25.8739C50.7218 25.8759 50.719 25.877 50.716 25.877C50.7131 25.877 50.7102 25.8759 50.708 25.8739H50.73C50.8 25.8879 50.87 25.9039 50.93 25.9099C51.288 25.9679 51.64 26.0139 51.978 26.0539C53.114 26.2179 54.194 26.3379 55.2 26.4299C56.634 26.5579 57.93 26.6299 59.122 26.6559C61.5244 26.7117 63.9255 26.5024 66.282 26.0319C67.5789 25.7055 68.9127 25.5488 70.25 25.5659C70.34 25.5608 70.4303 25.5689 70.518 25.5899C71.6511 25.6201 72.7373 26.0486 73.586 26.7999C73.8918 27.0894 74.1479 27.4273 74.344 27.7999V27.7999Z"
        fill="url(#paint17_linear_797_114129)"
      />
      <path
        d="M50.582 20.1539C50.3029 20.5539 50.1596 21.0329 50.1733 21.5205C50.1869 22.008 50.3569 22.4783 50.658 22.8619C52.41 24.1879 59.278 18.5659 58.886 17.8619C58.786 17.6799 58.286 17.9719 55.748 18.5499C52.2 19.3539 51.18 19.2379 50.582 20.1539Z"
        fill="url(#paint18_linear_797_114129)"
      />
      <path
        d="M53.5862 24.4878C53.6461 24.8124 53.6392 25.1458 53.5659 25.4677C53.4927 25.7895 53.3546 26.0931 53.1602 26.3598L53.1362 26.3878C52.9142 26.6198 52.5702 26.7878 50.7122 26.9038C50.3122 26.9498 49.8302 26.9758 49.2702 26.9918C48.7502 27.0178 48.3222 27.0278 47.9442 27.0338C46.2642 27.0558 45.8802 26.9458 45.6502 26.7878L45.5822 26.7258C45.3489 26.5067 45.1628 26.2421 45.0356 25.9483C44.9083 25.6546 44.8425 25.3379 44.8422 25.0178C44.7838 24.3526 44.7798 23.6837 44.8302 23.0178C44.8302 22.8598 44.8522 22.6778 44.8562 22.5078C44.9522 20.6118 45.0262 18.9938 45.9842 18.3838C46.0101 18.3597 46.0398 18.3401 46.0722 18.3258C46.3191 18.186 46.6078 18.1392 46.8862 18.1938C47.6109 18.3323 48.3488 18.39 49.0862 18.3658C49.8141 18.3197 50.5364 18.208 51.2442 18.0318C51.4468 17.9879 51.6583 18.0139 51.8442 18.1058C51.8582 18.1178 51.8702 18.1178 51.8842 18.1298C52.9542 18.6478 53.1722 20.3698 53.4062 22.5978C53.4194 22.6536 53.4268 22.7105 53.4282 22.7678C53.5062 23.4498 53.5742 24.0178 53.5862 24.4878Z"
        fill="url(#paint19_radial_797_114129)"
      />
      <path
        d="M31.078 51.6362L36.32 54.0182L36.72 85.7662L30.77 82.2002C30.8727 72.0149 30.9754 61.8269 31.078 51.6362Z"
        fill="url(#paint20_linear_797_114129)"
      />
      <path
        d="M65.954 50.4399L60.834 52.9579C60.65 63.4046 60.4654 73.8493 60.28 84.2919L65.2 81.6339C65.456 71.2366 65.7074 60.8386 65.954 50.4399Z"
        fill="url(#paint21_linear_797_114129)"
      />
      <path
        d="M33.7001 84.0459C29.6601 81.7459 25.6201 79.4259 21.6001 77.1259C21.0668 62.1086 20.5268 47.0819 19.9801 32.0459L32.3201 37.5859C32.7601 53.0619 33.2201 68.5486 33.7001 84.0459Z"
        fill="url(#paint22_linear_797_114129)"
      />
      <path
        d="M76.044 75.4899L62.866 83.0739C63.1566 67.2073 63.4453 51.3406 63.732 35.4739L76.422 29.5219C76.486 29.4339 76.544 29.3979 76.6 29.3979C76.744 29.3979 76.94 29.6319 77.176 30.5979L76.044 75.4899Z"
        fill="url(#paint23_linear_797_114129)"
      />
      <path
        d="M63.502 35.724C63.5827 35.8828 63.6335 36.0549 63.652 36.232C63.6932 36.4932 63.7106 36.7577 63.704 37.022L77.17 30.852C77.184 30.618 77.186 30.416 77.184 30.252C77.1992 30.0158 77.1721 29.7787 77.104 29.552C77.0367 29.3738 76.9308 29.2126 76.794 29.08C76.6847 28.9753 76.5573 28.8913 76.418 28.832L62.818 35.068C62.9408 35.1481 63.0572 35.2377 63.166 35.336C63.2988 35.4458 63.4123 35.5769 63.502 35.724V35.724Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_797_114129"
          x1="75.558"
          y1="86.0601"
          x2="53.678"
          y2="18.9501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CD0000" />
          <stop offset="1" stopColor="#B60000" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_797_114129"
          x1="3.78801"
          y1="72.8861"
          x2="82.382"
          y2="34.8481"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6E6E" />
          <stop offset="1" stopColor="#B60000" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_797_114129"
          x1="73.5879"
          y1="55.2097"
          x2="54.7339"
          y2="13.0517"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CD0000" />
          <stop offset="1" stopColor="#B60000" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_797_114129"
          x1="-2.89409"
          y1="34.0681"
          x2="121.672"
          y2="69.2681"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6E6E" />
          <stop offset="1" stopColor="#B60000" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_797_114129"
          x1="24.5961"
          y1="39.3138"
          x2="92.8121"
          y2="-0.0262414"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E51515" />
          <stop offset="1" stopColor="#B60000" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_797_114129"
          x1="15.396"
          y1="28.71"
          x2="26.562"
          y2="14.544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6E6E" />
          <stop offset="1" stopColor="#B60000" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_797_114129"
          x1="40.438"
          y1="39.9863"
          x2="53.874"
          y2="22.9383"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6E6E" />
          <stop offset="1" stopColor="#B60000" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_797_114129"
          x1="62.998"
          y1="51.4862"
          x2="77.668"
          y2="-5.14577"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6E6E" />
          <stop offset="1" stopColor="#B60000" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_797_114129"
          x1="47.2219"
          y1="33.4999"
          x2="48.3439"
          y2="85.9599"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.099" stopColor="#FF6E6E" />
          <stop offset="0.267" stopColor="#EE5454" />
          <stop offset="0.593" stopColor="#D02727" />
          <stop offset="0.839" stopColor="#BD0B0B" />
          <stop offset="0.975" stopColor="#B60000" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_797_114129"
          x1="8.10406"
          y1="40.9642"
          x2="94.5721"
          y2="5.36823"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.0001" stopColor="#B8B8B8" />
          <stop offset="0.811" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_797_114129"
          x1="23.6699"
          y1="10.162"
          x2="91.7619"
          y2="43.998"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.848" stopColor="#E4E4E4" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_797_114129"
          x1="50.8399"
          y1="7.93"
          x2="64.9999"
          y2="59.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#272727" />
          <stop offset="0.383" stopColor="white" />
          <stop offset="0.707" stopColor="white" stopOpacity="0.397" />
          <stop offset="0.844" stopColor="white" stopOpacity="0.182" />
          <stop offset="0.945" stopColor="white" stopOpacity="0.05" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_797_114129"
          x1="52.872"
          y1="8.31994"
          x2="34.186"
          y2="61.2959"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.299" stopColor="#6A6A6A" />
          <stop offset="0.387" stopColor="white" />
          <stop offset="0.546" stopColor="white" stopOpacity="0.69" />
          <stop offset="0.709" stopColor="white" stopOpacity="0.397" />
          <stop offset="0.845" stopColor="#F9F9F9" stopOpacity="0.182" />
          <stop offset="0.945" stopColor="white" stopOpacity="0.05" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_797_114129"
          x1="24.0787"
          y1="19.0503"
          x2="45.4449"
          y2="21.8993"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#949494" />
          <stop offset="0.276614" stopColor="#FDFDFD" />
          <stop offset="1" stopColor="#A6A6A6" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_797_114129"
          x1="35.6973"
          y1="17.8336"
          x2="37.4638"
          y2="36.0026"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.372" stopColor="#858585" />
          <stop offset="0.453125" stopColor="#9E9E9E" />
          <stop offset="0.77" stopColor="white" />
          <stop offset="0.916" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_797_114129"
          x1="35.75"
          y1="8.75024"
          x2="46.2151"
          y2="19.3064"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.708333" stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#686868" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_797_114129"
          x1="74.2142"
          y1="16.6903"
          x2="53.2891"
          y2="21.8732"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0C0C0" />
          <stop offset="0.364583" stopColor="white" />
          <stop offset="0.677083" stopColor="white" />
          <stop offset="1" stopColor="#979797" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_797_114129"
          x1="62.9054"
          y1="11.8732"
          x2="61.7696"
          y2="56.4465"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.263" stopColor="#6F6F6F" />
          <stop offset="0.5" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_797_114129"
          x1="62.5689"
          y1="5.01928"
          x2="53.8638"
          y2="20.8497"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.708333" stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#686868" />
        </linearGradient>
        <radialGradient
          id="paint19_radial_797_114129"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(47.9599 15.9538) rotate(81.3758) scale(11.6936 6.63294)"
        >
          <stop stopColor="white" />
          <stop offset="0.5" stopColor="#FAFAFA" />
          <stop offset="1" stopColor="#CACACA" />
        </radialGradient>
        <linearGradient
          id="paint20_linear_797_114129"
          x1="10.826"
          y1="68.7862"
          x2="64.432"
          y2="68.5882"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.408" stopColor="#C11010" stopOpacity="0.855" />
          <stop offset="0.889" stopColor="#FF6E6E" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_797_114129"
          x1="72.2537"
          y1="66.7358"
          x2="55.5048"
          y2="67.8912"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.387" stopColor="#B60000" stopOpacity="0.8" />
          <stop offset="0.478" stopColor="#B80404" stopOpacity="0.774" />
          <stop offset="0.582" stopColor="#BF0E0E" stopOpacity="0.699" />
          <stop offset="0.691" stopColor="#CB1F1F" stopOpacity="0.575" />
          <stop offset="0.805" stopColor="#DA3737" stopOpacity="0.4" />
          <stop offset="0.92" stopColor="#EF5555" stopOpacity="0.179" />
          <stop offset="1" stopColor="#6EDFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#FF6E6E" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_797_114129"
          x1="21.0001"
          y1="33.2499"
          x2="40.0001"
          y2="92.4999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.848" stopColor="white" />
          <stop offset="1" stopColor="#AFAFAF" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_797_114129"
          x1="64.9999"
          y1="49.9999"
          x2="90.7499"
          y2="58.7499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#979797" />
        </linearGradient>
      </defs>

      <path
        d="M85.086 68.5161C85.0801 69.0279 84.9411 69.5294 84.6826 69.9712C84.4242 70.413 84.0552 70.78 83.612 71.0361L50.052 90.5101C49.5264 90.8079 48.9653 91.0384 48.382 91.1961C48.1133 91.2667 47.8409 91.3228 47.566 91.3641V44.3861L86.034 27.0381C85.89 40.8001 85.268 54.7361 85.086 68.5161Z"
        fill="url(#paint0_linear_797_114129)"
      />
      <path
        d="M47.566 91.3641C47.22 91.324 46.8776 91.2571 46.542 91.1641C45.9881 91.005 45.4576 90.7736 44.964 90.4761C44.076 89.9441 31.844 82.9821 14.82 73.2861C14.3608 73.0253 13.9758 72.6517 13.7014 72.2006C13.427 71.7494 13.2723 71.2357 13.252 70.7081L11.76 29.3621L47.56 44.3861V91.3641H47.566Z"
        fill="url(#paint1_linear_797_114129)"
      />
      <path
        d="M87.8919 37.9397C87.877 38.3212 87.7605 38.6917 87.5545 39.0131C87.3484 39.3344 87.0603 39.595 86.7199 39.7677L47.7599 59.5177C47.6607 59.4845 47.5649 59.4416 47.4739 59.3897C47.4041 59.3477 47.3373 59.3009 47.2739 59.2497V43.2777L87.9219 24.5737C87.9791 24.6367 88.0319 24.7035 88.0799 24.7737C88.2318 25.0053 88.3352 25.2651 88.3839 25.5377C88.2199 29.6751 88.0559 33.8091 87.8919 37.9397Z"
        fill="url(#paint2_linear_797_114129)"
      />
      <path
        d="M47.5659 59.6161L11.3799 41.6001C10.9267 41.3747 10.5425 41.0314 10.2676 40.6063C9.9928 40.1812 9.83746 39.69 9.81791 39.1841L9.35791 27.1841C9.3922 26.9458 9.51857 26.7304 9.70991 26.5841C9.81794 26.5061 9.9425 26.4541 10.0739 26.4321L47.5439 43.1801L47.5659 59.6161Z"
        fill="url(#paint3_linear_797_114129)"
      />
      <path
        d="M87.6541 24.3238C87.7048 24.427 87.7354 24.539 87.7441 24.6538L47.2701 43.2778L9.83606 26.3418L10.0541 26.1298L10.5401 25.6618L48.6781 11.8098C49.3411 11.5901 50.0356 11.4806 50.7341 11.4858C51.3022 11.4484 51.8726 11.5093 52.4201 11.6658L87.2741 23.9318C87.4379 24.0205 87.5705 24.1573 87.6541 24.3238V24.3238Z"
        fill="url(#paint4_linear_797_114129)"
      />
      <path
        d="M21.42 30.346C21.4716 30.5465 21.4503 30.7587 21.3598 30.9448C21.2693 31.131 21.1155 31.2789 20.926 31.362C20.9026 31.5293 20.8279 31.6852 20.712 31.808C20.6156 31.9 20.4997 31.9691 20.373 32.0103C20.2463 32.0515 20.112 32.0637 19.98 32.046L9.36198 27.276C9.33987 27.1499 9.33516 27.0214 9.34798 26.894C9.36834 26.7163 9.41837 26.5432 9.49598 26.382C9.63994 26.1638 9.82941 25.9793 10.0514 25.8412C10.2734 25.7031 10.5226 25.6147 10.782 25.582L21.42 30.346Z"
        fill="url(#paint5_linear_797_114129)"
      />
      <path
        d="M48.62 43.7263C48.4942 43.978 48.2783 44.1732 48.0153 44.2732C47.7523 44.3732 47.4613 44.3707 47.2 44.2663L32.32 37.5863C32.3 37.1063 32.3 36.6463 32.28 36.1663C32.64 36.0063 33 35.8663 33.36 35.7063L48.08 42.3063C48.3393 42.4238 48.5415 42.639 48.6427 42.905C48.7438 43.171 48.7357 43.4663 48.62 43.7263V43.7263Z"
        fill="url(#paint6_linear_797_114129)"
      />
      <path
        d="M47.568 44.3522C47.3237 44.3517 47.0868 44.2678 46.8966 44.1144C46.7063 43.961 46.574 43.7474 46.5216 43.5087C46.4692 43.2701 46.4997 43.0206 46.6081 42.8016C46.7166 42.5827 46.8964 42.4072 47.118 42.3042L87.142 23.8862C87.5073 23.9594 87.8344 24.1609 88.064 24.4542C88.1925 24.6281 88.2841 24.8263 88.3333 25.0367C88.3825 25.2472 88.3882 25.4655 88.35 25.6782L48.014 44.2542C47.8742 44.3186 47.722 44.352 47.568 44.3522V44.3522Z"
        fill="url(#paint7_linear_797_114129)"
      />
      <path
        d="M47.5659 59.6159C47.3792 59.6058 47.1965 59.5576 47.029 59.4744C46.8615 59.3912 46.7128 59.2747 46.5919 59.1319L46.5599 58.7319L46.4919 43.5179C46.4914 43.2336 46.6034 42.9606 46.8036 42.7586C47.0037 42.5566 47.2756 42.4421 47.5599 42.4399V42.4399C47.8438 42.4399 48.116 42.5523 48.3173 42.7524C48.5185 42.9525 48.6324 43.2241 48.6339 43.5079C48.6526 48.6866 48.6699 53.8646 48.6859 59.0419C48.557 59.2184 48.3886 59.3622 48.1941 59.4619C47.9996 59.5616 47.7845 59.6143 47.5659 59.6159V59.6159Z"
        fill="url(#paint8_linear_797_114129)"
      />
      <path
        d="M32.2781 36.1562L20.6161 31.2002C20.6019 31.1045 20.6095 31.0067 20.6381 30.9142C20.6715 30.8201 20.7244 30.734 20.7934 30.6617C20.8623 30.5893 20.9457 30.5323 21.0381 30.4942L62.1341 15.0942L72.8461 18.8662L32.2781 36.1562Z"
        fill="url(#paint9_linear_797_114129)"
      />
      <path
        d="M27.4539 19.52L38.1159 15.646L76.2659 28.824C76.3485 28.8516 76.4216 28.9022 76.4765 28.9698C76.5314 29.0375 76.5659 29.1194 76.5759 29.206C76.5805 29.308 76.5494 29.4085 76.4879 29.49L63.7319 35.474L27.4539 19.52Z"
        fill="url(#paint10_linear_797_114129)"
      />
      <path
        d="M27.4539 19.52L38.1159 15.646L76.4179 28.836C76.4384 28.9857 76.4337 29.1378 76.4039 29.286C76.3832 29.3863 76.3502 29.4837 76.3059 29.576L63.7319 35.474L27.4539 19.52Z"
        fill="url(#paint11_linear_797_114129)"
      />
      <path
        d="M32.6121 36.3202C32.4998 36.4788 32.4152 36.6553 32.3621 36.8422C32.2954 37.0844 32.2811 37.338 32.3201 37.5862L19.9801 32.0462C19.9611 31.8604 19.9858 31.6728 20.0521 31.4982C20.12 31.3377 20.2162 31.1907 20.3361 31.0642C20.4479 30.9277 20.5755 30.8049 20.7161 30.6982C20.9272 30.5387 21.1658 30.4194 21.4201 30.3462L33.3601 35.7062C33.2156 35.7616 33.0806 35.8392 32.9601 35.9362C32.8222 36.0425 32.7043 36.1725 32.6121 36.3202V36.3202Z"
        fill="white"
      />
      <path
        d="M72.84 18.8459L33.36 35.7059L21.42 30.3459C34.98 25.2659 48.5534 20.1793 62.14 15.0859C65.7 16.3459 69.28 17.5999 72.84 18.8459Z"
        fill="url(#paint12_linear_797_114129)"
      />
      <path
        d="M48.3999 19.7059C47.6739 18.8339 38.1499 7.57794 31.9319 8.53194C30.9819 8.67788 30.0753 9.03013 29.2759 9.56394C27.0459 11.1999 26.8219 14.1479 26.4759 17.4779C25.3459 28.4779 24.3599 29.8919 25.5819 30.7939C27.3819 32.1279 30.0479 29.6179 37.4399 27.9939C40.3622 27.3571 43.3397 27.0074 46.3299 26.9499"
        fill="url(#paint13_linear_797_114129)"
      />
      <path
        d="M25.022 30.0381C25.1716 30.3929 25.3836 30.7181 25.648 30.9981C25.9803 31.3351 26.3697 31.6107 26.798 31.8121C26.8617 31.8494 26.9286 31.8809 26.998 31.9061C27.5393 32.1365 28.1138 32.2796 28.7 32.3301C28.86 32.3521 29.008 32.3621 29.17 32.3721C29.2111 32.3781 29.2529 32.3781 29.294 32.3721C29.494 32.3721 29.706 32.3901 29.908 32.3861C29.9491 32.3921 29.9909 32.3921 30.032 32.3861C30.2 32.3741 30.374 32.3761 30.542 32.3641C31.6365 32.298 32.7259 32.1644 33.804 31.9641C34.236 31.8861 34.67 31.8081 35.09 31.7281C35.69 31.6101 36.358 31.4621 37.11 31.2541C37.362 31.1981 37.632 31.1221 37.91 31.0341C38.8931 30.7397 39.8581 30.3878 40.8 29.9801C41.9081 29.5281 42.9828 28.9981 44.016 28.3941C44.458 28.1661 44.91 27.8921 45.368 27.5941C45.692 27.3941 46.016 27.1741 46.342 26.9581C46.742 26.6961 47.142 26.4081 47.53 26.1081C47.73 25.9761 47.93 25.8201 48.13 25.6641C48.188 25.6261 48.238 25.5741 48.298 25.5261C48.3014 25.5267 48.3049 25.5261 48.3079 25.5242C48.3109 25.5224 48.313 25.5195 48.314 25.5161H48.29C48.2278 25.539 48.1643 25.5583 48.1 25.5741C47.752 25.6721 47.404 25.7561 47.072 25.8321C45.964 26.1221 44.902 26.3601 43.912 26.5621C42.502 26.8461 41.22 27.0601 40.04 27.2201C37.6581 27.5393 35.2483 27.5963 32.854 27.3901C31.5275 27.2074 30.1828 27.1987 28.854 27.3641C28.7636 27.3678 28.6745 27.386 28.59 27.4181C27.4671 27.5725 26.4346 28.1178 25.674 28.9581C25.3992 29.2801 25.1789 29.645 25.022 30.0381V30.0381Z"
        fill="url(#paint14_linear_797_114129)"
      />
      <path
        d="M47.384 19.3802C47.7158 19.7375 47.9243 20.1919 47.9786 20.6765C48.033 21.1611 47.9304 21.6503 47.686 22.0722C46.136 23.6302 38.55 19.0222 38.84 18.2722C38.914 18.0722 39.44 18.2982 42.04 18.5142C45.666 18.8142 46.664 18.5562 47.384 19.3802Z"
        fill="url(#paint15_linear_797_114129)"
      />
      <path
        d="M49.964 20.1061C50.59 19.1601 58.812 6.92208 65.098 7.18408C66.0584 7.22398 66.9985 7.47384 67.852 7.91608C70.252 9.29808 70.798 12.2021 71.504 15.4761C73.84 26.2761 74.974 27.5801 73.862 28.6101C72.214 30.1361 69.292 27.9341 61.766 27.1441C58.7917 26.8331 55.7941 26.8137 52.816 27.0861"
        fill="url(#paint16_linear_797_114129)"
      />
      <path
        d="M74.344 27.7999C74.2364 28.1691 74.0622 28.5155 73.83 28.8219C73.5369 29.1939 73.18 29.5108 72.776 29.7579C72.7198 29.8021 72.6595 29.841 72.596 29.8739C72.0824 30.1595 71.5269 30.3621 70.95 30.4739C70.796 30.5139 70.648 30.5399 70.49 30.5679C70.45 30.5809 70.4081 30.587 70.366 30.5859C70.166 30.6159 69.966 30.6479 69.766 30.6659C69.7258 30.6779 69.684 30.684 69.642 30.6839C69.474 30.6839 69.3 30.7119 69.134 30.7159C68.0382 30.7696 66.9401 30.7556 65.846 30.6739C65.408 30.6439 64.968 30.6139 64.54 30.5799C63.94 30.5299 63.252 30.4559 62.482 30.3339C62.224 30.3059 61.948 30.2579 61.658 30.2039C60.6445 30.0199 59.6427 29.7762 58.658 29.4739C57.5072 29.1469 56.381 28.7385 55.288 28.2519C54.7999 28.0614 54.3219 27.8457 53.856 27.6059C53.512 27.4399 53.166 27.2599 52.818 27.0819C52.392 26.8659 51.97 26.6239 51.544 26.3679C51.344 26.2579 51.122 26.1239 50.908 25.9919C50.8443 25.9564 50.7828 25.917 50.724 25.8739C50.7218 25.8759 50.719 25.877 50.716 25.877C50.7131 25.877 50.7102 25.8759 50.708 25.8739H50.73C50.8 25.8879 50.87 25.9039 50.93 25.9099C51.288 25.9679 51.64 26.0139 51.978 26.0539C53.114 26.2179 54.194 26.3379 55.2 26.4299C56.634 26.5579 57.93 26.6299 59.122 26.6559C61.5244 26.7117 63.9255 26.5024 66.282 26.0319C67.5789 25.7055 68.9127 25.5488 70.25 25.5659C70.34 25.5608 70.4303 25.5689 70.518 25.5899C71.6511 25.6201 72.7373 26.0486 73.586 26.7999C73.8918 27.0894 74.1479 27.4273 74.344 27.7999V27.7999Z"
        fill="url(#paint17_linear_797_114129)"
      />
      <path
        d="M50.582 20.1539C50.3029 20.5539 50.1596 21.0329 50.1733 21.5205C50.1869 22.008 50.3569 22.4783 50.658 22.8619C52.41 24.1879 59.278 18.5659 58.886 17.8619C58.786 17.6799 58.286 17.9719 55.748 18.5499C52.2 19.3539 51.18 19.2379 50.582 20.1539Z"
        fill="url(#paint18_linear_797_114129)"
      />
      <path
        d="M53.5862 24.4878C53.6461 24.8124 53.6392 25.1458 53.5659 25.4677C53.4927 25.7895 53.3546 26.0931 53.1602 26.3598L53.1362 26.3878C52.9142 26.6198 52.5702 26.7878 50.7122 26.9038C50.3122 26.9498 49.8302 26.9758 49.2702 26.9918C48.7502 27.0178 48.3222 27.0278 47.9442 27.0338C46.2642 27.0558 45.8802 26.9458 45.6502 26.7878L45.5822 26.7258C45.3489 26.5067 45.1628 26.2421 45.0356 25.9483C44.9083 25.6546 44.8425 25.3379 44.8422 25.0178C44.7838 24.3526 44.7798 23.6837 44.8302 23.0178C44.8302 22.8598 44.8522 22.6778 44.8562 22.5078C44.9522 20.6118 45.0262 18.9938 45.9842 18.3838C46.0101 18.3597 46.0398 18.3401 46.0722 18.3258C46.3191 18.186 46.6078 18.1392 46.8862 18.1938C47.6109 18.3323 48.3488 18.39 49.0862 18.3658C49.8141 18.3197 50.5364 18.208 51.2442 18.0318C51.4468 17.9879 51.6583 18.0139 51.8442 18.1058C51.8582 18.1178 51.8702 18.1178 51.8842 18.1298C52.9542 18.6478 53.1722 20.3698 53.4062 22.5978C53.4194 22.6536 53.4268 22.7105 53.4282 22.7678C53.5062 23.4498 53.5742 24.0178 53.5862 24.4878Z"
        fill="url(#paint19_radial_797_114129)"
      />
      <path
        d="M31.078 51.6362L36.32 54.0182L36.72 85.7662L30.77 82.2002C30.8727 72.0149 30.9754 61.8269 31.078 51.6362Z"
        fill="url(#paint20_linear_797_114129)"
      />
      <path
        d="M65.954 50.4399L60.834 52.9579C60.65 63.4046 60.4654 73.8493 60.28 84.2919L65.2 81.6339C65.456 71.2366 65.7074 60.8386 65.954 50.4399Z"
        fill="url(#paint21_linear_797_114129)"
      />
      <path
        d="M33.7001 84.0459C29.6601 81.7459 25.6201 79.4259 21.6001 77.1259C21.0668 62.1086 20.5268 47.0819 19.9801 32.0459L32.3201 37.5859C32.7601 53.0619 33.2201 68.5486 33.7001 84.0459Z"
        fill="url(#paint22_linear_797_114129)"
      />
      <path
        d="M76.044 75.4899L62.866 83.0739C63.1566 67.2073 63.4453 51.3406 63.732 35.4739L76.422 29.5219C76.486 29.4339 76.544 29.3979 76.6 29.3979C76.744 29.3979 76.94 29.6319 77.176 30.5979L76.044 75.4899Z"
        fill="url(#paint23_linear_797_114129)"
      />
      <path
        d="M63.502 35.724C63.5827 35.8828 63.6335 36.0549 63.652 36.232C63.6932 36.4932 63.7106 36.7577 63.704 37.022L77.17 30.852C77.184 30.618 77.186 30.416 77.184 30.252C77.1992 30.0158 77.1721 29.7787 77.104 29.552C77.0367 29.3738 76.9308 29.2126 76.794 29.08C76.6847 28.9753 76.5573 28.8913 76.418 28.832L62.818 35.068C62.9408 35.1481 63.0572 35.2377 63.166 35.336C63.2988 35.4458 63.4123 35.5769 63.502 35.724V35.724Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_797_114129"
          x1="75.558"
          y1="86.0601"
          x2="53.678"
          y2="18.9501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CD0000" />
          <stop offset="1" stopColor="#B60000" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_797_114129"
          x1="3.78801"
          y1="72.8861"
          x2="82.382"
          y2="34.8481"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6E6E" />
          <stop offset="1" stopColor="#B60000" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_797_114129"
          x1="73.5879"
          y1="55.2097"
          x2="54.7339"
          y2="13.0517"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CD0000" />
          <stop offset="1" stopColor="#B60000" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_797_114129"
          x1="-2.89409"
          y1="34.0681"
          x2="121.672"
          y2="69.2681"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6E6E" />
          <stop offset="1" stopColor="#B60000" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_797_114129"
          x1="24.5961"
          y1="39.3138"
          x2="92.8121"
          y2="-0.0262414"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E51515" />
          <stop offset="1" stopColor="#B60000" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_797_114129"
          x1="15.396"
          y1="28.71"
          x2="26.562"
          y2="14.544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6E6E" />
          <stop offset="1" stopColor="#B60000" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_797_114129"
          x1="40.438"
          y1="39.9863"
          x2="53.874"
          y2="22.9383"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6E6E" />
          <stop offset="1" stopColor="#B60000" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_797_114129"
          x1="62.998"
          y1="51.4862"
          x2="77.668"
          y2="-5.14577"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6E6E" />
          <stop offset="1" stopColor="#B60000" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_797_114129"
          x1="47.2219"
          y1="33.4999"
          x2="48.3439"
          y2="85.9599"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.099" stopColor="#FF6E6E" />
          <stop offset="0.267" stopColor="#EE5454" />
          <stop offset="0.593" stopColor="#D02727" />
          <stop offset="0.839" stopColor="#BD0B0B" />
          <stop offset="0.975" stopColor="#B60000" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_797_114129"
          x1="8.10406"
          y1="40.9642"
          x2="94.5721"
          y2="5.36823"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.0001" stopColor="#B8B8B8" />
          <stop offset="0.811" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_797_114129"
          x1="23.6699"
          y1="10.162"
          x2="91.7619"
          y2="43.998"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.848" stopColor="#E4E4E4" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_797_114129"
          x1="50.8399"
          y1="7.93"
          x2="64.9999"
          y2="59.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#272727" />
          <stop offset="0.383" stopColor="white" />
          <stop offset="0.707" stopColor="white" stopOpacity="0.397" />
          <stop offset="0.844" stopColor="white" stopOpacity="0.182" />
          <stop offset="0.945" stopColor="white" stopOpacity="0.05" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_797_114129"
          x1="52.872"
          y1="8.31994"
          x2="34.186"
          y2="61.2959"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.299" stopColor="#6A6A6A" />
          <stop offset="0.387" stopColor="white" />
          <stop offset="0.546" stopColor="white" stopOpacity="0.69" />
          <stop offset="0.709" stopColor="white" stopOpacity="0.397" />
          <stop offset="0.845" stopColor="#F9F9F9" stopOpacity="0.182" />
          <stop offset="0.945" stopColor="white" stopOpacity="0.05" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_797_114129"
          x1="24.0787"
          y1="19.0503"
          x2="45.4449"
          y2="21.8993"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#949494" />
          <stop offset="0.276614" stopColor="#FDFDFD" />
          <stop offset="1" stopColor="#A6A6A6" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_797_114129"
          x1="35.6973"
          y1="17.8336"
          x2="37.4638"
          y2="36.0026"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.372" stopColor="#858585" />
          <stop offset="0.453125" stopColor="#9E9E9E" />
          <stop offset="0.77" stopColor="white" />
          <stop offset="0.916" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_797_114129"
          x1="35.75"
          y1="8.75024"
          x2="46.2151"
          y2="19.3064"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.708333" stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#686868" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_797_114129"
          x1="74.2142"
          y1="16.6903"
          x2="53.2891"
          y2="21.8732"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0C0C0" />
          <stop offset="0.364583" stopColor="white" />
          <stop offset="0.677083" stopColor="white" />
          <stop offset="1" stopColor="#979797" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_797_114129"
          x1="62.9054"
          y1="11.8732"
          x2="61.7696"
          y2="56.4465"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.263" stopColor="#6F6F6F" />
          <stop offset="0.5" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_797_114129"
          x1="62.5689"
          y1="5.01928"
          x2="53.8638"
          y2="20.8497"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.708333" stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#686868" />
        </linearGradient>
        <radialGradient
          id="paint19_radial_797_114129"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(47.9599 15.9538) rotate(81.3758) scale(11.6936 6.63294)"
        >
          <stop stopColor="white" />
          <stop offset="0.5" stopColor="#FAFAFA" />
          <stop offset="1" stopColor="#CACACA" />
        </radialGradient>
        <linearGradient
          id="paint20_linear_797_114129"
          x1="10.826"
          y1="68.7862"
          x2="64.432"
          y2="68.5882"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.408" stopColor="#C11010" stopOpacity="0.855" />
          <stop offset="0.889" stopColor="#FF6E6E" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_797_114129"
          x1="72.2537"
          y1="66.7358"
          x2="55.5048"
          y2="67.8912"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.387" stopColor="#B60000" stopOpacity="0.8" />
          <stop offset="0.478" stopColor="#B80404" stopOpacity="0.774" />
          <stop offset="0.582" stopColor="#BF0E0E" stopOpacity="0.699" />
          <stop offset="0.691" stopColor="#CB1F1F" stopOpacity="0.575" />
          <stop offset="0.805" stopColor="#DA3737" stopOpacity="0.4" />
          <stop offset="0.92" stopColor="#EF5555" stopOpacity="0.179" />
          <stop offset="1" stopColor="#6EDFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#FF6E6E" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_797_114129"
          x1="21.0001"
          y1="33.2499"
          x2="40.0001"
          y2="92.4999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.848" stopColor="white" />
          <stop offset="1" stopColor="#AFAFAF" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_797_114129"
          x1="64.9999"
          y1="49.9999"
          x2="90.7499"
          y2="58.7499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#979797" />
        </linearGradient>
      </defs>
    </Icon>
  );
});

export default GiftColorIcon;
