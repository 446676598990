import {
  Box,
  ColorMode,
  Flex,
  Link,
  PrimaryButton,
  PrimaryHeading,
  PrimaryText,
  Text,
  color,
  spacing,
  typography,
} from 'deepstash-ui';
import Image from 'next/image';
import { NextRouter } from 'next/router';
import React from 'react';
import { convertNodeToElement } from 'react-html-parser';
import { PAYWALL_INCLUDED_LIST } from 'src/components/paywall/WhatsIncludedDropdown';
import { GAP_SIZE_REM, SQUARE_WIDTH, getRemWidthFromColumns } from './global';
import { ProShieldSvg } from 'theme/icons/ProShieldSvg';

const css2obj = (css: string) => {
  const r = /(?<=^|;)\s*([^:]+)\s*:\s*([^;]+)\s*/g;
  const o: Record<string, string> = {};
  css.replace(r, (_m, p, v) => (o[p] = v));
  return o;
};

export const ideaHasContent = (content: string) =>
  stripHTMLTags(content).trim() !== '';

export const trimIdeaNewLines = (content: string) =>
  content.replace(
    /<p( class=\\".*\\")?>((<br\/?>)|(<em>(<br\/?>)?<\/em>))?<\/p>/g,
    '',
  );

const handleAndTag = (content: string) => {
  return content.replace(/&amp;/g, ' ');
};

const handleNbspTag = (content: string) => {
  return content.replace(/&nbsp;/g, ' ');
};

export const stripHTMLTags = (content: string) => {
  const regex = /(<([^>]+)>)/gi;
  let curatedContent = handleAndTag(content);
  curatedContent = handleNbspTag(curatedContent);
  curatedContent = String(curatedContent).replace(regex, '');
  return curatedContent;
};

/**
 * transform the textHtml from string to html code with the correct style
 * ex:
 * <a class='ds-u'>@teni</a> is&amp;drinking <a class='ds-topic'>#beer</a>
 *
 * it will be transformed to:
 * (at)teni is drinking #beer
 *
 * where (at)teni & #beer will be clickable to the userPage & topicPage
 */
export const completeDeepstashHtml = (
  textHtml: string,
  colorMode: ColorMode,
) => {
  // retrieve the anchor urls from its content
  const anchorsUrl = textHtml
    .split('</a>')
    .map(anchor => anchor.split('>')[1])
    .filter(Boolean);

  // replace the user and topic anchor tags with the correct props
  // in order to be able to navigate to their tag
  let htmlToTextWithProps = textHtml
    .split('</a>')
    .map((html, index) => {
      if (html.match('ds-u')) {
        return html.replace(
          `ds-u'`,
          `ds-u-${colorMode}' href='/u/${anchorsUrl[index].slice(
            1,
          )}/' target='_blank' `,
        );
      } else if (html.match('ds-topic')) {
        return html.replace(
          `ds-topic'`,
          `ds-topic-${colorMode}' href='/topic/${anchorsUrl[index].slice(
            1,
          )}/' target='_blank' `,
        );
      } else if (html.match('ds-internal-link')) {
        return html.replace(
          `ds-internal-link'`,
          `ds-internal-link-${colorMode}' href='${anchorsUrl[index]}/' target='_blank' `,
        );
      } else if (html.match('ds-dynamic-link')) {
        return html.replace(
          `ds-dynamic-link'`,
          `ds-dynamic-link-${colorMode}' href='${anchorsUrl[index]}/' target='_blank' `,
        );
      } else if (html.match('ds-web-link')) {
        return html.replace(
          `ds-web-link'`,
          `ds-web-link-${colorMode}' href='${anchorsUrl[index]}/' target='_blank' `,
        );
      }
      return html;
    })
    .join('</a>');

  // check for the &amp; tags and replace it with empty space
  if (htmlToTextWithProps.match('&amp;')) {
    htmlToTextWithProps = htmlToTextWithProps.replace('&amp;', ' ');
  }

  // return decoded htmlString
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: htmlToTextWithProps,
      }}
    />
  );
};

// creates a working link for ReactHtmlParser transformer
export const renderLink = ({
  href,
  data,
  className,
  target,
  rel,
}: {
  href: string;
  data: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  className: string;
  target?: string;
  rel: string;
}) => {
  return (
    <span>
      <Link
        className={className}
        rel={rel}
        href={href}
        target={target}
        onClick={e => {
          // Prevent the click action to trigger other events
          e.stopPropagation();
        }}
        color="#551A8B"
      >
        {data}
      </Link>
    </span>
  );
};

export const makeTransformHtmlParserBookPageDescription = () => {
  const transform = (node: any, index: number) => {
    if (node.attribs) node.attribs.style = '';
    if (node === undefined) {
      return null;
    }
    if (node.type === 'tag' && node.name === 'html') {
      return null;
    }

    if (node.type === 'tag' && node.name === 'gwmw') {
      return null;
    }

    if (node.type === 'tag' && node.name === 'button') {
      return null;
    }
    if (node.type === 'tag' && node.name === 'b') {
      node.name = 'span';
    }

    if (node.type === 'ul' || node.type === 'ol' || node.type === 'li') {
      return null;
    }

    if (node.type === 'tag' && node.name === 'li') {
      return convertNodeToElement(node, index, transform);
    }

    return undefined;
  };
  return transform;
};

export const makeTransformHtmlParserStoryTOC = ({
  colorMode,
}: {
  colorMode: ColorMode;
}) => {
  const transform = (node: any, index: number) => {
    if (node.attribs) node.attribs.style = '';
    if (node === undefined) {
      return null;
    }

    if (node.type === 'tag' && node.name === 'html') {
      return null;
    }

    if (node.type === 'tag' && node.name === 'gwmw') {
      return null;
    }

    if (node.type === 'tag' && node.name === 'button') {
      return null;
    }

    if (node.type === 'tag' && (node.name === 'ul' || node.name === 'ol')) {
      node.attribs.style = `color: ${color[colorMode].textSecondary};margin-left: 16px;`;
      return convertNodeToElement(node, index, transform);
    }

    if (node.type === 'tag' && node.name === 'li') {
      node.attribs.style = `margin-bottom: 0.5em;text-decoration: none;`;
      return convertNodeToElement(node, index, transform);
    }

    // Stylize the link
    if (node.type === 'tag' && node.name === 'a') {
      node.attribs.style = `color: ${color[colorMode].primary.default};`;
      return convertNodeToElement(node, index, transform);
    }

    return undefined;
  };
  return transform;
};

export const makeTransformHtmlParserStoryContent = ({
  isMobileView,
  colorMode,
  router,
}: {
  isMobileView?: boolean;
  colorMode: ColorMode;
  router: NextRouter;
}) => {
  const transform = (node: any, index: number) => {
    if (node.attribs && node.name !== 'img') node.attribs.style = '';
    if (node === undefined) {
      return null;
    }

    if (node.type === 'tag' && node.name === 'html') {
      return null;
    }

    if (node.type === 'tag' && node.name === 'gwmw') {
      return null;
    }

    if (node.type === 'tag' && node.name === 'button') {
      return (
        <Link href={node.parent?.attribs?.href}>
          <PrimaryButton
            my={GAP_SIZE_REM}
            minHeight={spacing.toRem((isMobileView ? 1.5 : 2) * SQUARE_WIDTH)}
            {...(isMobileView
              ? typography.primaryTextFonts.medium.semiBold
              : typography.primaryTextFonts.large.semiBold)}
          >
            <Text>{node.children[0]?.data}</Text>
          </PrimaryButton>
        </Link>
      );
    }

    if (node.type === 'tag' && node.name === 'p') {
      node.attribs.style = `margin-block-start: 1em; margin-block-end: 1em;`;
      return convertNodeToElement(node, index, transform);
    }

    if (node.type === 'tag' && node.name === 'h2') {
      node.attribs.style = `margin-top: 3em; font-size: 2em; line-height: 1.125em`;
      return convertNodeToElement(node, index, transform);
    }

    if (node.type === 'tag' && node.name === 'h3') {
      node.attribs.style = `margin-top: 2em; font-size: 1.5em; line-height: 1.125em`;
      return convertNodeToElement(node, index, transform);
    }

    if (node.type === 'tag' && (node.name === 'ul' || node.name === 'ol')) {
      node.attribs.style = `margin-left: 1.5em;`;
      return convertNodeToElement(node, index, transform);
    }

    if (node.type === 'tag' && node.name === 'li') {
      node.attribs.style = `margin-bottom: 1.5em;`;
      return convertNodeToElement(node, index, transform);
    }

    // Stylize the link
    if (node.type === 'tag' && node.name === 'a') {
      node.attribs.style = `color: ${color[colorMode].primary.default};`;
      return convertNodeToElement(node, index, transform);
    }

    // Stylize the image
    if (node.type === 'tag' && node.name === 'img') {
      const styleProps = css2obj(node.attribs.style);
      return (
        <Flex
          width={'100%'}
          alignItems="center"
          justifyContent="center"
          height={'auto'}
        >
          <Image
            src={node.attribs.src}
            // For SEO & accessibility purposes,
            // our images need to have descriptive alt tags.
            alt={node.attribs.alt}
            width={styleProps.width ?? '800px'}
            height={styleProps.height ?? '400px'}
            objectFit={'contain'}
          />
        </Flex>
      );
    }

    // Insert a Try Deepstash ad
    if (
      node.type === 'tag' &&
      node.name === 'div' &&
      node.attribs?.class === 'try-deepstash-pro-ad-box'
    ) {
      return (
        <Flex
          w="100%"
          flexDirection={isMobileView ? 'column' : 'row'}
          alignItems={isMobileView ? 'flex-start' : 'flex-end'}
          my={GAP_SIZE_REM}
          padding={GAP_SIZE_REM}
          gridGap={GAP_SIZE_REM}
          backgroundColor={color[colorMode].surface}
          background={`linear-gradient(180deg, ${color[colorMode].top} 0%, ${color[colorMode].background} 100%)`}
          color={color[colorMode].text}
          onClick={() => router?.push('/get-pro')}
          cursor="pointer"
          position="relative"
          boxShadow="0px 6px 16px rgba(0, 0, 0, 0.1); 0px 8px 4px rgba(0, 0, 0, 0.05);"
          _hover={{
            transform: 'scale(1.01)',
            boxShadow:
              '0px 6px 16px rgba(0, 0, 0, 0.2); 0px 8px 4px rgba(0, 0, 0, 0.1);',
          }}
          transition="all 0.3s cubic-bezier(.11,.57,.44,.8)"
          borderRadius="1rem"
          overflow="hidden"
        >
          <Flex alignItems={'center'} flexGrow={1} gridGap={spacing.M.rem}>
            <Box
              position={isMobileView ? 'absolute' : 'relative'}
              w={spacing.toRem(192)}
              right={isMobileView ? spacing.toRem(-72) : 'unset'}
              top={isMobileView ? spacing.toRem(16) : 'unset'}
            >
              <ProShieldSvg width={192} height={192} />
            </Box>
            <Flex flexDirection="column" gridGap={spacing.M.rem}>
              <PrimaryHeading
                size="h3"
                textSize="l"
                type="bold"
                textAlign={'left'}
                color={color[colorMode].text}
              >
                {'Read like a Pro'}
              </PrimaryHeading>
              <Flex maxW={getRemWidthFromColumns(4)} overflow="hidden">
                <Flex
                  flexDir="column"
                  gridGap={spacing.toRem(8)}
                  width="230px"
                  minWidth="144px"
                >
                  {PAYWALL_INCLUDED_LIST.map((included, index) => (
                    <Flex
                      gridGap={spacing.toRem(8)}
                      alignItems="center"
                      key={`paywall-included-${index}`}
                    >
                      <Flex
                        alignItems="center"
                        borderRadius="full"
                        boxSize={spacing.toRem(32)}
                        p={spacing.toRem(4)}
                      >
                        {included.renderIcon(color[colorMode].text)}
                      </Flex>
                      <PrimaryText
                        {...typography.primaryTextFonts.medium.regular}
                      >
                        {included.text}
                      </PrimaryText>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Link href={'/get-pro'}>
            <PrimaryButton
              minHeight={spacing.toRem((isMobileView ? 1.5 : 2) * SQUARE_WIDTH)}
              {...(isMobileView
                ? typography.primaryTextFonts.medium.semiBold
                : typography.primaryTextFonts.large.semiBold)}
            >
              <Text>Try Pro for free</Text>
            </PrimaryButton>
          </Link>
        </Flex>
      );
    }

    return undefined;
  };
  return transform;
};
