import {
  color,
  Dropdown,
  Flex,
  HeadphonesSvg,
  HelpSvg,
  LibrarySvg,
  PrimaryText,
  spacing,
  StashedSvg,
  useColorMode,
} from 'deepstash-ui';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import { Analytics, Events } from 'src/services/analytics';

type PaywallIncludedType = {
  renderIcon: (color: string) => React.ReactElement;
  text: React.ReactChild;
};

export const PAYWALL_INCLUDED_LIST: PaywallIncludedType[] = [
  {
    renderIcon: color => <StashedSvg fill={color} />,
    text: (
      <>
        Save <b>unlimited ideas</b>
      </>
    ),
  },
  {
    renderIcon: color => <HeadphonesSvg color={color} />,
    text: (
      <>
        <b>Listen</b> to ideas
      </>
    ),
  },
  {
    renderIcon: color => <LibrarySvg width={24} height={24} fill={color} />,
    text: (
      <>
        Start <b> unlimited journeys</b>
      </>
    ),
  },
];

const WhatsIncludedDropdown: React.FC = () => {
  const { colorMode } = useColorMode();
  const router = useRouter();

  const onWhatsIncludedClick = () => {
    Analytics.logEvent({
      eventName: Events.payments.tapEventPaywall,
      properties: {
        url: router.asPath,
        type: 'web-see-whats-included',
      },
      platforms: ['amplitude'],
    });
  };

  const dropdownButtonRenderer = useCallback(
    () => (
      <Flex gridGap={spacing.toRem(4)} alignItems="center">
        <HelpSvg
          fill={color[colorMode].pro.primary}
          boxSize={spacing.toRem(20)}
        />
        <PrimaryText
          size="m"
          type="semiBold"
          color={color[colorMode].pro.primary}
        >
          What's included
        </PrimaryText>
      </Flex>
    ),
    [colorMode],
  );

  return (
    <Dropdown
      wrapperStyle={{
        zIndex: 1000,
        p: 0,
      }}
      menuStyle={{
        p: 0,
        boxShadow:
          '0px 6px 14px -6px rgba(0, 0, 0, 0.12), 0px 10px 32px -4px rgba(0, 0, 0, 0.1)',
      }}
      dropdownButtonRenderer={dropdownButtonRenderer}
      onClick={onWhatsIncludedClick}
      wrapperButtonStyle={{
        bgColor: 'none',
        _hover: {
          textDecoration: `underline ${color[colorMode].pro.primary}`,
          cursor: 'pointer',
        },
        _active: {
          bgColor: 'none',
        },
      }}
    >
      <Flex
        flexDir="column"
        p={spacing.toRem(16)}
        borderRadius={spacing.toRem(16)}
        bgColor={color[colorMode].top}
        boxShadow="0px 6px 14px -6px rgba(0, 0, 0, 0.12), 0px 10px 32px -4px rgba(0, 0, 0, 0.1)"
        gridGap={spacing.toRem(8)}
      >
        {PAYWALL_INCLUDED_LIST.map((included, index) => (
          <Flex
            gridGap={spacing.toRem(8)}
            alignItems="center"
            key={`paywall-included-${index}`}
          >
            <Flex
              alignItems="center"
              bgColor={color[colorMode].pro.primary}
              borderRadius="full"
              boxSize={spacing.toRem(24)}
              p={spacing.toRem(4)}
            >
              {included.renderIcon(color.light.text)}
            </Flex>
            <PrimaryText color={color[colorMode].text}>
              {included.text}
            </PrimaryText>
          </Flex>
        ))}
      </Flex>
    </Dropdown>
  );
};

export default WhatsIncludedDropdown;
