import {
  deleteArticle,
  getArticleByInsightId,
  publishArticle,
} from 'api/Article';
import { followHashtag, unfollowHashtag } from 'api/Hashtag';
import {
  deleteInsight as deleteInsightApiCall,
  removeInsight,
  stashIdea as stashIdeaApi,
} from 'api/Insight';
import { followUser, unfollowUser } from 'api/Profile';
import {
  createStash as createStashRequest,
  deleteStash as deleteStashRequest,
  updateStash as updateStashRequest,
} from 'api/Stash';
import { UserStash } from 'types/models';
import { ideaHasContent } from 'utils/html-utils';
import { AsyncAction } from '../types/actions.types';
import {
  ActivityProviderHiddenAction,
  CreateStashArgs,
  DeleteIdeaArgs,
  DeleteStashArgs,
  PublishSourceArgs,
  ToggleFollowHashtagArgs,
  ToggleFollowUserArgs,
  ToggleIdeaStashArgs,
  UpdateStashArgs,
} from './ActivityProviderTypes';

export const deleteIdea: AsyncAction<
  ActivityProviderHiddenAction,
  DeleteIdeaArgs
> = async (dispatch, { codeSource, ideaId }) => {
  const article = await getArticleByInsightId(ideaId);

  //This source is not a draft since this action is only for published sources
  //If this is the last idea then we should delete the whole article

  if (article.blocks?.length === 1) {
    deleteArticle(article.id);
    // Also delete the ideas
    if (ideaId && ideaId > 0) deleteInsightApiCall(ideaId, codeSource);
    dispatch({
      type: 'delete-idea/success',
      payload: {
        sourceId: article.id,
        ideaId,
      },
    });
    return;
  }

  //Delete just the idea on the backend
  if (ideaId && ideaId > 0) deleteInsightApiCall(ideaId, codeSource);
  dispatch({
    type: 'delete-idea/success',
    payload: {
      ideaId,
    },
  });
};

export const publishSource: AsyncAction<
  ActivityProviderHiddenAction,
  PublishSourceArgs
> = async (
  dispatch,
  { ideas, sourceId, stashId, hashtags, context, onFailure, onSuccess },
) => {
  try {
    //First delete the unfinished ideas
    const unfinishedIdeas = ideas.filter(idea => !ideaHasContent(idea.content));
    const deleteRequests = unfinishedIdeas.map(idea =>
      deleteIdea(dispatch, {
        codeSource: 'publish-draft-dropdown',
        ideaId: idea.id,
      }),
    );
    //Make all the delete api calls
    await Promise.all(deleteRequests);

    await publishArticle(sourceId, stashId, hashtags, context);
    onSuccess?.();
    dispatch({
      type: 'publish-source/success',
      payload: {
        ideasIds: ideas.map(idea => idea.id),
        sourceId,
        stashId,
      },
    });
  } catch (e) {
    onFailure?.();
  }
};

export const toggleIdeaStash: AsyncAction<
  ActivityProviderHiddenAction,
  ToggleIdeaStashArgs
> = (dispatch, { ideaId, sourceId, stashId, onSuccess }) => {
  if (stashId) {
    stashIdeaApi(ideaId, stashId !== -1 ? stashId : undefined).then(res => {
      onSuccess?.();
      dispatch({
        type: 'toggle-idea-stash/success',
        payload: {
          allIdeasStashed: res.save_all,
          ideaId,
          sourceId,
          stashId,
        },
      });
    });
  } else {
    removeInsight(ideaId);
    onSuccess?.();
    dispatch({
      type: 'toggle-idea-stash/success',
      payload: {
        ideaId,
        sourceId,
      },
    });
  }
};

export const toggleFollowHashtag: AsyncAction<
  ActivityProviderHiddenAction,
  ToggleFollowHashtagArgs
> = (dispatch, { hashtag, isFollowed, onFinish, onSuccess, onFailure }) => {
  if (isFollowed) {
    unfollowHashtag(hashtag)
      .then(_ => {
        dispatch({
          type: 'toggle-follow-hashtag',
          payload: {
            hashtag,
          },
        });
        onSuccess?.();
      })
      .catch(_ => {
        onFailure?.();
      })
      .finally(() => {
        onFinish?.();
      });
  } else {
    followHashtag(hashtag)
      .then(_ => {
        dispatch({
          type: 'toggle-follow-hashtag',
          payload: {
            hashtag,
          },
        });
        onSuccess?.();
      })
      .catch(_ => {
        onFailure?.();
      })
      .finally(() => {
        onFinish?.();
      });
  }
};

export const toggleFollowUser: AsyncAction<
  ActivityProviderHiddenAction,
  ToggleFollowUserArgs
> = (dispatch, { userId, isFollowed, onFinish, onSuccess, onFailure }) => {
  if (isFollowed) {
    unfollowUser(userId)
      .then(_ => {
        dispatch({
          type: 'toggle-follow-user',
          payload: {
            userId,
          },
        });
        onSuccess?.();
      })
      .catch(_ => {
        onFailure?.();
      })
      .finally(() => {
        onFinish?.();
      });
  } else {
    followUser(userId)
      .then(_ => {
        dispatch({
          type: 'toggle-follow-user',
          payload: {
            userId,
          },
        });
        onSuccess?.();
      })
      .catch(_ => {
        onFailure?.();
      })
      .finally(() => {
        onFinish?.();
      });
  }
};

// [Like functionality]
// export const toggleLikeIdea: AsyncAction<
//   ActivityProviderHiddenAction,
//   ToggleLikeIdeaArgs
// > = (dispatch, { ideaId, isLiked, onFinish, onSuccess, onFailure }) => {
//   if (isLiked) {
//     unlikeIdea(ideaId)
//       .then(_ => {
//         dispatch({
//           type: 'toggle-like-idea',
//           payload: {
//             ideaId,
//           },
//         });
//         onSuccess?.();
//       })
//       .catch(_ => {
//         onFailure?.();
//       })
//       .finally(() => {
//         onFinish?.();
//       });
//   } else {
//     likeIdea(ideaId)
//       .then(_ => {
//         dispatch({
//           type: 'toggle-like-idea',
//           payload: {
//             ideaId,
//           },
//         });
//         onSuccess?.();
//       })
//       .catch(_ => {
//         onFailure?.();
//       })
//       .finally(() => {
//         onFinish?.();
//       });
//   }
// };

export const createStash: AsyncAction<
  ActivityProviderHiddenAction,
  CreateStashArgs,
  Promise<UserStash>
> = (dispatch, { name, emoji, onSuccess }) => {
  return createStashRequest(name, emoji).then(stashResponse => {
    const { id, name, emoji } = stashResponse;

    const newStash: UserStash = {
      id,
      name,
      emoji,
      ideaCount: 0,
    };
    dispatch({
      type: 'create-stash-success',
      payload: {
        stash: newStash,
      },
    });

    onSuccess?.(id);
    return newStash;
  });
};

export const updateStash: AsyncAction<
  ActivityProviderHiddenAction,
  UpdateStashArgs
> = (dispatch, { id, name, emoji, onSuccess }) => {
  updateStashRequest(id, { name, emoji }).then(stashResponse => {
    const { id, name, emoji, stash_ideas } = stashResponse;

    const updatedStash: UserStash = {
      id,
      name,
      emoji,
      ideaCount: stash_ideas?.length ?? 0,
    };

    dispatch({
      type: 'update-stash-success',
      payload: {
        stash: updatedStash,
      },
    });
    onSuccess?.(id);
  });
};

export const deleteStash: AsyncAction<
  ActivityProviderHiddenAction,
  DeleteStashArgs
> = (dispatch, { id, onSuccess }) => {
  deleteStashRequest(id).then(() => {
    dispatch({
      type: 'delete-stash-success',
      payload: {
        id,
      },
    });
    onSuccess?.();
  });
};
