import useRouter from 'hooks/useRouter';

/**
 * hook that return the first subdirectory of the URL, which means the current location of the app
 */
const useLocation = () => {
  const router = useRouter();
  const appLocation = router.asPath.split('/')[1];
  if (appLocation.includes('?')) {
    //Split again to get the app location without the query string
    return appLocation.split('?')[0];
  } else {
    return appLocation;
  }
};

export default useLocation;
