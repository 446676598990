import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import {
  NsiActivityProviderContextType,
  NsiActivityProviderReducer,
  NsiActivityProviderState,
} from './NsiActivityProviderTypes';
import nsiActivityReducer from './NsiActivityReducer';
import usePrevious from 'hooks/usePrevious';
import * as Sentry from '@sentry/nextjs';
import { Severity } from '@sentry/nextjs';
import { getObjectDiff } from 'utils/global';
import useLocation from 'hooks/useLocation';

const INITIAL_STATE: NsiActivityProviderState = {
  stash: { stashedIdeas: {}, stashedSources: [] },
  reads: { sessionReads: new Set<number>(), totalReads: 0 },
};

export const NsiActivityContext = createContext<NsiActivityProviderContextType>(
  {
    dispatch: () => {
      return;
    },
    state: INITIAL_STATE,
    extra: {
      getCanUseNsiActivity: () => {
        return false;
      },
    },
  },
);

NsiActivityContext.displayName = 'NsiActivityContext';

/**
 * NSI activity tracking will work only on these pages.
 * `canUseNsiActivity` will return true only for these pages
 */
const WHITELISTED_PAGES = ['article', 'idea', 'playground'];
export const SESSION_STORAGE_STASH_KEY = 'nsi_stash';
export const SESSION_STORAGE_READ_KEY = 'nsi_read';
export const SESSION_STORAGE_TIMER_KEY = 'nsi_timer';

const NsiActivityProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer<NsiActivityProviderReducer>(
    nsiActivityReducer,
    INITIAL_STATE,
  );
  const location = useLocation();
  const initialRender = useRef(true);

  const prevNsiActivity = usePrevious(state);
  const getCanUseNsiActivity = useCallback(
    () => WHITELISTED_PAGES.includes(location),
    [location],
  );
  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
      extra: {
        getCanUseNsiActivity,
      },
    }),
    [state, dispatch, getCanUseNsiActivity],
  );

  //Logs
  useEffect(() => {
    //Log the differences
    if (!prevNsiActivity) {
      Sentry.addBreadcrumb({
        data: state,
        category: 'nsi-activity',
        level: Severity.Log,
        message: 'Initial NSI activity',
      });
      return;
    }

    const diff = getObjectDiff(state, prevNsiActivity);

    Sentry.addBreadcrumb({
      data: diff,
      category: 'nsi-activity',
      level: Severity.Log,
      message: 'NSI activity updated',
    });
  }, [state]);

  // Write data to session storage
  useEffect(() => {
    if (!getCanUseNsiActivity()) {
      // Don't write to the session storage if we are not on a whitelisted page
      return;
    }

    if (!initialRender.current) {
      sessionStorage.setItem(
        SESSION_STORAGE_STASH_KEY,
        JSON.stringify(state.stash),
      );
    }
  }, [state.stash]);

  useEffect(() => {
    if (!getCanUseNsiActivity()) {
      // Don't write to the session storage if we are not on a whitelisted page
      return;
    }

    if (!initialRender.current) {
      sessionStorage.setItem(
        SESSION_STORAGE_READ_KEY,
        JSON.stringify({
          totalReads: state.reads.totalReads,
          sessionReads: [...state.reads.sessionReads],
        }),
      );
    }
  }, [state.reads]);

  // Init state from session storage
  useEffect(() => {
    // Allow subsequent calls to the write use effects to work now
    initialRender.current = false;

    const initStashes = sessionStorage.getItem(SESSION_STORAGE_STASH_KEY);
    const initReads = sessionStorage.getItem(SESSION_STORAGE_READ_KEY);
    dispatch({
      type: 'init-state',
      payload: {
        stash: initStashes
          ? JSON.parse(initStashes)
          : { stashedIdeas: {}, stashedSources: [] },
        reads: initReads
          ? JSON.parse(initReads)
          : { sessionReads: [], totalReads: 0 },
      },
    });
  }, []);

  return (
    <NsiActivityContext.Provider value={contextValue}>
      {children}
    </NsiActivityContext.Provider>
  );
};

export default NsiActivityProvider;
