import * as AuthService from 'api/Auth.ts';
import { fetchCurrent } from 'api/Profile';
import cookie from 'cookie';
import { DeepstashUIProvider } from 'deepstash-ui';
// This imports the CSS animations for components in DS-UI
import 'deepstash-ui/lib/style/index.css';
//Polyfill IO
import 'intersection-observer';
import jsCookie from 'js-cookie';
import Router from 'next/router';
import NProgress from 'nprogress';
// The styling for the progress bar
import 'nprogress/nprogress.css';
import React from 'react';
import AllProviders from 'src/providers/AllProviders';
import { Analytics, Events } from 'src/services/analytics';
import TrackingPlatforms from 'utils/analytics/TrackingPlatforms';
import * as gtag from 'utils/analytics/gtag';
import { DEFAULT_COOKIE_EXPIRATION_DATE } from 'utils/constants';
import PUBLIC_FILES from 'utils/publicFiles.json';
import '../global.css';
import useLastLocation from 'hooks/useLastLocation';
import useUtmCampaign from 'hooks/useUtmCampaign';

Router.events.on('routeChangeComplete', url => gtag.pageview(url));

const App = ({
  Component,
  pageProps,
  err,
  userId,
  token,
  refreshToken,
  profile,
  consent,
  reactions,
  userAgent,
}) => {
  const modifiedPageProps = { ...pageProps, err };

  // Progress bar for loading
  NProgress.configure({ showSpinner: false });
  Router.events.on('routeChangeStart', () => NProgress.start());
  Router.events.on('routeChangeComplete', () => NProgress.done());
  Router.events.on('routeChangeError', () => NProgress.done());

  useLastLocation();
  useUtmCampaign();

  React.useEffect(() => {
    if (profile) {
      Analytics.setUserIdAndProperties({ profile });
    }
    // The refreshToken is defined only when the refreshAuthToken function
    // has been called in getInitialProps and we have a new auth token.
    if (refreshToken) {
      Analytics.logEvent({
        eventName: Events.configuration.refreshAuthenticationToken,
        platforms: ['amplitude'],
      });
      jsCookie.set('token', token, {
        expires: DEFAULT_COOKIE_EXPIRATION_DATE,
        sameSite: 'lax',
        secure:
          process.env.NODE_ENV === 'production' &&
          window.location.hostname !== 'localhost',
      });
      jsCookie.set('refresh_token', refreshToken, {
        expires: DEFAULT_COOKIE_EXPIRATION_DATE + 1,
        sameSite: 'lax',
        secure:
          process.env.NODE_ENV === 'production' &&
          window.location.hostname !== 'localhost',
      });
      // We reload the page to prevent API calls using the old
      // token triggered by useEffects in children components.
      Router.reload();
    }
  }, []);

  // Initiliaze Amplitude and Experiments(Feature Flags)
  React.useEffect(() => {
    Analytics.initAmplitude();
    // Analytics.initializeExperiments();
  }, []);

  return refreshToken ? null : (
    <DeepstashUIProvider
      initialColorMode={pageProps.colorMode}
      userAgent={userAgent}
      breakpointsVersion={2}
    >
      <TrackingPlatforms />
      <AllProviders
        token={token}
        userId={userId}
        profile={profile}
        cookieAgreement={consent}
        reactions={reactions}
      >
        <Component {...modifiedPageProps} />
      </AllProviders>
    </DeepstashUIProvider>
  );
};

App.getInitialProps = async ({ Component, ctx }) => {
  let cookies = {};
  let userId;
  let token;
  let refreshToken = undefined;
  let profile;
  let consent;
  let userAgent;
  try {
    // Don't run GetInitialProps for the files in public
    // Substring the url to get only the file name (remove the leading /)
    if (!PUBLIC_FILES.routes.includes(ctx.req.url.substring(1))) {
      if (ctx.req) {
        // ctx.req exists server-side only
        if (typeof window === 'undefined' && ctx.req.headers.cookie) {
          cookies = cookie.parse(ctx.req.headers.cookie);
        }
      }

      userId = cookies.userId ? parseInt(cookies.userId) : undefined;
      token = cookies.token ?? undefined;
      if (token) {
        try {
          profile = token ? await fetchCurrent(token, false) : null;
        } catch (e) {
          // Cannot fetch the profile using the current token
          // Try to get a new token using the refresh token
          try {
            refreshToken = cookies.refresh_token;
            const loginResponse = await AuthService.refreshAuthToken({
              refreshToken,
            });
            if (loginResponse?.access_token) {
              token = loginResponse.access_token;
              refreshToken = loginResponse.refresh_token;
              profile = await fetchCurrent(token, false);
            }
          } catch (e) {
            // Cannot refresh the token
            token = undefined;
            refreshToken = undefined;
            profile = null;
          }
        }
      } else {
        // No token
        profile = null;
      }
      consent = !!cookies.cookieConsent;

      if (ctx.req && ctx.req.headers['user-agent']) {
        //Init the device detector
        userAgent = ctx.req.headers['user-agent'];
      }
    }
  } catch (e) {
    // Sentry.captureMessage(
    //   `Error with token: ${token} : ${JSON.stringify(e)}`,
    //   'debug',
    // );

    token = undefined;
    profile = undefined;
    console.log('check 500 ', e);
  }

  // We update the ctx with the refreshed tokens so the children
  // components also have them.
  if (token && refreshToken && ctx.req?.headers?.cookie) {
    ctx.req.headers.cookie = ctx.req.headers.cookie.replace(
      'token=' + cookies.token,
      'token=' + token,
    );
    ctx.req.headers.cookie = ctx.req.headers.cookie.replace(
      'refresh_token=' + cookies.refresh_token,
      'refresh_token=' + refreshToken,
    );
  }

  return {
    pageProps: {
      // Call page-level getInitialProps
      ...(Component.getInitialProps
        ? await Component.getInitialProps(ctx)
        : {}),
      colorMode: cookies.colorMode,
    },
    userId: isNaN(userId) ? undefined : userId,
    token,
    refreshToken,
    profile,
    consent,
    cookies: ctx?.req?.headers?.cookie ?? '',
    userAgent,
  };
};

export default App;
