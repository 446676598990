import {
  CurrentProfileApiResponse,
  ProfileApiResponse,
  ReactionApiResponse,
  ReceivedReactionApiResponse,
  SaleApiResponse,
  UserDataApi,
} from 'api/api.types';
import { UserActivity } from 'types';
import {
  Reaction,
  Sale,
  UserData,
  UserProfile,
  UserProfileCommonFields,
} from 'types/models';
import { Normalizer } from './types';

export const normalizeProfile: Normalizer<
  CurrentProfileApiResponse,
  UserProfile
> = profileFromApi => {
  return {
    firstName: profileFromApi.first_name,
    lastName: profileFromApi.last_name,
    id: profileFromApi.id,
    email: profileFromApi.email,
    bio: profileFromApi.bio ?? undefined,
    username: profileFromApi.username,
    location: profileFromApi.location ?? undefined,
    createdAt: profileFromApi.created_at,
    emailSubscribed: {
      daily: profileFromApi.daily_emails,
      weekly: profileFromApi.subscribed,
      product: profileFromApi.product_emails,
    },
    dailyReadingGoal: profileFromApi.daily_reading_goal,
    photo: profileFromApi.photo ?? undefined,
    imagePreview:
      profileFromApi.image_preview ?? profileFromApi.photo ?? undefined,
    url: profileFromApi.url ?? undefined,
    newNotificationsCount: profileFromApi.new_notifications_count,
    didOnboarding: profileFromApi.did_onboarding,
    didOnboardingStashing: profileFromApi.did_onboarding_stashing,
    readingGoalAlert: profileFromApi.reading_goal_alert,
    twitterUsername: profileFromApi.twitter_username ?? '',
    department: profileFromApi.department,
    userType: profileFromApi.user_type,
    hashtagRankings: profileFromApi.hashtag_rankings,
    publishedIdeasTopics: profileFromApi.published_ideas_hashtags,
    recentSavedTopics: profileFromApi.recent_saved_hashtags,
    savesOnPublishedIdeas: profileFromApi.num_saves_on_published_ideas,
    publishedIdeas: profileFromApi.num_published_ideas,
    readIdeas: profileFromApi.read_ideas,
    allReactions: profileFromApi.all_reactions.map(normalizeReaction),
    receivedReactions: normalizeReceivedReactions(
      profileFromApi.received_reactions ?? [],
      profileFromApi.all_reactions.map(normalizeReaction),
    ),
    subscriptionType: profileFromApi.subscription_type,
    showOnboardingTooltips: profileFromApi.creation_onboarding,
    promotionalSale: profileFromApi.promotional_sale
      ? normalizeSaleApiResponse(profileFromApi.promotional_sale)
      : undefined,
  };
};

export const normalizeSaleApiResponse: Normalizer<
  SaleApiResponse,
  Sale
> = sale => {
  return {
    id: sale.sale_id,
    title: sale.title,
    discountPercentage: sale.discount_percentage,
    startDate: sale.start_date,
    endDate: sale.end_date,
  };
};

export const normalizeUserData: Normalizer<UserDataApi, UserData> = user => {
  return {
    firstName: user.first_name,
    id: user.id,
    lastName: user.last_name,
    photo: user.photo ?? undefined,
    photo_blurhash: user.photo_blurhash,
    username: user.username,
    bio: user.bio ?? undefined,
    url: user.url ?? undefined,
  };
};

export const normalizeFromApiToCommonFields: Normalizer<
  ProfileApiResponse,
  UserProfileCommonFields
> = profileFromServer => ({
  id: profileFromServer.id,
  firstName: profileFromServer.first_name,
  lastName: profileFromServer.last_name,
  username: profileFromServer.username,
  photo: profileFromServer.photo ?? '',
  followers: profileFromServer.num_followers,
  following: profileFromServer.num_following,
  dailyReadingGoal: profileFromServer.daily_reading_goal,
  readToday: profileFromServer.read_today,
  numReads: profileFromServer.num_reads,
  currentStreak: profileFromServer.current_streak,
  bio: profileFromServer.bio ?? '',
  location: profileFromServer.location ?? '',
  url: profileFromServer.url ?? '',
  twitterUsername: profileFromServer.twitter_username ?? undefined,
  hashtagRankings: profileFromServer.hashtag_rankings,
  publishedIdeasTopics: profileFromServer.published_ideas_hashtags,
  recentSavedTopics: profileFromServer.recent_saved_hashtags,
  savesOnPublishedIdeas: profileFromServer.num_saves_on_published_ideas,
  numPublishedIdeas: profileFromServer.num_published_ideas,
  numStashedIdeas: profileFromServer.num_saved_ideas,
  subscriptionType: profileFromServer.subscription_type,
});

export const normalizeFromProviderToCommonFields = (
  profile: UserProfile,
  activity: UserActivity,
): UserProfileCommonFields => ({
  id: profile.id,
  firstName: profile.firstName,
  lastName: profile.lastName,
  username: profile.username ?? '',
  photo: profile.photo ?? '',
  followers: activity.followers.length,
  following: activity.following.length ?? 0,
  dailyReadingGoal: profile.dailyReadingGoal,
  readToday: activity.readToday,
  numReads: activity.totalReads,
  currentStreak: activity.currentStreak,
  bio: profile.bio ?? '',
  location: profile.location ?? '',
  url: profile.url ?? '',
  twitterUsername: profile.twitterUsername ?? undefined,
  hashtagRankings: profile.hashtagRankings,
  publishedIdeasTopics: profile.publishedIdeasTopics,
  recentSavedTopics: profile.recentSavedTopics,
  savesOnPublishedIdeas: profile.savesOnPublishedIdeas,
  numPublishedIdeas: profile.publishedIdeas,
  numStashedIdeas: activity.stashedIdeas.length,
  subscriptionType: profile.subscriptionType,
});

export const normalizeReaction: Normalizer<
  ReactionApiResponse,
  Reaction
> = reactionFromServer => ({
  id: reactionFromServer.id,
  name: reactionFromServer.name,
  url: reactionFromServer.reaction_url,
});

export const normalizeAllReactions: Normalizer<
  ReactionApiResponse[],
  Reaction[]
> = reactions => {
  return reactions.map(normalizeReaction);
};

export const normalizeReceivedReaction = (
  receivedReaction: ReceivedReactionApiResponse,
  allReactions: Reaction[],
): Reaction => {
  const id = receivedReaction.reaction_id;
  const url = allReactions?.find(r => r.id === id)?.url ?? '';
  const name = allReactions?.find(r => r.id === id)?.name ?? '';
  return {
    id,
    name,
    url,
    count: receivedReaction.total_users,
  };
};

export const normalizeReceivedReactions = (
  receivedReactions: ReceivedReactionApiResponse[],
  allReactions: Reaction[],
): Reaction[] => {
  return receivedReactions.map(reaction =>
    normalizeReceivedReaction(reaction, allReactions ?? []),
  );
};
