import {
  Flex,
  spacing,
  Divider,
  color,
  Text,
  useColorMode,
  FlexProps,
} from 'deepstash-ui';
import React from 'react';
import { GAP_SIZE } from 'utils/global';
import commonStrings from 'utils/strings/commonStrings';

interface TitledSectionSeparatorProps extends FlexProps {
  title?: string;
}

const TitledSectionSeparator: React.FC<TitledSectionSeparatorProps> = ({
  title = commonStrings.or,
  ...props
}) => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      my={GAP_SIZE}
      width="100%"
      alignItems="center"
      gridGap={spacing.M.rem}
      {...props}
    >
      <Divider color={color[colorMode].underground} flex={1} />
      <Text
        fontSize={spacing.toRem(14)}
        fontWeight={600}
        textTransform={'uppercase'}
        color={color[colorMode].textDisabled}
      >
        {title}
      </Text>
      <Divider color={color[colorMode].underground} flex={1} />
    </Flex>
  );
};

export default TitledSectionSeparator;
