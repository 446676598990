import {
  CaretRightSvg,
  color,
  Divider,
  Flex,
  Link,
  spacing,
  StyleProps,
  Text,
  typography,
  useColorMode,
} from 'deepstash-ui';
import React, { useState } from 'react';
import { MENU_STRUCTURE, UNSORTED_TOPICS } from 'utils/metatopics.constants';
import NextLink from '../navigation/NextLink';
import { BASE_DEEPSTASH_URL } from 'utils/constants';

interface CategoryMenuProps {
  textColorOverride?: string;
}

const CategoryMenu: React.FC<CategoryMenuProps & StyleProps> = ({
  textColorOverride,
  ...props
}) => {
  const { colorMode } = useColorMode();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openSubmenuIndex, setOpenSubmenuIndex] = useState(-1);

  const dropdownTextStyle = {
    ...typography.primaryTextFonts.medium.bold,
    fontWeight: 600,
    color: color[colorMode].textSecondary,
  };

  return (
    <nav>
      <Flex
        cursor="pointer"
        alignItems="center"
        gridGap={spacing.XXS.rem}
        onMouseEnter={() => setIsMenuOpen(true)}
        onMouseLeave={() => {
          setOpenSubmenuIndex(-1);
          setIsMenuOpen(false);
        }}
        position="relative"
        zIndex={1001}
        {...props}
      >
        <Text
          color={textColorOverride ?? color[colorMode].text}
          {...typography.primaryTextFonts.large.semiBold}
        >
          Idea Categories
        </Text>
        <CaretRightSvg transform="rotate(90deg)" />
        <Flex
          position="absolute"
          display={isMenuOpen ? 'flex' : 'none'}
          top={0}
          left={0}
          paddingTop={spacing.toRem(40)}
        >
          <Flex
            borderRadius={spacing.M.rem}
            bgColor={color[colorMode].surface}
            boxShadow="0px 6px 16px rgba(0, 0, 0, 0.2); 0px 8px 4px rgba(0, 0, 0, 0.1);"
            w="200px"
            flexDirection="column"
          >
            {MENU_STRUCTURE.map((menuItem, index) => (
              <Flex
                _hover={{ backgroundColor: color[colorMode].underground }}
                flexDirection="column"
                position="relative"
                key={`category-menu-item-${index}`}
                borderRadius={spacing.M.rem}
                overflow={openSubmenuIndex === index ? 'visible' : 'hidden'}
                onMouseEnter={() => setOpenSubmenuIndex(index)}
              >
                {index !== 0 && (
                  <Divider
                    marginY={0}
                    marginX={spacing.M.rem}
                    flex={1}
                    color={color[colorMode].underground}
                  />
                )}

                <Flex alignItems="center" justifyContent="space-between">
                  <Text padding={spacing.S.rem} {...dropdownTextStyle}>
                    {UNSORTED_TOPICS[menuItem.id]?.seo_title}
                  </Text>
                  <CaretRightSvg
                    flexShrink={0}
                    marginRight={spacing.XXS.rem}
                    boxSize="1rem"
                  />
                </Flex>

                <Flex
                  position="absolute"
                  display={openSubmenuIndex === index ? 'flex' : 'none'}
                  top={-40}
                  left={200}
                  paddingTop={spacing.toRem(40)}
                >
                  <Flex
                    overflow="hidden"
                    bgColor={color[colorMode].surface}
                    boxShadow="0px 6px 16px rgba(0, 0, 0, 0.2); 0px 8px 4px rgba(0, 0, 0, 0.1);"
                    w="200px"
                    flexDirection="column"
                    borderRadius={spacing.M.rem}
                  >
                    {menuItem.subtopicsById.map((subtopicId, index) => (
                      <NextLink
                        as={`/topic/${subtopicId}`}
                        href={'/topic/[hashTag]'}
                        key={`category-menu-item-${index}`}
                        passHref
                      >
                        <Flex
                          _hover={{
                            backgroundColor: color[colorMode].underground,
                          }}
                          flexDirection="column"
                          borderRadius={spacing.M.rem}
                        >
                          {index !== 0 && (
                            <Divider
                              marginY={0}
                              marginX={spacing.M.rem}
                              flex={1}
                              color={color[colorMode].surface}
                            />
                          )}
                          <Link
                            href={BASE_DEEPSTASH_URL + `/topic/${subtopicId}`}
                          >
                            <Text
                              padding={spacing.S.rem}
                              {...dropdownTextStyle}
                            >
                              {UNSORTED_TOPICS[subtopicId]?.seo_title}
                            </Text>
                          </Link>
                        </Flex>
                      </NextLink>
                    ))}
                  </Flex>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </nav>
  );
};

export default CategoryMenu;
