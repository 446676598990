import {
  color,
  Flex,
  Link,
  SearchSvg,
  spacing,
  typography,
  useColorMode,
  useIsMobileView,
  Text,
  PrimaryText,
  ExternalSvg,
} from 'deepstash-ui';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React from 'react';
import LogoWithTextSvg from 'theme/icons/LogoWithText';
import { GAP_SIZE_REM, getRemWidthFromColumns } from 'utils/global';
import commonStrings from 'utils/strings/commonStrings';
import useAuth from '../../providers/hooks/useAuth';
import NextLink from '../navigation/NextLink';
import SearchInput from '../search-input/SearchInput';
import actionStrings from 'utils/strings/actionStrings';
import useNsi from 'providers/hooks/useNsi';
import CategoryMenu from './CategoryMenu';
import CategoryMenuMobile from './CategoryMenuMobile';
import jsCookie from 'js-cookie';

const SiNavbar = dynamic(() => import('./SiNavbar'));

interface NavbarProps {
  showSearchBar?: boolean;
  textColorOverride?: string;
  showMenu?: boolean;
  showSearchIcon?: boolean;
}

const NAVBAR_LINKS = [
  { link: '/collections/1', title: 'Collections' },
  { link: '/get-pro', title: 'Get Pro' },
];

const Navbar: React.FC<NavbarProps> = ({
  showSearchBar = true,
  showMenu = true,
  showSearchIcon = true,
  textColorOverride,
}) => {
  const { isLoggedIn } = useAuth();
  const isMobileView = useIsMobileView();
  const { colorMode } = useColorMode();
  const router = useRouter();
  const { nsiDispatch } = useNsi();

  const onLogInClick = () => {
    nsiDispatch({
      type: 'openAuth',
      payload: {
        type: 'sign-in',
      },
    });
  };

  return (
    <Flex
      alignItems="center"
      gridGap={{ base: spacing.S.rem, md: GAP_SIZE_REM }}
      width="100%"
      py={spacing.toRem(24)}
    >
      <NextLink href="/" passHref keepScroll={!isMobileView}>
        <Link
          _active={undefined}
          _focus={undefined}
          _hover={undefined}
          aria-label="Home"
          display="flex"
          alignItems="center"
          flexGrow={{ base: 1, md: 0 }}
          h={{ base: spacing.toRem(24), md: spacing.toRem(48) }}
          w={{ base: 'auto', md: getRemWidthFromColumns(2) }}
        >
          <LogoWithTextSvg color={textColorOverride ?? color[colorMode].text} />
        </Link>
      </NextLink>
      {showSearchBar &&
        (router.pathname !== '/' || isLoggedIn) &&
        !isMobileView && (
          <SearchInput
            placeholder={commonStrings.search}
            width={getRemWidthFromColumns(4)}
          />
        )}
      {isMobileView && showMenu && (
        <CategoryMenuMobile textColorOverride={textColorOverride} />
      )}
      {isMobileView && showSearchIcon && router.pathname !== '/explore' && (
        <NextLink href={'/explore'} passHref>
          <Link>
            <SearchSvg
              id="navbar2-mobile-search-button-icon"
              boxSize={spacing.toRem(26)}
              color={textColorOverride ?? color[colorMode].text}
              mr={{ base: 0, md: spacing.M.rem }}
            />
          </Link>
        </NextLink>
      )}
      {isMobileView && !isLoggedIn && (
        <Flex
          onClick={() =>
            router.push(jsCookie?.get('loginLink') ?? 'https://dsta.sh/get-app')
          }
          gridGap={spacing.XXS.rem}
        >
          <PrimaryText color={color[colorMode].textSecondary} fontWeight={800}>
            {actionStrings.openApp}
          </PrimaryText>
          <ExternalSvg color={color[colorMode].textSecondary} />
        </Flex>
      )}
      {isMobileView ? null : (
        <Flex
          flexGrow={{ base: 0, md: 1 }}
          justifyContent="right"
          alignItems="center"
          gridGap={spacing.L.rem}
        >
          {showMenu &&
            (isLoggedIn ? (
              <SiNavbar textColorOverride={textColorOverride} />
            ) : (
              <>
                <CategoryMenu textColorOverride={textColorOverride} />
                {NAVBAR_LINKS.map(({ title, link }) => (
                  <NextLink key={`${title}-${link}`} href={link} passHref>
                    <Link
                      color={textColorOverride ?? color[colorMode].text}
                      {...typography.primaryTextFonts.large.semiBold}
                    >
                      {title}
                    </Link>
                  </NextLink>
                ))}
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  paddingX={spacing.M.rem}
                  paddingY={spacing.XXS.rem}
                  bgColor={color[colorMode].text}
                  borderRadius={spacing.S.rem}
                  onClick={onLogInClick}
                  cursor="pointer"
                >
                  <Text
                    {...typography.primaryTextFonts.large.semiBold}
                    color={color[colorMode].textInverted}
                  >
                    {actionStrings.logIn}
                  </Text>
                </Flex>
              </>
            ))}
        </Flex>
      )}
    </Flex>
  );
};

export default Navbar;
