// -- IMPORTANT RULES --
// 1. Values should be Pascal Case, but with space between words
//  i. BAD: 'Enter paywall'
//  ii. GOOD: 'Enter Paywall'
//
// 2. Key should be the value in camelCase
//  i. eg: changeTheme: 'Change Theme'
//
// 3. Properties are noted in comments with @param annotation
//
// 4. Property values should be in kebab-case
//  i. eg: @param changed = daily-on | daily-off | weekly-on | weekly-off | product-on | product-off
// ------------------------

const Events = {
  configuration: {
    /**
     * Change Daily Goal setting.
     * @param value = number
     * @param location = 'menu-dropdown' | 'settings-page'
     */
    changeDailyGoal: 'Change Daily Goal',

    /**
     * Change Theme in Preferences.
     * @param theme = 'light' | 'dark'
     */
    changeTheme: 'Change Theme',

    /**
     * The login action.
     * @param email? = string
     * @param method = 'email' | 'google' | 'facebook' | 'apple'
     */
    loginAction: 'Login Action',

    /**
     * When entering the feed logged in.
     */
    // loginState: 'Login State',

    /**
     * When logging out.
     * @param email? = string
     */
    logout: 'Logout',

    /**
     * Sign Up in Authentication
     * @param method = 'email' | 'google' | 'facebook' | 'apple'
     * @param email? = string
     */
    signUp: 'Sign Up',

    /**
     * When viewing the upgrading to the latest version screen.
     * @param newVersion = string
     */
    viewUpgradeScreen: 'View Upgrade Screen',

    /**
     * When unsubscribing to emails notifications.
     * @param email = string
     */
    emailUnsubscribe: 'Email Unsubscribe',

    /**
     * When asking to reset the password.
     * @param email = string
     */
    resetPasswordRequest: 'Reset Password Request',

    /**
     * When viewing the /activate-account page.
     */
    viewActivateAccountPage: 'View Activate Account Page',

    /**
     * When viewing the reset password page.
     */
    resetPasswordPage: 'Reset Password Page',

    /**
     * When changing account settings.
     * @param setting = 'personal-info' | daily-goal-alert'
     */
    changeSettings: 'Change Settings',

    /**
     * Change email preferences
     * @param changed = daily-on | daily-off | weekly-on | weekly-off
     * | product-on | product-off
     */
    changeMailingFrequency: 'Change Mailing Frequency',

    /**
     * When deleting account.
     * @param deleted = boolean
     * @param reason = string
     */
    deleteUser: 'Delete User',

    /**
     * When opening the image picker.
     */
    openImagePicker: 'Open image picker',

    /**
     * View auth modal variants
     *
     * @param variant = sign-up | sign-in
     * @param open-from-element = string
     *
     */
    viewAuthModal: 'View Auth Modal',

    /**
     * Refresh the authentication token
     */
    refreshAuthenticationToken: 'Refresh Authentication Token',
  },
  creation: {
    /**
     * When viewing the /studio page.
     */
    viewStudio: 'View Studio',

    /**
     * When opening the import modal
     */
    openImportModal: 'Open Import Modal',

    /**
     * When creating a new idea in the creation flow.
     * @param sourceId = number
     * @param sourceType
     * @param ideaType = 'default' | 'quote'
     * @param image? = 'local' | 'link'
     * @param from = 'article-page' | 'fab'
     * @param length = number
     * @param hasTitleOrAuthor = boolean
     * @param destination = 'saved-ideas' | 'stash'
     */
    createIdea: 'Create Idea',

    /**
     * When deleting own ideas.
     * @param ideaId = number
     * @param draft? = boolean
     * @param completed: boolean
     */
    deleteIdea: 'Delete Idea',

    /**
     * @param codeSource = 'insight-dropdown' | 'insight-creator'
     * | 'own-source-dropdown' | 'publish-draft-dropdown'
     * | 'draft-delete' | 'finish-edit-source'
     * @param id = number
     */
    deleteIdeaApiCall: 'Delete Idea Api Call',

    /**
     * When deleting an imported source.
     * @param sourceId = number
     * @param ideaCount = number
     * @param completed = boolean
     * @param published = boolean
     */
    deleteImported: 'Delete Imported',

    /**
     * When editing an idea.
     * @param sourceId = number
     * @param ideaId = number
     * @param sourceType
     * @param ideaType = 'default' | 'quote'
     * @param image? = 'local' | 'link'
     * @param from = 'article-page' | 'fab'
     * @param length = number
     * @param hasTitleOrAuthor = boolean
     * @param destination = 'saved-ideas' | 'stash'
     */
    editIdea: 'Edit Idea',

    /**
     * When importing a source.
     * @param type = 'article' | 'book' | 'video' | 'podcast' | 'tweet'
     * @param method = 'drafts-page' | 'fab'
     */
    importSource: 'Import Source',

    /**
     * When searching on unsplash.
     * @param keyword = string
     */
    unsplashSearch: 'Unsplash Search',

    /**
     * When entering Imported Articles.
     */
    viewImported: 'View Imported',

    /**
     * When restoring a source.
     * @param sourceId = number
     */
    restoreSource: 'Restore Source',

    /**
     * When publishing a new article.
     * @param sourceId = number
     * @param numberOfIdeas = number
     * @param sourceType = SourceType
     */
    publishArticle: 'Publish Article',

    /**
     * When interacting with the idea reordering page.
     * @param sourceId = number
     * @param reorder = boolean
     * @param saved? = boolean
     */
    ideaReorderToggle: 'Idea Reorder Toggle',

    /**
     * When toggling the editor.
     * @param isOn = boolean
     */
    toggleEditor: 'Toggle Editor',

    /**
     * When adding quick ideas.
     */
    openAddIdeas: 'Open add ideas',

    /**
     * When opening drafts list.
     */
    openDraftsList: 'Open drafts',

    /**
     * When adding an image to an idea.
     */
    addImageToIdea: 'Add image to idea',
  },
  consumption: {
    /**
     * Handle web open from link
     * @param 'link-type'
     * @param 'full-path'
     * @param 'first-path'
     * @param 'second-path' (optional)
     * @param [...query-args] (optional)
     *
     * The link is processed as follows:
     * The full URL from link (e.g. https://deepstash/article/[id]?query_arg1=val)
     * is split into
     * {
     *  fullPath - the entire route, containing also the query arguments (e.g. /article/[id]?query_arg1=val)
     *  firstPath - the first route called (e.g. article)
     *  secondPath - the rest of the route (if it exists, e.g. [id])
     * }
     */
    webOpenFromLink: 'Web Open From Link',

    /**
     * When tapping on content.
     * @param id = number | string
     * @param type = article | book | video | podcast | tweet | stash | topic
     *  | idea | profile | draft | url | snackbar | configure-button | hashtag | trending-button
     *  | follow-topics | recommended-stashers | comment
     * @param location = article-card | swipeable-article-title | user-stashes
     *  | feed | article-page | draft | idea-card | swipe-row | drafts | ideas-list
     *  | similar | swipeable-article-title | user-stash-carousel | follow-list | fab
     *  | edit-stashes | imported-page | select-stash-modal | profile-page
     *  | saved-ideas | stash-page | topic-section | webview | source-card
     *  | stash-section-list | user-bio | comments-page
     * @param element = user-header | user-element | topic-header | title | card
     *  | more-ideas | author | share-icon | share | save-to | saves | photo-edit-icon
     *  | idea-saved | edit | delete | stash-card | label-saved-ideas | user-label
     *  | options | draft | single-tap | double-tap-save | primary-idea
     *  | secondary-idea | long-press | single-tap-article-open | three-dot
     *  | more-stashes-card | chip-topic | user | user-saved | add-source-menu
     *  | extract-more-ideas | show-published-ideas | more-options | remove-all
     *  | stash-item | add-button | new-stash | followers | following | saved
     *  | published-title | saved-title | sort | show-by-owner | search | progress-bar
     *  | source-background-counter | mention | settings-icon | edit-profile-button | settings-icon
     *  | profile-stash-row
     */
    contentTap: 'Content Tap',

    /**
     * When archiving an imported source.
     * @param articleId = number
     * @param from = 'side-menu'
     */
    archiveImported: 'Archive Imported',

    /**
     * When user exceeds the daily goal for reading ideas
     * with a multiplier.
     * @param multiplier = number
     */
    dailyGoalExceeded: 'Daily Goal Exceeded',

    /**
     * When user reaches the daily goal for reading ideas.
     * @param ideasNumber = number
     */
    dailyGoalReached: 'Daily Goal Reached',

    /**
     * How long does a user scroll on feed.
     * @param page = number
     * @param type = 'home' | 'featured' | 'trending' | 'following'
     */
    homeFeedScroll: 'Home Feed Scroll',

    /**
     * When a user taps on feed.
     * @param item: 'recommendation' | 'own-source' | 'featured' |
     * 'trending' | 'quotes' | 'topics' | 'topic-button' |
     * 'featured-stash' | 'saved-ideas' | 'profile-name' |
     * 'reconnect-ideas'
     */
    homeFeedTap: 'Home Feed Tap',

    /**
     * When a user reads an idea.
     * @param ideaId = number
     * @param readMethod = 'automatic' | 'manual'
     */
    ideaRead: 'Idea Read',

    /**
     * When interacting with the FAB.
     */
    fabInteraction: 'Fab Interaction',

    /**
     * When opening the url of a source.
     * @domain = string
     */
    openSourceUrl: 'Open Source URL',

    /**
     * When using search.
     * @param text = string
     * @param scope = 'own-ideas' | 'all ideas'
     * @param type = 'stashes' | 'insights' | 'articles' | 'hashtags'
     * @param location? = 'button' | 'dropdown'
     * @param error? = 'too-short'
     */
    search: 'Search',

    /**
     * When entering an article.
     * @param articleId = number
     * @param title = string
     * @param utm_source? = string
     * @param utm_medium? = string
     */
    viewArticle: 'View Article',

    /**
     * When viewing a master book page
     * /book/id/slug
     * @param bookId = number
     * @param title = string
     * @param utm_source? = string
     * @param utm_medium? = string
     */
    viewBookPage: 'View Book Page',

    /**
     * When viewing a master book author page
     * /book/author/id/slug
     * @param authorId = number
     * @param name = string
     * @param utm_source? = string
     * @param utm_medium? = string
     */
    viewBookAuthorPage: 'View Book Author Page',

    /**
     * When entering own articles.
     * @param tab = 'drafts' | 'similar' | 'kl' | 'profile-page' |
     * 'topic-page' | 'stash-card' | 'search'
     */
    viewOwnSources: 'View Own Sources',

    /**
     * When entering progress page.
     */
    viewProgress: 'View Progress',

    /**
     * When entering a stash page.
     * @param stashId = number
     * @param official = boolean
     */
    viewStash: 'View Stash',

    /**
     * When entering a topic.
     * @param topic = string
     */
    viewTopic: 'View Topic',

    /**
     * When viewing an idea.
     * @param ideaId = number
     * @param title? = string
     * @param authorName? = string
     */
    viewIdea: 'View Idea',

    /**
     * When viewing private content.
     * @param contentId = number
     * @param contentType = 'article' | 'book' | 'video' |
     * 'tweet' | 'podcast' | 'idea' | 'stash' |
     * 'topic' | 'profile'
     */
    viewPrivate: 'View Private',

    /**
     * When viewing the landing page.
     */
    viewLanding: 'View Landing',

    viewPurchaseSubscription: 'View Purchase Subscription',

    /**
     * When viewing the ProductHunt landing page.
     */
    viewProductHunt: 'View Product Hunt',

    /**
     * When viewing the knowledge library.
     * @param type = 'stashes' | 'ideas' |' articles' |
     * 'topics' | 'profiles' | 'collections'
     * @param page = number
     */
    viewKL: 'View KL',

    /**
     * When tapping on the side menu.
     * @param button
     */
    sideMenuTap: 'Side Menu Tap',

    /**
     * When tapping on the footer.
     * @param button
     */
    footerTap: 'Footer Tap',

    /**
     * When going back from a page.
     * @param location = PageType
     */
    goBack: 'Go Back',

    /**
     * When tapping on the breadcrumb.
     * @param index = number
     * @param item = string
     */
    breadcrumbTap: 'Breadcrumb Tap',

    /**
     * When loading more stashes.
     * @param page? = number
     * @param targetId? = number
     */
    loadMoreStashes: 'Load More Stashes',

    /**
     * When scrolling through notifications list.
     * @param page = number
     */
    notificationsScroll: 'Notifications Scroll',

    /**
     * When user clicks or deletes a notification.
     * @param type = trackingId
     * @param option = 'click' | 'delete'
     * @param destination -  destinationAppScreen
     */
    handleNotification: 'Handle Notification',

    /**
     * @param page = number
     */
    viewAux: 'View Aux',

    /**
     * When viewing the For Teams page
     */
    viewForTeams: 'View For Teams',

    /**
     * When viewing the How It Works page
     */
    viewHowItWorks: 'View How It Works',

    /**
     * When viewing the Get Pro page
     */
    viewGetPro: 'View Get Pro',

    /**
     * When tapping on help center.
     */
    openHelpCenter: 'Help Center Opened',

    /**
     * When enter profile
     * @param user = string (username) | number (userId)
     */
    viewProfile: 'View Profile',

    /**
     * When opening a profile published tab.
     * @param userId = number
     */
    viewProfilePublishedTab: 'View Profile Published Tab',

    /**
     * When opening a profile saved tab
     * @param userId = number
     */
    viewProfileSavedTab: 'View Profile Saved Tab',

    /**
     * When opening a profile saved tab
     * @param userId = number
     */
    viewOwnSavedIdeas: 'View Profile Own Saved Tab',

    /**
     * When opening a profile stashes tab
     * @param userId = number
     */
    viewProfileStashesTab: 'View Profile Stashes Tab',

    /**
     * When tapping on the more text for the leaderboard
     * @param userId = number
     */
    viewProfileLeaderboard: 'View Profile Leaderboard',

    /**
     * When tapping on other profile picture
     * @param userId = number
     */
    viewUserProfilePicture: 'View Other User Profile Picture',

    /**
     * When tapping on a stashed topic
     * @param hashtagName = string
     * @param userId = number
     */
    profileStashedIdeasTopicTap: 'Tap on the stashed profile topic',

    /**
     * When tapping on a stashed topic
     * @param hashtagName = string
     * @param userId = number
     */
    profilePublishedIdeasTopicTap: 'Tap on the published profile topic',

    /**
     * When a user changes the tabs in the feed
     * @param feedType = 'recommended' | 'following'
     */
    changeFeedTab: 'Change Feed Tab',

    /**
     * When tapping to see all related topics
     * @param hashtagName = string
     */
    selectTopStashers: 'See all top stashers selected',

    /**
     * When tapping to see all related topics
     * @param hashtagName = string
     */
    selectRelatedTopics: 'See all related topics selected',

    /**
     * Used when a user taps on an idea card
     * @param sourceId = number
     * @param location = feed | search-screen-ideas-result | topic-screen
     * | profile-screen-saved-list | profile-screen-saved-list
     * | hashtag-screen | generator-screen | source-screen-related-sources
     * | search-screen-sources-result | feed-onboarding
     * @param element = preview-idea | stash-name | username | source-title
     * | source-domain | source-background-counter
     */
    sourcePreviewClick: 'Idea Card Tap',

    /**
     * When viewing a collection page
     * @param collectionId = number
     * @param collectionStatus = 'new' | 'started' | 'completed'
     * @param isBlocked = boolean
     */
    viewCollection: 'View Collection',

    /**
     * When viewing a collection source page
     * @param collectionId = number
     * @param sourceId = number
     * @param collectionStatus = 'new' | 'started' | 'completed'
     * @param isBlocked = boolean
     */
    viewCollectionSource: 'View Collection Source',

    /**
     * When viewing all collections
     * @param isBlocked = boolean
     */
    viewAllCollections: 'View Collection List',

    /**
     * When viewing a shared page
     * @param type = 'stash' | 'collection' | 'article' | 'idea' | 'profile'
     * @param id = number
     * @param title = string
     * @param sharedBy = number
     * @param sharedByUsername = string
     * @param isInitialLoggedIn = boolean
     * @param isMobileView = boolean
     * @param shareMessage = string
     */
    viewSharePage: 'View Share Page',

    /**
     * When starting a collection
     * @param collectionId = number
     * @param location = 'collection-page'
     */
    collectionStartReading: 'Collection Start reading',

    /**
     * When continuing a collection
     * @param collectionId = number
     * @param location = 'collection-page' | 'collection-source-page'
     * @param sourceId? = number, if location = 'collection-source-page'
     */
    collectionContinueReading: 'Collection Continue Reading',

    /**
     * When clicking the dropdown to continue a collection tomorrow
     */
    collectionContinueTomorrowDropdown: 'Collection Continue Tomorrow Dropdown',

    /**
     * When finishing a source from a collection
     * @param collectionId = number
     * @param sourceId = number
     * @param sourceType = number
     */
    collectionFinishSource: 'Collection Finish Article',

    /**
     * When finishing a collection
     * @param collectionId = number
     */
    collectionFinishAllArticles: 'Collection Finish All Articles',

    /**
     * When choosing to get the app to enhance collection functionality
     * @param collectionId = number
     * @param action = 'continue-reading' | 'continue-tomorrow'
     */
    collectionGetApp: 'Collection Get App',

    /**
     * When tapping a CollectionCollapsed card
     * @param id = number
     * @param dataType = 'collection' | 'stash'
     * @param status? = 'new' | 'completed' | 'started'
     */
    groupCardTap: 'Group Card Tap',
    /**
     * Click on a category card on the explore page
     * @param name : String, category.title
     * @param type : String, section.id (the id of the section the category belongs to)
     */
    viewExploreCategory: 'View Category',
    /**
     * Clicked on an element from a section inside a category from explore page
     * That element can be either a source or an idea (we don't send the events for the curators cards) or the See all button
     * We don't send this event when clicking on a source or an idea on the see all page
     * @param elementTapped:'see_all'|'source'|'idea'
     * @param itemId?:number the id of the source/idea
     */
    viewSectionItemInCategory: ' View Section Item In Category',
    /**
     * Triggered when viewing a /reedem page
     * @param code: router.query.code
     */
    viewRedeemPage: 'View Redeem Page',
  },
  organising: {
    /**
     * When creating a new stash.
     * @param stashName = string
     * @param from = string
     */
    createStash: 'Create Stash',

    /**
     * When moving an idea.
     * @param destination = 'saved-ideas' | 'stash'
     */
    moveIdea: 'Move Idea',

    /**
     * When un-saving an idea.
     * @param ideaId = number
     */
    removeIdea: 'Remove Idea',

    /**
     * When saving all ideas from a source.
     * @param sourceId = number
     * @param destination = 'saved-ideas' | 'stash'
     * @param stashId? = number
     * @param ideaCount = number
     * @param newCount = number
     */
    saveAllIdeas: 'Save All Ideas',

    /**
     * When saving an idea from a source.
     * @param ideaId = number
     * @param sourceId = number
     * @param destination = 'saved-ideas' | 'stash'
     */
    saveIdea: 'Save Idea',

    /**
     * When entering a page with saved ideas.
     * (eg: stash page)
     * @param location = 'saved-ideas' |'stash'
     * @param numberOfIdeas = number
     * @param sortingType = 'recent' | 'oldest' | 'reads' | 'popular' | 'random'
     */
    viewSavedIdeas: 'View Saved Ideas',

    /**
     * When editing a stash.
     * @param stashId = number
     * @param element = 'name' |'image' | 'description'
     */
    editStash: 'Edit Stash',

    /**
     * Toggle stashes edit.
     * @param edit = boolean
     */
    stashesEditToggle: 'Stashes Edit Toggle',

    /**
     * When deleting a stash.
     * @param stashId = number
     * @param completed = boolean
     */
    deleteStash: 'Delete Stash',

    /**
     * When un-saving all ideas from a source.
     * @param sourceId = number
     * @param ideaCount = number
     */
    unsaveAllIdeas: 'Unsave All Ideas',

    /**
     * When expanding content
     * @param sourceId = number
     * @param expanded = boolean
     * @param content = 'article' | 'book-description'
     */
    expandContentToggle: 'Expand Content Toggle',

    /**
     * When tapping on elements from profile page.
     * @param target = 'saved-ideas' | 'website' | 'feed' | 'stash'
     * @param targetId = number | string
     * @param page? = number
     */
    profilePageInteraction: 'Profile Page Interaction',
  },
  sharing: {
    /**
     * When sharing content.
     * @param id = number
     * @param contentType = 'article' | 'book' | 'video' |
     * 'tweet' | 'podcast' | 'idea' | 'stash' |
     * 'topic' | 'profile'
     * @param destination? = string
     * @param type = 'link' | 'image' | 'text'
     */
    shareContent: 'Share Content',

    /**
     * When sending content to editors.
     * @param sourceId = number
     * @param ideaCount = number
     */
    sendToEditors: 'Send to Editors',
  },
  onboarding: {
    /**
     * On different steps of onboarding.
     * @param step =
     * 'welcome-screen'          - the one with the reviews
     * 'department'              - the 'A bit about yourself' screen
     * 'daily-goal'              - setting the daily goal
     * 'daily-goal-confirmation' - confirming the daily goal
     * 'topics'                  - pick the topics
     * 'following'               - pick who to follow
     * 'paywall'                 - the paywall
     * 'all-done'                - confirmation screen
     * @param stepNumber = number
     * @param isPro? = boolean - used when 'all-done' for Pros.
     */
    viewWebSetup: 'View Web Setup',
    viewWebSetupPaywall: 'View Web Setup Paywall',
    viewWebSetupDiscountPage: 'View Web Setup Discount Page',
    /**
     * On different steps of onboarding.
     * @param step =
     * 'welcome-screen'          - the one with the reviews
     * 'department'              - the 'A bit about yourself' screen
     * 'daily-goal'              - setting the daily goal
     * 'daily-goal-confirmation' - confirming the daily goal
     * 'topics'                  - pick the topics
     * 'following'               - pick who to follow
     * 'paywall'                 - the paywall
     * 'all-done'                - confirmation screen
     * "product-explanation-page-0 | 0.5 | 1 | 2 | 3 | 4'
     * @param hasSavedIdeas 'true' | 'false'
     * @param stepNumber = number
     * @param isPro? = boolean - used when 'all-done' for Pros.
     */
    viewJtbdSetup: 'View JTBD Setup',
    viewQuizSetup: 'View Quiz Setup',
    /**
     * @param goal = string[]
     */
    clickContinueGoalLanding: 'Click Continue Goal Landing',
    /**
     * @param step = string
     * @param ...params-related-to-step
     */
    continueSetupStep: 'Continue Setup Step',
    /**
     * When pressing back on setup
     * @param step = string
     */
    backSetupStep: 'Back Setup Step',
    viewSetupPage: 'View Setup Page',
    viewSetupSignUpPage: 'View Setup SignUp Page',
    tapStart: 'Tap Start',
  },
  modals: {
    /**
     * When opening the auth modal.
     * @param location = 'side-menu' | 'save-button' |
     * 'content-ad' | 'feed-banner' | 'curtain' | 'login-page'
     * @param chosenOption = 'login' | 'signup'
     * @param automatic? = boolean
     */
    openAuthModal: 'Open Auth Modal',

    /**
     * When seeing the modal saying you have unfinished ideas.
     * @param outcome = 'delete' | 'cancel'
     */
    unfinishedIdeasModal: 'Unfinished Ideas Modal',

    /**
     * When the publish modal is interacted with.
     * @param sourceId = number
     * @param event = 'open' | 'cancel' | 'publish'
     */
    publishModal: 'Publish Modal',

    /**
     * @deprecated
     * When the Quality Modal shows up
     * @param isOpen = boolean
     * @param ideaQuality = number
     * @param location = 'article-page' | 'fab'
     */
    qualityModal: 'Quality Modal',

    /**
     * When viewing the users modal.
     * @param type = 'saves' | 'followers' | 'following' | 'top-stashers'
     * @param page? = number
     * @param id? = string
     */
    usersModal: 'Users Modal',

    /**
     * When viewing the setup modal.
     * @param event = 'open' | 'close' | 'click-settings'
     * | 'click-reading' | 'username-error' | 'profile-setup'
     * @param profileId = number
     * @param firstName? = string
     * @param lastName? = string
     * @param username? = string
     * @param profilePictureUrl? = string
     * @param error? = string
     * @param isNewUser? = boolean
     */
    setupModal: 'Profile Modal',

    /**
     * When opening the report modal.
     * @param type = string
     */
    openReportModal: 'Open Report Modal',

    /**
     * When opening the 'complete collection' modal.
     * @param collectionId = number
     */
    openCompleteCollectionModal: 'Open Complete Collection Modal',
  },
  stability: {
    /**
     * When login action fails.
     * @param method = 'email' | 'google' | 'facebook' | 'apple'
     */
    failedLogin: 'Failed Login',

    /**
     * When viewing an error page.
     * @param path = string
     */
    errorPage: 'Error Page',

    /**
     * Event for wrong inputs or flows.
     * @param element = 'stash' | 'import' | 'publish' | 'share'
     * | 'username' | 'daily-goal' | 'read' | 'create'
     * @param warning = 'no-name' | 'no-first-name' | 'no-last-name'
     * | 'no-author' | 'no-content' | 'too-long' | 'exists'
     * | 'no-unfinished-ideas' | 'unfinished-ideas' | 'no-ideas'
     * | 'no-source' | 'username-exists' | 'deepstash-link'
     * | 'no-link' | invalid-number' | 'too-big' | 'already-read'
     * | 'no-username'
     */
    warningEvent: 'Warning Event',

    /**
     * @param type = string
     * @param id = string
     * @param TTFB = number
     * @param FCP = number
     * @param LCP = number
     * @param FID = number
     * @param CLS = number
     */
    vitalPerformanceReport: 'Vital Performance Report',

    /**
     * @param type = string
     * @param id = string
     * @param value = number
     */
    hydratePerformanceReport: 'Hydrate Performance Report',

    renderPerformanceReport: 'Render Performance Report',
    reRenderPerformanceReport: 'Re-Render Performance Report',
    eventHandlingPerformanceReport: 'Event Handling Performance Report',

    /**
     * When app crashes.
     */
    appCrashed: 'App Crashed',

    /**
     * When submitting the For Teams form fails.
     */
    forTeamsFormError: 'For Teams Form Error',
  },
  social: {
    /**
     * When following a user.
     * @param targetProfileId = number
     */
    followUser: 'Follow User',

    /**
     * When following a user.
     */
    followAllUsers: 'Follow All Recommended Users',

    /**
     * When un-following a user.
     * @param targetProfileId = number
     */
    unfollowUser: 'Unfollow User',

    /**
     * When following a hashtag.
     * @param hashtagName = string
     */
    followHashtag: 'Follow Hashtag',

    /**
     * When un-following a hashtag.
     * @param hashtagName = string
     */
    unfollowHashtag: 'Unfollow Hashtag',

    /**
     * When using the invite functionality.
     * @param element = 'topic' | 'topics' | 'ios' | 'android
     * | 'follow' | 'following' | 'own' | 'logo' | 'join-web'
     */
    inviteUsers: 'Invite Users',

    /**
     * When reporting content or user.
     * @param type = string
     * @param reason = string
     */
    reportContentOrUser: 'Report Content or User',
  },
  curators: {
    /**
     * @param step = 'view-page' | 'open-modal' | 'log-in' | 'close-modal'
     * @param modalPosition = 'top' | 'bottom'
     * @param isLoggedIn = boolean
     * @param email = string
     */
    curatorFunnelEvent: 'Curator Funnel Event',
  },
  payments: {
    /**
     * Event triggered when trying to redeem a code
     * @param couponCode string
     * @param response
     */
    redeemCode: 'Redeem Code',
    /**
     * Event for when entering on the paywall
     * @param location enum PaywallAnalyticsLocation
     * @param URL = router.asPath
     */
    enterPaywall: 'Enter Paywall',
    /**
     * Event for when a user selects (clicks `continue` on) a subscription type
     * @param location enum PaywallAnalyticsLocation
     * @param URL = router.asPath
     * @param type = 'web-pick-monthly' | 'web-pick-yearly' | 'web-see-whats-included'
     * @param isMobile? if on mobile devices
     */
    tapEventPaywall: 'Tap Event Paywall',
    /**
     * Event for when the user finishes their subscription acquisition process
     * @param location enum PaywallAnalyticsLocation
     * @param URL = router.asPath
     */
    subscribeEventSuccess: ' Subscribe Event Success',
    /**
     * Event for pressing the Claim Offer on influencer page
     */
    claimOfferEvent: 'Tap Event Claim Offer on Influencer Page',
    /**
     * Event for when opening the checkout modal
     * @param location enum PaywallAnalyticsLocation
     * @param URL = router.asPath
     * @param type UserSubscriptionType
     * @param price number
     * @param hasTrial boolean
     */
    viewCheckoutModal: 'View Checkout Modal',

    /**
     * @param URL = router.asPath
     * @param landingLocation string
     */
    clickStartTrial: `Click Start Trial`,

    /**
     * Event for registering a tap on the offer banner
     * @param URL = router.asPath
     */
    clickOfferBanner: 'Click Offer Banner',
  },
  newsletter: {
    /**
     * @param email
     * @param URL = router.asPath
     */
    submitEmailNewsletter: 'Submit Email Newsletter',
  },
  story: {
    /**
     * @param URL = router.asPath
     */
    viewStoryPage: 'View Story Page',
    /**
     * @param URL = router.asPath
     */
    clickSaveIdea: 'Click Save Idea',
  },
  experiments: {
    /**
     * @param expName: string
     * @param variant: string '0' | '1' ...
     */
    viewGrowthPlanExperiment: 'View Growth Plan Experiment',
  },
  growthPlan: {
    viewGrowthPlanPage: 'View Growth Plan Page',
    viewGrowthPlanPaywallPage: 'View Growth Plan Paywall Page',
    viewGrowthPlanDiscountPage: 'View Growth Plan Discount Page',
    /**
     * @param step = 1,2,3...
     */
    continueGrowthPlanStep: 'Continue Growth Plan Step',
  },
};

export default Events;
