import { ProfileContext } from '../profile/ProfileProvider';
import {
  ProfileContextType,
  ProfileProviderAction,
  ProfileProviderAsyncActions,
  ProfileProviderHiddenAction,
  ProfileProviderStateType,
} from '../profile/ProfileProviderTypes';
import * as asyncActions from '../profile/ProfileActions';
import useCreateContextConsumer from './useCreateContextConsumer';

const useProfile = () => {
  const { dispatch, ...contextConsumer } = useCreateContextConsumer<
    ProfileProviderStateType,
    ProfileProviderAction,
    ProfileContextType,
    ProfileProviderHiddenAction,
    ProfileProviderAsyncActions
  >({ context: ProfileContext, asyncActions, withLogging: true });
  return {
    ...contextConsumer,
    profileDispatch: dispatch,
  };
};

export default useProfile;
