import { CurrentProfileApiResponse } from 'api/api.types';
import { Flex, PrimaryHeading, spacing, useIsMobileView } from 'deepstash-ui';
import {
  useAppleLoginCallback,
  useFacebookLoginCallback,
  useGoogleLoginCallback,
} from 'hooks/auth';
import { SocialAuthCallbackProps } from 'hooks/auth/useAuthCallbacks';
import React, { useEffect, useRef } from 'react';
import SocialLoginButton from 'src/components/buttons/SocialLoginButton';
import { authType } from 'src/providers/nsi/NsiProviderTypes';
import actionStrings from 'utils/strings/actionStrings';
import { AuthRequestStatus } from '../AuthModalContent';

interface AuthModalTypeHeaderProps {
  authModalType: authType;
  requestStatus: AuthRequestStatus;
  setRequestStatus: (val: AuthRequestStatus) => void;
  discountCode?: string;
  shouldSkipOnboarding?: boolean;
  onAuthSuccess?: (profile: CurrentProfileApiResponse) => void;
}

const AuthModalTypeHeader: React.FC<AuthModalTypeHeaderProps> = ({
  authModalType,
  requestStatus,
  setRequestStatus,
  discountCode,
  shouldSkipOnboarding,
  onAuthSuccess,
}) => {
  const isMobileView = useIsMobileView();
  /**  Set by social auth responses, flagging if the account is new created or not*/
  const isNewAcount = useRef(false);

  const socialAuthProps: SocialAuthCallbackProps = {
    setRequestStatus,
    setIsSignup: (val: boolean) => (isNewAcount.current = val),
    discountCode,
    shouldSkipOnboarding,
    onAuthSuccess,
  };

  const googleCallback = useGoogleLoginCallback(socialAuthProps);
  const facebookCallback = useFacebookLoginCallback(socialAuthProps);
  const appleCallback = useAppleLoginCallback(socialAuthProps);

  //Keep ref in sync with state
  useEffect(() => {
    isNewAcount.current = authModalType === 'sign-up';
  }, [authModalType]);

  return (
    <>
      <Flex
        width="100%"
        justifyContent="flex-start"
        paddingX={isMobileView ? spacing.L.rem : spacing.XXXL.rem}
        mb={spacing.XL.rem}
      >
        {authModalType === 'sign-up' ? (
          <PrimaryHeading
            size="h2"
            textSize={isMobileView ? 's' : 'l'}
            type="bold"
          >
            {actionStrings.signUp}
          </PrimaryHeading>
        ) : (
          <PrimaryHeading size="h2" textSize="l" type="bold">
            {actionStrings.signIn}
          </PrimaryHeading>
        )}
      </Flex>

      <SocialLoginButton
        callback={googleCallback}
        isSignup={authModalType === 'sign-up'}
        network="Google"
        requestStatus={requestStatus}
        h={'auto'}
        px={isMobileView ? spacing.L.rem : spacing.XXXL.rem}
        mb={spacing.M.rem}
      />
      <SocialLoginButton
        callback={facebookCallback}
        isSignup={authModalType === 'sign-up'}
        network="Facebook"
        h={'auto'}
        requestStatus={requestStatus}
        px={isMobileView ? spacing.L.rem : spacing.XXXL.rem}
        mb={spacing.M.rem}
      />
      <SocialLoginButton
        callback={appleCallback}
        isSignup={authModalType === 'sign-up'}
        network="Apple"
        h={'auto'}
        requestStatus={requestStatus}
        px={isMobileView ? spacing.L.rem : spacing.XXXL.rem}
        mb={spacing.M.rem}
      />
    </>
  );
};

export default AuthModalTypeHeader;
