import {
  AuthContext,
  AuthContextType,
  AuthProviderAction,
  AuthProviderStateType,
} from '../auth';
import useCreateContextConsumer from './useCreateContextConsumer';

const useAuth = () => {
  const { dispatch, ...contextConsumer } = useCreateContextConsumer<
    AuthProviderStateType,
    AuthProviderAction,
    AuthContextType
  >({ context: AuthContext });
  return {
    ...contextConsumer,
    authDispatch: dispatch,
  };
};

export default useAuth;
