import { UserProfile } from 'types';
import {
  AllReactionsApiResponse,
  CurrentProfileApiResponse,
  DepartmentsApiResponse,
  InviteDynamicLinkApiResponse,
  PaginatedApiResponse,
  ProfileApiResponse,
  ProfilePhotoUpdateResponse,
  UnsubscribeElementResponse,
  UnsubscribeUserData,
  UpdateElementResponse,
  UpdateProfileRequestData,
  UserPreferencesRequestData,
  UserTopicApiResponse,
} from './api.types';
import {
  deleteRequest,
  getApiToken,
  getRequest,
  patchRequest,
  postFormRequest,
  postRequest,
  putRequest,
} from './apiRequest';

export function updateUserPreferences(
  token: string,
  userId: number,
  userData: Partial<UserProfile>,
): Promise<UpdateElementResponse> {
  const url = `/user/${userId}/`;
  const data: UserPreferencesRequestData = {
    daily_reading_goal: userData.dailyReadingGoal,
    reading_goal_alert: userData.readingGoalAlert,
  };
  if (userData.emailSubscribed) {
    data.daily_emails = userData.emailSubscribed.daily;
    data.subscribed = userData.emailSubscribed.weekly;
    data.product_emails = userData.emailSubscribed.product;
  }
  return putRequest<UpdateElementResponse, UserPreferencesRequestData>(
    url,
    token,
    data,
  );
}

export function updateProfileInfo(
  token: string,
  userProfile: Partial<UserProfile>,
): Promise<UpdateElementResponse | null> {
  const url = `/user/${userProfile.id}/`;
  const data: UpdateProfileRequestData = {
    first_name: userProfile.firstName,
    last_name: userProfile.lastName,
    email: userProfile.email,
    bio: userProfile.bio,
    url: userProfile.url,
    username: userProfile.username,
    location: userProfile.location,
    did_onboarding_stashing: userProfile.didOnboardingStashing,
    department: userProfile.department,
    creation_onboarding: userProfile.showOnboardingTooltips,
  };

  // remove undefined fields
  for (const key in data) {
    if (
      data[key as keyof UpdateProfileRequestData] === undefined ||
      data[key as keyof UpdateProfileRequestData] === null
    ) {
      delete data[key as keyof UpdateProfileRequestData];
    }
  }

  if (!userProfile.id) return Promise.resolve(null);
  return patchRequest<UpdateElementResponse, UpdateProfileRequestData>(
    url,
    token,
    data,
  );
}

export const updateProfilePhoto = async (
  photoForm: FormData,
): Promise<ProfilePhotoUpdateResponse> => {
  try {
    const token = getApiToken();
    return postRequest<ProfilePhotoUpdateResponse, FormData>(
      '/user/upload_photo/',
      token,
      photoForm,
    );
  } catch (e) {
    return Promise.reject('Token error!');
  }
};

export function unsubscribeUser({
  email,
  id,
  key,
}: {
  email?: string;
  id?: string;
  key: string;
}): Promise<UnsubscribeElementResponse> {
  const url = '/user/unsubscribe/';
  const data: UnsubscribeUserData = {
    email,
    id,
    key,
  };
  const qs = require('qs');

  return postFormRequest<UnsubscribeElementResponse, UnsubscribeUserData>(
    url,
    getApiToken({ allowDefault: true }),
    qs.stringify(data),
  );
}

export function fetchCurrent(
  token?: string,
  withTimezone = true,
): Promise<CurrentProfileApiResponse> {
  try {
    const authToken = token ?? getApiToken();
    const url = `/current/?updated${
      withTimezone ? `&tz=${new Date().getTimezoneOffset()}` : ''
    }`;

    return getRequest(url, authToken);
  } catch (e) {
    return Promise.reject();
  }
}

export function fetchProfile(
  data: string,
  type: 'id' | 'username',
): Promise<ProfileApiResponse> {
  const parsedParam = encodeURIComponent(data);
  const url = `/user/profile${
    type === 'id' ? `/${parsedParam}` : `/username/${parsedParam}`
  }/`;
  return getRequest<ProfileApiResponse>(
    url,
    getApiToken({ allowDefault: true }),
  );
}

export function followUser(userId: number) {
  try {
    const token = getApiToken();
    const url = `/user/follow/${userId}/`;

    return postRequest(url, token);
  } catch (e) {
    return Promise.resolve();
  }
}

export function followUsers(userIds: number[]) {
  try {
    const token = getApiToken();
    const url = `/user/add_followings/`;
    const requestBody = {
      users: userIds,
    };

    return postRequest(url, token, requestBody);
  } catch (e) {
    return Promise.resolve();
  }
}

export function unfollowUser(userId: number) {
  try {
    const token = getApiToken();
    const url = `/user/follow/${userId}/`;

    return deleteRequest(url, token);
  } catch (e) {
    return Promise.resolve();
  }
}

// [Like functionality]
// export function likeIdea(ideaId: number) {
//   const token = jsCookie.get('token');
//   if (!token) return Promise.resolve();

//   const url = `/block/${ideaId}/like/`;

//   return postRequest(url, token);
// }

// export function unlikeIdea(ideaId: number) {
//   const token = jsCookie.get('token');
//   if (!token) return Promise.resolve();

//   const url = `/block/${ideaId}/like/`;

//   return deleteRequest(url, token);
// }

export function generateInstallLink({
  sourceType,
  username,
  message,
}: {
  username: string;
  message?: string;
  sourceType?: 'share';
}) {
  const baseUrl = `/invite/new/?u=${username}`;
  const messageQueryParam = message
    ? `&message=${encodeURIComponent(message ?? '')}`
    : '';

  const sourceQueryParam = sourceType ? '&source=share' : '';

  const url = baseUrl + messageQueryParam + sourceQueryParam;

  return getRequest<InviteDynamicLinkApiResponse>(
    url,
    getApiToken({ allowDefault: true }),
  );
}

export function getOnboardingUserTopics(
  username: string,
): Promise<UserTopicApiResponse> {
  const url = `/user/${username}/topics`;

  return getRequest(url, getApiToken({ allowDefault: true }));
}

export function updateInvitationStatus(id: string, hash: string) {
  const url = `/invite/status/?h=${hash}&s=3&u=${id}`;

  return postRequest(url, getApiToken({ allowDefault: true }));
}

/**
 * Delete a user with a required reason
 * @param userId
 * @param reason
 */
export function deleteAccount(
  userId: number,
  reason: string,
): Promise<UpdateElementResponse> {
  try {
    const token = getApiToken();
    const url = `/user/${userId}/`;

    const config = { data: { reason: reason } };
    return deleteRequest(url, token, config);
  } catch (e) {
    throw new Error('No token!');
  }
}

/**
 * Get sources liked by the user
 */
// [Like functionality]
// export const getLikedSourcesApiResponse = (
//   token?: string,
// ): Promise<LikedArticlesApiResponse | void> => {
//   const authToken = token ?? jsCookie.get('token');
//   if (!authToken) return Promise.resolve();

//   const url = `/article/liked/?limit=5&offset=0&expanded`;

//   return getRequest<LikedArticlesApiResponse>(url, authToken);
// };

/**
 * GET twitter oauth for authoring twitter account
 */
export const getTwitterOauthToken = (): Promise<{
  oauth_token: string;
}> => {
  const token = getApiToken();

  const url = `/twitter/oauth_token/`;

  return getRequest(url, token);
};

/**
 * POST the Twitter user by sending Twitter Credentials
 * @param twOauth
 * @returns twitter-username
 */
export const postTwitterAuthorizeWithOauth = (twOauth: {
  oauthToken: string;
  oauthVerifier: string;
}): Promise<{
  username: string;
}> => {
  const token = getApiToken();

  const data = {
    oauth_token: twOauth.oauthToken,
    oauth_verifier: twOauth.oauthVerifier,
  };

  const url = `/twitter/authorize_with_oauth/`;

  return postRequest(url, token, data);
};

export const getSetupProfessions =
  (): Promise<DepartmentsApiResponse | void> => {
    const authToken = getApiToken();

    const url = `/hashtag/onboarding/setup/professionals/`;

    return getRequest<DepartmentsApiResponse>(url, authToken);
  };

export const getKLProfiles = (
  offset = 0,
  limit = 0,
): Promise<PaginatedApiResponse<string>> => {
  const url = `/user/sitemap_users/?limit=${limit}&offset=${offset}`;
  return getRequest(url, getApiToken({ allowDefault: true }));
};

export const getAllReactions = (): Promise<AllReactionsApiResponse> => {
  const url = `/reaction/all/`;

  return getRequest(url, getApiToken({ allowDefault: true }));
};

// Response codes:
// - HTTP 2xx -> Success
// - HTTP 429 -> Wait for the current export to finish
export const postExportLibrary = (): Promise<void> => {
  const url = `/library/export/`;
  return postRequest(url, getApiToken());
};
