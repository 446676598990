// Event payload types

export enum ProfilePageElement {
  SAVED_IDEAS = 'saved-ideas',
  WEBSITE = 'website',
  FEED = 'feed',
  STASH = 'stash',
}

export enum UsersModalType {
  SAVES = 'saves',
  FOLLOWERS = 'followers',
  FOLLOWING = 'following',
  // [Like functionality]
  // LIKES = 'likes',
  TOP_STASHERS = 'top-stashers',
}

export enum ScrollingModalEvent {
  OPEN = 'open',
  CLOSE = 'close',
  SCROLL = 'scroll',
  ITEM_CLICK = 'item-click',
}

export enum ProfileModalEvent {
  OPEN = 'open',
  CLOSE = 'close',
  CLICK_SETTINGS = 'click-settings',
  CLICK_READING = 'click-reading',
  USERNAME_ERROR = 'username-error',
  PROFILE_SETUP = 'profile-setup',
}

export enum SourcePublishStatus {
  DRAFT = 'draft',
  PUBISHED = 'published',
}

export enum ImageType {
  LOCAL = 'local',
  LINK = 'link',
}

export enum ThemeType {
  LIGHT = 'light',
  DARK = 'dark',
  SYSTEM = 'system',
}

export enum OnboardingDecision {
  SKIP = 'skip',
  CREATE_ACCOUNT = 'create-account',
}

export enum DailyGoalLocation {
  MENU_DROPDOWN = 'menu-dropdown',
  SETTINGS_PAGE = 'settings-page',
}

export enum Platform {
  EMAIL = 'email',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export enum StashCreationLocation {
  SIDE_MENU = 'side-menu',
}

export enum IdeaCreationLocation {
  ARTICLE_PAGE = 'article-page', // implicit
  FAB = 'fab',
}

export enum FeedItem {
  RECOMMENDATION = 'recommendation',
  OWN_SOURCE = 'own-source',
  FEATURED = 'featured',
  TRENDING = 'trending',
  QUOTES = 'quotes',
  TOPICS = 'topics',
  TOPIC_BUTTON = 'topic-button',
  FEATURED_STASH = 'featured-stash',
  SAVED_IDEAS = 'saved-ideas',
  PROFILE_NAME = 'profile-name',
  RECONNECT_IDEAS = 'reconnect-ideas',
}

export enum ReadMethod {
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
}

export enum ArticleStatus {
  DEFAULT = 0,
  DRAFT = 2,
  PUBLISHED = 3,
}

export enum CuratorFunnelStep {
  VIEW_PAGE = 'view-page',
  OPEN_MODAL = 'open-modal',
  LOG_IN = 'log-in',
  CLOSE_MODAL = 'close-modal',
}

export enum CuratorModalPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum IdeaStatus {
  DEFAULT = 0,
  IN_APPROVAL = 1,
  APPROVED = 2,
  DRAFT = 3,
}

export enum Content {
  ARTICLE = 'article',
  BOOK = 'book',
  VIDEO = 'video',
  TWEET = 'tweet',
  PODCAST = 'podcast',
}

export enum ContentType {
  ARTICLE = 'article',
  BOOK = 'book',
  VIDEO = 'video',
  TWEET = 'tweet',
  PODCAST = 'podcast',
  IDEA = 'idea',
  STASH = 'stash',
  TOPIC = 'topic',
  PROFILE = 'profile',
}

export enum SettingType {
  PERSONAL_INFO = 'personal-info',
  DAILY_GOAL_ALERT = 'daily-goal-alert',
}

export enum SearchError {
  TOO_SHORT = 'too-short',
}

export enum WarningElement {
  STASH = 'stash',
  IMPORT = 'import',
  PUBLISH = 'publish',
  SHARE = 'share',
  USERNAME = 'username',
  DAILY_GOAL = 'daily-goal',
  READ = 'read',
  CREATE = 'create',
}

export enum UnfinishedIdeasModalOutcome {
  DELETE = 'delete',
  CANCEL = 'cancel',
}

export enum WarningType {
  NO_NAME = 'no-name',
  NO_FIRST_NAME = 'no-first-name',
  NO_LAST_NAME = 'no-last-name',
  NO_AUTHOR = 'no-author',
  NO_CONTENT = 'no-content',
  TOO_LONG = 'too-long',
  EXISTS = 'exists',
  NO_FINISHED_IDEAS = 'no-unfinished-ideas',
  UNFINISHED_IDEAS = 'unfinished-ideas',
  NO_IDEAS = 'no-ideas',
  NO_SOURCE = 'no-source',
  USERNAME_EXISTS = 'username-exists',
  DEEPSTASH_LINK = 'deepstash-link',
  NO_LINK = 'no-link',
  INVALID_NUMBER = 'invalid-number',
  TOO_BIG = 'too-big',
  ALREADY_READ = 'already-read',
  NO_USERNAME = 'no-username',
  // INCOMPLETE_IDEA = 'incomplete-idea',
}

export enum ExpandedContent {
  ARTICLE = 'article',
  BOOK_DESCRIPTION = 'book-description',
}

export enum ContentSource {
  DRAFTS_PAGE = 'drafts-page',
  FAB = 'fab',
}

export enum IdeaPlace {
  SAVED_IDEAS = 'saved-ideas',
  STASH = 'stash',
}

export enum SideMenuButton {
  HOME = 'home',
  TRENDING = 'trending',
  FEATURED = 'featured',
  TOPICS = 'topics',
  SAVED_IDEAS = 'saved-ideas',
  DRAFTS = 'drafts',
  PROGRESS = 'progress',
  PROFILE = 'profile',
  DROPDOWN = 'dropdown',
  LOGO = 'logo',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  MEDIUM = 'medium',
  INSTAGRAM = 'instagram',
  TIKTOK = 'tiktok',
  YOUTUBE = 'youtube',
  LINKEDIN = 'linkedin',
  ABOUT_US = 'about-us',
  FOR_BUSINESSES = 'for-businesses',
  JOBS = 'jobs',
  TERMS_OF_SERVICE = 'terms-of-service',
  PRIVACY_POLICY = 'privacy-policy',
  SITEMAP = 'sitemap',
  CURATORS = 'curators',
  YOUR_PROFILE = 'your-profile',
  NOTIFICATIONS = 'notifications',
  PRESS_KIT = 'press-kit',
  MOBILE_APP = 'mobile-app',
}

export enum PublishModalEvent {
  OPEN = 'open',
  CANCEL = 'cancel',
  PUBLISH = 'publish',
}

export enum MailingFrequency {
  DAILY_ON = 'daily-on',
  DAILY_OFF = 'daily-off',
  WEEKLY_ON = 'weekly-on',
  WEEKLY_OFF = 'weekly-off',
  PRODUCT_ON = 'product-on',
  PRODUCT_OFF = 'product-off',
}

export enum ContentTapLocation {
  HOME = 'home', // TODO
  SIMILAR = 'similar',
  ARCHIVED = 'archived',
  DRAFTS = 'drafts',
  PUBLISHED = 'published',
  KL = 'kl',
  SAVED_IDEAS = 'saved-ideas',
  STASH_PAGE = 'stash-page',
  TOPIC_PAGE = 'topic-page',
  ARTICLE_PAGE = 'article-page',
  IDEA_CARD = 'idea-card',
  IDEA_PAGE = 'idea-page',
  IDEA_SEARCH = 'idea-search',
  ARTICLE_SEARCH = 'article-search',
  STASH_SEARCH = 'stash-search',
  PROFILE_PAGE = 'profile-page',
  FAB = 'fab',
}

export enum ContentTapElement {
  TITLE = 'title',
  IMAGE = 'image',
  MAIN_IDEA = 'main-idea',
  SECONDARY_IDEA = 'secondary-idea',
  MORE = 'more',
  VIEW_BUTTON = 'view-button',
  VIEW_DROPDOWN = 'view-dropdown',
  KL_IMAGE = 'kl-image',
  KL_TITLE = 'kl-title',
  BUTTON = 'button',
  FAB_ARTICLE = 'fab-article',
  SAVE_COUNT = 'save-count',
  // [Like functionality]
  // LIKE_COUNT = 'like-count',
}

export enum InviteElement {
  TOPIC = 'topic',
  TOPICS = 'topics',
  IOS = 'ios',
  ANDROID = 'android',
  FOLLOW = 'follow',
  FOLLOWING = 'following',
  OWN = 'own',
  LOGO = 'logo',
  JOIN_WEB = 'join-web',
}

export enum NotificationScreen {
  ARTICLE_SCREEN = 'article_screen',
  ARTICLE_SCREEN_BY_ID = 'article_screen_by_id',
  COLLECTION_SCREEN = 'collection_screen',
  STASH_SCREEN = 'stash_screen',
  SIGN_UP = 'sign_up',
  SAVED_IDEAS = 'saved_ideas',
  IMPORTED_ARTICLES = 'imported_articles',
  ONBOARDING = 'onboarding',
  SETTINGS = 'settings',
  PREFERENCES = 'preferences',
  HOME = 'home',
  PROFILE_SCREEN = 'profile_screen',
  DRAFT_MODAL = 'draft_modal',
  PROGRESS_SCREEN = 'progress_screen',
  FOLLOWERS_SCREEN = 'followers_screen',
}

export enum NotificationTrackingID {
  DAILY_TRENDING_RECOMMENDATION = 'daily_trending_recommendation',
  FINISH_ONBOARDING = 'finish_onboarding',
  REGISTERATION_REMINDER = 'registeration_reminder',
  FIRST_READS_NUDGE = 'first_reads_nudge',
  DAILY_READS_GOAL_NUDGE = 'daily_reads_goal_nudge',
  STREAK_NUDGE = 'streak_nudge',
  WEEKLY_REVIEW = 'weekly_review',
  DAILY_READING_GOAL_MET = 'daily_reading_goal_met',
  FINAL_NOTIFICATION = 'final_notification',
  IMPORT_REVIEW = 'import_review',
  IMPORT_REMINDER = 'import_reminder',
  SOCIAL_PUBLISH = 'social_publish',
  SOCIAL_SAVE = 'social_save',
  SOCIAL_FOLLOW = 'social_follow',
  SOCIAL_CONTENT_PERFORMING_WELL = 'social_content_performing_well',
  SOCIAL_FOLLOWING_CONTENT_PERFORMS = 'social_following_content_performs',
  SOCIAL_SUGGEST_FOLLOW_FROM_FOLLOWING = 'social_suggest_follow_from_following',
  SOCIAL_SUGGEST_FOLLOW_FROM_FOLLOWERS = 'social_suggest_follow_from_followers',
  SOCIAL_CONTENT_READ_BY_USER = 'social_content_read_by_user',
  SOCIAL_FOLLOWERS_INCREASE = 'social_followers_increase',
}

export enum SearchList {
  STASHES = 'stashes',
  INSIGHTS = 'insights',
  ARTICLES = 'articles',
  TOPICS = 'hashtags',
}

export enum KlList {
  ARTICLES = 'articles',
  COLLECTIONS = 'collections',
  STORIES = 'stories',
}

export enum SearchTrigger {
  BUTTON = 'button',
  DROPDOWN = 'dropdown',
}

export enum StashElement {
  NAME = 'name',
  IMAGE = 'image',
  DESCRIPTION = 'description',
}

export enum ShareKind {
  LINK = 'link',
  IMAGE = 'image',
  TEXT = 'text',
}

export enum FeedType {
  HOME = 'home', // implicit
  FEATURED = 'featured',
  TRENDING = 'trending',
  FOLLOWING = 'following',
}

export enum AuthAdLocation {
  SIDE_MENU = 'side-menu',
  SAVE_BUTTON = 'save-button',
  CONTENT_AD = 'content-ad',
  FEED_BANNER = 'feed-banner',
  CURTAIN = 'curtain',
  LOGIN_PAGE = 'login-page',
}

export enum AuthOption {
  LOGIN = 'login',
  SIGNUP = 'signup',
}

export enum SearchLocation {
  OWN_IDEAS = 'own-ideas',
  ALL_IDEAS = 'all-ideas',
}

export enum OnboardingStepPropName {
  'localize' = 'welcome-screen',
  'yourself' = 'department',
  'daily' = 'daily-goal',
  'choice' = 'daily-goal-confirmation',
  'hashtags' = 'topics',
  'people' = 'following',
  'ready' = 'all-done',
  'paywall' = 'paywall',
  'readyPro' = 'all-done',
  'welcome' = 'jtbd-welcome',
  'goals' = 'jtbd-goals',
  'improvements' = 'jtbd-improvements',
  'loading' = 'jtbd-loading',
  'loading-done' = 'jtbd-loading-done',
}

export enum OnboardingStepPropNumber {
  'localize' = 20,
  'yourself' = 21,
  'daily' = 22,
  'choice' = 23,
  'hashtags' = 24,
  'people' = 25,
  'ready' = 27,
  'paywall' = 26,
  'readyPro' = 27,
  'welcome' = 30,
  'goals' = 31,
  'improvements' = 32,
  'loading' = 34,
  'loading-done' = 35,
}

export enum Achievement {
  IdeasCreated = 'ideas_created',
  ArticlesImported = 'articles_imported',
  StashedCreated = 'stashes_created',
  IdeasSaved = 'ideas_saved',
  IdeasRead = 'ideas_read',
}

/**
 * Used for the record in DeleteAccountScreen
 */
export enum DeleteAccountReason {
  HowItWorks = "I don't understand how it works",
  NoUsefulContent = "I don't find the content useful",
  OwnLanguage = 'I want content in my own language',
  DifferentApp = 'I switched to a different app',
  SomethingElse = 'Something else',
}

export enum SourceType {
  // Not final values
  ARTICLE = 0,
  BOOK = 1,
  VIDEO = 2,
  PODCAST = 3,
  YOURSELF = 4,
  UNKNOWN = 5,
}

export enum SourceSubtype {
  // Not final values
  DEFAULT = 0,
  VIDEO_YOUTUBE = 1,
  PODCAST_SPOTIFY = 2,
}

/**
 * Used for the greeting word in SetupModal
 */
export enum LanguageCode {
  english = 'en',
  french = 'fr',
  german = 'de',
  indonesian = 'id',
  italian = 'it',
  korean = 'ko',
  japanese = 'ja',
  spanish = 'es',
  russian = 'ru',
  turkish = 'tr',
  hindi = 'hi',
  dutch = 'nl',
  swedish = 'sv',
  malay = 'ms',
  danish = 'da',
  polish = 'pl',
  hebrew = 'he',
  finnish = 'fi',
  portuguese = 'pt',
}

export enum IdeaType {
  DEFAULT = 0,
  QUOTE = 1,
  IMAGE = 2,
}

export enum ReviewStatus {
  IN_REVIEW,
  REVIEWED,
  PUBLISHED,
}

export enum DraftsError {
  NO_ERROR = 'no-error',
  NO_SOURCE = 'no-source',
  NO_IDEAS = 'no-ideas',
  NO_CONTENT = 'no-content',
  CHARS_LIMIT = 'chars-limit',
  NO_AUTHOR = 'no-author',
  NO_TITLE = 'no-title',
}

/**
 * Based on this, the users can or cannot make some actions.
 */
export enum UserSubscriptionType {
  Legacy = 0,
  Free = 1,
  Premium = 2,
}

/**
 * The status for a user subscription
 * [0 -> Active] |
 * [1 -> Canceled] |
 * [2 -> On Hold (subscription free w/o details)] |
 * [3 -> in grace (another field in_grace_end_date)] |
 * [4 -> paused (subscription, also free)]
 * [5 -> expired]
 * [6 -> revoked (apple only)]
 */
export enum UserSubscriptionStatus {
  Active,
  Canceled,
  OnHold,
  InGrace,
  Paused,
  Expired,
  Revoked,
}

export enum SubscriptionPlan {
  ProMonthly = 1,
  ProYearly = 2,
}

export enum PaymentProvider {
  GOOGLE = 0,
  APPLE = 1,
  STRIPE = 2,
  DEEPSTASH = 3,
}

export enum PaywallAnalyticsLocation {
  HashtagFilterCarousel = 'hashtag-filter-carousel',
  BlockedBlurredIdea = 'blocked-blurred-idea',
  NewStashFromProfile = 'new-stash-from-profile',
  NewStashFromDrawer = 'new-stash-from-drawer',
  NewStashFromPublish = 'new-stash-from-publish',
  SortIdeasButton = 'sort-ideas-button',
  SettingsPage = 'settings-page',
  Navbar = 'navbar',
  SetupSimple = 'setup-simple',
  SetupInfluencer = 'setup-influencer',
  LoginInfluencer = 'login-influencer',
  AlreadyLoggedInInfluencer = 'already-logged-in-influencer',
  DevMenu = 'dev-menu',
  SIMobileBlocked = 'si-mobile-blocked',
  FeedCollections = 'feed-collections',
  AllCollections = 'all-collections',
  KlCollections = 'kl-collections',
  ReadCollectionSourcePage = 'read-collection-source-page',
  CollectionContainer = 'collection-container',
  GetProPage = 'get-pro-page',
  SavedIdeas = 'saved-ideas',
  SaveIdeaButton = 'save-idea-button',
  MoveToStashDropdown = 'move-to-stash-dropdown',
  PaywallSubscriptionPage = 'paywall-subscription-page',
  TopSaleBanner = 'top-sale-banner',
  ProAdCta = 'pro-ad-cta',
  ProductHunt = 'product-hunt',
  BlackFriday22 = 'black-friday-22',
  Christmas22 = 'xmas-22',
  Christmas = 'xmas',
  SpecialOffer = 'special23',
  Quiz = 'quiz',
}

export enum CollectionStatus {
  NEW = 'new',
  STARTED = 'started',
  COMPLETED = 'completed',
}
