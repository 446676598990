import 'firebase/analytics';
import firebase from 'firebase/app';

const clientCredentials = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

export const initFirebase = () => {
  if (typeof window !== 'undefined' && !firebase.apps.length) {
    firebase.initializeApp(clientCredentials);
    firebase.analytics();
  }
};

initFirebase();

export default firebase;
