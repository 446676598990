import { useRouter } from 'next/router';
import { useEffect } from 'react';
import useAuth from 'src/providers/hooks/useAuth';
import useNsi from 'src/providers/hooks/useNsi';
import { authType } from '../../providers/nsi/NsiProviderTypes';

type NSIEnterActionType = 'redirect' | 'show-auth-modal';

interface UseHandleNsiProps {
  /**
   * True if the user should be shown the auth modal when NSI.
   * @default 'show-auth-modal'
   */
  onNsiEnterAction?: NSIEnterActionType;
  /**
   * The action the user will be suggested to take:
   * 'sign-in' or 'sign-up'
   * @default 'sign-in'
   */
  authModalActionType?: authType;
  /**
   * The URL the user will be redirected to, if
   * onNsiEnterAction = 'redirect'.
   * @default '/''
   */
  redirectUrl?: string;
}

/**
 * A hook to handle NSI users for SI-restricted pages.
 */
const useHandleNsi = ({
  onNsiEnterAction = 'show-auth-modal',
  authModalActionType = 'sign-in',
  redirectUrl = '/',
}: UseHandleNsiProps) => {
  const { isLoggedIn } = useAuth();
  const { nsiDispatch } = useNsi();
  const router = useRouter();

  useEffect(() => {
    if (!isLoggedIn) {
      switch (onNsiEnterAction) {
        case 'show-auth-modal': {
          nsiDispatch({
            type: 'openAuth',
            payload: {
              type: authModalActionType,
              preventClosure: true,
            },
          });
          return;
        }
        case 'redirect': {
          router.replace(redirectUrl);
          return;
        }
        default:
          return;
      }
    }
  }, [isLoggedIn, nsiDispatch, router]);

  return;
};

export default useHandleNsi;
