import { AuthModalProps } from 'providers/auth';
import { NsiProviderAction, NsiProviderStateType } from './NsiProviderTypes';

const createNsiReducer = (
  onOpen: (authModalProps: AuthModalProps) => void,
  onClose: () => void,
) => {
  return (state: NsiProviderStateType, action: NsiProviderAction) => {
    switch (action.type) {
      case 'openAuth':
        onOpen(action.payload);
        return state;
      case 'closeAuth':
        onClose();
        return state;
      case 'set-goal': {
        const { setupGoal } = action.payload;
        return {
          ...state,
          setupGoal,
        };
      }
      case 'set-email': {
        const { email } = action.payload;
        return {
          ...state,
          email,
        };
      }
      case 'set-password': {
        const { password } = action.payload;
        return {
          ...state,
          password,
        };
      }
      case 'add-source-like': {
        const { sourceId } = action.payload;
        return {
          ...state,
          likedSourceIds: [...new Set([...state.likedSourceIds, sourceId])],
        };
      }
      case 'set-source-likes': {
        const { likedSourceIds } = action.payload;
        return {
          ...state,
          likedSourceIds,
        };
      }
      default: {
        return state;
      }
    }
  };
};
export default createNsiReducer;
