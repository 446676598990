import { useEffect, useMemo, useState } from 'react';
import { StripePricesData } from 'types/models';
import { normalizeStripePrices } from 'utils/normalizers/purchases';
import useProfile from 'src/providers/hooks/useProfile';
import { UserSubscriptionType } from 'types/enums';
import { Observable } from 'utils/global';
import { RequestStatus } from 'types';
import * as PurchaseApi from 'api/Purchases';

type StripeCacheItem = {
  price: StripePricesData | undefined;
  requestStatus: RequestStatus;
  errorMessage?: string;
};

const STRIPE_PRICES_CONTROLLER: {
  priceCache: Record<string, Observable<StripeCacheItem>>;
} = {
  priceCache: {},
};

const useStripePrices = ({
  promoCode,
  requestNsi,
  extraRequestParam,
}: {
  promoCode?: string;
  requestNsi?: boolean;
  extraRequestParam?: string;
}) => {
  const [stripePrices, setStripePrices] = useState<
    StripePricesData | undefined
  >(undefined);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>('loading');
  const [errorMessage, setErrorMessage] = useState('');

  const { profile } = useProfile();

  const isProUser = profile?.subscriptionType === UserSubscriptionType.Premium;

  // We want to refetch the prices if we are not in the live env & with a dev account
  const shouldFetchDevPrices = !!profile?.email.endsWith('@deepstash.com');

  const cacheKey = useMemo(
    () =>
      `${isProUser} ${shouldFetchDevPrices} ${promoCode} ${profile?.id} ${extraRequestParam}}`,
    [
      isProUser,
      shouldFetchDevPrices,
      promoCode,
      profile?.id,
      extraRequestParam,
    ],
  );

  const fetchPrices = async () => {
    setRequestStatus('loading');
    try {
      const pricesApiResponse = await PurchaseApi.getStripePrices({
        promoCode,
        extraRequestParam,
        customToken: localStorage.getItem('quizAccessToken'),
      });

      setRequestStatus('success');

      STRIPE_PRICES_CONTROLLER.priceCache[cacheKey].updateData({
        price: normalizeStripePrices(pricesApiResponse),
        requestStatus: 'success',
      });
      setErrorMessage('');
    } catch (e) {
      STRIPE_PRICES_CONTROLLER.priceCache[cacheKey].updateData({
        price: undefined,
        requestStatus: 'failure',
        errorMessage: (e as any)?.data?.error as string,
      });
      setRequestStatus('failure');
      setErrorMessage((e as any)?.data?.error as string);
      console.log('[useStripePrices Error] :', e);
    }
  };

  // Get stripe prices from backend
  useEffect(() => {
    if (!profile?.id && !requestNsi) {
      // The user is NSI (don't use `isLoggedIn` since that changes before the profile is loaded), but we don't want to make the price request for NSI
      // We can't yet decide if we should request live prices or test prices and we don't need NSI requests
      return () => {
        return;
      };
    }
    const observable = STRIPE_PRICES_CONTROLLER.priceCache[cacheKey];
    const onData = (cacheData: StripeCacheItem) => {
      setRequestStatus(cacheData.requestStatus);
      setStripePrices(cacheData.price);
      setErrorMessage(cacheData.errorMessage || '');
    };
    if (!observable) {
      // This is the first fetch for this cache key so create the observable and kick off the fetch
      // Following calls for this cache key will subscribe to this and wait for data
      STRIPE_PRICES_CONTROLLER.priceCache[cacheKey] =
        new Observable<StripeCacheItem>({
          price: undefined,
          requestStatus: 'loading',
        });
      // Now subscribe to the observable to receive the data when available
      STRIPE_PRICES_CONTROLLER.priceCache[cacheKey].subscribe(onData);
      fetchPrices();
      return () => {
        return;
      };
    } else {
      observable.subscribe(onData);
      return () => {
        observable.unsubscribe(onData);
      };
    }
  }, [cacheKey, profile?.id, requestNsi]);

  return { fetchPrices, stripePrices, requestStatus, errorMessage };
};
export default useStripePrices;
