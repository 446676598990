import { useRouter } from 'next/router';
import usePaywall from 'providers/hooks/usePaywall';
import { useEffect, useRef, useState } from 'react';
import useProfile from 'src/providers/hooks/useProfile';
import { PaywallAnalyticsLocation, UserSubscriptionType } from 'types/enums';

const useCheckPromoCodeAfterSignIn = (promoCodeFromProps?: string) => {
  const router = useRouter();
  const { profile } = useProfile();

  const { onPaywallModalOpen, onPaywallModalClose, isPaywallOpen } =
    usePaywall();

  const [promoCode, setPromoCode] = useState(
    promoCodeFromProps ?? router.query.promo_code?.toString(),
  );
  const initialRender = useRef(true);
  const onDisablePromoCode = () => setPromoCode(undefined);

  useEffect(() => {
    if (initialRender.current) {
      // Don't do anything on the initial render since it will mess up the initial state value
      initialRender.current = false;
    } else {
      setPromoCode(router.query.promo_code?.toString());
    }
  }, [router.query.promo_code?.toString()]);

  useEffect(() => {
    // We also have profile?.department !== null -- to show the paywallModal only to new accounts
    // Department is null only if you create a new account that has not completed the setup
    // Legacy accounts which didn't choose a department the value for the department is an empty string, not null
    // So we have all the cases covered
    if (
      promoCode &&
      profile?.department !== null &&
      profile?.subscriptionType !== UserSubscriptionType.Premium
    ) {
      onPaywallModalOpen({
        location: PaywallAnalyticsLocation.LoginInfluencer,
        showCloseButton: true,
        promoCode,
      });
    }
  }, [promoCode]);

  return {
    isPaywallModalOpen: isPaywallOpen,
    onPaywallModalClose,
    promoCode,
    onDisablePromoCode,
  };
};

export default useCheckPromoCodeAfterSignIn;
