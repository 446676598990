import {
  Modal,
  useDisclosure,
  useIsMobileView,
  spacing,
  useColorMode,
  color,
} from 'deepstash-ui';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import AuthModalContent from './auth-modal-content/AuthModalContent';
import {
  AuthModalRefType,
  authType,
} from '../../../providers/nsi/NsiProviderTypes';
import useRouter from 'hooks/useRouter';
import { CurrentProfileApiResponse } from 'api/api.types';
import { AuthModalProps } from 'providers/auth';

interface OwnProps {
  trackLogin?: (email: string) => void;
}

// eslint-disable-next-line react/display-name
export const AuthModal = React.forwardRef<AuthModalRefType, OwnProps>(
  ({ trackLogin }, ref) => {
    const isMobileView = useIsMobileView();
    const { colorMode } = useColorMode();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const router = useRouter();
    const [authModalType, setAuthModalType] = useState<authType>('sign-up');
    const discountCodeRef = useRef<string>();
    const preventClosureRef = useRef<boolean>(false);
    const shouldOpenEmailFieldsRef = useRef<boolean>(false);
    const onAuthSuccessRef =
      useRef<(profile: CurrentProfileApiResponse) => void>();
    const shouldSkipOnboardingRef = useRef<boolean>(false);

    const onOpenAuthModal = useCallback(
      ({
        type,
        discountCode,
        preventClosure,
        shouldOpenEmailFields,
        onAuthSuccess,
        shouldSkipOnboarding,
      }: AuthModalProps) => {
        setAuthModalType(type);
        discountCodeRef.current = discountCode;
        preventClosureRef.current = !!preventClosure;
        shouldOpenEmailFieldsRef.current = !!shouldOpenEmailFields;
        onAuthSuccessRef.current = onAuthSuccess;
        shouldSkipOnboardingRef.current = !!shouldSkipOnboarding;
        if (!isOpen) {
          onOpen();
        }
      },
      [isOpen, onClose, router.pathname],
    );

    useEffect(() => {
      if (router.query.code && isMobileView) {
        // Auth code for social auth came back from redirect
        // Open the modal to login the user

        onOpen();
      }
    }, [router.query]);

    useImperativeHandle(
      ref,
      () => ({
        onOpenAuth: (authModalProps: AuthModalProps) => {
          onOpenAuthModal(authModalProps);
        },
        onCloseAuth: () => {
          onClose();
        },
      }),
      [onOpenAuthModal, onClose],
    );

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        w={{ base: '100%', lg: spacing.toRem(526) }}
        maxH={`calc(100% - ${spacing.XS.rem})`} // a small spacing is left for the modal to not touch the borders of the window
        modalBodyProps={{ padding: 0 }}
        p={0}
        borderBottomRadius={{ base: 0, lg: spacing.L.rem }}
        modalPositionProps={{
          alignItems: isMobileView ? 'flex-end' : 'center',
        }}
        bottom={{ base: 0, lg: 'unset' }}
        closeOnClickOutside={!preventClosureRef.current}
        closeButton={preventClosureRef.current ? 'none' : 'inside'}
        color={color[colorMode].text}
      >
        <AuthModalContent
          authModalType={authModalType}
          trackLogin={trackLogin}
          discountCode={discountCodeRef.current}
          shouldOpenEmailFields={shouldOpenEmailFieldsRef.current}
          onAuthSuccess={onAuthSuccessRef.current}
          shouldSkipOnboarding
          closeModal={onClose}
        />
      </Modal>
    );
  },
);

export default AuthModal;
