import {
  color,
  Flex,
  Modal,
  PrimaryHeading,
  spacing,
  Spinner,
  useIsMobileView,
} from 'deepstash-ui';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { Analytics, Events } from 'services/analytics';
import { FreeTrialData } from 'types/models';
import { ProductType, PurchaseType, SubscriptionType } from 'types/types';
import pageStrings from 'utils/strings/pageStrings';
import PaywallCheckoutLeftBanner from './PaywallCheckoutLeftBanner';
import PaywallCheckoutModalContent from './PaywallCheckoutModalContent';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, StripeElementsOptions } from '@stripe/stripe-js';
import { getFacebookTrackingParams } from 'utils/global';

export interface PaymentCheckoutDetails {
  freeTrial?: FreeTrialData;
  priceAmount: number;
  renewalPriceAmount?: number;
  priceCurrency: string;
  subscriptionOrProductType: SubscriptionType | ProductType;
}
interface PaywallCheckoutModalProps {
  isCheckoutModalOpen: boolean;
  onCheckoutModalClose: () => void;
  clientSecret: string;
  paymentDetails: PaymentCheckoutDetails;
  isFromSetup: boolean;
  subscriptionId: string;
  location?: string;
  disclaimer?: string;
  stripe: Stripe | PromiseLike<Stripe | null> | null;
  elementsOptions?: StripeElementsOptions;
  extraRequestParam?: string;
  type: PurchaseType;
}

const PaywallCheckoutModal: React.FC<PaywallCheckoutModalProps> = ({
  isCheckoutModalOpen,
  onCheckoutModalClose,
  clientSecret,
  paymentDetails,
  isFromSetup,
  subscriptionId,
  disclaimer,
  location,
  stripe,
  elementsOptions,
  extraRequestParam,
  type,
}) => {
  const isMobileView = useIsMobileView();
  const router = useRouter();
  const [isPaymentIntentLoading, setIsPaymentIntentLoading] = useState(true);

  const onClose = () => {
    onCheckoutModalClose();
    setIsPaymentIntentLoading(true);
  };

  useEffect(() => {
    if (isCheckoutModalOpen) {
      Analytics.logEvent({
        eventName: Events.payments.viewCheckoutModal,
        properties: {
          location,
          url: router.asPath,
          type,
          subscriptionType: paymentDetails.subscriptionOrProductType,
          price: paymentDetails.priceAmount,
          currency: paymentDetails.priceCurrency,
          hasTrial: paymentDetails.freeTrial,
          email: localStorage?.getItem('quizEmail'),
          extraRequestParam,
          ...getFacebookTrackingParams(),
        },
        platforms: [
          !!localStorage?.getItem('quizEmail')
            ? 'amplitude-mobile'
            : 'amplitude',
        ],
      });
    }
  }, [isCheckoutModalOpen]);

  return (
    <Modal
      isOpen={isCheckoutModalOpen}
      onClose={onClose}
      width={{ base: '100%', lg: spacing.toRem(800) }}
      height={{ base: '100%', lg: spacing.toRem(700) }}
      bg={color.light.surface}
      bottom={{ base: '0%', lg: 'unset' }}
      borderRadius="1rem"
      overflow="hidden"
      borderBottomRadius={{ base: '0rem', lg: '1rem' }}
      modalBodyProps={{
        paddingInline: '0rem',
      }}
      colorMode="light"
      py={0}
    >
      <PrimaryHeading
        size="h3"
        textSize={isMobileView ? 's' : 'm'}
        mt={{ base: spacing.L.rem, lg: spacing.XXXL.rem }}
        type="extraBold"
        px={spacing.XXXL.rem}
        color={color.light.text}
      >
        {pageStrings.checkout.title}
      </PrimaryHeading>
      <Flex
        flexDir={{ base: 'column', lg: 'row' }}
        mt={{ base: spacing.M.rem, lg: spacing.XXXL.rem }}
        height="100%"
      >
        <PaywallCheckoutLeftBanner
          paymentDetails={paymentDetails}
          disclaimer={disclaimer}
        />
        {clientSecret ? (
          <Elements stripe={stripe} options={elementsOptions}>
            <PaywallCheckoutModalContent
              clientSecret={clientSecret}
              paymentDetails={paymentDetails}
              isPaymentIntentLoading={isPaymentIntentLoading}
              setIsPaymentIntentLoading={val => setIsPaymentIntentLoading(val)}
              isFromSetup={isFromSetup}
              subscriptionId={subscriptionId}
              productType={
                type === 'product' ? 'lifetime_subscription' : undefined
              }
              location={location}
              containerStyleProps={{
                px: { base: spacing.M.rem, lg: spacing.XXXL.rem },
                pb: { base: spacing.M.rem, lg: spacing.XXXL.rem },
                height: '100%',
                backgroundColor: color.light.background,
              }}
              extraRequestParam={extraRequestParam}
              type={type}
            />
          </Elements>
        ) : (
          <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
            <Spinner />
          </Flex>
        )}
      </Flex>
    </Modal>
  );
};

export default PaywallCheckoutModal;
