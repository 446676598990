import { PaywallContext } from 'providers/paywall';
import { useContext } from 'react';

const usePaywall = () => {
  const { isPaywallOpen, onPaywallModalOpen, onPaywallModalClose } =
    useContext(PaywallContext);
  return {
    isPaywallOpen,
    onPaywallModalOpen,
    onPaywallModalClose,
  };
};

export default usePaywall;
