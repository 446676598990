import { Flex, Modal, spacing } from 'deepstash-ui';
import { StaticImageData } from 'next/image';
import React from 'react';
import NextImage from 'src/components/image/NextImage';

interface OwnProps {
  isOpen: boolean;
  onClose: () => void;
  image?: string;
  imageBlurhash?: string;
  height?: string;
  width?: string;
  rounded?: boolean;
  defaultImage?: StaticImageData;
}

const ImageModal: React.FC<OwnProps> = ({
  isOpen,
  onClose,
  image,
  imageBlurhash,
  height = '400px',
  width = '700px',
  rounded = false,
  defaultImage,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{ backgroundColor: 'transparent' }}
      modalBodyProps={{
        overflowY: 'hidden',
        padding: 0,
      }}
      height={height}
      width={rounded ? height : width}
      closeButton="outside"
      borderRadius={rounded ? 'full' : spacing.M.rem}
      padding={0}
    >
      <Flex width={rounded ? height : width} height={height}>
        <NextImage
          wrapperStyle={{
            height,
            //If the image is round, match the width and height
            width: rounded ? height : width,
            borderRadius: rounded ? 'full' : spacing.M.rem,
          }}
          imageUrl={image}
          defaultImage={defaultImage}
          blurhash={imageBlurhash}
          objectFit="contain"
        />
      </Flex>
    </Modal>
  );
};

export default ImageModal;
