export const AUTH = 'authentication';
export const HOME = 'home';
export const AUX = 'aux';
export const SAVED_IDEAS = 'saved-ideas';
export const STASH = 'stash';
export const TOPIC = 'topic';
export const ARTICLE = 'article';
export const DRAFTS = 'drafts';
export const KNOWLEDGE_LIBRARY = 'knowledge-library';
export const TOPICS = 'topics';
export const INSIGHT = 'insight';
export const TRENDING = 'trending';
export const FEATURED = 'featured';
export const PROGRESS = 'progress';
export const PROFILE = 'profile';
export const SEARCH = 'search';
export const SETTINGS = 'settings';
export const INVITE = 'invite';
export const SHARE = 'share';
export const ERROR = 'error';
export const NOT_FOUND = 'not-found';
export const NOTIFCIATIONS = 'notifications';
export const PREFERENCES = 'preferences';
export const PERSONAL_INFORMATION = 'personal-information';
export const EMAIL_SUBSCRIPTIONS = 'email-subscriptions';
export const SETUP = 'setup';
export const ABOUT = 'about';
export const MOBILE_APP = 'mobile-app';
export const INFLUENCER = 'influencer';
export const SI_MOBILE_BLOCKED = 'si-mobile-blocked';
export const PAYWALL = 'paywall';
export const CHECKOUT = 'checkout';
export const ALL_COLLECTIONS = 'all-collections';
export const ADD_SOURCE = 'add-source';
export const IDEA = 'idea';
export const FOR_TEAMS = 'for-teams';
export const HOW_IT_WORKS = 'how-it-works';
export const LANDING = 'landing';
export const GET_PRO = 'get-pro';
export const EXPLORE = 'explore';
export const ONBOARDING = 'onboarding';
export const PRODUCT_HUNT = 'product-hunt';
export const REDEEM = 'redeem';
export const ACTIVATE_ACCOUNT = 'activate-account';
export const QUIZ_FUNNEL = 'quiz-funnel';
export const SETUP_SIGN_UP = 'setup-sign-up';
export const STUDIO = 'studio';
