import {
  PrimaryText,
  color,
  Link,
  useColorMode,
  Flex,
  FlexProps,
} from 'deepstash-ui';
import React from 'react';

const SignUpAgreement: React.FC<FlexProps> = props => {
  const { colorMode } = useColorMode();

  return (
    <Flex width="100%" justifyContent={'center'} {...props}>
      <PrimaryText
        size="s"
        color={color[colorMode].textDisabled}
        textAlign="center"
      >
        By creating an account, you agree with our <br />
        <Link href="/terms-of-service" textDecor={'underline'} isExternal>
          <PrimaryText display="inline-flex">Terms of Service</PrimaryText>
        </Link>
        {' & '}
        <Link href="/privacy-policy" textDecor={'underline'} isExternal>
          <PrimaryText display="inline-flex">Privacy Policy</PrimaryText>
        </Link>
      </PrimaryText>
    </Flex>
  );
};

export default SignUpAgreement;
