import useLocation from 'hooks/useLocation';
import { useEffect } from 'react';
import jsCookie from 'js-cookie';

// Pages to ignore as they are part of the onboarding flow
const BLACKLISTED_LOCATIONS = ['setup', 'activate-account', 'purchase'];

const useLastLocation = () => {
  const location = useLocation();

  useEffect(() => {
    if (
      !jsCookie.get('lastLocation') ||
      !BLACKLISTED_LOCATIONS.includes(location)
    ) {
      jsCookie.set('lastLocation', location === '' ? 'home' : location, {
        expires: 1,
      });
    }
  }, [location]);

  return {
    lastLocation: jsCookie.get('lastLocation') ?? 'none',
  };
};

export default useLastLocation;
