import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import {
  ActivityProviderContextType,
  ActivityProviderReducer,
  ActivityProviderState,
} from './ActivityProviderTypes';
import { UserActivity } from 'src/types/models';
import activityReducer from './ActivityReducer';
import { useColorMode, useToast } from 'deepstash-ui';
import useProfile from '../hooks/useProfile';
import { TOAST_TIME } from 'utils/constants';
import { Router } from 'next/router';
import usePrevious from 'hooks/usePrevious';
import GoalToast from 'src/components/toasts/GoalToast';
import { Analytics, Events } from 'src/services/analytics';
import { getCollectionIdOnSourcePage } from 'utils/collection.utils';
import * as Sentry from '@sentry/nextjs';
import { Severity } from '@sentry/nextjs';
import { getObjectDiff } from 'utils/global';
import {
  SESSION_STORAGE_READ_KEY,
  SESSION_STORAGE_STASH_KEY,
  SESSION_STORAGE_TIMER_KEY,
} from '../nsi-activity';
import { createStash, toggleIdeaStash } from './ActivityActions';

const NSI_STASH_NAME = 'My Ideas';
const NSI_STASH_EMOJI = '♥';

const INITIAL_STATE: ActivityProviderState = {
  activity: undefined,
  isBannerOpen: false,
};

export const ActivityContext = createContext<ActivityProviderContextType>({
  dispatch: () => {
    return;
  },
  state: INITIAL_STATE,
});

ActivityContext.displayName = 'ActivityContext';

interface ActivityProviderProps {
  activity?: UserActivity;
}

const ActivityProvider: React.FC<ActivityProviderProps> = ({
  activity,
  children,
}) => {
  const [state, dispatch] = useReducer<ActivityProviderReducer>(
    activityReducer,
    { activity, isBannerOpen: false },
  );

  const prevActivity = usePrevious(state.activity);

  useEffect(() => {
    //Keep the reducer in sync with the prop
    // The activity prop gets updated once every 20 minutes when we recheck `/current`
    if (activity) {
      dispatch({
        type: 'set-activity',
        payload: {
          activity,
          keepSessionReads: true,
        },
      });
    }
  }, [activity]);

  useEffect(() => {
    if (!profile) {
      return;
    }

    // Check to see if there was any activity during NSI
    const nsiStashes = sessionStorage.getItem(SESSION_STORAGE_STASH_KEY);
    const nsiReads = sessionStorage.getItem(SESSION_STORAGE_READ_KEY);

    if (nsiReads) {
      // Parse the saved data and save it in the activity provider
      const parsedReads = JSON.parse(nsiReads);
      dispatch({
        type: 'bulk-read-increment',
        payload: {
          reads: (parsedReads.sessionReads as number[]).reduce(
            (acc, current) => {
              acc[current] = 1;
              return acc;
            },
            {} as Record<number, number>,
          ),
          readingGoal: profile.dailyReadingGoal,
        },
      });
    }

    if (nsiStashes) {
      // Parse the saved data
      const parsedStashes: {
        stashedIdeas: Record<number, { sourceId: number; ideaId: number }>;
        stashedSources: number[];
      } = JSON.parse(nsiStashes);

      if (Object.keys(parsedStashes.stashedIdeas).length !== 0) {
        // We have ideas to stash

        // Check to see if the user has the NSI stash
        const nsiStash = state.activity?.stashes?.find(
          stash => stash.name === NSI_STASH_NAME,
        );

        const stashNsiIdeas = (stashId: number) => {
          // Save the stashed ideas to the backend and activity provider
          for (const key in parsedStashes.stashedIdeas) {
            const { ideaId, sourceId } = parsedStashes.stashedIdeas[key];
            toggleIdeaStash(dispatch, { ideaId, stashId, sourceId });
          }
        };

        if (!nsiStash) {
          // Create a new stash for these ideas and then stash them
          createStash(dispatch, {
            name: NSI_STASH_NAME,
            emoji: NSI_STASH_EMOJI,
          }).then(stash => stashNsiIdeas(stash.id));
        } else {
          stashNsiIdeas(nsiStash.id);
        }
      }
    }

    // Clear the session storage
    sessionStorage.removeItem(SESSION_STORAGE_READ_KEY);
    sessionStorage.removeItem(SESSION_STORAGE_STASH_KEY);
    sessionStorage.removeItem(SESSION_STORAGE_TIMER_KEY);
  }, []);

  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch],
  );

  //Reads section

  const prevReads = usePrevious(state.activity?.readToday);
  const toast = useToast();
  const { colorMode } = useColorMode();
  const { profile } = useProfile();

  //Daily goal toast
  useEffect(() => {
    if (
      profile?.readingGoalAlert &&
      prevReads !== undefined &&
      prevReads < profile.dailyReadingGoal &&
      (state.activity?.readToday ?? 0) >= profile.dailyReadingGoal
    ) {
      toast({
        id: 'reading-goal',
        duration: TOAST_TIME,
        // eslint-disable-next-line react/display-name
        render: () => (
          <GoalToast currentReadsCount={state.activity?.readToday ?? 0} />
        ),
      });

      // Send the analytics for daily goal reached
      Analytics.logEvent({
        eventName: Events.consumption.dailyGoalReached,
        properties: {
          ideasNumber: state.activity?.readToday ?? 0,
        },
        platforms: ['amplitude'],
      });
    }
  }, [
    state.activity?.readToday,
    profile?.readingGoalAlert,
    colorMode,
    profile?.dailyReadingGoal,
  ]);

  //Sync reads

  useEffect(() => {
    const syncData = () => {
      dispatch({
        type: 'sync-reads',
        payload: {
          collectionId: getCollectionIdOnSourcePage(),
        },
      });
    };
    window.addEventListener('blur', syncData);
    window.addEventListener('beforeunload', syncData);
    Router.events.on('routeChangeStart', syncData);

    return () => {
      window.removeEventListener('blur', syncData);
      window.removeEventListener('beforeunload', syncData);
      Router.events.off('routeChangeStart', syncData);
    };
  }, []);

  useEffect(() => {
    //Log the differences
    if (!prevActivity || !state.activity) {
      Sentry.addBreadcrumb({
        data: state.activity,
        category: 'activity',
        level: Severity.Log,
        message: 'Initial activity',
      });
      return;
    }

    const diff = getObjectDiff(state.activity, prevActivity);

    Sentry.addBreadcrumb({
      data: diff,
      category: 'activity',
      level: Severity.Log,
      message: 'Activity updated',
    });
  }, [state.activity]);

  return (
    <ActivityContext.Provider value={contextValue}>
      {children}
    </ActivityContext.Provider>
  );
};

export default ActivityProvider;
