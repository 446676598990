import { ListOrder } from 'types';
import { KNOW_LIB_STASH_COUNT, STASH_LOAD_LIMIT } from 'utils/constants';
import {
  StashApiResponse,
  StashesResponse,
  UpdateElementResponse,
  UpdateStashRequestData,
} from './api.types';
import {
  deleteRequest,
  getApiToken,
  getRequest,
  patchRequest,
  postRequest,
} from './apiRequest';

/**
 * Api call to fetch all information for a stash
 * @param id The id of the stash
 */
export function fetchStash(
  stashId: number,
  sortBy?: ListOrder,
): Promise<StashApiResponse> {
  const token = getApiToken();
  const url = `/stash/${stashId}/?${
    sortBy ? `order=${sortBy}&` : ''
  }expanded=&article_limit=${STASH_LOAD_LIMIT}&article_offset=0`;
  return getRequest<StashApiResponse>(url, token);
}

export function fetchLatestStashes(offset = 0): Promise<StashesResponse> {
  const url = `/stash/latest/?limit=${KNOW_LIB_STASH_COUNT}&offset=${offset}`;
  return getRequest<StashesResponse>(url, getApiToken({ allowDefault: true }));
}

/**
 * Api call to create a new stash
 * @param name The name of the new stash
 */
export function createStash(
  name: string,
  emoji?: string,
): Promise<StashApiResponse> {
  const token = getApiToken();

  const url = '/stash/';
  const data: UpdateStashRequestData = {
    name,
    emoji,
  };

  return postRequest<StashApiResponse, UpdateStashRequestData>(
    url,
    token,
    data,
  );
}

/**
 * Api call to delete a user stash
 * @param id The id of the stash
 */
export function deleteStash(stashId: number): Promise<UpdateElementResponse> {
  const token = getApiToken();

  const url = `/stash/${stashId}/`;
  return deleteRequest<UpdateElementResponse>(url, token);
}

/**
 * Api call to modify a stash. Currently can only update name
 * @param id The id of the stash
 * @param data Object containing the new stash information
 */
export function updateStash(
  stashId: number,
  data: UpdateStashRequestData,
): Promise<StashApiResponse> {
  const token = getApiToken();

  const url = `/stash/${stashId}/`;

  return patchRequest<StashApiResponse, UpdateStashRequestData>(
    url,
    token,
    data,
  );
}
