import * as React from 'react';
import { Icon, IconProps } from 'deepstash-ui';

export const SocializingIcon = React.memo<IconProps>(props => {
  return (
    <Icon width="48px" height="48px" viewBox="0 0 48 48" fill="none" {...props}>
      <path
        d="M28.3084 36.2286C26.9546 36.8861 25.3796 37.6644 23.7094 38.4321C22.7107 38.9001 21.6663 39.3619 20.6245 39.7859C19.9194 40.09 19.2019 40.3614 18.4995 40.6082C17.8349 40.8427 17.203 41.0824 16.5957 41.323C15.5451 41.7276 14.5685 42.133 13.6104 42.4997C12.519 42.9317 11.4001 43.2909 10.2612 43.575C9.84901 43.6308 9.42953 43.5905 9.03557 43.4571C8.64162 43.3237 8.28388 43.101 7.99038 42.8063C7.69688 42.5116 7.47559 42.153 7.34384 41.7585C7.21209 41.364 7.17345 40.9443 7.23096 40.5324C7.49097 39.4748 7.81972 38.329 8.20048 37.11L8.20753 37.1038C8.5169 36.0603 8.87122 34.9885 9.23787 33.922C9.52873 33.0618 9.83898 32.2086 10.1431 31.3731C10.3387 30.8486 10.5282 30.3295 10.7309 29.8236C11.1611 28.7104 11.6035 27.6474 12.0469 26.6233C12.7361 25.0482 13.4386 23.5745 14.134 22.2075C18.8606 26.8789 23.5854 31.5526 28.3084 36.2286Z"
        fill="url(#paint0_linear_210_88397)"
      />
      <path
        d="M28.2615 35.9533C29.5743 34.6405 27.5505 30.4882 23.7412 26.6789C19.9319 22.8697 15.7796 20.8458 14.4669 22.1586C13.1541 23.4714 15.1779 27.6237 18.9872 31.433C22.7965 35.2423 26.9487 37.2661 28.2615 35.9533Z"
        fill="url(#paint1_radial_210_88397)"
      />
      <path
        d="M27.117 36.5669C25.1682 36.5669 21.9212 34.6789 18.8319 31.5888C14.8771 27.634 12.8914 23.4236 14.3122 22.0037C15.0173 21.2986 16.4213 21.4017 18.2652 22.2999C20.3555 23.3993 22.257 24.8251 23.8981 26.5235C27.852 30.4782 29.8378 34.6886 28.4179 36.1085C28.2428 36.2682 28.0374 36.391 27.8139 36.4697C27.5905 36.5485 27.3534 36.5815 27.117 36.5669ZM15.6184 21.9799C15.4385 21.9671 15.2578 21.9901 15.0868 22.0476C14.9159 22.1051 14.758 22.1959 14.6224 22.3149C13.5498 23.3875 15.1107 27.2444 19.1422 31.2777C23.1736 35.3109 27.0323 36.8709 28.105 35.7974C29.1776 34.7239 27.6167 30.8669 23.5852 26.8346C21.9786 25.1713 20.1173 23.7744 18.0713 22.6965C17.3148 22.2838 16.4781 22.0394 15.6184 21.9799Z"
        fill="url(#paint2_linear_210_88397)"
      />
      <path
        d="M37.7972 20.8647L37.9223 23.0753L39.8146 24.2246L37.7513 25.0275L37.2428 27.1816L35.8414 25.4673L33.6353 25.6489L34.8331 23.7874L33.9781 21.7453L36.119 22.3085L37.7972 20.8647Z"
        fill="url(#paint3_radial_210_88397)"
      />
      <path
        d="M8 19.1391L9.27008 18.4419L9.41639 17L10.4714 17.9924L11.8878 17.6857L11.2699 18.9963L11.9997 20.2479L10.5622 20.0655L9.59707 21.1469L9.32649 19.7235L8 19.1391Z"
        fill="url(#paint4_radial_210_88397)"
      />
      <path
        d="M21.54 10.7434L22.625 10.6403L23.1539 9.6875L23.5884 10.687L24.6575 10.8959L23.8413 11.6177L23.9727 12.6992L23.034 12.1457L22.046 12.6049L22.2822 11.5419L21.54 10.7434Z"
        fill="url(#paint5_radial_210_88397)"
      />
      <path
        d="M38.0283 32.2076L39.0569 32.5672L39.9357 31.9238L39.9119 33.0132L40.795 33.6513L39.7515 33.9642L39.4174 35.0016L38.7978 34.1061L37.7075 34.1097L38.3677 33.2424L38.0283 32.2076Z"
        fill="url(#paint6_radial_210_88397)"
      />
      <path
        d="M34.767 16.5349C35.5647 13.2112 26.0096 10.2506 25.791 6.82557L25.8792 4.40088C26.8707 7.11819 36.4443 11.4149 35.206 14.7034L34.767 16.5349Z"
        fill="url(#paint7_linear_210_88397)"
      />
      <path
        d="M26.8191 17.1006C27.6167 13.7769 34.1928 18.9295 34.8133 16.3444L35.254 14.5129C34.637 17.098 28.0583 11.9463 27.2598 15.27L26.8191 17.1006Z"
        fill="url(#paint8_linear_210_88397)"
      />
      <path
        d="M26.8191 17.1006C27.6167 13.7769 34.1928 18.9295 34.8133 16.3444L35.254 14.5129C34.637 17.098 28.0583 11.9463 27.2598 15.27L26.8191 17.1006Z"
        fill="url(#paint9_linear_210_88397)"
      />
      <path
        d="M29.2921 24.4814C29.8659 21.8019 26.0178 20.1432 26.8648 16.9014L27.3055 15.0708C26.6885 17.6559 30.5296 19.3226 29.7319 22.6463L29.2921 24.4814Z"
        fill="url(#paint10_linear_210_88397)"
      />
      <path
        d="M29.2921 24.4814C29.8659 21.8019 26.0178 20.1432 26.8648 16.9014L27.3055 15.0708C26.6885 17.6559 30.5296 19.3226 29.7319 22.6463L29.2921 24.4814Z"
        fill="url(#paint11_linear_210_88397)"
      />
      <path
        d="M20.9203 30.9702C21.7179 27.6465 28.6712 27.0692 29.2934 24.4841L29.7341 22.6526C29.1083 24.8975 21.2728 24.188 20.4734 27.6888L20.9203 30.9702Z"
        fill="url(#paint12_linear_210_88397)"
      />
      <path
        d="M20.9203 30.9702C21.7179 27.6465 28.6712 27.0692 29.2934 24.4841L29.7341 22.6526C29.1083 24.8975 21.2728 24.188 20.4734 27.6888L20.9203 30.9702Z"
        fill="url(#paint13_linear_210_88397)"
      />
      <path
        d="M23.9789 32.3379C23.0084 32.4439 22.0553 32.6728 21.1426 33.0192L21.1505 33.0254C21.4934 33.2819 21.7102 33.4493 21.8151 33.5296C21.9587 33.6388 22.1676 33.794 22.4409 33.9755C23.4057 33.8532 24.381 33.837 25.3494 33.9271C25.8666 33.9791 26.3808 34.0573 26.8901 34.1615C30.147 34.8823 33.0311 36.7616 35.0059 39.4498C35.1619 38.7535 35.3179 38.0652 35.473 37.3777C34.0983 35.6814 32.3375 34.3389 30.3379 33.4621C28.3383 32.5853 26.1579 32.1999 23.9789 32.3379Z"
        fill="url(#paint14_linear_210_88397)"
      />
      <path
        d="M13.8202 17.3181C13.0657 14.5629 19.8788 13.0187 19.3015 10.9113L18.3391 8.22046C18.9164 10.327 12.3844 12.8178 13.1389 15.573L13.8202 17.3181Z"
        fill="url(#paint15_linear_210_88397)"
      />
      <path
        d="M20.4859 20.9319C19.7315 18.1767 13.969 19.0528 13.3917 16.9463L13.0576 15.106C13.634 17.2134 19.3974 16.3399 20.1519 19.0925L20.4859 20.9319Z"
        fill="url(#paint16_linear_210_88397)"
      />
      <path
        d="M17.3662 27.1705C16.5562 24.4302 21.2116 22.3484 20.592 20.2533L19.9275 18.5073C20.5444 20.6024 15.8907 22.6851 16.7007 25.4244L17.3662 27.1705Z"
        fill="url(#paint17_linear_210_88397)"
      />
      <path
        d="M25.4608 25.7152C24.8835 25.6741 24.3341 25.4506 23.8919 25.0771C23.5507 24.7829 23.2884 24.4081 23.1287 23.9868C23.4554 23.4351 23.7821 22.8828 24.1088 22.3298C24.3204 22.8371 24.6869 23.2647 25.1558 23.5514C25.421 23.7087 25.7129 23.8157 26.017 23.867L25.4608 25.7152Z"
        fill="url(#paint18_linear_210_88397)"
      />
      <path
        d="M25.4608 25.7152C24.8835 25.6741 24.3341 25.4506 23.8919 25.0771C23.5507 24.7829 23.2884 24.4081 23.1287 23.9868C23.4554 23.4351 23.7821 22.8828 24.1088 22.3298C24.3204 22.8371 24.6869 23.2647 25.1558 23.5514C25.421 23.7087 25.7129 23.8157 26.017 23.867L25.4608 25.7152Z"
        fill="#C56A08"
      />
      <path
        d="M23.4703 19.3965C23.2292 19.6596 22.9306 19.8633 22.5977 19.9919C22.2649 20.1205 21.9068 20.1704 21.5515 20.1378V18.6561C22.1272 18.6758 22.6959 18.5252 23.1865 18.2234L23.4703 19.3965Z"
        fill="url(#paint19_linear_210_88397)"
      />
      <path
        d="M23.4703 19.3965C23.2292 19.6596 22.9306 19.8633 22.5977 19.9919C22.2649 20.1205 21.9068 20.1704 21.5515 20.1378V18.6561C22.1272 18.6758 22.6959 18.5252 23.1865 18.2234L23.4703 19.3965Z"
        fill="#C56A08"
      />
      <path
        d="M37.6577 8.82027C37.2979 9.21084 36.8462 9.50533 36.3436 9.67698C36.1197 9.75199 35.8878 9.8008 35.6526 9.82241V8.10723C35.8678 8.0582 36.0783 7.99067 36.2819 7.90539C36.6551 7.74913 37.0018 7.53594 37.3096 7.27344L37.6577 8.82027Z"
        fill="url(#paint20_linear_210_88397)"
      />
      <path
        d="M37.6577 8.82027C37.2979 9.21084 36.8462 9.50533 36.3436 9.67698C36.1197 9.75199 35.8878 9.8008 35.6526 9.82241V8.10723C35.8678 8.0582 36.0783 7.99067 36.2819 7.90539C36.6551 7.74913 37.0018 7.53594 37.3096 7.27344L37.6577 8.82027Z"
        fill="#C56A08"
      />
      <path
        d="M9.59985 9.61322C9.72463 9.94693 9.92476 10.2473 10.1846 10.491C10.4445 10.7347 10.7572 10.9151 11.0982 11.0182L11.6517 9.64319C11.4606 9.57434 11.2771 9.48572 11.1044 9.37878C10.7921 9.18017 10.5196 8.92523 10.3006 8.62695L9.59985 9.61322Z"
        fill="#C56A08"
      />
      <path
        d="M33.617 29.7021C33.2573 30.0939 32.8056 30.3898 32.3028 30.5632C32.0789 30.6382 31.847 30.687 31.6118 30.7086V28.9891C32.2232 28.8491 32.7914 28.563 33.2679 28.1553L33.617 29.7021Z"
        fill="url(#paint21_linear_210_88397)"
      />
      <path
        d="M33.617 29.7021C33.2573 30.0939 32.8056 30.3898 32.3028 30.5632C32.0789 30.6382 31.847 30.687 31.6118 30.7086V28.9891C32.2232 28.8491 32.7914 28.563 33.2679 28.1553L33.617 29.7021Z"
        fill="#C56A08"
      />
      <path
        d="M39.8067 18.8764C39.0838 18.7135 38.4198 18.3542 37.8879 17.8381L38.4326 16.2913C38.6077 16.6039 38.8462 16.8766 39.1328 17.0918C39.4193 17.307 39.7476 17.46 40.0967 17.5411L39.8067 18.8764Z"
        fill="url(#paint22_linear_210_88397)"
      />
      <path
        d="M39.8067 18.8764C39.0838 18.7135 38.4198 18.3542 37.8879 17.8381L38.4326 16.2913C38.6077 16.6039 38.8462 16.8766 39.1328 17.0918C39.4193 17.307 39.7476 17.46 40.0967 17.5411L39.8067 18.8764Z"
        fill="#C56A08"
      />
      <defs>
        <linearGradient
          id="paint0_linear_210_88397"
          x1="9.5"
          y1="30"
          x2="21"
          y2="43.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4AA3B" />
          <stop offset="1" stopColor="#B56D01" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_210_88397"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.818 13.7839) rotate(45) scale(18.1661 18.5558)"
        >
          <stop stopColor="#271202" />
          <stop offset="1" stopColor="#AE5400" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_210_88397"
          x1="12.9575"
          y1="31.1895"
          x2="26.8613"
          y2="27.6587"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFA756" />
          <stop offset="1" stopColor="#AF5E13" />
        </linearGradient>
        <radialGradient
          id="paint3_radial_210_88397"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.725 24.0232) rotate(90) scale(3.15845 3.0897)"
        >
          <stop stopColor="#FFDB5C" />
          <stop offset="0.296875" stopColor="#FFA552" />
          <stop offset="0.635417" stopColor="#FF7F07" />
          <stop offset="0.84375" stopColor="#FF7F07" />
          <stop offset="1" stopColor="#D97111" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_210_88397"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.99986 19.0735) rotate(90) scale(2.07346 1.99986)"
        >
          <stop stopColor="#FFDB5C" />
          <stop offset="0.296875" stopColor="#FFA552" />
          <stop offset="0.635417" stopColor="#FF7F07" />
          <stop offset="0.84375" stopColor="#FF7F07" />
          <stop offset="1" stopColor="#D97111" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_210_88397"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.0988 11.1933) rotate(90) scale(1.50585 1.55873)"
        >
          <stop stopColor="#FFDB5C" />
          <stop offset="0.296875" stopColor="#FFA552" />
          <stop offset="0.635417" stopColor="#FF7F07" />
          <stop offset="0.84375" stopColor="#FF7F07" />
          <stop offset="1" stopColor="#D97111" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_210_88397"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(39.2513 33.4627) rotate(90) scale(1.5389 1.54375)"
        >
          <stop stopColor="#FFDB5C" />
          <stop offset="0.296875" stopColor="#FFA552" />
          <stop offset="0.635417" stopColor="#FF7F07" />
          <stop offset="0.84375" stopColor="#FF7F07" />
          <stop offset="1" stopColor="#D97111" />
        </radialGradient>
        <linearGradient
          id="paint7_linear_210_88397"
          x1="35.8976"
          y1="11.6477"
          x2="24.8865"
          y2="9.35414"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E4400" />
          <stop offset="0.293" stopColor="#FF7A00" />
          <stop offset="0.428" stopColor="#EA7E00" />
          <stop offset="0.708" stopColor="#B46C00" />
          <stop offset="1" stopColor="#764E00" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_210_88397"
          x1="27.3838"
          y1="14.8085"
          x2="34.6879"
          y2="16.8049"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E0000" />
          <stop offset="0.293" stopColor="#FF0000" />
          <stop offset="0.428" stopColor="#EA0000" />
          <stop offset="0.708" stopColor="#B40000" />
          <stop offset="1" stopColor="#760000" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_210_88397"
          x1="35.7687"
          y1="15.8966"
          x2="29.2495"
          y2="11.0156"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E4400" />
          <stop offset="0.293" stopColor="#FF7A00" />
          <stop offset="0.428" stopColor="#EA7E00" />
          <stop offset="0.708" stopColor="#B46C00" />
          <stop offset="1" stopColor="#764E00" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_210_88397"
          x1="30.271"
          y1="20.2106"
          x2="26.3733"
          y2="19.3988"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E0000" />
          <stop offset="0.293" stopColor="#FF0000" />
          <stop offset="0.428" stopColor="#EA0000" />
          <stop offset="0.708" stopColor="#B40000" />
          <stop offset="1" stopColor="#760000" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_210_88397"
          x1="30.0306"
          y1="20.6911"
          x2="26.321"
          y2="20.3669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E4400" />
          <stop offset="0.293" stopColor="#FF7A00" />
          <stop offset="0.428" stopColor="#EA7E00" />
          <stop offset="0.708" stopColor="#B46C00" />
          <stop offset="1" stopColor="#764E00" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_210_88397"
          x1="21.3489"
          y1="27.4968"
          x2="28.9049"
          y2="26.1935"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.293" stopColor="#FF0000" />
          <stop offset="0.428" stopColor="#EA0000" />
          <stop offset="0.708" stopColor="#B40000" />
          <stop offset="1" stopColor="#760000" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_210_88397"
          x1="30.2992"
          y1="27.6201"
          x2="20.0261"
          y2="24.5852"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E4400" />
          <stop offset="0.293" stopColor="#FF7A00" />
          <stop offset="0.428" stopColor="#EA7E00" />
          <stop offset="0.708" stopColor="#B46C00" />
          <stop offset="1" stopColor="#764E00" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_210_88397"
          x1="15.0188"
          y1="37.5205"
          x2="26.9755"
          y2="24.6708"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.188" stopColor="#38230B" />
          <stop offset="0.598" stopColor="#CA6100" />
          <stop offset="0.648" stopColor="#C05D01" />
          <stop offset="0.733" stopColor="#A54804" />
          <stop offset="0.843" stopColor="#793708" />
          <stop offset="0.927" stopColor="#532E0C" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_210_88397"
          x1="10.4039"
          y1="14.8596"
          x2="20.3121"
          y2="11.2104"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.188" stopColor="#38230B" />
          <stop offset="0.598" stopColor="#CA6100" />
          <stop offset="0.648" stopColor="#C05D01" />
          <stop offset="0.733" stopColor="#A54804" />
          <stop offset="0.843" stopColor="#793708" />
          <stop offset="0.927" stopColor="#532E0C" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_210_88397"
          x1="9.88332"
          y1="19.3575"
          x2="18.9938"
          y2="13.1382"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.188" stopColor="#38230B" />
          <stop offset="0.598" stopColor="#CA6100" />
          <stop offset="0.648" stopColor="#C05D01" />
          <stop offset="0.733" stopColor="#A54804" />
          <stop offset="0.843" stopColor="#793708" />
          <stop offset="0.927" stopColor="#532E0C" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_210_88397"
          x1="14.8779"
          y1="24.8293"
          x2="21.7205"
          y2="23.1195"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.188" stopColor="#38230B" />
          <stop offset="0.598" stopColor="#CA6100" />
          <stop offset="0.648" stopColor="#C05D01" />
          <stop offset="0.733" stopColor="#A54804" />
          <stop offset="0.843" stopColor="#793708" />
          <stop offset="0.927" stopColor="#532E0C" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_210_88397"
          x1="62.8843"
          y1="66.6398"
          x2="60.79"
          y2="62.2106"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.188" stopColor="#1E380B" />
          <stop offset="0.598" stopColor="#97CA00" />
          <stop offset="0.648" stopColor="#8FC001" />
          <stop offset="0.733" stopColor="#78A504" />
          <stop offset="0.843" stopColor="#537908" />
          <stop offset="0.927" stopColor="#33530C" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_210_88397"
          x1="66.2192"
          y1="68.1306"
          x2="66.6756"
          y2="64.7337"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.188" stopColor="#1E380B" />
          <stop offset="0.598" stopColor="#97CA00" />
          <stop offset="0.648" stopColor="#8FC001" />
          <stop offset="0.733" stopColor="#78A504" />
          <stop offset="0.843" stopColor="#537908" />
          <stop offset="0.927" stopColor="#33530C" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_210_88397"
          x1="76.7925"
          y1="53.8277"
          x2="77.5066"
          y2="50.9841"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.188" stopColor="#1E380B" />
          <stop offset="0.598" stopColor="#97CA00" />
          <stop offset="0.648" stopColor="#8FC001" />
          <stop offset="0.733" stopColor="#78A504" />
          <stop offset="0.843" stopColor="#537908" />
          <stop offset="0.927" stopColor="#33530C" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_210_88397"
          x1="55.8875"
          y1="57.7449"
          x2="56.6016"
          y2="54.9022"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.188" stopColor="#1E380B" />
          <stop offset="0.598" stopColor="#97CA00" />
          <stop offset="0.648" stopColor="#8FC001" />
          <stop offset="0.733" stopColor="#78A504" />
          <stop offset="0.843" stopColor="#537908" />
          <stop offset="0.927" stopColor="#33530C" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_210_88397"
          x1="68.9188"
          y1="51.5012"
          x2="67.688"
          y2="48.297"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.188" stopColor="#1E380B" />
          <stop offset="0.598" stopColor="#97CA00" />
          <stop offset="0.648" stopColor="#8FC001" />
          <stop offset="0.733" stopColor="#78A504" />
          <stop offset="0.843" stopColor="#537908" />
          <stop offset="0.927" stopColor="#33530C" />
        </linearGradient>
      </defs>
    </Icon>
  );
});

export default SocializingIcon;
