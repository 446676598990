import {
  Button,
  color,
  Flex,
  PrimaryText,
  spacing,
  StyleProps,
  TextInputProps,
  useColorMode,
} from 'deepstash-ui';
import React from 'react';
import pageStrings from 'utils/strings/pageStrings';
import AuthTextInput from './AuthTextInput';

interface AuthPasswordTextInputProps {
  onEnterPressed: () => void;
  passwordError?: string;
  password: string;
  onChange?: (val: string) => void;
  textInputProps?: TextInputProps;
  forgotPasswordCallback?: () => void;
  hideLabel?: boolean;
}

const AuthPasswordTextInput: React.FC<
  AuthPasswordTextInputProps & StyleProps
> = ({
  onEnterPressed,
  passwordError,
  password,
  onChange,
  forgotPasswordCallback,
  textInputProps,
  hideLabel,
  ...props
}) => {
  const { colorMode } = useColorMode();

  return (
    <Flex flexDir={'column'} width="100%" {...props}>
      <Flex width="100%" justifyContent="space-between" align="center">
        {!hideLabel && (
          <PrimaryText
            size="s"
            type="bold"
            ml={spacing.XXS.rem}
            color={color[colorMode].textSecondary}
          >
            {pageStrings.authentication.password}
          </PrimaryText>
        )}
        {forgotPasswordCallback && (
          <Button
            onClick={forgotPasswordCallback}
            color={color[colorMode].primary.default}
            p={0}
            height="unset"
          >
            <PrimaryText size="s">
              {pageStrings.authentication.forgotPassword}?
            </PrimaryText>
          </Button>
        )}
      </Flex>
      <AuthTextInput
        value={password}
        onChange={onChange}
        placeholder={pageStrings.authentication.password}
        type="password"
        isInvalid={(passwordError?.length ?? 0) > 0}
        errorText={passwordError}
        onEnterPressed={onEnterPressed}
        {...textInputProps}
      />
    </Flex>
  );
};

export default AuthPasswordTextInput;
