export const pageview = url => {
  if (window.gtag)
    window.gtag('config', process.env.GA_TRACKING_ID, {
      page_path: url,
      cookie_flags: 'SameSite=None;Secure',
    });
};

export const event = ({
  action,
  category,
  label,
  value,
  transaction_id,
  currency,
}) => {
  if (window.gtag) {
    try {
      window.gtag('event', action, {
        send_to: process.env.GA_TRACKING_ID,
        event_category: category,
        event_label: label,
        value,
        currency,
        transaction_id,
      });
    } catch (error) {
      console.error('Error sending event to GA', error);
    }
  }
};

export const grantConsent = () => {
  if (window.gtag)
    window.gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
};

export const initGTM = () => {
  if (!window.dataLayer) {
    const tagManagerArgs = {
      gtmId: process.env.GTM_ID,
    };
    TagManager.initialize(tagManagerArgs);
  }
};
