import useCreateContextConsumer from './useCreateContextConsumer';
import {
  NsiContextType,
  NsiProviderAction,
  NsiProviderStateType,
} from '../nsi/NsiProviderTypes';
import { NsiContext } from '../nsi/NsiProvider';

const useNsi = () => {
  const { dispatch, ...contextConsumer } = useCreateContextConsumer<
    NsiProviderStateType,
    NsiProviderAction,
    NsiContextType
  >({ context: NsiContext });
  return {
    ...contextConsumer,
    nsiDispatch: dispatch,
  };
};

export default useNsi;
