import {
  AppStoreColoredSvg,
  Box,
  color,
  ExternalSvg,
  Flex,
  FullStarSvg,
  GooglePlayColoredSvg,
  Link,
  PrimaryButton,
  PrimaryText,
  spacing,
  typography,
  useColorMode,
  useDeviceDetect,
} from 'deepstash-ui';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import NextImage from 'src/components/image/NextImage';
import Navbar from 'src/components/navbar/Navbar';
import useCheckPromoCodeAfterSignIn from 'src/page-components/home/useAuthPromoCodeInternals';
import { useSubscriptionDoneInternals } from 'src/page-components/home/useSubscriptionDoneInternals';
import useProfile from 'src/providers/hooks/useProfile';
import {
  GAP_SIZE_REM,
  getRemWidthFromColumns,
  SECTION_GAP_REM,
} from 'utils/global';
import actionStrings from 'utils/strings/actionStrings';
import commonStrings from 'utils/strings/commonStrings';
import pageStrings from 'utils/strings/pageStrings';
import FullWidthSectionWrapper from './FullWidthSectionWrapper';

const DeveloperMenuDropdownMobile = dynamic(
  () => import(`src/components/developer-menu/DeveloperMenuDropdownMobile`),
);

const AllDoneModal = dynamic(
  () => import('src/components/paywall/AllDoneModal'),
);

const GET_THE_APP_URL = 'https://dsta.sh/fvZKQJNh8mUi81Vo7';

const SIMobileBlocked: React.FC = () => {
  const { profile } = useProfile();
  const { colorMode, setColorMode } = useColorMode();
  const router = useRouter();

  const { isIos } = useDeviceDetect();

  useCheckPromoCodeAfterSignIn();

  useEffect(() => {
    if (colorMode === 'dark') {
      setColorMode('light');
    }
  }, [colorMode]);

  const {
    isSubscriptionCompleted,
    isSubscriptionTrialEligible,
    freeTrialDuration,
    isSubscriptionDoneOpen,
    onSubscriptionDoneClose,
  } = useSubscriptionDoneInternals();

  const SIMobileStrings = pageStrings['si-mobile-blocked'];

  return (
    <Box pos="relative">
      {/* Navbar */}
      <FullWidthSectionWrapper>
        <Navbar showMenu={false} showSearchBar={false} showSearchIcon={false} />
      </FullWidthSectionWrapper>
      {profile?.userType === 'developer' && (
        <Box position="absolute" left={200}>
          <DeveloperMenuDropdownMobile pos="absolute" />
        </Box>
      )}
      <FullWidthSectionWrapper
        containerProps={{
          overflow: 'hidden',
          minH: '80vh',
          alignItems: 'center',
        }}
        position="relative"
        overflow={'hidden'}
      >
        <Flex
          flexDirection="column"
          width={{ base: '60%', sm: '50%', md: 'auto' }}
        >
          <PrimaryText
            fontWeight={700}
            lineHeight={{ base: '2rem', sm: '2.25rem', md: '3.5rem' }}
            fontSize={{ base: '1.75rem', sm: '2rem', md: '3rem' }}
            w="100%"
            marginBottom={spacing.M.rem}
          >
            {profile?.firstName ? profile.firstName + ', ' : ''}
            {SIMobileStrings.pageTitle}
          </PrimaryText>

          <PrimaryText
            fontWeight={600}
            lineHeight={{ base: '1.5rem', md: '2.5rem' }}
            fontSize={{ base: '1.25rem', md: '2rem' }}
            w="100%"
            color={color[colorMode].textSecondary}
          >
            {SIMobileStrings.switchToMobile}
          </PrimaryText>
          <Flex flexDirection="column" marginTop={GAP_SIZE_REM}>
            <Link href={GET_THE_APP_URL} isExternal textDecoration="none">
              <PrimaryButton
                mt={spacing.M.rem}
                color={color[colorMode].textInverted}
                bgColor={color[colorMode].text}
                height={spacing.toRem(54)}
                flexShrink={0}
                whiteSpace="nowrap"
                minW={getRemWidthFromColumns(2)}
              >
                {actionStrings.getDeepstash}
                <ExternalSvg marginLeft={spacing.XXS.rem} />
              </PrimaryButton>
            </Link>
            <Flex marginTop={GAP_SIZE_REM}>
              {Array(5)
                .fill(1)
                .map((_, index) => (
                  <FullStarSvg
                    key={index}
                    color={color[colorMode].secondary.default}
                    boxSize="1.75rem"
                  />
                ))}
            </Flex>
            <Flex
              marginTop={spacing.S.rem}
              alignItems="center"
              gridGap={spacing.XXS.rem}
            >
              {isIos ? (
                <AppStoreColoredSvg boxSize={'2.5rem'} />
              ) : (
                <GooglePlayColoredSvg boxSize={'2.5rem'} />
              )}
              <PrimaryText
                {...typography.primaryTextFonts.medium.semiBold}
                lineHeight="1.2em"
                color={color[colorMode].textSecondary}
              >
                {commonStrings.installCount}
                <br />
                {commonStrings.appScore}
              </PrimaryText>
            </Flex>
          </Flex>
        </Flex>
        <NextImage
          imageUrl={
            'https://static.deepstash.com/images/web/growth-plan/activate_app_phone.png'
          }
          wrapperStyle={{
            width: {
              base: '50vh',
              md: getRemWidthFromColumns(4),
              lg: getRemWidthFromColumns(5),
            },
            height: {
              base: '80vh',
              md: getRemWidthFromColumns(6),
              lg: getRemWidthFromColumns(8),
            },
            cursor: 'unset',
            position: 'absolute',
            left: '40vw',
            zIndex: -1,
          }}
          objectFit="contain"
          priority
        />
      </FullWidthSectionWrapper>

      <FullWidthSectionWrapper
        containerProps={{ paddingX: 0 }}
        py={SECTION_GAP_REM}
      >
        <NextImage
          imageUrl={`https://static.deepstash.com/images/web/growth-plan/bento_mobile_${
            colorMode ?? 'light'
          }.png`}
          onClick={() => router.push(GET_THE_APP_URL)}
          wrapperStyle={{
            width: {
              base: '100vw',
              md: getRemWidthFromColumns(8),
              lg: getRemWidthFromColumns(12),
            },
            height: {
              base: '480vw',
              md: getRemWidthFromColumns(5),
              lg: getRemWidthFromColumns(7.5),
            },
            marginInline: 'auto',
            cursor: 'unset',
          }}
          objectFit="contain"
          priority
        />
      </FullWidthSectionWrapper>
      <FullWidthSectionWrapper>
        <PrimaryButton
          mb={SECTION_GAP_REM}
          {...typography.primaryHeadingFonts.small.bold}
          color={color[colorMode].textInverted}
          bgColor={color[colorMode].text}
          width="100%"
          height={spacing.toRem(80)}
          borderRadius={spacing.toRem(24)}
          onClick={() => router.push(GET_THE_APP_URL)}
        >
          {actionStrings.getDeepstashApp}
        </PrimaryButton>
      </FullWidthSectionWrapper>
      <AllDoneModal
        isOpen={isSubscriptionDoneOpen}
        onClose={onSubscriptionDoneClose}
        isPro={isSubscriptionCompleted}
        isSubscriptionTrialEligible={isSubscriptionTrialEligible}
        freeTrialDuration={freeTrialDuration}
      />
    </Box>
  );
};

export default SIMobileBlocked;
