import {
  Button,
  color,
  Flex,
  PrimaryText,
  spacing,
  Spinner,
  useColorMode,
} from 'deepstash-ui';
import React, { useCallback, useEffect, useState } from 'react';
import AnimatedHeightContainer from 'src/components/animation/AnimatedHeightContainer';
import TitledSectionSeparator from 'src/components/layout/components/TitledSectionSeparator';
import useNsi from 'src/providers/hooks/useNsi';
import { GAP_SIZE, validatePassword } from 'utils/global';
import actionStrings from 'utils/strings/actionStrings';
import pageStrings from 'utils/strings/pageStrings';
import {
  AuthRequestStatus,
  AuthSceneType,
} from '../auth-modal-content/AuthModalContent';
import AuthEmailTextInput from './components/AuthEmailTextInput';
import AuthPasswordTextInput from './components/AuthPasswordTextInput';

interface SignInSceneProps {
  onLogin: (
    values: {
      email: string;
      password: string;
    },
    trackLogin?: ((email: string) => void) | undefined,
  ) => Promise<void>;
  requestStatus: AuthRequestStatus;
  authErrorMessage: string;
  setAuthErrorMessage: (val: string) => void;
  trackLogin?: (email: string) => void;
  isVisible: boolean;
  setAuthSceneType: (val: AuthSceneType) => void;
}

const SignInScene: React.FC<SignInSceneProps> = ({
  onLogin,
  requestStatus,
  authErrorMessage: authError,
  setAuthErrorMessage,
  trackLogin,
  isVisible,
  setAuthSceneType,
}) => {
  const { colorMode } = useColorMode();

  const { nsiDispatch, email, password } = useNsi();
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  //Clear errors when input value changes
  useEffect(() => {
    setAuthErrorMessage('');
  }, [email, password]);

  useEffect(() => {
    setEmailError('');
    setPasswordError('');
  }, [password, email]);

  const validateInputs = (email: string, password: string) => {
    if (email.length === 0) {
      setEmailError(pageStrings.authentication.emailNotValid);
      return false;
    }
    if (!validatePassword(password)) {
      setPasswordError(pageStrings.authentication.passwordFieldEmpty);
      return false;
    }

    return true;
  };

  const mainButtonCallback = useCallback(() => {
    if (!validateInputs(email, password)) return;
    onLogin(
      {
        email,
        password,
      },
      trackLogin,
    );
  }, [onLogin, email, password, trackLogin]);

  const onChangeEmailHandler = useCallback(
    (val: string) => {
      nsiDispatch({
        type: 'set-email',
        payload: {
          email: val,
        },
      });
    },
    [nsiDispatch],
  );

  const onChangePasswordHandler = useCallback(
    (val: string) => {
      nsiDispatch({
        type: 'set-password',
        payload: {
          password: val,
        },
      });
    },
    [nsiDispatch],
  );

  const isDisabled =
    authError.length > 0 ||
    passwordError.length > 0 ||
    emailError.length > 0 ||
    email.length == 0 ||
    password.length == 0;

  const isDisabledFromRequestStatus =
    requestStatus !== 'success' && requestStatus !== 'failure';

  return (
    <AnimatedHeightContainer
      isVisible={isVisible}
      width="calc(100% + 4rem)"
      paddingLeft={spacing.XXXL.rem}
      paddingRight={spacing.XXXL.rem}
    >
      <TitledSectionSeparator px={GAP_SIZE} />

      <AuthEmailTextInput
        email={email}
        onChange={onChangeEmailHandler}
        onEnterPressed={mainButtonCallback}
        emailError={emailError}
        px={spacing.XXXL.rem}
        authModalType={'sign-in'}
        mt={0}
      />

      <AuthPasswordTextInput
        onEnterPressed={mainButtonCallback}
        passwordError={
          (passwordError?.length ?? 0) > 0 ? passwordError : authError
        }
        password={password}
        onChange={onChangePasswordHandler}
        px={spacing.XXXL.rem}
        forgotPasswordCallback={() => setAuthSceneType('forgot-password')}
      />

      <Flex width="100%" px={spacing.XXXL.rem} pb={spacing.M.rem}>
        <Button
          height="unset"
          width="100%"
          border="1px solid transparent"
          borderRadius={spacing.toRem(36)}
          position="relative"
          color={color[colorMode].textInverted}
          backgroundColor={color[colorMode].primary.default}
          px={spacing.XXXL.rem}
          py={spacing.S.rem}
          _active={
            isDisabledFromRequestStatus
              ? {}
              : { backgroundColor: color[colorMode].primary.darker }
          }
          _disabled={
            isDisabledFromRequestStatus && !isDisabled ? {} : { opacity: 0.4 }
          }
          _hover={{
            boxShadow:
              '0px 6px 14px -6px rgba(0, 0, 0, 0.12), 0px 10px 32px -4px rgba(0, 0, 0, 0.1)',
          }}
          onClick={mainButtonCallback}
          isDisabled={isDisabled}
          disabled={isDisabledFromRequestStatus}
          id="auth-modal-signin-button"
        >
          {requestStatus !== 'loading' ? (
            <PrimaryText
              size="l"
              type="bold"
              id="auth-modal-signin-button-text"
            >
              {actionStrings.signIn}
            </PrimaryText>
          ) : (
            <Spinner
              width={spacing.XL.rem}
              height={spacing.XL.rem}
              color={
                colorMode === 'light'
                  ? color[colorMode].textInverted
                  : color[colorMode].textDisabled
              }
            />
          )}
        </Button>
      </Flex>
    </AnimatedHeightContainer>
  );
};

export default SignInScene;
