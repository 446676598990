type FontWeight =
  | 'normal'
  | 'black'
  | 'hairline'
  | 'thin'
  | 'medium'
  | 'semibold'
  | 'bold'
  | 'extrabold'
  | 'light';

export const font = {
  primary: {
    normal: {
      fontWeight: 'normal' as FontWeight,
    },

    semiBold: {
      fontWeight: 'semibold' as FontWeight,
    },

    bold: {
      fontWeight: 'bold' as FontWeight,
    },

    extraBold: {
      fontWeight: 'extrabold' as FontWeight,
    },
  },
};

/**
 * Font family and variations used in the app
 */
export const typography = {
  /**
   * Text style used for the title of an article
   * Used in combination with text secondary color
   */
  articleTitle: {
    ...font.primary.extraBold,
    fontSize: '24px',
    lineHeight: '30px',
  },
  /**
   * Text style used for the title of an idea / insight card
   * Used in combination with text main color
   */
  ideaTitle: {
    ...font.primary.extraBold,
    fontSize: '20px',
    lineHeight: '25px',
  },
  /**
   * Text style used for the title of a simple idea / insight card
   * Used in combination with text main color
   */
  ideaTitleFocus: {
    ...font.primary.extraBold,
    fontSize: '18px',
    lineHeight: '1.2',
  },
  /**
   * Text style used for the title of a small insight card
   * Used in combination with text main color
   */
  ideaTitleSmall: {
    ...font.primary.extraBold,
    fontSize: '10px',
    lineHeight: '12px',
  },
  /**
   * Text style used for the body of an insight card
   * Used in combination with text main color
   */
  ideaBody: {
    ...font.primary.normal,
    fontSize: '14px',
    lineHeight: '17px',
  },
  /**
   * Text style used for the body of a small insight card
   * Used in combination with text main color
   */
  ideaBodySmall: {
    ...font.primary.normal,
    fontSize: '8px',
    lineHeight: '8px',
  },

  /**
   * Text style used for the title of a stash row
   * Used in combination with the secondary text color
   */

  stashName: {
    ...font.primary.extraBold,
    fontSize: '14px',
    lineHeight: '19px',
  },
  /**
   * Text style used for article subtitles
   * Used in combination with the muted text color
   */
  contentSubtitles: {
    ...font.primary.normal,
    fontSize: '13px',
    lineHeight: '15px',
  },
  /**
   * Text style used on the big greeting generally residing on Home/Feed
   * Used in combination with the secondary text color
   */
  UIGreetings: {
    ...font.primary.normal,
    fontSize: '24px',
    lineHeight: '29px',
  },
  /**
   * Text style used in the  application header title and modal title
   * Used in combination with the secondary text color
   */
  header: {
    ...font.primary.extraBold,
    fontSize: '16px',
    lineHeight: '22px',
  },
  /**
   * Text style used in the picker boxes and tab titles throughout the app
   * Used in combination with the secondary text color
   */
  option: {
    ...font.primary.extraBold,
    fontSize: '12px',
    lineHeight: '16px',
  },
  /**
   * Text style used on the title of the selected bottom tab
   * Used in combination with secondary text color
   */
  bottomNavigationSelected: {
    ...font.primary.semiBold,
    fontSize: '11px',
  },
  /**
   * Text style used on the title of the unselected bottom tabs
   * Used in combination with secondary text color
   */
  bottomNavigationUnselected: {
    ...font.primary.normal,
    fontSize: '11px',
  },
  /**
   * Text style used on the title of a BIG button
   * Used in combination with the inverted text color for active button or muted text color for inactive button
   */
  buttonBig: {
    ...font.primary.bold,
    fontSize: '18px',
    lineHeight: '25px',
    textTransform: 'uppercase' as const,
  },
  /**
   * Text style used on the title of a SMALL button
   * Used in combination with the inverted text color for active button or muted text color for inactive button
   */
  buttonSmall: {
    ...font.primary.bold,
    fontSize: '14px',
    lineHeight: '19px',
    textTransform: 'uppercase' as const,
  },
  /**
   * Text style used for the number of Ideas on Topic, Article or Stash Screen
   * Used in combination with the muted text color
   */
  UISectionHeaderSmall: {
    ...font.primary.bold,
    fontSize: '12px',
    textTransform: 'uppercase' as const,
  },
  /**
   * Text style used for the title of a section on Feed or on other main screens
   * Used in combination with the muted text color
   */
  UISectionHeaderBig: {
    ...font.primary.bold,
    fontSize: '15px',
    lineHeight: '20px',
    textTransform: 'uppercase' as const,
  },
  /**
   * Text style used for the title of an option in the Picker Modal
   * Used in combination with the secondary text color
   */
  selectorOption: {
    ...font.primary.bold,
    fontSize: '15px',
    lineHeight: '20px',
  },
  /**
   * Text style used for the main title on the Topic or Stash screen
   * Used in combination  with the secondary text color
   */
  bigHeader: {
    ...font.primary.extraBold,
    fontSize: '22px',
    lineHeight: '27px',
  },

  default: 'normal',
  fontWeight: {
    normal: {
      fontWeight: 'normal',
    },

    italic: {
      fontStyle: 'italic',
    },

    semibold: {
      fontWeight: '600',
    },

    bold: {
      fontWeight: '700',
    },
  },
  text: {
    small: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
    medium: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    large: {
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
    },
  },
  heading: {
    small: {
      primary: {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
      },
    },
    medium: {
      primary: {
        default: {
          fontSize: '1.5rem',
          lineHeight: '2.25rem',
          letterSpacing: '-0.0625%',
        },
        bold: {
          fontSize: '1.5rem',
          lineHeight: '2.25rem',
          letterSpacing: '-0.0625%',
          fontWeight: '700',
        },
        extraBold: {
          fontSize: '1.5rem',
          lineHeight: '2.25rem',
          letterSpacing: '-0.0625%',
          fontWeight: '900',
        },
      },
    },
    large: {
      fontSize: '2rem',
      lineHeight: '2.5rem',
      letterSpacing: '-0.125%',
    },
    XLarge: {
      fontSize: '3rem',
      lineHeight: '3.75rem',
      letterSpacing: '-0.125%',
    },
    XXLarge: {
      fontSize: '4.5rem',
      lineHeight: '4rem',
      letterSpacing: '-0.1875%',
    },
  },
};
