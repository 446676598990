import {
  Button,
  ButtonProps,
  PrimaryText,
  typography,
  useColorMode,
  spacing,
  color,
} from 'deepstash-ui';
import React from 'react';

interface OwnProps {
  size?: 'small' | 'medium';
  colorMode?: 'light' | 'dark';
}

const LabelButton: React.FC<OwnProps & ButtonProps> = ({
  children,
  size = 'medium',
  colorMode: colorModeFromProps,
  ...props
}) => {
  const { colorMode: colorModeFromHook } = useColorMode();
  const colorMode = colorModeFromProps ?? colorModeFromHook;

  return (
    <Button
      size="sm"
      borderRadius="0.5rem"
      border="none"
      color={color[colorMode].textSecondary}
      py={size === 'small' ? '0' : spacing.XS.rem}
      backgroundColor={color.transparent}
      _hover={{
        backgroundColor: color[colorMode].background,
        color:
          colorMode === 'dark'
            ? color[colorMode].textInverted
            : color[colorMode].text,
      }}
      _active={{
        outline: 'none',
        color:
          colorMode === 'dark'
            ? color[colorMode].textInverted
            : color[colorMode].text,
      }}
      _focus={{
        outline: 'none',
      }}
      _disabled={{
        backgroundColor: color[colorMode].underground,
        color: color[colorMode].textDisabled,
      }}
      height="unset"
      minW="unset"
      {...typography.primaryTextFonts.medium.bold}
      {...props}
    >
      <PrimaryText type="bold" size="m" display="flex">
        {children}
      </PrimaryText>
    </Button>
  );
};

export default LabelButton;
