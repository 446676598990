import { StripePricesApiResponse } from 'api/api.types';
import {
  ArticleSvg,
  BookSvg,
  color,
  DownloadSvg,
  DraftSvg,
  HeadphonesSvg,
  LibrarySvg,
  MailSvg,
  PersonAddSvg,
  PhoneSvg,
  QualitySvg,
  RandomSvg,
  StashedSvg,
  StashesSvg,
} from 'deepstash-ui';
import { Reaction } from 'types';
import { LanguageCode, SideMenuButton } from 'types/enums';
import { JtbdSetupStep, JtbdSetupStepKey, ProBenefit } from 'types/types';
import React from 'react';
import commonStrings from './strings/commonStrings';
import { HistorySvg } from 'theme/icons/HistorySvg';

// Experiment IDs
export const AB_GROWTH_PLAN_EBOOK_AD_ID = 'growth-plan-ebook-ad';
export const AB_GROWTH_PLAN_FIRST_PAGE = 'growth_plan_first_page';
export const AB_GROWTH_PLAN_HEADWAY_STEPS = 'growth_plan_headway_steps';
export const AB_GROWTH_PLAN_SPECIAL_OFFER = 'growth_plan_special_offer';
export const AB_GROWTH_PLAN_REMOVE_TOPICS_STEP =
  'growth_plan_remove_topics_step';
export const AB_GROWTH_PLAN_REPHRASE_STEPS = 'growth_plan_rephrase_steps';

export const GROWTH_PLAN_SPECIAL_OFFER = {
  bgColor: '#000000',
  textColor: '#FFFFFF',
  accentColor: '#C54B43',
  svgLink:
    'https://static.deepstash.com/illustrations/paywall_price_icon_black_friday_v2.svg',
  title: 'BLACK FRIDAY SALE',
  planColors: {
    border: '#C54B43',
    background: '#282828',
    text: '#FFFFFF',
    textSecondary: '#D0D0D0',
  },
  planLabel: 'BLACK FRIDAY SALE',
};

export const DEFAULT_TRIAL_PROMO_CODE = 'FREETRIAL69';

export const STRIPE_URLS = {
  // LIFETIME_250: 'https://buy.stripe.com/eVafZG1W1ghx4Xm4gg',
  // LIFETIME_150: 'https://buy.stripe.com/bIYaFmcAFc1hcpO6os',
  LIFETIME_299: 'https://buy.stripe.com/eVa8xecAFaXdcpO00j',
  LIFETIME_199: 'https://buy.stripe.com/cN28xe4493uL9dC9AS',
};

export const WEB_FUNNEL_CAMPAIGNS_FOR_EXPERIMENT = [
  '120204962217710474',
  '120208317181650474',
  '120208149213070474',
];

export const BASE_DEEPSTASH_STATIC_WEB_IMAGES_URL =
  'https://static.deepstash.com/images/web';
export const BASE_DEEPSTASH_URL = 'https://deepstash.com';

export const VLADIMIR_USER_ID = 2;

export const BEARER_PREFIX = 'Bearer ';

// Match all the query params that would trigger making the call for /iap/subscription/confirm
export const CLEAN_URL_AFTER_CONFIRM_REGEX =
  /((payment_intent)|(payment_intent_client_secret)|(subscription_id)|(setup_intent)|(price_amount)|(setup_intent_client_secret)|(free_trial_duration))=((.+?&)|(.+?$))/g;

export const FANCY_GRAPHICS_COLLECTION_IDS = [
  4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 17, 19, 32, 37, 45, 69, 70, 75,
];

// Theme colors
export const PRO_GRADIENT_BACKGROUND = {
  SHINE:
    'linear-gradient(131.79deg, rgba(255, 255, 255, 0.2) 7.43%, rgba(255, 255, 255, 0.328) 17.89%, rgba(255, 255, 255, 0.4) 24.02%, rgba(255, 255, 255, 0.330857) 29.83%, rgba(255, 255, 255, 0.312) 29.83%, rgba(255, 255, 255, 0) 56.19%), linear-gradient(39.99deg, #9B07F6 20.82%, #0085FF 79.91%);',
  SOLID: 'linear-gradient(39.99deg, #9B07F6 20.82%, #0085FF 79.91%);',
};

export const PRO_COLOR = {
  light: '#005BAF',
  dark: '#55AEFF',
};

// Google Optimize Experiments
export const experimentIds: Record<string, string> = {};

export const getExperimentUserProp = (experimentId: string) => {
  switch (experimentId) {
    default:
      return 'AB_WEB_UNKNOWN';
  }
};

export const FADING_BACKGROUND_COLOR_NSI = '#EBF1F4';

/**
 * List with all the page names that have context
 * and render well with Additional Info NSI
 */

/**
 * List of all the pages which should be rendered
 * without a header or a footer
 */
export const WHITELIST_FULLSCREEN_PAGES = ['read'];

// UI Elements

const hexDigitToDec = (hexDigit: string): number => {
  switch (hexDigit) {
    case '0':
      return 0;
    case '1':
      return 1;
    case '2':
      return 2;
    case '3':
      return 3;
    case '4':
      return 4;
    case '5':
      return 5;
    case '6':
      return 6;
    case '7':
      return 7;
    case '8':
      return 8;
    case '9':
      return 9;
    case 'a':
      return 10;
    case 'A':
      return 10;
    case 'b':
      return 11;
    case 'B':
      return 11;
    case 'c':
      return 12;
    case 'C':
      return 12;
    case 'd':
      return 13;
    case 'D':
      return 13;
    case 'e':
      return 14;
    case 'E':
      return 14;
    case 'f':
      return 15;
    case 'F':
      return 15;
    default:
      return 0;
  }
};

export const createGradient = (color?: string, angle?: number): string => {
  if (!color) return '';
  let colorString = `-webkit-linear-gradient(${angle}deg, `;
  if (color[0] === '#' && color.length === 7) {
    const red = hexDigitToDec(color[1]) * 16 + hexDigitToDec(color[2]);
    const green = hexDigitToDec(color[3]) * 16 + hexDigitToDec(color[4]);
    const blue = hexDigitToDec(color[5]) * 16 + hexDigitToDec(color[6]);
    colorString += `rgba(${red}, ${green}, ${blue}, 0)`;
  } else if (color[0].startsWith('rgba')) {
    const substrings = color.split(',');
    substrings[substrings.length - 1] = '0)';
    colorString += substrings.join(',');
  } else if (color[0].startsWith('rgb')) {
    const substrings = color.split(',');
    substrings[0] =
      substrings[0].substring(0, 4) + 'a' + substrings[0].substring(4);
    substrings[substrings.length - 1] =
      substrings[substrings.length - 1].substring(
        0,
        substrings[substrings.length - 1].length - 1,
      ) + ', 0)';
    colorString += substrings.join(',');
  }
  colorString += ` 0%, ${color} 100%)`;
  return colorString;
};

// USER DATA
export const MAX_READING_GOAL = 99;
export const STASHY_USER_ID = 1436457;

// TIMING
export const DEFAULT_READ_TIME = 4;
export const TRANSITION_TIME = 0.1;
export const TOAST_TIME = 5000;
export const EVENT_LISTENER_FAST = 100;
export const EVENT_LISTENER_AVERAGE = 250;
export const EVENT_LISTENER_SLOW = 500;
export const SEARCH_TIMEOUT = 500;

// KNOWLEDGE LIBRARY ITEM COUNTS
export const KNOW_LIB_STASH_COUNT = 30;
export const KNOW_LIB_INSIGHT_COUNT = 40;
export const KNOW_LIB_ARTICLE_COUNT = 10;
export const KNOW_LIB_TOPIC_COUNT = 30;
export const KNOW_LIB_COLLECTION_COUNT = 20;
export const KNOW_LIB_STORIES_COUNT = 15;

// CHARACTER COUNTS
export const MAX_STASH_LEN = 70;
export const LOGGED_OUT_CUTOFF_LENGTH = 300;
export const MAX_RECOMMENDED_IDEA_LENGTH = 500;
export const UNIQUE_CONTENT_THRESHOLD = 20;

// LOADING
export const SCROLL_THRESHOLD = 5;

// STYLING
export const SOURCE_COLLAPSED_LARGE_IMAGE_SIZE_DESKTOP = 160;
export const SOURCE_COLLAPSED_LARGE_IMAGE_SIZE_MOBILE = 96;

export const skeletonPropsCard = (
  colorMode: 'light' | 'dark' = 'light',
): { borderRadius: number | string; startColor: string; endColor: string } => {
  return {
    borderRadius: 30,
    startColor: color[colorMode].underground,
    endColor: color[colorMode].surface,
  };
};

export const missingImage = (colorMode: 'light' | 'dark') =>
  `https://static.deepstash.com/illustrations/missing_${colorMode}.svg`;

/**
 *
 * @param param0 colorMode and withLaptop, if the image should contain laptop
 * @returns URL to the spiral image
 */
export const spiralImage = ({
  colorMode,
  withLaptop = false,
}: {
  colorMode: 'light' | 'dark';
  withLaptop?: boolean;
}) =>
  `https://static.deepstash.com/illustrations/spiral${
    withLaptop ? '_laptop' : ''
  }${colorMode === 'dark' ? '_dark' : ''}.svg`;

export type Country = {
  name: string;
  code: string;
};

export const countries: Country[] = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea, Democratic People'S Republic of", code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People'S Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'Rwanda', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
];

export const STASH_LOAD_LIMIT = 5;

export const pressKitFacts = [
  {
    item: 'Launched: ',
    data: 'April 2019',
  },
  {
    item: 'Users: ',
    data: '3M+ worldwide',
  },
  {
    item: 'Founders: ',
    data: 'Vladimir Oane (CEO), Dan Ciotu (CTO), Cristian Mezei (CMO)',
  },
  {
    item: 'Team count: ',
    data: '30+ across Europe, Asia, and Africa',
  },
  {
    item: 'Category: ',
    data: 'Education',
  },
  {
    item: 'Headquarters: ',
    data: 'USA & Romania',
  },
  {
    item: 'Number of sources: ',
    data: 'Tens of thousands',
  },
  {
    item: 'Number of ideas: ',
    data: '200,000+',
  },
];

export const pressKitFounders = [
  {
    image: 'https://static.deepstash.com/Press_Kit_VO.jpg',
    name: 'Vladimir Oane (CEO)',
    description:
      'Vladimir Oane is the co-founder and CEO of Deepstash. He served as one of Hootsuite’s Product leads, after the acquisition of uberVU, the company he previously co-founded.',
    profile: 'https://deepstash.com/u/vladimir',
  },
  {
    image: 'https://static.deepstash.com/Press_Kit_DC.jpg',
    name: 'Dan Ciotu (CTO)',
    description:
      'Dan Ciotu is the co-founder and CTO of Deepstash. He served as CTO and co-founder at uberVU, and was responsible for building scalable processes that enabled the business to maintain its rapid growth.',
    profile: 'https://deepstash.com/u/dan',
  },
  {
    image: 'https://static.deepstash.com/Press_Kit_CM.jpg',
    name: 'Cristian Mezei (CMO)',
    description:
      'Cristian Mezei is the co-founder and CMO of Deepstash. He is an experienced entrepreneur, having founded several marketing agencies & product companies from the time he was in high-school.',
    profile: 'https://deepstash.com/u/cristianmezei',
  },
];

export const openBookColors = [
  '#0D2633',
  '#0D2633',
  '#0070AE',
  '#0070AE',
  '#0070AE',
];

export const lightBulbColors = [
  '#47104E',
  '#47104E',
  '#743C7A',
  '#743C7A',
  '#B786BB',
];

export const WARNING_COLOR = '#E59842';
export const AI_COLOR = '#E083B5';

export const curatorSteps = [
  {
    number: '01',
    text: 'After you sign up, an introductory call is scheduled to talk about the platform in detail, onboard you and answer any questions you may have.',
  },
  {
    number: '02',
    text: 'A Deepstash Content Manager will be assigned to you to give the tools and resources to curate content in the best possible way.',
  },
  {
    number: '03',
    text: (
      <span>
        Publish your work using the Deepstash app or Deepstash for the web.
        <br /> Tadaaaaa! That’s it!'
      </span>
    ),
  },
];

export const WELCOME_MESSAGE_RECORD: Record<string, string> = {
  [LanguageCode.english]: 'Hi there!',
  [LanguageCode.french]: 'Bonjour!',
  [LanguageCode.german]: 'Hallo',
  [LanguageCode.indonesian]: 'Halo',
  [LanguageCode.italian]: 'Ciao!',
  [LanguageCode.korean]: '안영하세요!',
  [LanguageCode.japanese]: 'こんにちは!',
  [LanguageCode.spanish]: '¡Hola!',
  [LanguageCode.russian]: 'Привет!',
  [LanguageCode.turkish]: 'Merhaba!',
  [LanguageCode.hindi]: 'नमस्ते',
  [LanguageCode.dutch]: 'Hallo!',
  [LanguageCode.swedish]: 'Hej!',
  [LanguageCode.malay]: 'Hai!',
  [LanguageCode.danish]: 'Hej!',
  [LanguageCode.polish]: 'Cześć!',
  [LanguageCode.hebrew]: '!שלום',
  [LanguageCode.finnish]: 'Terve!',
  [LanguageCode.portuguese]: 'Olá!',
};

export const PUBLIC_PAGES = [
  {
    id: 0,
    title: 'Topics',
    href: '/topics/1',
    page: 'topics',
    button: SideMenuButton.TOPICS,
    iconDesktop: <QualitySvg mr="0.25rem" />,
    iconMobile: <QualitySvg mr="0.5rem" boxSize="1.25rem" />,
  },
  {
    id: 1,
    title: 'About',
    href: '/about',
    page: 'about',
    button: SideMenuButton.ABOUT_US,
    iconDesktop: <StashesSvg mr="0.25rem" />,
    iconMobile: <StashesSvg mr="0.5rem" boxSize="1.25rem" />,
  },
  {
    id: 2,
    title: 'Get the free app',
    href: '/mobile-app',
    page: 'mobile-app',
    button: SideMenuButton.ABOUT_US,
    iconDesktop: <PhoneSvg mr="0.25rem" />,
    iconMobile: <PhoneSvg mr="0.5rem" boxSize="1.25rem" />,
  },
  {
    id: 3,
    title: 'Become a curator',
    href: '/curators',
    page: 'curators',
    button: SideMenuButton.CURATORS,
    iconDesktop: <DraftSvg mr="0.25rem" />,
    iconMobile: <DraftSvg mr="0.5rem" boxSize="1.25rem" />,
  },
  {
    id: 4,
    title: 'Jobs',
    href: '/jobs',
    page: 'jobs',
    button: SideMenuButton.JOBS,
    iconDesktop: <PersonAddSvg mr="0.25rem" />,
    iconMobile: <PersonAddSvg mr="0.5rem" boxSize="1.25rem" />,
  },
  {
    id: 5,
    title: 'Press Kit',
    href: '/press-kit',
    page: 'press-kit',
    button: SideMenuButton.PRESS_KIT,
    iconDesktop: <BookSvg mr="0.25rem" />,
    iconMobile: <BookSvg mr="0.5rem" boxSize="1.25rem" />,
  },

  {
    id: 6,
    title: 'Terms of Service',
    href: '/terms-of-service',
    page: 'terms-of-service',
    button: SideMenuButton.TERMS_OF_SERVICE,
    iconDesktop: <ArticleSvg mr="0.25rem" />,
    iconMobile: <ArticleSvg mr="0.5rem" boxSize="1.25rem" />,
  },
  {
    id: 7,
    title: 'Privacy Policy',
    href: '/privacy-policy',
    page: 'privacy-policy',
    button: SideMenuButton.PRIVACY_POLICY,
    iconDesktop: <MailSvg mr="0.25rem" />,
    iconMobile: <MailSvg mr="0.5rem" boxSize="1.25rem" />,
  },
  {
    id: 8,
    title: 'Sitemap',
    href: '/sitemap.xml',
    page: 'sitemap.xml',
    button: SideMenuButton.PRIVACY_POLICY,
    iconDesktop: <RandomSvg mr="0.25rem" />,
    iconMobile: <RandomSvg mr="0.5rem" boxSize="1.25rem" />,
  },
];

export const CONTENT_LENGTH_LIMIT = 600;
export const SOURCE_CONTEXT_LIMIT = 600;
export const TITLE_LENGTH_LIMIT = 128;
export const AUTHOR_LENGTH_LIMIT = 64;

export const MEDIA_APPEARANCES_LIST = [
  {
    date: {
      day: '1 Apr',
      year: '2022',
    },
    publication: 'MakeUseOf',
    title: 'The Top 7 Apps that Make You Smarter Every Day',
    url: 'https://www.makeuseof.com/top-apps-make-you-smarter/',
  },
  {
    date: {
      day: '10 Jun',
      year: '2022',
    },
    publication: 'Ness Labs',
    title: 'Get smarter everyday with Vladimir Oane, founder of Deepstash',
    url: 'https://nesslabs.com/deepstash-featured-tool',
  },
  {
    date: {
      day: '20 Mar',
      year: '2022',
    },
    publication: 'The Recursive',
    title:
      'How is AI transforming industries: A map with 10+ verticals, 80+ startups in SEE',
    url: 'https://therecursive.com/ai-transforming-industries-ai-applications-10-verticals-see/',
  },
  {
    date: {
      day: '15 Dec',
      year: '2021',
    },
    publication: 'Product Hunt',
    title: 'Bookstash, #1 Product of the Day',
    url: 'https://www.producthunt.com/posts/bookstash',
  },
  {
    date: {
      day: '5 Aug',
      year: '2021',
    },
    publication: 'Authority Magazine',
    title:
      'Vladimir Oane of Deepstash On How Their Technology Will Make An Important Positive Impact',
    url: 'https://medium.com/authority-magazine/social-impact-tech-vladimir-oane-of-deepstash-on-how-their-technology-will-make-an-important-ae4ef74f429',
  },
  {
    date: {
      day: '9 Jun',
      year: '2021',
    },
    publication: 'The Recursive',
    title:
      'Vladimir Oane of Deepstash on how to turn infinite content into knowledge',
    url: 'https://therecursive.com/vladimir-oane-of-deepstash-on-how-to-turn-infinite-content-into-knowledge/',
  },
  {
    date: {
      day: '5 Jun',
      year: '2021',
    },
    publication: 'Silicon Canals',
    title: 'The best entrepreneurs are generalists, not experts',
    url: 'https://siliconcanals.com/news/startups/the-best-entrepreneurs-are-generalists-not-experts/',
  },
  {
    date: {
      day: '20 May',
      year: '2021',
    },
    publication: 'The Cambridge Student',
    title: 'Interview with Vladimir Oane',
    url: 'https://www.tcs.cam.ac.uk/interview-vladimir-oane-co-founder-of-deepstash/',
  },
  {
    date: {
      day: '10 Dec',
      year: '2020',
    },
    publication: 'Trending Topics',
    title:
      'Deepstash, The Romanian Startup That Aims To Help People Deal With Information Overload, Announces A €3m Seed Round',
    url: 'https://www.trendingtopics.eu/deepstash-the-romanian-startup-that-aims-to-help-people-deal-with-information-overload-announces-a-e3m-seed-round/',
  },
  {
    date: {
      day: '3 Dec',
      year: '2020',
    },
    publication: 'Sifted',
    title: 'Antidote to social media overload?',
    url: 'https://sifted.eu/articles/deepstash-3m-socialmedia-twitter/',
  },
  {
    date: {
      day: '26 Oct',
      year: '2020',
    },
    publication: 'Silicon Canals',
    title:
      'These 9 startups will join Google for Startups Accelerator: Europe’s program to learn from the best',
    url: 'https://siliconcanals.com/news/startups/google-startups-accelerator-europe/',
  },
  {
    date: {
      day: '14 May',
      year: '2020',
    },
    publication: 'The Org',
    title: 'The Top 10 Startup Teams in Romania',
    url: 'https://theorg.com/insights/the-top-10-startup-teams-in-romania',
  },
  {
    date: {
      day: '24 Aug',
      year: '2019',
    },
    publication: 'The Economic Times',
    title:
      'A curated platform of ideas on topics like health & time management',
    url: 'https://economictimes.indiatimes.com/magazines/panache/deepstash-review-a-curated-platform-of-ideas-on-topics-like-health-time-management/articleshow/70813957.cms',
  },
  {
    date: {
      day: '15 Aug',
      year: '2019',
    },
    publication: 'The Irish Times',
    title: 'Deepstash: Self-improvement app for the time poor',
    url: 'https://www.irishtimes.com/business/technology/deepstash-self-improvement-app-for-the-time-poor-1.3985221',
  },
  {
    date: {
      day: '12 Aug',
      year: '2019',
    },
    publication: 'Alabama News Network',
    title: 'What the Tech? App of the Day: Deepstash',
    url: 'https://www.alabamanews.net/2019/08/12/what-the-tech-app-of-the-day-deepstash/',
  },
];

/**
 * Default avatar for users who didn't upload the profile photo
 * */
export const DEFAULT_AVATARS: string[] = [
  'https://static.deepstash.com/profile/1.png',
  'https://static.deepstash.com/profile/2.png',
  'https://static.deepstash.com/profile/3.png',
  'https://static.deepstash.com/profile/4.png',
  'https://static.deepstash.com/profile/5.png',
  'https://static.deepstash.com/profile/6.png',
];

/**
 * Chip component related constants
 */
export const HASHTAG_SVG_HEIGHT = 20;
export const CHIP_PADDING_Y = 8;
export const CHIP_MARGIN_BOTTOM = 8;
export const CHIP_HEIGHT =
  HASHTAG_SVG_HEIGHT + CHIP_PADDING_Y * 2 + CHIP_MARGIN_BOTTOM;

/**
 * Setup steps
 */
export const JTBD_STEPS: Record<JtbdSetupStepKey, JtbdSetupStep> = {
  welcome: {
    current: 'welcome',
    next: 'goals',
    index: 1,
  },
  goals: {
    current: 'goals',
    next: 'improvements',
    index: 2,
  },
  improvements: {
    current: 'improvements',
    next: 'hashtags',
    index: 3,
  },
  hashtags: {
    current: 'hashtags',
    next: 'loading',
    index: 4,
  },
  loading: {
    current: 'loading',
    next: 'loading-done',
    index: 5,
  },
  'loading-done': {
    current: 'loading-done',
    next: 'paywall',
    index: 6,
  },
  paywall: {
    current: 'paywall',
    next: 'ready',
    index: 7,
  },
  ready: {
    current: 'ready',
    next: 'welcome',
    index: 8,
  },
  readyPro: {
    current: 'readyPro',
    next: 'welcome',
    index: 8,
  },
};

export const NSI_BACKGROUND_COLORS = {
  orange: '#FF7B02',
  red: '#D44C3A',
  blue: '#202F9E',
  green: '#0A8976',
  backgroundColorPage1: '#EBF1F4',
  backgroundColorPage2: '#E9EBE9',
  backgroundColorPage3: '#E5F6F6',
  backgroundColorPage4: '#EBF1F4',
  backgroundColorPage5: 'white',
  backgroundColorPage6: '#EFF5F8',
};

/**
 * Default blurDataUrl for next/image, it's a grey placeholder
 */
export const BLUR_HASH_DEFAULT = 'L7I}-Q00IURj~qxuRjfQWBofj[ay';

export const COOKIE_ANALYTICS_TOAST_ENABLED = 'COOKIE_ANALYTICS_TOAST_ENABLED';

/**
 * use for router.pathname
 * pathname for genericTag page
 */
export const GENERIC_TAG_PATHNAME_PAGE = '/[genericTag]';

export const ALL_REACTIONS: Record<number, Reaction> = {
  1: {
    id: 1,
    name: 'Inspiring',
    url: '/assets/reactions/inspiring.png',
  },
  2: {
    id: 2,
    name: 'Love',
    url: '/assets/reactions/love.png',
  },
  3: {
    id: 3,
    name: 'Mindblowing',
    url: '/assets/reactions/mindblowing.png',
  },
  4: {
    id: 4,
    name: 'Deep',
    url: '/assets/reactions/deep.png',
  },
  5: {
    id: 5,
    name: 'Useful',
    url: '/assets/reactions/useful.png',
  },
};

// For the image modal inside the idea editor
export const ERROR_ADDRESS = 'http://error';

// For Stripe
export const MOCKED_STRIPE_PRICES_RESPONSE: StripePricesApiResponse = {
  publishable_key:
    'pk_live_51CP5wiBnN2mMSWQdNkEZMZhGhppZO1nehyrrq3neRkax4aOvd7DzYiUn44FgNLEPlF8lAsIaArbABNDkEJKjVYhp00nNLXO7pR',
  subscriptions: [
    {
      normalized_monthly_price: 12.99,
      normalized_yearly_price: 155.88,
      price_amount: 12.99,
      price_currency: 'usd',
      price_id: 'price_1KbfBoBnN2mMSWQdrsVgYrlr',
      sku: 'com.deepstash.pro_monthly_subscription',
      subscription_period_number: 1,
      subscription_period_unit: 'MONTH',
      yearly_baseline_price: 155.88,
    },
    {
      normalized_monthly_price: 7.5,
      normalized_yearly_price: 89.99,
      price_amount: 89.99,
      price_currency: 'usd',
      price_id: 'price_1KbfBoBnN2mMSWQdey3ovSpq',
      sku: 'com.deepstash.pro_yearly_subscription',
      subscription_period_number: 1,
      subscription_period_unit: 'YEAR',
      yearly_savings_percentage: 42,
      yearly_baseline_price: 155.88,
    },
  ],
};

// The default cookie expiration date is half a year
export const DEFAULT_COOKIE_EXPIRATION_DATE = 182;

// The number of miliseconds in a day
export const MS_IN_A_DAY = 86400000;

export const NUM_DAYS_IN_A_MONTH = 31;

// Pro benefits
export const PRO_BENEFITS_LIST: ProBenefit[] = [
  {
    renderIcon: props => <LibrarySvg {...props} />,
    name: commonStrings.benefits[6],
    accessLevel: 'si',
  },
  {
    renderIcon: props => <LibrarySvg {...props} />,
    name: commonStrings.benefits[7],
    accessLevel: 'si',
  },
  {
    renderIcon: props => <LibrarySvg {...props} />,
    name: commonStrings.benefits[0],
    accessLevel: 'si',
  },
  {
    renderIcon: props => <PhoneSvg {...props} />,
    name: commonStrings.benefits[1],
    accessLevel: 'si',
  },
  {
    renderIcon: props => <StashedSvg {...props} />,
    name: commonStrings.benefits[2],
    accessLevel: 'pro',
  },
  {
    renderIcon: props => <HistorySvg {...props} />,
    name: commonStrings.benefits[3],
    accessLevel: 'pro',
  },
  {
    renderIcon: props => <HeadphonesSvg {...props} />,
    name: commonStrings.benefits[4],
    accessLevel: 'pro',
  },
  {
    renderIcon: props => <DownloadSvg {...props} />,
    name: commonStrings.benefits[5],
    accessLevel: 'pro',
  },
  // {
  //   renderIcon: props => <BookSvg {...props} />,
  //   name: commonStrings.benefits[6],
  //   accessLevel: 'pro',
  // },
];
