import * as Sentry from '@sentry/nextjs';
import { Severity } from '@sentry/nextjs';
import { CurrentProfileApiResponse } from 'api/api.types';
import { useInviteProcedureCallback } from 'hooks/auth';
import useStripePrices from 'hooks/purchases/useStripePrices';
import usePrevious from 'hooks/usePrevious';
import jsCookie from 'js-cookie';
import React, {
  createContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { Analytics, Events } from 'src/services/analytics';
import { UserProfile } from 'types/models';
import { getObjectDiff } from 'utils/global';
import { setSentryUserContext } from 'utils/log-utils';
import useAuth from '../hooks/useAuth';
import * as asyncActions from './ProfileActions';
import {
  ProfileContextType,
  ProfileProviderReducer,
  ProfileProviderStateType,
} from './ProfileProviderTypes';
import { createProfileReducer } from './ProfileReducer';

export const INITIAL_STATE: ProfileProviderStateType = {
  profile: undefined,
  errorOccurred: false,
};

export const ProfileContext = createContext<ProfileContextType>({
  dispatch: () => {
    return;
  },
  state: INITIAL_STATE,
});

ProfileContext.displayName = 'ProfileContext';
// [amplitude]
// let amplitude: any;
// if (typeof window !== 'undefined') {
//   amplitude = require('amplitude-js');
// }

interface ProfileProviderProps {
  profile?: UserProfile;
  /**
   * A callback that notifies the GlobalProvider that the profile was revalidated and thus the activity state should be updated
   */
  notifyProfileRevalidation: (profile?: CurrentProfileApiResponse) => void;
}

const ProfileProvider: React.FC<ProfileProviderProps> = ({
  profile,
  notifyProfileRevalidation,
  children,
}) => {
  const initialState: ProfileProviderStateType = useMemo(
    () => ({
      profile,
      requestStatus: 'success',
    }),
    [profile],
  );

  const reducer = useMemo(
    () => createProfileReducer(notifyProfileRevalidation),
    [notifyProfileRevalidation],
  );

  const [state, dispatch] = useReducer<ProfileProviderReducer>(
    reducer,
    initialState,
  );

  const prevProfile = usePrevious(state.profile);

  const { isLoggedIn, authDispatch } = useAuth();
  const inviteProcedure = useInviteProcedureCallback();

  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch],
  );

  //Revalidate profile automatically
  const autoRevalidateTimeout = useRef<
    ReturnType<typeof setTimeout> | undefined
  >(undefined);

  useStripePrices({});

  useEffect(() => {
    const refresh = () => {
      asyncActions.revalidateProfile(dispatch, {
        onSuccess: () => {
          return;
        },
      });
    };
    window.addEventListener('focus', refresh);
    autoRevalidateTimeout.current = setTimeout(refresh, 30 * 60 * 1000);
    return () => {
      if (autoRevalidateTimeout.current) {
        clearTimeout(autoRevalidateTimeout.current);
      }
      window.removeEventListener('focus', refresh);
    };
  }, []);

  useEffect(() => {
    if (
      state.errorOccurred === true &&
      isLoggedIn &&
      !jsCookie.get('refresh_token')
    ) {
      //Invalid token
      authDispatch({
        type: 'logout',
      });
    }
  }, [state.errorOccurred]);

  useEffect(() => {
    if (!isLoggedIn && state.profile) {
      dispatch({
        type: 'clear',
      });
      Analytics.logEvent({
        eventName: Events.configuration.logout,
        properties: {
          email: profile?.email,
        },
        platforms: ['amplitude'],
      });
    } else if (isLoggedIn && !state.profile) {
      //The user logged in
      //Get the profile

      const onSuccess = (profile: CurrentProfileApiResponse) => {
        inviteProcedure(profile);
      };

      asyncActions.revalidateProfile(dispatch, { onSuccess });
    }
  }, [isLoggedIn]);

  // Identify user for SDKs
  useEffect(() => {
    if (isLoggedIn) {
      Sentry.setUser({
        email: profile?.email,
        username: profile?.username,
        id: (profile?.id ?? -1).toString(),
      });
    } else {
      Sentry.configureScope(scope => scope.setUser(null));
    }
  }, [isLoggedIn]);

  useEffect(() => {
    // The profile got updated, so update the sentry context
    setSentryUserContext(state.profile ?? null);

    //Log the differences
    if (!prevProfile || !state.profile) {
      Sentry.addBreadcrumb({
        data: state.profile,
        category: 'profile',
        level: Severity.Log,
        message: 'Initial profile',
      });
      return;
    }
    const diff = getObjectDiff(state.profile, prevProfile);
    Sentry.addBreadcrumb({
      data: diff,
      category: 'source',
      level: Severity.Log,
      message: 'Profile updated',
    });
  }, [state.profile]);

  return (
    <ProfileContext.Provider value={contextValue}>
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
