import {
  Box,
  color,
  SearchSvg,
  spacing,
  StyleProps,
  TextInput,
  useColorMode,
  useIsMobileView,
  useToast,
} from 'deepstash-ui';
import useRouter from 'hooks/useRouter';
import { SearchTypeQueryParam } from 'pages/search';
import React, { useRef, useState } from 'react';
import { Analytics, Events } from 'src/services/analytics';
import { TOAST_TIME } from 'utils/constants';

interface SearchInputProps extends StyleProps {
  textInputHeight?: number;
  searchInputStyle?: StyleProps;
  placeholder?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  textInputHeight,
  searchInputStyle,
  placeholder,
  ...props
}) => {
  const { colorMode } = useColorMode();
  const router = useRouter();
  const [searchText, setSearchText] = useState(
    router.asPath.includes('search') && router.query.tab && router.query.q
      ? `${router.query.q}`
      : '',
  );

  const isMobileView = useIsMobileView();

  const toast = useToast();

  const searchBarRef = useRef<HTMLDivElement>(null);

  const triggerSearch = (query: string, tab: SearchTypeQueryParam) => {
    if (query.trim().length < 3) {
      Analytics.logEvent({
        eventName: Events.consumption.search,
        properties: {
          error: 'too-short',
          text: query,
          where: (router.query.owner as string)
            ? (router.query.owner as string) + '-ideas'
            : 'all-ideas',
          type: tab,
        },
        platforms: ['amplitude'],
      });
      toast({
        id: 'keyword-short',
        title: 'Keyword is Too Short!',
        description: 'Please search for something at least 3 letters long.',
        status: 'warning',
        duration: TOAST_TIME,
        isClosable: true,
      });
    } else {
      Analytics.logEvent({
        eventName: Events.consumption.search,
        properties: {
          text: query,
          where: (router.query.owner as string)
            ? (router.query.owner as string) + '-ideas'
            : 'all-ideas',
          type: tab,
        },
        platforms: ['amplitude'],
      });
      router.push({
        pathname: '/search',
        query: {
          q: query,
          tab,
          owner: 'all',
        },
      });
    }
  };

  return (
    <Box ref={searchBarRef} position="relative" {...props}>
      <TextInput
        placeholder={
          placeholder ??
          (isMobileView
            ? 'Search Ideas'
            : 'Search for ideas, articles or curators...')
        }
        rounded="full"
        leftIcon={
          <SearchSvg
            color={`${color[colorMode].text}99`}
            ml={spacing.toRem(6)}
            pos="relative"
            bottom={textInputHeight ? spacing.toRem(2) : 'unset'}
          />
        }
        border={0}
        backgroundColor={`${color[colorMode].top}66`}
        color={`${color[colorMode].text}99`}
        value={searchText}
        onChange={setSearchText}
        transition="all 0.2s ease-out"
        onKeyUp={ev => {
          if (ev.key !== 'Enter') return;
          triggerSearch(
            searchText,
            (router.query.tab as SearchTypeQueryParam) ?? 'ideas',
          );
        }}
        h={spacing.toRem(textInputHeight ? textInputHeight : 48)}
        w="100%"
        boxSizing="border-box"
        paddingLeft={spacing.toRem(44)}
        _placeholder={{
          fontSize: '1rem',
          fontWeight: 400,
          color: `${color[colorMode].text}99`,
        }}
        _hover={{
          shadow: 'none',
          border: 0,
        }}
        _focus={{
          shadow: 'none',
          border: 0,
          backgroundColor: color[colorMode].top,
        }}
        {...searchInputStyle}
      />
      {/* May be reimplemented upon improving the search algorithm
      {isOpen && searchText.length > 0 && (
        <SearchDropdown
          searchText={searchText}
          w={{ base: '100%', md: '75%' }}
          ml={{ base: 0, md: '48px' }}
          top="44px"
          isOpen={isOpen}
          onClose={onClose}
          triggerSearch={triggerSearch}
          searchBarRef={searchBarRef}
        />
      )} */}
    </Box>
  );
};

export default SearchInput;
