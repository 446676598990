import {
  Box,
  BoxProps,
  color,
  Flex,
  Img,
  Spinner,
  Text,
  useColorMode,
  useDisclosure,
  useIsMobileView,
} from 'deepstash-ui';
import { useNextImageConfiguredDomain } from 'hooks/image';
import Image, { ImageProps } from 'next/image';
import React, { useEffect, useState } from 'react';
import { Blurhash } from 'react-blurhash';
import ImageModal from 'src/components/image/ImageModal';
import { RequestStatus } from 'types/types';
import { BLUR_HASH_DEFAULT } from 'utils/constants';
import DEFAULT_IMAGE from '../../../public/default.png';

export interface NextImageProps {
  /**
   * Styling how the Image should look like, eg: width, height, borderRadius
   */
  wrapperStyle?: BoxProps;
  /**
   * The url of image
   *
   * If `undefined` the default error image is showed
   */
  imageUrl?: string;
  /**
   * The text describing the image
   */
  alt?: string;
  /**
   * When true, the image will be considered high priority and preload.
   *
   * Should only be used when the image is visible above the fold.
   */
  priority?: boolean;
  /**
   * Placeholder type for next/image
   *
   * if `blur` is set then blurDataUrl must be completed
   */
  placeholder?: 'blur' | 'empty';
  /**
   * A Data URL to be used as a placeholder image
   * before the src image successfully loads.
   *
   * Only takes effect when combined with placeholder="blur".
   *
   * Must be a base64-encoded image.
   */
  blurDataUrl?: string;
  /**
   * The hash for blur canvas
   *
   * Using react-blurhash instead of next/image blur because the blurhash isn't supported yet
   *
   * Will be used for loading when `placeholder !== 'blur'`
   */
  blurhash?: string;
  /**
   * When not undefined, the image modal is showed on clicking the image
   * If value is rounded, the modal will be rounded
   */
  imageModal?: 'rect' | 'rounded';
  /**
   * Full image modal size for when opening the modal
   */
  imageModalSize?: { width?: string; height?: string };
  /**
   * When clicking on image
   */
  onClick?: () => void;
  /**
   * Called if an error ocurred while loading the image
   */
  onError?: () => void;
  /**
   * Default image if image prop is not loaded
   */
  defaultImage?: any;
  /**
   * Whether to have a static image that is not interactable
   */
  isNotInteractable?: boolean;
  /**
   * Whether to have a class assigned to the Next image.
   *
   * Used in particular for fixing border-radius on Safari
   * when using drop-shadow filters on idea cards.
   */
  imageClassName?: string;
  /**
   * Whether to show a spinner while loading the image.
   * @default false
   */
  showLoadingSpinner?: boolean;
  /**
   * Whether to show an error message on load.
   * @default false
   */
  showErrorMessage?: boolean;
  /**
   * Override the object fit property on the image directly
   */
  objectFit?: 'contain' | 'cover' | 'fill' | 'unset';
  /**
   * Override the object position on the image
   */
  objectPosition?: string | number | undefined;
  /**
   * Next.JS image option
   */
  nextImageProps?: Partial<ImageProps>;
  isBase64?: boolean;
}

const BASE64_REGEX = /(data:image\/[^;]+;base64[^"]+)/;

const NextImage: React.FC<NextImageProps> = ({
  wrapperStyle,
  imageUrl,
  alt,
  priority,
  placeholder,
  blurDataUrl,
  blurhash,
  imageModal,
  imageModalSize,
  onClick,
  onError,
  defaultImage = DEFAULT_IMAGE,
  isNotInteractable,
  imageClassName,
  showLoadingSpinner,
  showErrorMessage,
  objectFit,
  objectPosition,
  nextImageProps,
}) => {
  /**
   * Set as success directly for pre-fetched images in order to avoid re-renders
   */
  const [imageRequestStatus, setImageRequestStatus] = useState<RequestStatus>(
    priority ? 'success' : 'loading',
  );

  const isMobileView = useIsMobileView();
  const { colorMode } = useColorMode();

  useEffect(() => {
    //Reset the status since the image url changes
    setImageRequestStatus(priority ? 'success' : 'loading');
  }, [imageUrl]);

  const imageSrc = useNextImageConfiguredDomain(imageUrl ?? '');

  const onImageLoadError = () => {
    setImageRequestStatus('failure');
    onError?.();
  };

  const {
    isOpen: isImageModalOpen,
    onOpen: onOpenImageModal,
    onClose: onCloseImageModal,
  } = useDisclosure();

  const onWrapperClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    // disabled the next line so pressing the Yourself button inside the Empty Draft works
    // event.stopPropagation();

    if (imageModal && !isNotInteractable) {
      event.stopPropagation();
      onOpenImageModal();
    }
    onClick?.();
  };

  const onImageLoadingComplete = (result: {
    naturalWidth: number;
    naturalHeight: number;
  }) => {
    nextImageProps?.onLoadingComplete?.(result);
    if (imageRequestStatus !== 'success') {
      setImageRequestStatus('success');
    }
  };

  const getErrorMessage = () => {
    return 'There has been an error loading this image.';
  };

  return (
    <Box
      cursor={isNotInteractable ? 'unset' : 'pointer'}
      onClick={onWrapperClick}
      position="relative"
      overflow="hidden"
      {...wrapperStyle}
    >
      {imageRequestStatus === 'failure' && showErrorMessage ? (
        <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
          <Text color={color[colorMode].text}>{getErrorMessage()}</Text>
        </Flex>
      ) : imageSrc.match(BASE64_REGEX) ? (
        //This is DS-UI image component. Supports base64
        <Img
          src={
            imageRequestStatus === 'failure' ||
            imageUrl === undefined ||
            imageSrc === ''
              ? defaultImage
              : imageSrc
          }
          // For SEO & accessibility purposes,
          // our images need to have descriptive alt tags.
          alt={alt}
          width="100%"
          height="100%"
          objectFit={objectFit ?? 'cover'}
          objectPosition={objectPosition}
          onError={onImageLoadError}
          //If the image errored out we don't want to do anything when the placeholder finishes loading
          onLoadingComplete={
            imageRequestStatus === 'failure'
              ? undefined
              : onImageLoadingComplete
          }
        />
      ) : (
        //This is Next/Image
        <Image
          src={
            imageRequestStatus === 'failure' ||
            imageUrl === undefined ||
            imageSrc === ''
              ? defaultImage
              : imageSrc
          }
          // For SEO & accessibility purposes,
          // our images need to have descriptive alt tags.
          alt={alt}
          layout="fill"
          objectFit={objectFit ?? 'cover'}
          objectPosition={objectPosition}
          priority={priority}
          onError={onImageLoadError}
          // Safari disables the border radius on hover when using drop-shadow for idea cards.
          // We need to enable it like this.
          className={imageClassName}
          placeholder={placeholder}
          blurDataURL={blurDataUrl ?? BLUR_HASH_DEFAULT} // the default value won't show nothing, just to avoid errors
          {...nextImageProps}
          //If the image errored out we don't want to do anything when the placeholder finishes loading
          onLoadingComplete={result =>
            imageRequestStatus === 'failure'
              ? undefined
              : onImageLoadingComplete(result)
          }
        />
      )}
      {placeholder !== 'blur' &&
        imageRequestStatus === 'loading' &&
        (showLoadingSpinner ? (
          <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
            <Spinner />
          </Flex>
        ) : (
          <Blurhash
            hash={blurhash ?? BLUR_HASH_DEFAULT}
            width={'100%'}
            height={'100%'}
            resolutionX={32}
            resolutionY={32}
            punch={1}
          />
        ))}
      {imageModal && !isMobileView && (
        <ImageModal
          onClose={onCloseImageModal}
          isOpen={isImageModalOpen}
          image={imageRequestStatus === 'failure' ? undefined : imageSrc}
          imageBlurhash={blurhash}
          defaultImage={defaultImage}
          width={imageModalSize?.width}
          rounded={imageModal === 'rounded'}
        />
      )}
    </Box>
  );
};
export default React.memo(NextImage);
