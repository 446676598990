const createHmac = require('create-hmac');

/**
 * Converts an image url to the base 64 string
 * @param imageUrl
 */
const stringToBase64 = (imageUrl: string) => {
  return Buffer.from(imageUrl)
    .toString('base64')
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
};

/**
 * Creates a buffer from hex
 * @param hex
 */
const bufferFromHex = (hex: string) => Buffer.from(hex, 'hex');

/**
 *
 * @param salt constant IMGPROXY_SALT
 * @param target the custom path generated for proxy image, eg: /resize:fill:100:100
 * @param secret constant IMGPROXY_KEY
 */
const signProxyImage = (salt: string, target: string, secret: string) => {
  const hmac = createHmac('sha256', bufferFromHex(secret));
  hmac.update(bufferFromHex(salt));
  hmac.update(target);
  return stringToBase64(hmac.digest());
};

/**
 * Converts an http url to https
 * @param url
 */
export const httpToHttps = (url?: string) => {
  if (!url) return '';
  let newUrl = url;
  if (url.startsWith('http://')) {
    newUrl = url.replace('http://', 'https://');
  }
  return newUrl;
};

export const addHttpsPrefix = (url?: string) => {
  if (!url) return '';
  const isHttpFormat =
    url?.indexOf('http://') === 0 || url?.indexOf('https://') === 0;
  return isHttpFormat ? url : `https://${url}`;
};

/**
 * Compress & move the image to the configured domain cloudfront
 * @param url the url of image
 * @param width compress the width
 * @param height compress the height
 */
export const generateProxyImage = (url: string) => {
  try {
    if (!url) return '';

    /**
     * Base64 encoded image
     *
     * Don't generate proxy for Base64 images
     */
    if (url.startsWith('data:image/')) {
      return url;
    }

    // Keep the image under 1MB
    const path =
      `/resize:fill:0:0/gravity:ce/mb:1048576/` + stringToBase64(url);

    return httpToHttps(
      'https://d3t70bohx4vuj7.cloudfront.net/' +
        signProxyImage(
          process.env.IMGPROXY_SALT ?? '',
          path,
          process.env.IMGPROXY_KEY ?? '',
        ) +
        path,
    );
  } catch {
    return '';
  }
};
